<div class="cancel-modal-overlay">
    <div class="cancel-modal">
        <div class="cancel-modal-header">
            <div class="cancel-modal-title">{{isRequestSubmitted?(requestDecisionTypeId==RequestDecisionTypes.Approved?"Request for cancellation granted":"Request for cancellation received"):(isSingleNotice ? "Request a cancellation for Notice" : "Request a cancellation for multiple Notice(s)")}}
                <span class="error">{{errorMessageHeader}}</span>
            </div>
            <div class="close-icon" (click)="onCloseClick()">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body" *ngIf="!isRequestSubmitted">
            <label for="cancellationReasonType" class="bold-text">Select a reason for cancellation: <span class="label-hint">(Required)</span></label>
            <select class="form-select" [appSelectValidator]="true" #cancellationReasonTypeControl="ngModel"  id="cancellationReasonType" name="cancellationReasonType" [(ngModel)]="noticeCancellationRequest.noticeCancellationReasonTypeId" [ngClass]="{'is-invalid':(cancellationReasonTypeControl.touched || isSubmitClicked) && cancellationReasonTypeControl.errors?.defaultSelected}">
                <option [ngValue]="null">Select a reason</option>
                <option *ngFor="let noticeCancellationReasonType of noticeCancellationReasonTypes" [value]="noticeCancellationReasonType.id">{{noticeCancellationReasonType.name}}</option>
            </select>
            <div class="invalid-feedback" [ngClass]="{ 'show': (cancellationReasonTypeControl.touched || isSubmitClicked) && cancellationReasonTypeControl.errors?.defaultSelected }">
                <div>Reason of cancellation is required</div>
            </div>
            <label for="additionalNotes" class="bold-text mt-2">Additional Notes about this cancellation request: <span class="label-hint">(Required)</span></label>
            <textarea id="cancellationReasonTextarea" name="additionalNotes"
                class="cancel-reason-textarea" 
                [(ngModel)]="noticeCancellationRequest.noticeCancellationReasonNotes"
                #cancellationReasonModel="ngModel"                
                (input)="onCancelTextareaInput($event)"
                [ngClass]="{'alert-border': isSubmitClicked && cancellationReasonModel.invalid }"
                ></textarea>
            <span class="max-characters" [ngClass]="{ 'text-danger': remainingCharacters < 0 }">Max characters: {{remainingCharacters}}</span>
            <div class="invalid-feedback"
                [ngClass]="{ 'show': cancellationReasonModel.invalid && isSubmitClicked }">
                <div>Additional notes are required.</div>
            </div>  

            <div class="documents-section" *ngIf="isContraventionCancelled">
                <div class="documents-section-header">Uploaded Documents</div>
                <div class="d-flex mt-3 mb-3 flex-column">        
                    <table class="table">
                        <thead>
                            <tr>                                                
                                <th>Filename</th>
                                <th>Type</th>
                                <th>Date Added</th>
                                <th>Format</th>
                                <th>File Size</th>                                                                       
                            </tr>
                        </thead>                                    
                        <tbody *ngIf="documents?.length > 0">                    
                            <tr *ngFor="let document of documents">                                
                                <td>   
                                    <div class="d-flex align-items-center">
                                        <img class="download-file-icon" src="assets/images/download-file.png" />
                                        <a (click)="onDocumentNameClick(document)" class="download-file-link">{{document.documentName}}</a>
                                    </div>                                                       
                                </td>
                                <td style="padding-bottom: 8px !important;">{{getDocumentType(document.documentTypeId)}}</td>
                                <td style="padding-bottom: 8px !important;">{{document.uploadedDate | date: "mediumDate"}}</td>
                                <td class="document-format"  style="padding-bottom: 8px !important;">{{document.documentExtension}}</td>
                                <td  style="padding-bottom: 8px !important;">{{document.documentSize}}</td>                                
                            </tr>                      
                        </tbody>                               
                    </table>                    
                </div>
            </div>

            <div class="documents-section" *ngIf="!isContraventionCancelled">
                <div class="documents-section-header">Documents</div>
                <div class="document-container">
                    <div id="requiredDocumentContainer" class="d-flex justify-content-between required-document-container">
                        <div class="document-type">
                            Cancellation Request</div>
                        <div class="d-flex flex-column justify-content-around align-items-end">
                            <button class="upload-button" 
                                (click)="onUploadRequiredDocumentClick(requiredDocumentUpload)">Upload</button>
                            <input type="file" #requiredDocumentUpload (change)="onRequiredDocumentChange($event)" />
                            <div class="document-upload-hint">Allowed: PDF, maximum file size is 5 MB</div>
                            <div *ngIf="requiredDocumentUploadError?.length > 0" 
                                class="document-upload-error-container">
                                <div>Upload error:</div>
                                <div class="document-upload-error">{{requiredDocumentUploadError}}</div>
                            </div>
                        </div>
                    </div>
                    <div id="requiredDocumentUploadContainer" class="upload-container row">
                        <div class="col-12 col-lg-6">
                            <div class="document-type pt-0">Cancellation Request</div>
                            <a class="document-name" (click)="onTempDocumentNameClick(requiredDocument)">{{requiredDocumentName}}</a>                            
                        </div>
                        <div class="upload-bar col-12 col-lg-4">
                            <div id="requiredDocumentUploadProgress" class="upload-bar-progress"></div>              
                        </div>
                        <a id="requiredDocumentUploadRemoveFileLink" 
                            class="remove-file-link disabled col-12 col-lg-2" 
                            (click)="onRemoveFileLinkClick(requiredDocument)">Remove File</a>
                    </div>
                    <div id="additionalDocumentUploadContainer" class="upload-container row">
                        <div class="col-12 col-lg-6">
                            <div class="document-type pt-0">Additional Cancellation Document</div>
                            <a class="document-name" (click)="onTempDocumentNameClick(additionalDocument)">{{additionalDocumentName}}</a>
                        </div>
                        <div class="upload-bar col-12 col-lg-4">
                            <div id="additionalDocumentUploadProgress" class="upload-bar-progress"></div>              
                        </div>
                        <a id="additionalDocumentUploadRemoveFileLink" 
                            class="remove-file-link disabled col-12 col-lg-2" (click)="onRemoveFileLinkClick(additionalDocument)">Remove File</a>
                    </div>
                </div>
                <button class="additional-document-button"
                    (click)="onUploadAdditionalDocumentClick(additionalDocumentUpload)">Additional Document</button>
                <input type="file" #additionalDocumentUpload (change)="onAdditionalDocumentUploadChange($event)" />
                <div class="document-upload-hint">Allowed: Maximum file size is 5 MB</div>
                <div *ngIf="additionalDocumentUploadError?.length > 0" 
                    class="document-upload-error-container">
                    <div>Upload error:</div>
                    <div class="document-upload-error">{{additionalDocumentUploadError}}</div>
                </div>
            </div>                  
        </div>
        <div class="cancel-modal-body" *ngIf="isRequestSubmitted && requestDecisionTypeId!=RequestDecisionTypes.Approved">
            <label class="bold-text">Your request to cancel {{isSingleNotice?"the Notice":"multiple notices"}} has been received.</label>
            <label class="bold-text">An APIS administrator will review the request and notify you with the decision.</label>
        </div>
        <div class="cancel-modal-body" *ngIf="isRequestSubmitted && requestDecisionTypeId==RequestDecisionTypes.Approved">
            <label class="bold-text">The {{isSingleNotice?"notice":"notices"}} you selected {{isSingleNotice?"has":"have"}} been cancelled.</label>
            <label class="bold-text">Please take the appropriate next steps to advise the {{isSingleNotice?"recipient":"recipients"}} of the {{isSingleNotice?"cancellation":"cancellations"}}.</label>
        </div>
        <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row mt-4">  
            <span class="error">{{errorMessage}}</span>
        </div>
        <div class="button-container" *ngIf="!isRequestSubmitted">
            <div id="btnCancelModalCancel" class="cancel-button" (click)="onCloseClick()">Cancel</div>
            <div id="btnCancelModalConfirm" class="confirm-button" 
                [ngClass]="{'disabled': remainingCharacters < 0 || isContraventionCancelled || uploadingDocument }" 
                (click)="onConfirmCancellationClick(cancellationReasonModel.valid)">Confirm Cancellation</div>
        </div>
        <div class="button-container" *ngIf="isRequestSubmitted">
            <div id="btnCancelModalCancel" class="cancel-button" (click)="onCloseClick()">Close</div>
        </div>
    </div>
</div>
<div class="confirm-modal-overlay">
    <div class="confirm-modal">

        <div class="cancel-modal-header">
            <div class="cancel-modal-title">Confirm Cancellation</div>
            <div class="close-icon" (click)="showHideConfirmModal(false)">
                <i class="far fa-times-circle fa-lg"></i>
            </div>
        </div>
        <div class="cancel-modal-body">
            <div class="cancellation-alert" *ngIf="noticeCancellationRequest.noticeCancellationRequestMappings?.length > 5">
                <div class="alert-icon">                
                    <i class="fas fa-exclamation-triangle fa-lg"></i>
                </div>
                <div class="cancellation-alert-text">
                    <span class="bold-text">Are you sure you want to cancel the selected Notices?</span>
                </div>                       
            </div>

            <div *ngIf="noticeCancellationRequest.noticeCancellationRequestMappings?.length <= 5">
                <div class="mb-3">
                    <span class="bold-text">Are you sure you want to cancel {{isSingleNotice?"this Notice":"these Notices"}}?</span>
                </div>
                <div *ngFor="let notice of noticeCancellationRequest.noticeCancellationRequestMappings">
                    <div class="mb-3 row">
                        <div class="col-3">
                            <span class="bold-text">{{notice.contravention?notice.contravention.contraventionNumber:notice.vehicleSeizure.contraventionNumber}}</span>
                        </div>
                        <div class="col-3">
                            {{getChargeName(notice)}}
                        </div>
                        <div class="col-6">
                            {{notice.contravention?notice.contravention.recipientName:notice.vehicleSeizure.recipientName}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="!isSingleNotice" class="heading-underline"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-container">
                <button id="btnGoBack" class="btn btn-cancel" (click)="showHideConfirmModal(false)">Close</button>
                <button id="btnConfirm" class="btn btn-confirm" (click)="onConfirmClick()">Cancel {{isSingleNotice?"Notice":"Notices"}}</button>
            </div>
        </div>
    </div>
</div>

