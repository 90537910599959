<form #contraventionSelectionForm="ngForm">
	<div class="d-flex flex-column">
		<h1 class="pl-2 pl-md-0">Contravention Selection</h1>
		
        <div class="card mt-3 mt-lg-4">
			<div class="card-header">
				Contravention Information
			</div>
			<div class="card-body">
				<div *ngFor="let contravention of contraventions; let i = index; trackBy: trackContravention">
					<app-contravention-charge-selection [contravention]="contravention" [operatorInformation]="stopInformation.operatorInformation" [indexId]="i" [isSubmitClicked]="isSubmitClicked" [recipientTypeId]="stopInformation.recipient.recipientTypeId" [isOutOfProvinceDL]="stopInformation.recipient.recipientIdentification.issuingCountryId != 40" (onCancelEvent)="onCancelContravention($event)"></app-contravention-charge-selection>
                </div>
				<div class="form-group row mt-4" *ngIf="!isPendingSelection()">
                    <div class="col-lg-12">                    
                        <button class="btn button-secondary-outline" (click)="addAnotherContravention()">Add Another Contravention</button>                    
                    </div>
                </div> 
				
				<div class="form-group row mt-4" *ngIf="isPart2AlertRequired()">
					<div class="col-lg-12"> 
						<div class="card-alert my-4">
							<div class="container">
								<div class="row callout">
									<div class="col-md-1 callout-logo">   
										<svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
											<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
										</svg>
									</div>
									<div class="col-md-11 mt-4 mb-4" *ngIf="stopInformation.recipient.recipientIdentification.issuingCountryId == 40">   
										<h5>If a Part 2 ticket is issued, it must require the defendant to appear in Court (no voluntary payment option) unless:</h5>
										<div class="mt-2">
											<p class="mb-0 mt-0">• it was issued in the public interest because the defendant’s operator’s licence was an out-of-province one, or</p>
											<p class="mb-0 mt-0">• the specified penalty is more than $1,000.</p>
										</div>
									</div>
									<div class="col-md-11 mt-4 mb-4" *ngIf="stopInformation.recipient.recipientIdentification.issuingCountryId != 40">   
										<h5>A Part 2 Summons with a voluntary payment option may be issued:</h5>
										<div class="mt-2">
											<p class="mb-0 mt-0">• in the public interest because the defendant holds an <span class="bold-text">out-of-province operator’s licence</span>, or</p>
											<p class="mb-0 mt-0">• when the specified penalty is <span class="bold-text">more than $1,000</span>.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row">
					<div class="col-12 col-sm-12">
						<span class="error">{{errorMessage}}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12">
						<span class="error">{{showWarnings()}}</span>
					</div>
				</div>
		    </div>
		</div>

        <div class="card mt-4">
			<div class="card-header">
				Occurrence Information
			</div>
			<div class="card-body">
				<div class="form-group row">
					<div class="col-sm-4">
						<label for="occurrenceDate">Occurrence Date <span class="label-hint">(Required)</span></label>
						<input readonly required isLessThanStartDate="2020-12-01" [isAfterMinimumAgeDate]="stopInformation.recipient.recipientIdentification.dateOfBirth" #occurrenceDateControl="ngModel" type="text" bsDatepicker [bsConfig]="occurrenceDatePickerConfig" maxlength="10" class="form-control readonly-active-control" id="occurrenceDate" name="occurrenceDate" placeholder="yyyy/mm/dd" (bsValueChange)="onOccurrenceDateChange($event)" [(ngModel)]="occurrenceDate" [ngClass]="{'is-invalid':(occurrenceDateControl.touched || isSubmitClicked) && occurrenceDateControl.invalid}">
						<div class="invalid-feedback">
							<div *ngIf="occurrenceDateControl.hasError('required')">Occurrence Date is required</div>
							<div *ngIf="!occurrenceDateControl.hasError('required') && occurrenceDateControl.hasError('EarlierDate')">Occurrence Date can't be earlier than 01 Dec, 2020</div>
							<div *ngIf="!occurrenceDateControl.hasError('required') && !occurrenceDateControl.hasError('EarlierDate') && occurrenceDateControl.hasError('AfterMinimumAgeDate')">Recipient/Accused must be 12 or older</div>
							<div *ngIf="!occurrenceDateControl.hasError('required') && !occurrenceDateControl.hasError('EarlierDate') && !occurrenceDateControl.hasError('AfterMinimumAgeDate')">Invalid Occurrence Date</div>
						</div>
					</div>
					<div class="col-sm-4">
						<label for="occurrenceTime">Occurrence Time <span class="label-hint">(Required)</span></label>
						<input required #occurrenceTimeControl="ngModel" type="text" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
						pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="occurrenceTime" name="occurrenceTime" placeholder="--:--" (change)="onOccurrenceTimeChange()" [(ngModel)]="occurrenceTime" [ngClass]="{'is-invalid':(occurrenceTimeControl.touched || isSubmitClicked) && occurrenceTimeControl.invalid}">
						<div class="invalid-feedback">
                            <div *ngIf="occurrenceTimeControl.errors?.required">Occurrence Time is required</div>
                            <div *ngIf="!occurrenceTimeControl.errors?.required">Invalid Occurrence Time</div>
                        </div>
					</div>
				</div>
				<div class="custom-invalid-feedback mb-3" *ngIf="isFutureOccurrenceDateTime">
					<div>Occurrence date and time cannot be in the future</div>
				</div>

				<div class="form-group row">
					<div class="col-12" [ngClass]="availableTSA?'col-sm-6':'col-sm-8'">
                        <label for="location">Municipality At or Near <span class="label-hint">(Required)</span></label>
						<input required type="text" class="form-control" id="location" name="location" [formControl]="locationControl" [matAutocomplete]="autoLocations" placeholder="Select a muncipality, town, village etc." [(ngModel)]="selectedLocationType"  [ngClass]="{'is-invalid': (locationControl.touched || isSubmitClicked) && locationControl.invalid}"> 
						<mat-autocomplete #autoLocations="matAutocomplete" [displayWith]="displayLocation">
							<mat-option *ngFor="let locationType of filteredLocations | async" [value]="locationType">
								{{locationType.name}}
							</mat-option>
						</mat-autocomplete>
						<mat-error class="validation-message" *ngIf="(locationControl.touched || isSubmitClicked) && locationControl.hasError('required')">
							Location At or Near is required
						</mat-error>
						<mat-error class="validation-message" *ngIf="(locationControl.touched || isSubmitClicked) && locationControl.hasError('forbiddenLocations') && !locationControl.hasError('required')">
							Invalid Location At or Near option
						</mat-error>
					</div>
					<div class="col-12 col-sm-6" *ngIf="availableTSA">
						<label for="occurrenceLocation">Occurrence Location <span class="label-hint">(Required)</span></label>
						<input required #occurrenceLocationControl="ngModel" type="text" maxlength="100" class="form-control" id="occurrenceLocation" name="occurrenceLocation" placeholder="Enter Additional Location Information" [(ngModel)]="stopInformation.occurrenceLocation.additionalLocationDetails" [ngClass]="{'is-invalid':(occurrenceLocationControl.touched || isSubmitClicked) && occurrenceLocationControl.invalid}">
						<div class="invalid-feedback">
                            <div>Occurrence Location is required</div>
                        </div>
					</div>
				</div>

				<ng-container *ngIf="availableTSA && anyTSAChargeSelected()">
					<div class="form-group">
						<div class="row mt-3">
							<div class="col-12">
								<label class="w-100">Are there other officers involved in this stop? <span class="label-hint">(Required)</span></label>
								<div class="custom-control custom-radio custom-control-inline">
									<input required type="radio" class="custom-control-input" id="otherOfficerYes" name="otherOfficerYes" #otherOfficerYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isOtherOfficerInvolved" [ngClass]="{'is-invalid':(otherOfficerYesControl.touched || otherOfficerNoControl.touched || isSubmitClicked) && otherOfficerYesControl.invalid}">
									<label class="custom-control-label" for="otherOfficerYes">Yes</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input required type="radio" class="custom-control-input" id="otherOfficerNo" name="otherOfficerNo" #otherOfficerNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isOtherOfficerInvolved" [ngClass]="{'is-invalid':(otherOfficerYesControl.touched || otherOfficerNoControl.touched || isSubmitClicked) && otherOfficerNoControl.invalid}">
									<label class="custom-control-label" for="otherOfficerNo">No</label>
								</div>
								<div *ngIf="(otherOfficerYesControl.touched || otherOfficerNoControl.touched || isSubmitClicked) && otherOfficerYesControl.invalid">
									<div class="error">Please select one</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group row" *ngIf="stopInformation.isOtherOfficerInvolved">
						<div class="col-12">
							<label for="otherOfficer">Provide name(s) of other officer(s) <span class="label-hint">(Required)</span></label>
							<input required #otherOfficerControl="ngModel" type="text" maxlength="60" class="form-control" id="otherOfficer" name="otherOfficer" 
							placeholder="Enter name(s) of other officer(s)" [(ngModel)]="stopInformation.otherOfficerName" 
							[ngClass]="{'is-invalid':(otherOfficerControl.touched || isSubmitClicked) && otherOfficerControl.invalid}" (ngModelChange)="stopInformation.otherOfficerName=this.moddedTitleCasePipe.transform($event)">
							<div class="invalid-feedback">
								<div>Other officer name is required</div>
							</div>
						</div>
					</div>
				</ng-container>

				<div class="row">
					<div class="col-sm-12">
						<div class="alert-message">You can add more location details after printing</div>
					</div>
				</div>
			</div>
		</div>
		
        <div class="form-group">
			<div class="row pt-3">
				<div class="col-12 col-sm-6">                
					<a class="btn button-secondary btn-block" [routerLink]="['/contravention/submission/recipient-information']">Back to Recipient Information</a>
				</div>
				<div class="col-12 col-sm-6 text-right">                
					<button class="btn button-primary btn-block" (click)="onRoadsideAppealConfirmation(contraventionSelectionForm.valid)">Proceed to {{stopInformation.recipient.recipientTypeId == RecipientTypes.NotInVehicle?'Print':'Vehicle Information'}}</button>   
        </div>
			</div>
		</div>	
	</div>
</form>

<div class="confirm-modal-overlay">
	<div class="confirm-modal">

			<div class="confirm-modal-header">
					<div class="confirm-modal-title">Roadside Appeal Confirmation</div>
					<div class="close-icon" (click)="showHideConfirmModal(false)">
							<i class="far fa-times-circle fa-lg"></i>
					</div>
			</div>
			<div class="confirm-modal-body">
					<div class="confirm-alert">
							<div class="alert-icon">                
									<i class="fas fa-exclamation-triangle fa-lg"></i>
							</div>
							<div class="confirm-alert-text">
									You have selected {{ descriptionsSelectedText }}.<br />By selecting Refusal and others, you will be prompted to provide a roadside appeal.<br />If you select only Refusal, no roadside appeal will be provided.
							</div>          
            
					</div>
					<div>
						<br />Do you wish to continue?
					</div> 
					<div class="button-container">
							<button id="btnGoBack" class="btn btn-cancel" (click)="showHideConfirmModal(false)">No</button>
							<button id="btnConfirm" class="btn btn-confirm" (click)="onSubmitClick(contraventionSelectionForm.valid)">Yes</button>
					</div>
					
			</div>
			
	</div>
</div>


