<form #additionalDetailsForm="ngForm">
	<div class="d-flex flex-column">
		<h1 class="ps-2 ps-md-0">Additional Details</h1>
        <ng-container *ngIf="this.stopInformation.isIRSSelected">
			<div class="card mt-4">
				<div class="card-header">
					IRS Contravention Grounds
				</div>
				<div class="card-body">
					<div class="mb-3 row">
						<div class="col-sm-6">
							<label for="suspectedImpairmentType">Suspected Impairment Type <span class="label-hint">(Required)</span></label>
							<select [appSelectValidator]="true" #suspectedImpairmentTypeControl="ngModel" class="form-select" id="suspectedImpairmentType" name="suspectedImpairmentType" [(ngModel)]="impairmentScreeningInformation.suspectedImpairmentTypeId" [ngClass]="{'is-invalid':(suspectedImpairmentTypeControl.touched || isSubmitClicked) && suspectedImpairmentTypeControl.errors?.defaultSelected}">
								<option [value]="0">Select a Suspected Impairment Type</option>
								<option *ngFor="let suspectedImpairmentType of suspectedImpairmentTypes" [value]="suspectedImpairmentType.id">{{suspectedImpairmentType.name}}</option>
							</select>
							<div class="invalid-feedback">
								<div>Suspected Impairment Type is required</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<label for="contraventionGrounds">Was a Screening Method Used? <span class="label-hint">(Required)</span></label>
							<select [appSelectValidator]="true" #contraventionGroundsControl="ngModel" class="form-select" id="contraventionGrounds" name="contraventionGrounds" [(ngModel)]="irsContravention.contraventionGroundsTypeId" (change)="onContraventionGroundsChange($event.target.value)" [ngClass]="{'is-invalid':(contraventionGroundsControl.touched || isSubmitClicked) && contraventionGroundsControl.errors?.defaultSelected}">
								<option [ngValue]="null">Select one</option>
								<option *ngFor="let contraventionGroundsType of contraventionGroundsTypes" [value]="contraventionGroundsType.id">{{contraventionGroundsType.name}}</option>
							</select>
							<div class="invalid-feedback">
								<div>Contravention Grounds are required</div>
							</div>
						</div>
					</div>
				</div>	
			</div>

			<ng-container *ngIf="irsContravention.contraventionGroundsTypeId == 1">
				<div class="card mt-4">
					<div class="card-header">
						Impairment Screening Information
					</div>
					<div class="card-body">
						<div class="mb-3 row">
							<div class="col-sm-6">
								<label for="reasonableSuspicionOrMAS">Reasonable Suspicion or MAS? <span class="label-hint">(Required)</span></label>
								<select [appSelectValidator]="true" #reasonableSuspicionOrMASControl="ngModel" class="form-select" id="reasonableSuspicionOrMAS" name="reasonableSuspicionOrMAS" [(ngModel)]="impairmentScreeningInformation.impairmentScreeningTypeId" (change)="onImpairmentScreeningTypeChange($event.target.value)" [ngClass]="{'is-invalid':(reasonableSuspicionOrMASControl.touched || isSubmitClicked) && reasonableSuspicionOrMASControl.errors?.defaultSelected}">
									<option value=0>Select one</option>
									<option *ngFor="let impairmentScreeningType of impairmentScreeningTypes" [value]="impairmentScreeningType.id">{{impairmentScreeningType.name}}</option>
								</select>
								<div class="invalid-feedback">
									<div>Reasonable Suspicion or MAS is required</div>
								</div>
							</div>
						</div>

						<ng-container *ngIf="impairmentScreeningInformation.impairmentScreeningTypeId == 1">
							<div class="mb-3 row">
								<div class="col-sm-6">
									<label for="reasonableSuspicionBasedOn">Reasonable Suspicion Based On <span class="label-hint">(Required)</span></label>
									<mat-select required #reasonableSuspicionBasedOnControl="ngModel" class="form-control-multiselect" multiple id="reasonableSuspicionBasedOn" name="reasonableSuspicionBasedOn" [(ngModel)]="selectedSuspicionTypes" placeholder="Select the Suspicion Types" (selectionChange)="onSuspicionBasedOnChange($event.value)" [ngClass]="{'is-invalid':(reasonableSuspicionBasedOnControl.touched || isSubmitClicked) && reasonableSuspicionBasedOnControl.invalid}">
										<mat-option class="mat-primary" *ngFor="let suspicionType of suspicionTypes" [value]="suspicionType.id">{{suspicionType.name}}</mat-option>
									</mat-select>
									<div class="invalid-feedback">
										<div>Suspicion Types are required</div>
									</div>
								</div>

								<div class="col-sm-6" *ngIf="selectedSuspicionTypes?.includes(5)">
									<label for="otherReasonableSuspicion">Define ‘Other’ Reasonable Suspicion <span class="label-hint">(Required)</span></label>
									<input required #otherReasonableSuspicionControl="ngModel" maxlength="100" type="text" class="form-control" id="otherReasonableSuspicion" name="otherReasonableSuspicion" [(ngModel)]="impairmentScreeningInformation.suspicionOther" [ngClass]="{'is-invalid':(otherReasonableSuspicionControl.touched || isSubmitClicked) && otherReasonableSuspicionControl.invalid}">
									<div class="invalid-feedback">
										<div>‘Other’ Reasonable Suspicion is required</div>
									</div>
								</div>
							</div>
						</ng-container>

						<div class="mb-3 row">
							<ng-container *ngIf="impairmentScreeningInformation.impairmentScreeningTypeId == 1 || impairmentScreeningInformation.impairmentScreeningTypeId == 2">
								<div class="col-sm-6">
									<label for="impairmentScreeningMethod">Impairment Screening Method <span class="label-hint">(Required)</span></label>
									<select [appSelectValidator]="true" #impairmentScreeningMethodControl="ngModel" class="form-select" id="impairmentScreeningMethod" name="impairmentScreeningMethod" [(ngModel)]="impairmentScreeningMethodId" (change)="onImpairmentScreeningMethodChange($event.target.value)" [ngClass]="{'is-invalid':(impairmentScreeningMethodControl.touched || isSubmitClicked) && impairmentScreeningMethodControl.errors?.defaultSelected}">
										<option value="0">Select the Impairment Screening Method</option>
										<option *ngFor="let testType of testTypes" [value]="testType.id">{{testType.name}}</option>
									</select>
									<div class="invalid-feedback">
										<div>Impairment Screening Method is required</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>			
				
				<app-impairment-screening  *ngIf="impairmentScreeningInformation.impairmentScreeningTypeId == 1 || impairmentScreeningInformation.impairmentScreeningTypeId == 2" [impairmentScreeningModel]="impairmentScreeningModel" [contraventionTest]="contraventionTest" [occurrenceTime]="irsContravention.occurrenceTime" [submissionDate]="irsContravention.submissionDate" [impairmentScreeningTypeId]="impairmentScreeningInformation.impairmentScreeningTypeId"></app-impairment-screening>
			</ng-container>

			<ng-container *ngIf="irsContravention.contraventionGroundsTypeId == 2">
				<div class="card mt-4">
					<div class="card-header">
						Reasonable Grounds to Believe
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12">
								<label for="yourGrounds">Describe in Detail What Led to Your Grounds <span class="label-hint">(Required)</span></label>
							</div>
						</div>
						<div class="mb-3 row">
							<div class="col-sm-12">
								<textarea required #yourGroundsControl="ngModel" maxlength="3000" class="form-control" id="yourGrounds" name="yourGrounds" rows="5" placeholder="Describe in detail the Driving Evidence, Coordination Issues and Comprehension Issues that led to your grounds" [(ngModel)]="irsContravention.reasonableGroundsDetails" [ngClass]="{'is-invalid':(yourGroundsControl.touched || isSubmitClicked) && yourGroundsControl.invalid}"></textarea>
								<div class="invalid-feedback">
									<div>Reasonable Grounds are required</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-12">
								<label for="evidenceOfConsumption">Evidence of Consumption <span *ngIf="irsContravention.contraventionTypeId != 1 || (irsContravention.contraventionDetails != '1' && irsContravention.contraventionDetails != '18')" class="label-hint">(Required)</span></label>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12">
								<textarea [required]="irsContravention.contraventionTypeId != 1 || (irsContravention.contraventionDetails != '1' && irsContravention.contraventionDetails != '18')" #evidenceOfConsumptionControl="ngModel" maxlength="3000" class="form-control" id="evidenceOfConsumption" name="evidenceOfConsumption" rows="5" placeholder="Articulate any Evidence of Consumption you may have" [(ngModel)]="irsContravention.consumptionEvidence" [ngClass]="{'is-invalid':(evidenceOfConsumptionControl.touched || isSubmitClicked) && evidenceOfConsumptionControl.invalid}"></textarea>
								<div class="invalid-feedback">
									<div>Evidence of Consumption is required</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</ng-container>

			<div *ngFor="let additionalTest of additionalTests; let index = index">
				<app-impairment-screening *ngIf="additionalTest.testSequence != 0"  [impairmentScreeningModel]="additionalScreeningModels[index]" [contraventionTest]="additionalTest" [occurrenceTime]="irsContravention.occurrenceTime" [submissionDate]="irsContravention.submissionDate" (onRemoveEvent)="onRemoveAdditionalScreening($event)" (onTestTypeChangeEvent)="refreshRoadsideAppealTestTypes()"></app-impairment-screening>
			</div>

			<div class="row mt-4">
				<div class="col-12 col-sm-6">                
					<button class="btn button-secondary-outline btn-block" (click)="onAddAdditionalTestClick(true)">Add ASD/SFST/ADSE Test</button>   
                </div>
				<div class="col-12 col-sm-6">                    
					<button class="btn button-secondary-outline btn-block" (click)="onAddAdditionalTestClick(false)">Add Intox/DRE/Blood Test</button>                    
				</div>
			</div>

			<ng-container *ngIf="isRoadsideAppealVisible">
				<div class="card mt-4">
					<div class="card-header">
						Roadside Appeal Information
					</div>
					<div class="card-body">
						<div class="mb-3 row">
							<div class="col-sm-12">
								<label class="w-100">Was Recipient Presented with Roadside Appeal Information?  <span class="label-hint">(Required)</span></label>
								<label>{{roadsideAppeal.isRecipientPresentedWithInformation?'Yes':'No'}}</label>
							</div>
						</div>
						
						<div class="mb-3 row" *ngIf="roadsideAppeal.isRecipientPresentedWithInformation == false">
							<div class="col-12">
								<label for="appealExplain">If No, Explain?  <span class="label-hint">(Required)</span></label>
								<input readonly type="text" class="form-control" id="appealExplain" name="appealExplain" [value]="roadsideAppeal.appealOptionsNotPresentedReason">
							</div>
						</div>

						<div class="mb-3 row" *ngIf="roadsideAppeal.isRecipientPresentedWithInformation">
							<div class="col-12 col-sm-6 mb-2 mb-lg-0">										
								<label class="w-100">Did Driver Request a Roadside Appeal? <span class="label-hint">(Required)</span></label>
								<label>{{roadsideAppeal.hasDriverRequestedRoadsideAppeal?'Yes':'No'}}</label>
							</div>
							<div *ngIf="roadsideAppeal.isRecipientPresentedWithInformation && roadsideAppeal.hasDriverRequestedRoadsideAppeal" class="col-12 col-sm-6">
								<label for="roadsideAppealMethod">Roadside Appeal Method <span class="label-hint">(Required)</span></label>
								<select [appSelectValidator]="true" #roadsideAppealMethodControl="ngModel" class="form-select" id="roadsideAppealMethod" name="roadsideAppealMethod" [(ngModel)]="roadsideAppealTest.testTypeId" (change)="onRoadsideAppealMethodChange($event.target.value)" [ngClass]="{'is-invalid':(roadsideAppealMethodControl.touched || isSubmitClicked) && roadsideAppealMethodControl.errors?.defaultSelected}">
									<option value="0">Select the Appeal Method</option>
									<option *ngFor="let appealTestType of appealTestTypes" [value]="appealTestType.id">{{appealTestType.name}}</option>
								</select>
								<div class="invalid-feedback">
									<div>Roadside Appeal Method is required</div>
								</div>	
							</div>
						</div>
					
						<ng-container *ngIf="roadsideAppeal.isRecipientPresentedWithInformation && roadsideAppeal.hasDriverRequestedRoadsideAppeal && roadsideAppealTest.testTypeId == 8">			
							<div class="mb-3 row">
								<div class="col-12">
									<label for="appealNotProvidedReason">If Appeal Was Requested But Not Provided, Explain Why Not <span class="label-hint">(Required)</span></label>
									<input required #appealNotProvidedReasonControl="ngModel" maxlength="200" type="text" class="form-control" id="appealNotProvidedReason" name="appealNotProvidedReason" placeholder="Explain Why Roadside Appeal Was Not Provided" [(ngModel)]="roadsideAppealTest.appealNotProvidedReason" [ngClass]="{'is-invalid':(appealNotProvidedReasonControl.touched || isSubmitClicked) && appealNotProvidedReasonControl.invalid}">
									<div class="invalid-feedback">
										<div>Explanation is required</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>			

				<app-impairment-screening *ngIf="roadsideAppeal.isRecipientPresentedWithInformation && roadsideAppeal.hasDriverRequestedRoadsideAppeal"  [impairmentScreeningModel]="roadsideAppealModel" [contraventionTest]="roadsideAppealTest" [occurrenceTime]="irsContravention.occurrenceTime" [submissionDate]="irsContravention.submissionDate" [isRoadsideAppeal]="true"></app-impairment-screening>

				<div class="card mt-4" *ngIf="roadsideAppeal.hasDriverRequestedRoadsideAppeal && roadsideAppeal.roadsideAppealOutcomeTypeId != null && roadsideAppeal.roadsideAppealOutcomeTypeId != RoadsideAppealOutcomeTypes.Pending">
					<div class="card-header">
						Roadside Appeal Outcome
					</div>
					<div class="card-body">
						<div class="mb-3 row">
							<div class="col-12">
								<span class="label-message">{{roadsideAppealOutcomeType.description.substring(0, roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name))}} <span class="bold-text">{{roadsideAppealOutcomeType.name}}</span> {{roadsideAppealOutcomeType.description.substring(roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name) + roadsideAppealOutcomeType.name.length)}}</span>
							</div>
						</div>
						<div class="mb-3 row" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && reIssueEvent">
							<div class="col-12">
								<span class="label-message">{{reIssueEvent.eventDetails}}</span>
							</div>
						</div>
						<div class="mb-3 row" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration && irsContravention.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled">
							<div class="col-12">
								<span class="label-message">Contravention Cancelled</span>
							</div>
						</div>
					</div>
				</div>
			</ng-container> 
		</ng-container>

		<ng-container *ngIf="stopInformation.isSpeedingSelected">
			<div class="card mt-4">
				<div class="card-header">
					Speeding Contravention Grounds
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<label>How was vehicle speed determined? Select all that apply:</label>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isLidar" name="isLidar" [(ngModel)]="isLidar" (change)="onSpeedingDeviceChange()">
								<label class="form-check-label mb-0" for="isLidar">LIDAR</label>
							</div>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12 col-sm-2">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isRadar" name="isRadar" [(ngModel)]="isRadar" (change)="onSpeedingDeviceChange()">
								<label class="form-check-label mb-0" for="isRadar">RADAR</label>
							</div>
						</div>
						<div class="col-12 col-sm-10">
							<div class="error" *ngIf="isLidar && isRadar">RADAR and LIDAR can't both be selected</div>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12 col-sm-2">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isPacing" name="isPacing" [(ngModel)]="speedingTicket.isPacing">
								<label class="form-check-label mb-0" for="isRadar">Pacing</label>
							</div>
						</div>
						<div class="col-12 col-sm-10">
							<div class="error" *ngIf="isLidar && speedingTicket.isPacing">Pacing can't be selected as the method if LIDAR is selected</div>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isVisualEstimate" name="isVisualEstimate" [(ngModel)]="speedingTicket.isVisualEstimate">
								<label class="form-check-label mb-0" for="isVisualEstimate">Visual Estimate</label>
							</div>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isClocked" name="isClocked" [(ngModel)]="speedingTicket.isClocked">
								<label class="form-check-label mb-0" for="isClocked">Clocked</label>
							</div>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12 col-sm-2">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isAircraft" name="isAircraft" [(ngModel)]="speedingTicket.isAircraft">
								<label class="form-check-label mb-0" for="isAircraft">Aircraft</label>
							</div>
						</div>
						<div class="col-12 col-sm-10">
							<div class="error" *ngIf="(isLidar || isRadar || speedingTicket.isPacing || speedingTicket.isVisualEstimate) && speedingTicket.isAircraft">Aircraft can't be selected as the method if LIDAR, RADAR, Pacing, or Visual Estimate is selected</div>
							<label *ngIf="!isLidar && !isRadar && !speedingTicket.isPacing && !speedingTicket.isVisualEstimate && speedingTicket.isAircraft">You must upload the Aircraft Certificate on the next screen.</label>
						</div>
					</div>
					<div class="mb-3 row">
						<div class="col-12 col-sm-2">
							<div class="form-check">
								<input class="form-check-input mb-0 mt-2" type="checkbox" id="isOther" name="isOther" [(ngModel)]="speedingTicket.isOther" (change)="onOtherDeviceChange()">
								<label class="form-check-label mb-0" for="isOther">Other:</label>
							</div>
						</div>
						<div class="col-12 col-sm-10" *ngIf="speedingTicket.isOther">
							<input required #otherDeviceControl="ngModel" type="text" class="form-control" id="otherDevice" name="otherDevice" placeholder="specify other method of determining speed (required)" [(ngModel)]="speedingTicket.otherDevice" [ngClass]="{'is-invalid':(otherDeviceControl.touched || isSubmitClicked) && otherDeviceControl.invalid}">
							<div class="invalid-feedback">
								<div>Other method of determining speed is required</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="error" *ngIf="!isLidar && !isRadar && !speedingTicket.isPacing && !speedingTicket.isVisualEstimate && !speedingTicket.isClocked && !speedingTicket.isAircraft && !speedingTicket.isOther && isSubmitClicked">
								<div>At least one option must be selected</div>
							</div>
						</div>
					</div>

					<ng-container *ngIf="speedingTicket.isClocked && !speedingTicket.isVisualEstimate && !isRadar && !isLidar && !speedingTicket.isPacing">
						<div class="mb-3 row">
							<div class="col-12 col-sm-4">
								<label>Recipient vehicle direction</label>
								<input type="text" class="form-control" id="recipientVehicleDirection" name="recipientVehicleDirection" placeholder="Enter driver's direction of travel" [(ngModel)]="speedingTicket.recipientVehicleDirection">
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="speedingTicket.isVisualEstimate && !isRadar && !isLidar && !speedingTicket.isPacing">
						<div class="mb-3 row">
							<div class="col-12 col-sm-4">
								<label>Recipient vehicle direction</label>
								<input type="text" class="form-control" id="recipientVehicleDirection" name="recipientVehicleDirection" placeholder="Enter driver's direction of travel" [(ngModel)]="speedingTicket.recipientVehicleDirection">
							</div>
						</div>
						<div class="mb-3 row" *ngIf="speedingTicket.isVisualEstimate">
							<div class="col-12">
								<label>Additional Details</label>
								<textarea class="form-control" id="additionalDetails" name="additionalDetails" rows="4" maxlength="5000" placeholder="Provide further details on how you estimated the vehicle speed (optional)" [(ngModel)]="speedingTicket.additionalDetails"></textarea>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="speedingTicket.isPacing && !isRadar && !isLidar">
						<div class="mb-3 row">
							<div class="col-12 col-sm-4">
								<label>Recipient vehicle direction</label>
								<input type="text" class="form-control" id="recipientVehicleDirection" name="recipientVehicleDirection" placeholder="Enter driver's direction of travel" [(ngModel)]="speedingTicket.recipientVehicleDirection">
							</div>
							<div class="col-12 col-sm-4">
								<label>Police vehicle speed <span class="label-hint">(in km/hr)</span></label>
								<input type="text" class="form-control" id="policeVehicleSpeed" name="policeVehicleSpeed" placeholder="Enter your vehicle speed" [(ngModel)]="speedingTicket.policeVehicleSpeed">
							</div>
						</div>
						<div class="mb-3 row" *ngIf="speedingTicket.isVisualEstimate">
							<div class="col-12">
								<label>Additional Details</label>
								<textarea class="form-control" id="additionalDetails" name="additionalDetails" rows="4" maxlength="5000" placeholder="Provide further details on how you estimated the vehicle speed (optional)" [(ngModel)]="speedingTicket.additionalDetails"></textarea>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="(isRadar || isLidar) && !(isRadar && isLidar)">
						<div class="mb-3 row">
							<div class="col-12">
								<div class="form-check">
									<input class="form-check-input mb-0" type="checkbox" id="isNotDeviceOperator" name="isNotDeviceOperator" [(ngModel)]="speedingTicket.isNotSpeedingDeviceOperator" (change)="onSpeedingDeviceOperatorChange()">
									<label class="form-check-label mb-0" for="isNotDeviceOperator"></label><span class="bold-text mt-2">I was not the device operator</span>
								</div>
							</div>
						</div>
						<ng-container *ngIf="!speedingTicket.isNotSpeedingDeviceOperator">
							<div class="mb-3 row" *ngIf="isRadar">
								<div class="col-12 col-sm-4">
									<label>Police vehicle direction</label>
									<input type="text" class="form-control" id="policeVehicleDirection" name="policeVehicleDirection" placeholder="Enter your direction of travel" [(ngModel)]="speedingTicket.policeVehicleDirection">
								</div>
								<div class="col-12 col-sm-4">
									<label>Police vehicle speed <span class="label-hint">(in km/hr)</span></label>
									<input type="text" class="form-control" id="policeVehicleSpeed" name="policeVehicleSpeed" placeholder="Enter your vehicle speed" [(ngModel)]="speedingTicket.policeVehicleSpeed">
								</div>
							</div>
							
							<div class="mb-3 row">
								<div class="col-12 col-sm-4">
									<label>Recipient vehicle direction</label>
									<input type="text" class="form-control" id="recipientVehicleDirection" name="recipientVehicleDirection" placeholder="Enter driver's direction of travel" [(ngModel)]="speedingTicket.recipientVehicleDirection">
								</div>
								<div class="col-12 col-sm-4" *ngIf="isLidar">
									<label>LIDAR Distance to Vehicle (m)</label>
									<input type="text" class="form-control" id="speedingDeviceDistanceToVehicle" name="speedingDeviceDistanceToVehicle" placeholder="Enter distance in meters" [(ngModel)]="speedingTicket.speedingDeviceDistanceToVehicle">
								</div>
							</div>

							<div class="mb-3 row" *ngIf="speedingTicket.isVisualEstimate">
								<div class="col-12">
									<label>Additional Details</label>
									<textarea class="form-control" id="additionalDetails" name="additionalDetails" rows="4" maxlength="5000" placeholder="Provide further details on how you estimated the vehicle speed (optional)" [(ngModel)]="speedingTicket.additionalDetails"></textarea>
								</div>
							</div>

							<div class="mb-3 row">
								<div class="col-12">
									<div class="form-check">
										<input class="form-check-input mb-0" type="checkbox" id="isUploadOperatorReport" name="isUploadOperatorReport" [(ngModel)]="speedingTicket.isUploadOperatorReport" (change)="onUploadReportChange()">
										<label class="form-check-label mb-0" for="isUploadOperatorReport"></label><span class="bold-text mt-2">I will upload an Operator Report (tracking sheet) on the next screen</span>
									</div>
								</div>
							</div>

							<ng-container *ngIf="!speedingTicket.isUploadOperatorReport">
								<div class="row">
									<div class="col-12">
										<label>Instrument Type:</label>
									</div>
								</div>
								<div class="mb-3 row">
									<div class="col-12 col-sm-4">
										<label for="deviceMake">{{isRadar?"RADAR":"LIDAR"}} Make <span class="label-hint">(Required)</span></label>
										<select [appSelectValidator]="true" #deviceMakeControl="ngModel" class="form-select" id="deviceMake" name="deviceMake" [(ngModel)]="speedingTicket.speedingDeviceMakeTypeId" (change)="onSpeedingDeviceMakeChange()" [ngClass]="{'is-invalid':(deviceMakeControl.touched || isSubmitClicked) && deviceMakeControl.errors?.defaultSelected}">
											<option [ngValue]="null">Select make</option>
											<option *ngFor="let deviceMakeType of speedingDeviceMakeTypes" [value]="deviceMakeType.id">{{deviceMakeType.name}}</option>
										</select>
										<div class="invalid-feedback">
											<div>{{isRadar?"RADAR":"LIDAR"}} Make is required</div>
										</div>
									</div>
									<div class="col-12 col-sm-4">
										<label for="deviceModel">{{isRadar?"RADAR":"LIDAR"}} Model <span class="label-hint">(Required)</span></label>
										<select [appSelectValidator]="true" #deviceModelControl="ngModel" class="form-select" id="deviceModel" name="deviceModel" [(ngModel)]="speedingTicket.speedingDeviceModelTypeId" [ngClass]="{'is-invalid':(deviceModelControl.touched || isSubmitClicked) && deviceModelControl.errors?.defaultSelected}">
											<option [ngValue]="null">Select model</option>
											<option *ngFor="let deviceModelType of speedingDeviceModelTypes" [value]="deviceModelType.id">{{deviceModelType.name}}</option>
										</select>
										<div class="invalid-feedback">
											<div>{{isRadar?"RADAR":"LIDAR"}} Model is required</div>
										</div>
									</div>
									<div class="col-12 col-sm-4" *ngIf="speedingTicket.speedingDeviceModelTypeId == 99">
										<label for="otherDeviceModel">Other {{isRadar?"RADAR":"LIDAR"}} Model <span class="label-hint">(Required)</span></label>
										<input required #otherDeviceModelControl="ngModel" type="text" class="form-control" id="otherDeviceModel" name="otherDeviceModel" placeholder="Enter other model type" [(ngModel)]="speedingTicket.speedingDeviceModelOther" [ngClass]="{'is-invalid':(otherDeviceModelControl.touched || isSubmitClicked) && otherDeviceModelControl.invalid}">
										<div class="invalid-feedback">
											<div>Other {{isRadar?"RADAR":"LIDAR"}} Model is required</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<label>Testing and Certification:</label>
									</div>
								</div>
								<div class="mb-3 row">
									<div class="col-12">
										<label for="isOperatorCertified">Are you trained in the use and operation of the equipment?</label>
									</div>
									<div class="col-12">
										<div class="form-check">
											<input class="form-check-input mb-0" type="checkbox" id="isOperatorCertified" name="isOperatorCertified" [(ngModel)]="speedingTicket.isOperatorCertified">
											<label class="form-check-label mb-0" for="isOperatorCertified">I certify that I successfully completed the course required for the proper operation and use of the {{isRadar?"RADAR":"LIDAR"}} instrument used in this investigation.</label>
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="row">
										<div class="col-12">
											<label>Did you complete the manufacturer-approved tests at the start of your shift?</label>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<div class="form-check custom-control-inline">
												<input required type="radio" class="form-check-input" id="testCompletedYes" name="testCompletedRadio" #testCompletedYesControl="ngModel" [value]="true" [(ngModel)]="speedingTicket.isManufacturerTestCompleted" [ngClass]="{'is-invalid': speedingTicket.isManufacturerTestCompleted == null && (testCompletedYesControl.touched || testCompletedNoControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="testCompletedYes">Yes</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-12 col-sm-1">
											<div class="form-check custom-control-inline pt-2">
												<input required type="radio" class="form-check-input" id="testCompletedNo" name="testCompletedRadio" #testCompletedNoControl="ngModel" [value]="false" [(ngModel)]="speedingTicket.isManufacturerTestCompleted" [ngClass]="{'is-invalid': speedingTicket.isManufacturerTestCompleted == null && (testCompletedYesControl.touched || testCompletedNoControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="testCompletedNo">No</label>
											</div>
										</div>
										<div class="col-12 col-sm-11" *ngIf="speedingTicket.isManufacturerTestCompleted==false">
											<input required type="text" class="form-control" id="reasonTestNotCompleted" name="reasonTestNotCompleted" #reasonTestNotCompletedControl="ngModel" placeholder="Please Explain" [(ngModel)]="speedingTicket.reasonManufacturerTestNotCompleted" [ngClass]="{'is-invalid':(reasonTestNotCompletedControl.touched || isSubmitClicked) && reasonTestNotCompletedControl.invalid}">
											<div class="invalid-feedback">
												<div>Please explain the reason</div>
											</div>
										</div>
										<div class="col-12">
											<div class="error" *ngIf="speedingTicket.isManufacturerTestCompleted == null && (testCompletedYesControl.touched || testCompletedNoControl.touched || isSubmitClicked)">
												<div>Selection is required</div>
											</div>
										</div>
									</div>
								</div>
								<div class="mb-3">
									<div class="row">
										<div class="col-12">
											<label>Did the device pass the manufacturer-approved tests?</label>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<div class="form-check custom-control-inline">
												<input required type="radio" class="form-check-input" id="testPassedYes" name="testPassedYes" #testPassedYesControl="ngModel" [value]="true" [(ngModel)]="speedingTicket.isManufacturerTestPassed" [ngClass]="{'is-invalid': speedingTicket.isManufacturerTestPassed == null && (testPassedYesControl.touched || testPassedNoControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="testPassedYes">Yes</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-12 col-sm-1">
											<div class="form-check custom-control-inline pt-2">
												<input required type="radio" class="form-check-input" id="testPassedNo" name="testPassedNo" #testPassedNoControl="ngModel" [value]="false" [(ngModel)]="speedingTicket.isManufacturerTestPassed" [ngClass]="{'is-invalid': speedingTicket.isManufacturerTestPassed == null && (testPassedYesControl.touched || testPassedNoControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="testPassedNo">No</label>
											</div>
										</div>
										<div class="col-12 col-sm-11" *ngIf="speedingTicket.isManufacturerTestPassed==false">
											<input required type="text" class="form-control" id="reasonTestFailed" name="reasonTestFailed" #reasonTestFailedControl="ngModel" placeholder="Please Explain" [(ngModel)]="speedingTicket.reasonManufacturerTestFailed" [ngClass]="{'is-invalid':(reasonTestFailedControl.touched || isSubmitClicked) && reasonTestFailedControl.invalid}">
											<div class="invalid-feedback">
												<div>Please explain the reason</div>
											</div>
										</div>
										<div class="col-12">
											<div class="error" *ngIf="speedingTicket.isManufacturerTestPassed == null && (testPassedYesControl.touched || testPassedNoControl.touched || isSubmitClicked)">
												<div>Selection is required</div>
											</div>
										</div>
									</div>
								</div>
								<div class="mb-3 row">
									<div class="col-sm-4">
										<label for="testingDate">Date of testing <span class="label-hint">(Required)</span></label>
										<input required [isFutureDate]="true" #testingDateControl="ngModel" type="text" bsDatepicker [bsConfig]="testingDatePickerConfig" maxlength="10" class="form-control" id="testingDate" name="testingDate" placeholder="yyyy/mm/dd" [(ngModel)]="speedingTicket.dateOfTest" [ngClass]="{'is-invalid':(testingDateControl.touched || isSubmitClicked) && testingDateControl.invalid}">
										<div class="invalid-feedback">
											<div *ngIf="testingDateControl.hasError('required')">Date of testing is required</div>
											<div *ngIf="!testingDateControl.hasError('required') && testingDateControl.hasError('FutureDate')">Date of testing can't be a future date</div>
											<div *ngIf="!testingDateControl.hasError('required') && !testingDateControl.hasError('FutureDate')">Invalid Date of testing</div>
										</div>
									</div>
									<div class="col-sm-4">
										<label for="testingTime">Time of testing <span class="label-hint">(Required)</span></label>
										<input required #testingTimeControl="ngModel" type="text" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
										pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="testingTime" name="testingTime" placeholder="--:--" [(ngModel)]="speedingTicket.timeOfTest" [ngClass]="{'is-invalid':(testingTimeControl.touched || isSubmitClicked) && testingTimeControl.invalid}">
										<div class="invalid-feedback">
											<div *ngIf="testingTimeControl.errors?.required">Time of testing is required</div>
											<div *ngIf="!testingTimeControl.errors?.required">Invalid Time of testing</div>
										</div>
									</div>
								</div>
								<div class="row callout mx-1">
									<div class="col-md-1 callout-logo">   
										<svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
											<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
										</svg>
									</div>
									<div class="col-md-11 mt-4 mb-4">   
										<h5>{{isRadar?"RADAR":"LIDAR"}} Certification <span class="label-hint">(Required)</span></h5>
										<div class="form-check mt-2">
											<div class="form-check ps-1">
												<input required #chkCertificationControl="ngModel" type="checkbox" class="form-check-input" id="chkCertification" name="chkCertification" [(ngModel)]="isCertificationChecked" [ngClass]="{'is-invalid': (chkCertificationControl.touched || isSubmitClicked) && chkCertificationControl.invalid}">
												<label class="form-check-label section-label" for="chkCertification">
													I certify that I have completed all recommended manufacturer tests for the instrument used in this investigation. Based on the results I received, I am satisfied that the instrument was in proper working order and able to accurately measure the speed of moving vehicles in Km/h{{isRadar?".":" and their distance from the operator in meters."}}
												</label>
												<div class="invalid-feedback">
													<div>{{isRadar?"RADAR":"LIDAR"}} Certification must be checked</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>			

		<div class="mb-3">
			<div class="row pt-3">
				<div class="col-12 col-sm-6">                
					<a class="btn button-secondary btn-block" [routerLink]="['/contravention/add-details/contravention-details']">Back to Contravention Details</a>
				</div>
				<div class="col-12 col-sm-6 text-end">                
					<button class="btn button-primary btn-block" (click)="onSubmitClick(additionalDetailsForm.valid)">Proceed to Supporting Documents</button>   
                </div>
			</div>
		</div>	
		<div *ngIf="errorMessage.length > 0">
			<span class="error">{{errorMessage}}</span>
		</div>
    </div>
</form>       