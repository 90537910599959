<div class="d-flex flex-column w-100">
    <div class="d-flex justify-content-center pt-2 pb-2 pt-lg-5 pb-lg-5">
        <div class="d-flex flex-column">
            <div class="mb-4">
                <a class="lnk-back" [routerLink]="['../']">Back to Landing Page</a>
            </div>
            <h1>Reports</h1>    
            <div class="card mt-4">
                <div class="card-header">
                    Report Types
                </div>
                <div class="card-body">
                    <div class="mb-3 row border-bottom">
                        <div class="col-12 mb-3">
                            <a class="report-link" [routerLink]="['/reports/impaired-notices']">Impaired Notices</a>
                        </div>
                    </div>
                    <div class="mb-3 row border-bottom">
                        <div class="col-12 mb-3">
                            <a class="report-link" [routerLink]="['/reports/seizure-notices']">Seizure Notices</a>
                        </div>
                    </div> 
                    <div class="mb-3 row border-bottom">
                        <div class="col-12 mb-3">
                            <a class="report-link" [routerLink]="['/reports/traffic-tickets']">Traffic Tickets</a>
                        </div>
                    </div> 
                    <div class="mb-3 row border-bottom">
                        <div class="col-12 mb-3">
                            <a class="report-link" [routerLink]="['/reports/cancelled-contraventions']">Cancelled Contraventions</a>
                        </div>
                    </div> 
                    <div class="mb-3 row border-bottom">
                        <div class="col-12 mb-3">
                            <a class="report-link" [routerLink]="['/reports/cancelled-tickets']">Ticket Cancellation</a>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-12">
                            <a class="report-link" (click)="downloadUsersList()">User List</a>
                        </div>
                    </div> 
                </div>
            </div>
        </div>    
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Please be patient while APIS generates your report... </p></ngx-spinner>
</div>
