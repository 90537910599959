<div class="supporting-documents-section submissionMain">
    <div class="d-flex flex-column">
        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="float-end">
                    <div class="print-icon" title="Print APIS submission export" (click)="PrintSubmission()"></div>
                </div>
                <h1 class="ps-2 ps-md-0">Supporting Documents</h1>
            </div>
        </div>
        <div class="card mt-2 mt-lg-4">
            <div class="card-header">
                Supporting Documents
            </div>
            <div class="card-body">
                <div *ngFor="let doc of documents; let i = index; trackBy: trackDocument">
                    <intake-file-upload [document]="doc" [additionalDocumentTypes]="additionalDocumentTypes"
                        (onCancelEvent)="onCancelDcoument($event)"
                        (onUploadEvent)="onUploadDocument($event)"
                        (onDeleteEvent)="onDeleteDocument()">
                    </intake-file-upload>
                </div>
                <div *ngIf="isAdditionalDocumentAllowed" class="mb-3 row">
                    <div class="col-lg-12">
                        <button class="btn button-secondary-outline float-end" (click)="AddOptionalDocument()">Add
                            Additional Document</button>
                    </div>
                </div>
                <div *ngIf="errorMessage.length > 0">
                    <span class="error">{{errorMessage}}</span>
                </div>
            </div>
        </div>
        <div class="card mt-4 mb-4" *ngIf="hasChangesDeclaration()">
            <div class="container d-flex justify-content-left">
                <div class="row callout">
                    <div class="col-md-1 callout-logo">
                        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2"
                            fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                            <path
                                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                        </svg>
                    </div>
                    <div class="col-md-11 mt-4 mb-4">
                        <h5 class="heading">Supporting Document Declaration <span class="label-hint">(Required)</span>
                        </h5>
                        <div class="form-check mt-2">
                            <div class="form-check ps-1">
                                <input required #chkSupportingDocument="ngModel" [(ngModel)]="isTrueInformation"
                                    type="checkbox" id="chkSupportingDocument" name="chkSupportingDocument"
                                    class="form-check-input"
                                    [ngClass]="{'is-invalid': (chkSupportingDocument.touched || isSubmitClicked) && chkSupportingDocument.invalid}">
                                <label class="form-check-label section-label" for="chkSupportingDocument">
                                    {{ supportingDocumentsDeclaration }}
                                </label>
                                <div class="invalid-feedback">
                                    <div>Supporting Document Declaration must be checked</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="row pt-3">
                <div class="col-12 col-sm-6" *ngIf="!isLegacySubmission && stopInformation.userIsAddingDetails">
                    <a *ngIf="(stopInformation.isIRSSelected || stopInformation.isSpeedingSelected) && !stopInformation.isOnlySDPActive"
                        class="btn button-secondary btn-block"
                        [routerLink]="['/contravention/add-details/additional-details']">Back to Additional Details</a>
                    <a *ngIf="stopInformation.contraventions.length>0 && !stopInformation.isIRSSelected && !stopInformation.isSpeedingSelected && !stopInformation.isOnlySDPActive"
                        class="btn button-secondary btn-block"
                        [routerLink]="['/contravention/add-details/contravention-details']">Back to Contravention
                        Details</a>
                    <a *ngIf="stopInformation.isOnlySDPActive"
                        class="btn button-secondary btn-block"
                        [routerLink]="['/contravention/add-details/sdp-notice-details']">Back to Seizure Details</a>
                </div>
                <div class="col-12 col-sm-6" *ngIf="isLegacySubmission || !stopInformation.userIsAddingDetails">
                    <a class="btn button-secondary btn-block" [routerLink]="['/']">Back to Dashboard</a>
                </div>
                <div class="col-12 col-sm-6 text-end">
                    <button class="btn button-primary btn-block" [disabled]="!hasChanges()"
                        (click)="onSubmitClick()">Review Submission</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="stopInformation.submissionVersion != 'P2'" class="submission-print">
    <app-legacy-submission-print [issuerSubmission]="stopInformation"></app-legacy-submission-print>
</div>
<div style="letter-spacing: 0.01px;" *ngIf="stopInformation.submissionVersion == 'P2'" class="submission-print">
    <app-submission-print [stopInformation]="stopInformation"></app-submission-print>
</div>