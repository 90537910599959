import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContraventionDetailTypes, ContraventionTypes, DeclarationTypes, IntakeAppProcess, IssueServiceTypes, OffenceTypes, PaperSubmissionOptions, RecipientTypes, SubmissionProgrssStates } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Constants } from '@apis/shared/helpers/constants';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Agency } from '@apis/shared/models/agency.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Detachment } from '@apis/shared/models/detachment.model';
import { PoliceServiceInformation } from '@apis/shared/models/police-service-information.model';
import { Setting } from '@apis/shared/models/setting.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { IssueServiceType } from '@apis/shared/models/types/issue-service-type.model';
import { Warning } from '@apis/shared/models/warning.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { IntakeUser } from 'apps/intake/src/shared/models/intake-user.model';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { Guid } from 'guid-typescript';
import { SettingService } from 'apps/intake/src/shared/services/setting.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeclarationInformation } from '@apis/shared/models/declaration-information.model';
import { LicencePickupAddress } from '@apis/shared/models/licence-pickup-address.model';
import { PayCentreType } from '@apis/shared/models/types/pay-centre-type.model';
import { Observable } from 'rxjs';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CourtType } from '@apis/shared/models/types/court-type.model';
import { OffenceType } from '@apis/shared/models/types/offence-type.model';
import { IssuanceResponse } from '@apis/shared/models/issuance-response.model';
import { TicketStopInformation } from '@apis/shared/models/ticket-stop-information.model';
import { TicketResponse } from '@apis/shared/models/ticket-response.model';
import { environment } from 'apps/intake/src/environments/environment';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { SpeedingOffence } from '@apis/shared/models/speeding-offence.model';
import { TicketService } from 'apps/intake/src/shared/services/ticket.service';
import { DeclarationType } from '@apis/shared/models/types/declaration-type.model';

@Component({
  selector: 'app-print-and-issue',
  templateUrl: './print-and-issue.component.html',
  styleUrls: ['./print-and-issue.component.scss']
})
export class PrintAndIssueComponent implements OnInit {
  stopInformation: StopInformation;
  ticketStopInformation: TicketStopInformation;
  contraventions: Contravention[];
  issueServiceTypes: IssueServiceType[];
  courtTypes: CourtType[];
  offenceTypes: OffenceType[];
  isSubmitClicked: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  courtDatePickerConfig: Partial<BsDatepickerConfig>;
  IssueServiceTypes = IssueServiceTypes;
  RecipientTypes = RecipientTypes;
  errors: string[] = [];
  errorTicketResponse: TicketResponse[];

  occurrenceDate: Date;
  courtStartDate: Date;
  mailoutDate: Date;
  isReasonableGroundsToBelieve: boolean = false;
  isTSAReasonableGroundsToBelieve: boolean = false; //TODO: Is it required
  ContraventionTypes = ContraventionTypes;
  contraventionTypes: ContraventionType[];
  TypeTable = TypeTable;
  agencies: Agency[];
  detachments: Detachment[];
  pickupAddresses: LicencePickupAddress[] = [];
  selectedAgencyId: number;
  selectedDetachmentId: number;
  selectedPickupAddressId: number;
  nextPickupAddressId: number = 1;
  selectedPickupAddressTitle: string = '';
  cpsAgencyId: number;
  cesdDetachmentId: number;
  isUsingCesdPickupAddresses: boolean = false;

  enablePickupAddressSelection: boolean = false;
  user: IntakeUser;
  paperSubmissionOptionId: number;
  PaperSubmissionOptions = PaperSubmissionOptions;
  isPaperSubmissionPossible: boolean = false;
  isEmergencySubmissionPossible: boolean = false;
  isSubmitted: boolean = false;
  userPreviouslyRetriedToSubmit: boolean = false;
  Constants = Constants;
  payCentreControl = new UntypedFormControl();
  payCentreTypes: any;
  selectedPayCentreType: PayCentreType;
  filteredPayCentres: Observable<string[]>;
  availableTSA: boolean = false;
  speedingOffences: SpeedingOffence[];
  ticketsHealthStatusMessage: string = "";
  officerSubmissionDeclaration: DeclarationType;
  declarationTypes: DeclarationType[];
  isTrueInformation: boolean = false;

  constructor(private intakeService: IntakeService,
    private localStorageService: LocalStorageService,
    private readonly settingService: SettingService,
    private connectivityService: ConnectivityService,
    private router: Router,
    private readonly spinner: NgxSpinnerService,
    private ticketService: TicketService) { 
    //Check if TSA feature is allowed or not
    this.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();

    this.datePickerConfig = Object.assign({}, 
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true,
          customTodayClass: 'custom-today-class'
          })
      this.courtDatePickerConfig = Object.assign({},
        {
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat: 'YYYY/MM/DD',
          minDate: new Date(2020,11,1), //Month is zero based index so 11 is December
          isAnimated: true,
          customTodayClass: 'custom-today-class',
          daysDisabled: [0, 6]
          })

          //Get Pay Centre Types
          this.payCentreTypes = this.localStorageService.getPayCentreTypes();
          this.payCentreControl.setValidators(this.validatePayCentres(this.payCentreTypes));

          this.contraventionTypes = this.localStorageService.getContraventionTypes();
    }

  ngOnInit(): void {
    this.userPreviouslyRetriedToSubmit = false;

    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.CreatingContravention);

    //Get stop information object
    this.spinner.hide();
    this.stopInformation = this.intakeService.getStopInformationModel();
    this.contraventions = this.stopInformation.contraventions;
    this.issueServiceTypes = this.localStorageService.getIssueServiceTypes();
    this.courtTypes = this.localStorageService.getCourtTypes().filter(x => DateUtil.isActive(x.effectiveDate, x.expiryDate) && x.isYouthCourt == DateUtil.isYouth(this.stopInformation?.recipient?.recipientIdentification?.dateOfBirth));
    this.offenceTypes = this.localStorageService.getOffenceTypes();
    this.agencies = this.localStorageService.getAgencies().filter(x => !x.isDeleted);
    this.detachments = this.localStorageService.getDetachments().filter(x => !x.isDeleted);
    this.user = this.localStorageService.getUser();
    this.payCentreTypes = this.localStorageService.getPayCentreTypes();
    this.speedingOffences = this.localStorageService.getSpeedingOffences();
    this.declarationTypes = this.localStorageService.getDeclarationTypes();
    this.officerSubmissionDeclaration = this.declarationTypes?.find(d => d.id == DeclarationTypes.OfficerSubmissionDeclarationV2);
    this.filteredPayCentres = this.payCentreControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterPayCentres(value))
    );

    // Initialize pay centre
    this.selectedPayCentreType = this.payCentreTypes.find(x => x.id == this.stopInformation.ticketPayCentreTypeId);

    //Check if this step in the process is allowed or not.
    if (!this.stopInformation?.progressId || this.stopInformation.progressId < SubmissionProgrssStates.PrintAndIssue || this.stopInformation.stopInformationId > 0)
      this.router.navigateByUrl('/contravention/submission/recipient-information');

    this.occurrenceDate = this.contraventions[0].occurrenceDate;
    this.courtStartDate = DateUtil.addDays(this.occurrenceDate, 22); //Court date is at least 21 days away from occurrence date. So we allow Day 22 calendar days onwards as allowed dates.
    this.stopInformation.issueDate = this.contraventions[0].issueDate;
    this.mailoutDate = this.contraventions[0].mailoutDate;

    // Initialize licence pickup address properties
    this.selectedAgencyId = this.user?.service;
    this.selectedDetachmentId = this.user?.detachment;

    // Initialize properties for CESD detachment (Calgary Community Safety) licence pickup (JTI-7299)
    this.cpsAgencyId = this.agencies.find(agency => agency.agencyCode === "CPS").agencyId;
    let cpoAgencyId = this.agencies.find(agency => agency.agencyCode === "CPO").agencyId;
    this.cesdDetachmentId = this.detachments.find(detachment => detachment.agencyId === cpoAgencyId && detachment.agencyCode === "CESD").detachmentId;

    if (this.cesdDetachmentId === +this.selectedDetachmentId)
    {
      this.selectedPickupAddressId = null;
      this.isUsingCesdPickupAddresses = true;
      this.fillPickupAddressList(this.cpsAgencyId); // fill pickup address list for CPS agency, instead of the selected agency id (CPO)
    }
    else
    {
      this.fillPickupAddressList(this.selectedAgencyId);
      this.prefillSelectedPickupAddress(this.selectedDetachmentId);
    }

    //Check if emergency and paper submissions are possible
    this.isEmergencySubmissionPossible = this.stopInformation.contraventions.length == (this.stopInformation.contraventions.filter(x => this.isIRSorSDP(x.contraventionTypeId)).length);
    this.isPaperSubmissionPossible = this.isEmergencySubmissionPossible && this.isLegacySubmission();

    //Check if emergency/paper submission is allowed
    this.settingService.getSettings()
    .subscribe((settings: Setting[]) => {
      if (settings) {
        this.paperSubmissionOptionId = +settings.find(s => s.settingName == Constants.Settings.PAPER_SUBMISSION_OPTION)?.settingValue;
      }
    });

    //Check if the driver's licence is seized, meaning a pick-up address should be selected
    let licenceDestroyed = false;
    this.contraventions.forEach(contravention => {
      if (contravention.isLicenceSeized) {
        this.enablePickupAddressSelection = true;
      }
      if (contravention.isLicenceDestroyed) {
        licenceDestroyed = true;
      }
    })
    if (licenceDestroyed) {
      this.enablePickupAddressSelection = false;
    }

    //Set default court location
    var courtType = this.courtTypes.find(x => x.courtLocationId == this.user?.defaultCourtLocationId);
    if (courtType != null)
    {
      this.stopInformation.courtCode = courtType.code;
      this.stopInformation.courtTime = courtType.formattedAppearanceTime;
      this.stopInformation.courtEndTime = courtType.formattedAppearanceEndTime;
    }

    // Check the health of Ticket Service
    this.checkTicketServiceHealth();

    window.scroll(0,0);  
  }

  isLegacySubmission(): boolean {
    return this.occurrenceDate <= new Date(+Constants.Intake.PAPER_SUBMISSION_DEADLINE.substring(0, 4), +Constants.Intake.PAPER_SUBMISSION_DEADLINE.substring(5, 7)-1, +Constants.Intake.PAPER_SUBMISSION_DEADLINE.substring(8, 10))
  }

  onServiceTypeChange()
  {
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    if (this.stopInformation.issueServiceTypeId == IssueServiceTypes.Mailed)
    {
      this.mailoutDate =  DateUtil.addBusinessDays(today, 3, this.localStorageService.getHolidayList());
      this.mailoutDate.setHours(0, 0, 0, 0);
      let daysToAdd = this.stopInformation.recipient?.recipientContact?.provinceId == 1? 7 : 14; // If Recipient is from Alberta then 7 days otherwise 14 days
      this.stopInformation.issueDate = DateUtil.addDays(this.mailoutDate, daysToAdd);
    }
    else
    {
      this.mailoutDate = null;
      this.stopInformation.issueDate = today;
    }

    //Trim the time from date fields
    this.stopInformation.issueDate.setHours(0, 0, 0, 0);
  }

  onAgencyChange()
  {
    this.selectedDetachmentId = null;
    this.selectedPickupAddressId = null;
    this.isUsingCesdPickupAddresses = false;
    this.fillPickupAddressList(this.selectedAgencyId);
  }

  fillPickupAddressList(agencyId: number)
  {
    if (this.selectedAgencyId != null)
    {
      let returnByMailAdded = false;
      this.pickupAddresses = [];
      const agencyDetachments = this.detachments.filter(detachment => detachment.agencyId === +agencyId);
      agencyDetachments.forEach(detachment => {
        if (detachment.returnLicenceByMail && !returnByMailAdded)
        {
          this.pickupAddresses.unshift(new LicencePickupAddress(this.nextPickupAddressId, true, Constants.Resource.RETURN_LICENCE_BY_MAIL_TEXT));
          this.nextPickupAddressId += 1;
          returnByMailAdded = true;
        }
        else {
          if (detachment.pickupAddress1)
          {
            this.pickupAddresses.push(new LicencePickupAddress(this.nextPickupAddressId, false, `${detachment.detachmentName}: ${detachment.pickupAddress1}`, detachment.pickupAddress1, detachment.detachmentName));
            this.nextPickupAddressId += 1;
          }
          if (detachment.pickupAddress2)
          {
            this.pickupAddresses.push(new LicencePickupAddress(this.nextPickupAddressId, false, `${detachment.detachmentName}: ${detachment.pickupAddress2}`, detachment.pickupAddress2, detachment.detachmentName));
            this.nextPickupAddressId += 1;
          }
        }
      });
    }
  }

  onDetachmentChange(detachmentId: number)
  {
    if (!this.enablePickupAddressSelection)
    {
      return;
    }

    // If CESD detachment was previously selected, and we are selecting a different detachment:
    if (this.isUsingCesdPickupAddresses && this.cesdDetachmentId !== +detachmentId)
    {
      this.selectedPickupAddressId = null;
      this.isUsingCesdPickupAddresses = false;
      this.fillPickupAddressList(this.selectedAgencyId); // fill pickup address list for selected agency id
      this.prefillSelectedPickupAddress(detachmentId);
      return;
    }

    // If CESD detachment is selected
    if (this.cesdDetachmentId === +detachmentId)
    {
      this.selectedPickupAddressId = null;
      this.isUsingCesdPickupAddresses = true;
      this.fillPickupAddressList(this.cpsAgencyId); // fill pickup address list for CPS agency, instead of the selected agency id (CPO)
      return;
    }

    this.prefillSelectedPickupAddress(detachmentId);
  }

  prefillSelectedPickupAddress(detachmentId: number)
  {
    this.selectedPickupAddressId = null;
    const selectedDetachment = this.detachments.find(detachment => detachment.detachmentId === +detachmentId);
    if (selectedDetachment != null)
    {
      if (selectedDetachment.returnLicenceByMail)
      {
        this.selectedPickupAddressId = this.pickupAddresses.find(pickupAddress => pickupAddress.isReturnLicenceByMail)?.id;
      }
      else if (selectedDetachment.pickupAddress1)
      {
        this.selectedPickupAddressId = this.pickupAddresses.find(pickupAddress => pickupAddress.address === selectedDetachment.pickupAddress1)?.id;
      }
    }
    this.onPickupAddressChange(this.selectedPickupAddressId)
  }
  
  onPickupAddressChange(id: number | string) {
    this.selectedPickupAddressTitle = this.pickupAddresses.find(address => address.id === +id)?.name;
  }

  onMailedDateChange()
  {
    let daysToAdd = this.stopInformation.recipient?.recipientContact?.provinceId == 1? 7 : 14; // If Recipient is from Alberta then 7 days otherwise 14 days
    this.stopInformation.issueDate = DateUtil.addDays(this.mailoutDate, daysToAdd);
    this.stopInformation.issueDate.setHours(0, 0, 0, 0);
  }

  getContraventionTypeDescription(contraventionTypeId: number)
  {
    var contraventionType = this.contraventionTypes?.find(x=>x.id==contraventionTypeId);
    return contraventionType? contraventionType.formattedName : "";
  }

  isIRSorSDP(contraventionTypeId: number)
  {
    return this.contraventionTypes?.filter(x=>x.id==contraventionTypeId && (x.isIRS || x.isSDP)).length > 0;
  }

  isIRS(contraventionTypeId: number)
  {
    return this.contraventionTypes?.filter(x=>x.id==contraventionTypeId && x.isIRS).length > 0;
  }

  isSDP(contraventionTypeId: number)
  {
    return this.contraventionTypes?.filter(x=>x.id==contraventionTypeId && x.isSDP).length > 0;
  }

  isNonTSASDP(contraventionTypeId: number)
  {
    return this.contraventionTypes?.filter(x=>x.id==contraventionTypeId && x.isNonTSASDP).length > 0;
  }

  isOffenceTypeEditable(contravention: Contravention)
  {
    var contraventionType = this.contraventionTypes?.find(x=>x.id==(contravention.tertiaryContraventionTypeId??(contravention.secondaryContraventionTypeId??contravention.contraventionTypeId)));
    if (contraventionType.isSpeedingOffence)
    {
      return contravention.speedingTicket.isPart3Offence == true;
    }
    else
    {
      return contraventionType.offenceTypeId == OffenceTypes.Part3OffenceNotice;
    }
  }

  onOffenceTypeChange(contravention: Contravention)
  {
    var contraventionType = this.contraventionTypes?.find(x=>x.id==contravention.contraventionTypeId)
    contravention.fine.fineAmount = contravention.offenceTypeId == OffenceTypes.Part2SummonsMandatoryCourt? 0 : contraventionType.fineAmount;

    if (contravention.offenceTypeId == OffenceTypes.Part2SummonsMandatoryCourt)
      contravention.fine.fineAmount = 0;
    else
    {
      if (contraventionType.isSpeedingOffence)
        //Calculate speeding fine
        if (!isNaN(+contravention.speedingTicket.ticketedSpeed) && !isNaN(contravention.speedingTicket.speedLimit) && +contravention.speedingTicket.ticketedSpeed > +contravention.speedingTicket.speedLimit)
        {
          var kilometersOverSpeeding = contravention.speedingTicket.ticketedSpeed - contravention.speedingTicket.speedLimit;
          var speedingOffenceType =  this.speedingOffences.find(x=> kilometersOverSpeeding >= x.speedOverFrom && kilometersOverSpeeding <= x.speedOverTo && x.contraventionTypeId == (contravention.tertiaryContraventionTypeId??(contravention.secondaryContraventionTypeId??contravention.contraventionTypeId)));

          contravention.fine.fineAmount = speedingOffenceType.fineAmount;
          contravention.demerits = this.stopInformation?.recipient?.recipientTypeId == RecipientTypes.RegisteredOwner? 0 : speedingOffenceType.demerits; // Demerits are not applicable to RO
          contravention.speedingTicket.isPart3Offence = speedingOffenceType.isPart3Offence;
        }
        else
        {
          contravention.fine.fineAmount = 0;
          contravention.demerits = 0;
        }
      else
        contravention.fine.fineAmount = contraventionType.fineAmount
    }  

  }

  onCourtTypeChange()
  {
    this.stopInformation.courtTime = this.courtTypes.find(x => x.code == this.stopInformation.courtCode)?.formattedAppearanceTime;
    this.stopInformation.courtEndTime = this.courtTypes.find(x => x.code == this.stopInformation.courtCode)?.formattedAppearanceEndTime;
  }

  resetIssueDate()
  {
    if( this.stopInformation.isPaperSubmission) {
      this.stopInformation.issueDate = null;
    } else {
      this.stopInformation.issueDate = this.contraventions[0].issueDate;
    }
    this.mailoutDate = this.contraventions[0].mailoutDate;
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
    if (isValid && (this.selectedPayCentreType || !this.availableTSA || !(this.stopInformation.isTSASelected || this.stopInformation.isTSASDPSelected)))
    {
      //Double check if the selected court location is still active
      if (this.availableTSA && (this.stopInformation.isTSASelected || this.stopInformation.isTSASDPSelected))
      {
        var selectedCourt = this.courtTypes.find(x => x.code == this.stopInformation.courtCode);
        if (!DateUtil.isActive(selectedCourt.effectiveDate, selectedCourt.expiryDate))
        {
          this.errors = [];
          this.errors.push("Selected court location is no longer active. Please refresh the screen and retry");
          return;
        }
      }

      //Set the pay centre
      this.stopInformation.ticketPayCentreTypeId = this.selectedPayCentreType?.id;

      //Reset Time for date fields
      this.stopInformation.issueDate.setHours(0,0,0,0);
      if (this.stopInformation.issueServiceTypeId == IssueServiceTypes.Mailed)
        this.mailoutDate.setHours(0,0,0,0);

      if(this.stopInformation.recipient.recipientTypeId == RecipientTypes.NotInVehicle)
        this.stopInformation.vehicle = null;

      //Check if it is an emergency submission or paper submission
      if (!this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission)
      {
        this.contraventions.forEach(contravention => {
          contravention.contraventionNumber = null;
        if (contravention.vehicleSeizure)
          contravention.vehicleSeizure.seizureNumber = null;
        });
      }

      //Add police service information entity
      var policeServiceInformation = new PoliceServiceInformation();
      policeServiceInformation.detachmentId = this.selectedDetachmentId;
      policeServiceInformation.policeOfficerName = `${this.user?.firstName} ${this.user?.lastName}`;
      policeServiceInformation.regimentalNumber = this.user?.badgeNumber;
      policeServiceInformation.issuingOfficerId = this.user?.userId;

      //Update Police Service Information and recipient
      this.contraventions.forEach(contravention => {
        contravention.policeFileNumber = this.stopInformation.policeFileNumber;
        contravention.issueDate = this.stopInformation.issueDate;
        contravention.mailoutDate = this.mailoutDate;
        contravention.isReasonableGroundsToBelieve = this.isReasonableGroundsToBelieve;
        contravention.isTrueInformation = this.isTrueInformation;
        contravention.policeServiceInformation = [];
        contravention.policeServiceInformation.push(policeServiceInformation);
        contravention.policeOfficerFullName = `${this.user?.firstName} ${this.user?.lastName}`;
        if (contravention.vehicleSeizure)
        {
          contravention.vehicleSeizure.policeFileNumber = this.stopInformation.policeFileNumber;
          contravention.vehicleSeizure.stopInformationId = null;
          contravention.vehicleSeizure.issueDate = this.stopInformation.issueDate;
          contravention.vehicleSeizure.isTrueInformation = this.isTrueInformation;
          contravention.vehicleSeizure.contraventionTypeId = contravention.contraventionTypeId;
          contravention.vehicleSeizure.policeOfficerFullName = `${this.user?.firstName} ${this.user?.lastName}`;
          contravention.vehicleSeizure.policeServiceInformation.push(policeServiceInformation);
        }
        if (this.enablePickupAddressSelection && contravention.isLicenceSeized)
        {
          const selectedPickupAddress = this.pickupAddresses.find(pickupAddress => pickupAddress.id === +this.selectedPickupAddressId);
          if (selectedPickupAddress.isReturnLicenceByMail)
          {
            contravention.returnLicenceByMail = true;
            contravention.licencePickupAddress = null;
            contravention.licencePickupDetachment = null;
          }
          else
          {
            contravention.returnLicenceByMail = false;
            contravention.licencePickupAddress = selectedPickupAddress.address;
            contravention.licencePickupDetachment = selectedPickupAddress.detachmentName;
          }
        }
      });

      //Convert SDP seizure to its own stand alone seizure (without contravention)
      var sdpSeizureIds = this.contraventionTypes?.filter(x => x.isSDP).map(s => s.id);
      var sdpSeizureContravention = this.contraventions.find(x => sdpSeizureIds.includes(x.contraventionTypeId) && !x.isNoVehicleSeizureMade);
      if (sdpSeizureContravention)
      {
        sdpSeizureContravention.vehicleSeizure.policeFileNumber = this.stopInformation.policeFileNumber;
        sdpSeizureContravention.vehicleSeizure.issueDate = this.stopInformation.issueDate;
        sdpSeizureContravention.vehicleSeizure.isTrueInformation = this.isTrueInformation;
        sdpSeizureContravention.vehicleSeizure.contraventionTypeId = sdpSeizureContravention.contraventionTypeId;
        sdpSeizureContravention.vehicleSeizure.policeOfficerFullName = `${this.user?.firstName} ${this.user?.lastName}`;
        sdpSeizureContravention.vehicleSeizure.policeServiceInformation = [];
        sdpSeizureContravention.vehicleSeizure.policeServiceInformation.push(policeServiceInformation)
        this.stopInformation.vehicleSeizures = [];
        this.stopInformation.vehicleSeizures.push(sdpSeizureContravention.vehicleSeizure);
      }

      //Set SDP Violation Ticket default fields
      var sdpContravention = this.contraventions.find(x => sdpSeizureIds.includes(x.contraventionTypeId) && this.availableTSA);
      if (sdpContravention)
      {
        sdpContravention.offenceTypeId = OffenceTypes.Part2SummonsMandatoryCourt;
        sdpContravention.fine.fineAmount = 0;
        sdpContravention.fine.victimFineSurchargeAmount = 0;
        sdpContravention.demerits = 0;
      }

      //Convert all Warnings
      var warnings = this.contraventions.filter(x => x.isWarning);
      warnings.forEach(contravention => {
        var warning = new Warning({
          contraventionTypeId : contravention.contraventionTypeId,
          secondaryContraventionTypeId: contravention.secondaryContraventionTypeId,
          tertiaryContraventionTypeId : contravention.tertiaryContraventionTypeId,
          occurrenceDate : contravention.occurrenceDate,
          occurrenceTime : contravention.occurrenceTime,
          issueDate : contravention.issueDate,
          issueTime : contravention.occurrenceTime, //TODO-Fix mapping
          fineAmount: contravention.fine.fineAmount,
          victimFineSurchargeAmount: contravention.fine.victimFineSurchargeAmount,
          demerits: contravention.demerits,
          speedLimit: contravention.speedingTicket?.speedLimit,
          recipientVehicleSpeed: contravention.speedingTicket?.recipientVehicleSpeed,
          ticketedSpeed: contravention.speedingTicket?.ticketedSpeed,
          vehicle : contravention.vehicle,
          recipient : contravention.recipient
        });

        this.stopInformation.warnings.push(warning);
      });

      this.stopInformation.progressId = SubmissionProgrssStates.NoticeIssued;
      this.stopInformation.officerSubmissionDeclarationTypeId = this.officerSubmissionDeclaration.id;
      this.intakeService.saveStopInformationContext();
      this.isSubmitted = true;
      this.spinner.show();

      this.connectivityService.getOnlineStatusAsync().then( isOnline => {
        if (isOnline) {
          if (this.areAllSeizureIssueDatesValid(sdpSeizureContravention)) {
            this.intakeService.createStopAsync(this.stopInformation).subscribe(
              (issuanceResponse: IssuanceResponse) => {
                
                //Check if only TSA charges are submitted
                if ((!this.stopInformation.isIRSSelected && !this.stopInformation.isSDPSelected) || (this.stopInformation.isSDPSelected && this.stopInformation.contraventions.length == 1 && this.stopInformation.contraventions[0].isNoVehicleSeizureMade))
                {
                  //If there is any error reported and show them under error section and stay on the issuance page
                  this.errorTicketResponse = issuanceResponse.ticketResponse.filter(x => x.responseMessage != "ok");

                  if (this.errorTicketResponse.length > 0)
                  {
                    this.errors = [];
                    this.errorTicketResponse.forEach(errorResponse => {
                      this.errors.push(`${errorResponse.responseMessage} (${errorResponse.ticketDescription})`)
                    });
                    this.spinner.hide();
                  }
                  else
                  {
                    //Load Ticket Stop and route to print confirmation
                    this.intakeService.getTicketStopInformationByIdAsync(issuanceResponse.ticketStopInformationId?.toString())
                    .subscribe((ticketStopInformation: TicketStopInformation) => {
                        this.ticketStopInformation = ticketStopInformation;
                        this.ticketStopInformation.issuanceResponse = issuanceResponse;

                        this.intakeService.resetStopInformationModel();
                        this.intakeService.createTicketStopInformationContext(this.ticketStopInformation);
                        this.spinner.hide();
                        this.router.navigateByUrl(`/contravention/submission/print-confirmation`);
                    },
                    (error: any) => {
                      this.showErrors(error);
                      this.spinner.hide();
                    }
                    );
                  }
                }
                else
                {
                  //Setup new stop information context
                  this.intakeService.getStopInformationByIdAsync(issuanceResponse.stopInformationId?.toString())
                    .subscribe((stopInformation: StopInformation) => {
                        this.stopInformation = stopInformation;
                        this.stopInformation.issuanceResponse = issuanceResponse;
                        this.intakeService.resetTicketStopInformationModel();
                        this.intakeService.createStopInformationContext(this.stopInformation);
                        this.spinner.hide();
                        this.router.navigateByUrl(`/contravention/submission/print-confirmation`);
                    },
                    (error: any) => {
                      this.showErrors(error);
                      this.spinner.hide();
                    }
                  );
                }
              },
              (error: any) => {
                this.showErrors(error);
                this.spinner.hide();
              }
            );
          } else {
            this.spinner.hide();
            this.errors = [];
            this.errors.push("Something went wrong and issuance date is invalid. Please select the issuance date and submit again.");
            window.scroll(0,0);
          }
        } else {
          // assign the contravention numbers with offline numbers, if they aren't assigned already
          //
          this.stopInformation.contraventions.forEach( (c, index) => {

            if (!c.contraventionNumber || (c.contraventionNumber && (c.contraventionNumber === "0" || c.contraventionNumber === ""))) {
              let newContraventionNumber = this.localStorageService.getNextOfflineNumber();
              this.stopInformation.contraventions[index].contraventionNumber = newContraventionNumber;
              this.localStorageService.removeOfflineNumber(newContraventionNumber);
            }

            // if there is a vehicle seizure, assign it a number
            if (c.vehicleSeizure!==null && c.vehicleSeizure!==undefined) {
              if ( !c.vehicleSeizure.seizureNumber || (c.vehicleSeizure.seizureNumber && (c.vehicleSeizure.seizureNumber === "0" || c.vehicleSeizure.seizureNumber === ""))) {
                let newSeizureNumber = this.localStorageService.getNextOfflineSeizureNumber();
                this.stopInformation.contraventions[index].vehicleSeizure.seizureNumber = newSeizureNumber;
                this.localStorageService.removeOfflineSeizureNumber(c.vehicleSeizure.seizureNumber);
              }
            }
          });

          // assign the seizure numbers with offline numbers, if they aren't assigned already
          //
          if (this.stopInformation.vehicleSeizures!==null  && this.stopInformation.vehicleSeizures!==undefined) {
            this.stopInformation.vehicleSeizures.forEach( (v, index) => {
              if (v.seizureNumber && (v.seizureNumber === "" || v.seizureNumber === "")) {
                let newSeizureNumber = this.localStorageService.getNextOfflineSeizureNumber();
                this.stopInformation.vehicleSeizures[index].seizureNumber = newSeizureNumber;
                this.localStorageService.removeOfflineSeizureNumber(v.seizureNumber );
              }
            });
          }

          // update the contraventions with the generated contravention numbers
          this.intakeService.saveStopInformationContext();

          let currentOfflineKey: string = this.localStorageService.getCurrentOfflineId();

          // have we created the IndexDB row for the current contravention, if not, create it
          if (currentOfflineKey === null) {

            currentOfflineKey = Guid.create().toString();
            this.intakeService.createStopOffline(this.stopInformation, currentOfflineKey).then( result => {

              this.localStorageService.setCurrentOfflineId(currentOfflineKey);
              this.intakeService.createStopInformationContext(this.stopInformation);
              this.spinner.hide();
              this.router.navigateByUrl(`/contravention/submission/offline-print-confirmation`);

            },
            (error: any) => {
              this.showErrors(error);
              this.spinner.hide();
            });
          } else {

            this.intakeService.updateStopOffline(this.stopInformation, currentOfflineKey).then( result => {

              this.intakeService.createStopInformationContext(this.stopInformation);
              this.spinner.hide();
              this.router.navigateByUrl(`/contravention/submission/offline-print-confirmation`);

            }).catch(e => {
              this.showErrors(e);
              this.spinner.hide();
            });

          }
        }
      });

    }
    else
    {
      window.scroll(0,0);
    }
  }

  areAllSeizureIssueDatesValid(sdpSeizureContravention: Contravention): boolean {

    let validIssueDates = true;

    // check all the contravention seizures
    this.contraventions.forEach(contravention => {
      if (contravention.issueDate < this.occurrenceDate)
        validIssueDates = false;

      if (contravention.vehicleSeizure)
      {
        if (contravention.vehicleSeizure.issueDate < this.occurrenceDate)
          validIssueDates = false;
      }
    });

    if (sdpSeizureContravention)
    {
      if (sdpSeizureContravention.vehicleSeizure.issueDate < this.occurrenceDate)
        validIssueDates = false;
    }

    if (!validIssueDates) {
      if (!this.userPreviouslyRetriedToSubmit)
      {
        this.userPreviouslyRetriedToSubmit = true;
      } else {
        validIssueDates = true;
      }
    }
    return validIssueDates;
  }

  isCommercialContravention(): boolean {
    var irsContraventionTypeIds = this.contraventionTypes?.filter(x => x.isIRS).map(s => s.id);
    var irsContravention: Contravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));
    if (irsContravention!=null) {
      if( irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial1st ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial2nd ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial3rd) {
            return true;
      }
    }
    return false;
  }

  isRoadsideAppealRequired(contravention: Contravention)
  {
    // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
    //
    var detailsSelected = contravention?.contraventionDetails?.split(",");
    var totalDetailsSelected = detailsSelected?.length;

    var isRefusal = (
        (contravention.contraventionTypeId == ContraventionTypes.IRSFail1st &&
          (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
        (contravention.contraventionTypeId == ContraventionTypes.IRSFail2nd &&
          (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
        (contravention.contraventionTypeId == ContraventionTypes.IRSFail3rd &&
          (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
    )

    return contravention.contraventionTypeId != ContraventionTypes.IRS24 && !isRefusal;
  }

  showErrors(error: any) {
    this.errors = [];
    if (error?.error && Array.isArray(error?.error))
      this.errors = error.error;
    else if (error?.error?.errors && Array.isArray(error?.error?.errors))
      this.errors = error.error.errors;
    else if (typeof error?.error === 'string' || error?.error instanceof String)
      this.errors.push(error.error);
    else if (typeof error?.error?.error === 'string' || error?.error?.error instanceof String)
      this.errors.push(error.error.error);
    else if (typeof error?.error?.errors === 'string' || error?.error?.errors instanceof String)
      this.errors.push(error.error.errors);  
    else    
      this.errors.push("Submission timed out. Please check your dashboard before re-submission to avoid duplicates.");
  }

  sortedContraventions = (a: Contravention, b: Contravention): number => { //To always show contraventions in this order: Contravention, SDP Seizure, Traffic Ticket(s)
    if(!this.isIRSorSDP(a.contraventionTypeId) && this.isIRSorSDP(b.contraventionTypeId)) return 1;
    if((this.isIRSorSDP(a.contraventionTypeId) && !this.isIRSorSDP(b.contraventionTypeId)) || (this.isIRS(a.contraventionTypeId) && this.isSDP(b.contraventionTypeId))) return -1;
    if(this.isIRSorSDP(a.contraventionTypeId) && this.isIRS(b.contraventionTypeId)) return 0;
  }

  getTicketIndex(index: number)
  {
    var calculatedIndex = index + 1 - this.contraventions.filter(x => this.isIRSorSDP(x.contraventionTypeId)).length;
    if (this.contraventions.filter(x => this.isSDP(x.contraventionTypeId) && this.availableTSA).length > 0)
      calculatedIndex++;

    return calculatedIndex;
  }

  validatePayCentres(payCentreNames: PayCentreType[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = payCentreNames.findIndex(x => {
        return (new RegExp('\^' + x.name + '\$')).test(control.value?.name);
      });
      return index < 0 ? { 'forbiddenPayCentres': { value: control.value } } : null;
    };
  }

  displayPayCentre(subject){
    return subject? subject.code : undefined;
  }

  onCourtDateChange()
  {
    if (this.stopInformation.courtDate)
      this.stopInformation.courtDate.setHours(0, 0, 0, 0);

    this.intakeService.saveStopInformationContext();
  }

  private checkTicketServiceHealth()
  {
    // Perform ticket service health check only if there is any traffic ticket selected
    if (this.stopInformation.isTSASelected)
    {
      this.ticketService.getTicketServiceHealthCheck()        
      .subscribe((result: string) => {
        if (result.toLowerCase() != Constants.Status.HEALTHCHECK_HEALTHY_TEXT)
          this.ticketsHealthStatusMessage = Constants.Intake.Messages.TICKETS_HEALTH_STATUS_MESSAGE;
        else
          this.ticketsHealthStatusMessage = "";
      }, () => {
        this.ticketsHealthStatusMessage = Constants.Intake.Messages.TICKETS_HEALTH_STATUS_MESSAGE;
      });
    }
    else
    this.ticketsHealthStatusMessage = "";
  }

  private _filterPayCentres(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.payCentreTypes.filter(option =>
      option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue));
  }
}
