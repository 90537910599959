<div class="submissionMain">
    <div class="d-flex flex-column">
        <div class="row">
            <div class="col-12 col-sm-8">        
                <h1>
                    {{title}}
                </h1>
            </div>
        </div>

        <div class="card border-0">
            <h6>
                {{message}}
            </h6>
            <p class="mb-0 mt-0 ps-4" *ngFor="let error of errors">• {{error}}</p>
        </div>

        <div class="mb-3 mt-4">
            <div class="row pt-3">
                <div class="col-12 col-sm-12">                
                    <a class="btn button-primary float-end" [routerLink]="['/']">Return to Dashboard</a>
                </div>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
    </div>
</div>