<form #vehicleSeizureForm="ngForm">
    <div class="d-flex flex-column">
        <h1>Vehicle Seizure Details</h1> 
        <div class="row">
            <div class="col-12 col-sm-4" *ngIf="stopInformation.isIRSSelected">
                <div class="form-check mt-2">
                    <input class="form-check-input" type="checkbox" id="noIRSSeizureMade" name="noIRSSeizureMade" 
                    [disabled]="isCommercialContravention()" [(ngModel)]="contravention.isNoVehicleSeizureMade" (change)="onNoIRSSeizureMadeChange($event)">
                    <label class="form-check-label" for="noIRSSeizureMade">
                        No IRS Seizure Made
                    </label>
                </div>
            </div>
            <div class="col-12 col-sm-4" *ngIf="stopInformation.isTSASDPSelected && availableTSA">
                <div class="form-check mt-2">
                    <input class="form-check-input" type="checkbox" id="noSDPSeizureMade" name="noSDPSeizureMade" 
                    [(ngModel)]="contravention2.isNoVehicleSeizureMade" (change)="onNoSDPSeizureMadeChange($event)">
                    <label class="form-check-label" for="noSDPSeizureMade">
                        No SDP Seizure Made
                    </label>
                </div>
            </div>
        </div>
        
        <ng-container *ngIf="isNoIRSSeizureMade">
            <div class="card mt-4">
                <div class="card-header">
                    No IRS Vehicle Seizure Details
                </div>
                <div class="card-body">
                    <div class="row mt-1">
                        <div class="col-lg-12">
                            <textarea required #noSeizureReasonControl="ngModel" class="form-control" id="noSeizureReason" 
                            name="noSeizureReason" rows="3" maxlength="5000" placeholder="Max 5000 characters..." 
                            [(ngModel)]="contravention.noVehicleSeizureDetails" [disabled]="isCommercialContravention()"
                            [ngClass]="{'is-invalid':(noSeizureReasonControl.touched || isSubmitClicked) && noSeizureReasonControl.invalid}"></textarea>
                            <div class="invalid-feedback">
                                <div>Reason is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isNoSDPSeizureMade">
            <div class="card mt-4">
                <div class="card-header">
                    No SDP Vehicle Seizure Details
                </div>
                <div class="card-body">
                    <div class="row mt-1">
                        <div class="col-lg-12">
                            <textarea required #noSDPSeizureReasonControl="ngModel" class="form-control" id="noSDPSeizureReason" 
                            name="noSDPSeizureReason" rows="3" maxlength="5000" placeholder="Max 5000 characters..." 
                            [(ngModel)]="contravention2.noVehicleSeizureDetails" 
                            [ngClass]="{'is-invalid':(noSDPSeizureReasonControl.touched || isSubmitClicked) && noSDPSeizureReasonControl.invalid}"></textarea>
                            <div class="invalid-feedback">
                                <div>Reason is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="(stopInformation.isIRSSelected && !isNoIRSSeizureMade) || (stopInformation.isSDPSelected && !isNoSDPSeizureMade)">
            <div class="card mt-4">
                <div class="card-header">
                    Seizure Information
                </div>
                <div class="card-body">
                    <div class="mb-3 row">
                        <div class="col-lg-6" *ngIf="stopInformation.isIRSSelected && !isNoIRSSeizureMade">
                            <label for="seizureType">Seizure Type <span class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="true" #seizureTypeControl="ngModel" class="form-select" id="seizureType" name="seizureType" [(ngModel)]="selectedSeizureType" (ngModelChange)="onSeizureTypeChange($event)" [ngClass]="{'is-invalid':(seizureTypeControl.touched || isSubmitClicked) && seizureTypeControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Seizure Type</option>
                                <option *ngFor="let seizureType of seizureTypes" [ngValue]="seizureType">{{seizureType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Seizure Type is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="!stopInformation.isIRSSelected || isNoIRSSeizureMade">
                            <label for="seizureType">Seizure Type <span class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="true" #seizureTypeControl="ngModel" class="form-select" id="seizureType" name="seizureType" [(ngModel)]="selectedSeizureType2" (ngModelChange)="onSeizureType2Change($event)" [ngClass]="{'is-invalid':(seizureTypeControl.touched || isSubmitClicked) && seizureTypeControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Seizure Type</option>
                                <option *ngFor="let seizureType of seizureTypes2" [ngValue]="seizureType">{{seizureType.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Seizure Type is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-4">
                            <label for="seizureDate">Seizure Date <span class="label-hint">(Required)</span></label>
                            <input readonly required isLessThanStartDate="2020-12-01" maxlength="10" #seizureDateControl="ngModel" type="text" bsDatepicker [bsConfig]="seizureDatePickerConfig" class="form-control readonly-active-control" id="seizureDate" name="seizureDate" placeholder="yyyy/mm/dd" [(ngModel)]="seizureDate" (ngModelChange)="onSeizureDateChange()" [ngClass]="{'is-invalid':(seizureDateControl.touched || isSubmitClicked) && seizureDateControl.invalid,'warning': DateUtil.isPastDate(seizureDateControl.value, 2) && (seizureDateControl.touched || isSubmitClicked) && !seizureDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="seizureDateControl.hasError('required')">Seizure Date is required</div>
                                <div *ngIf="!seizureDateControl.hasError('required') && seizureDateControl.hasError('EarlierDate')">Seizure Date can't be earlier than 01 Dec, 2020</div>
                                <div *ngIf="!seizureDateControl.hasError('required') && !seizureDateControl.hasError('EarlierDate')">Invalid Seizure Date</div>
                            </div>
                            <div class="warningMessage" *ngIf="DateUtil.isPastDate(seizureDateControl.value, 2) && (seizureDateControl.touched || isSubmitClicked) && !seizureDateControl.invalid">
                                <div>Seizure Date is in the past, please verify.</div>                                
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label for="seizureTime">Seizure Time <span class="label-hint">(Required)</span></label>
                            <input required #seizureTimeControl="ngModel" type="text" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                            pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" placeholder="--:--" class="form-control" id="seizureTime" name="seizureTime" (change)="validateSeizureDateTime()" [(ngModel)]="seizureTime" [ngClass]="{'is-invalid':(seizureTimeControl.touched || isSubmitClicked) && seizureTimeControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="seizureTimeControl.errors?.required">Seizure Time is required</div>
                                <div *ngIf="!seizureTimeControl.errors?.required">Invalid Seizure Time</div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="stopInformation.isIRSSelected && !isNoIRSSeizureMade">
                            <label for="seizureDuration">Immediate Seizure Duration</label>
                            <input type="text" readonly class="form-control" id="seizureDuration" name="seizureDuration" value="{{(vehicleSeizure.seizureDurationType == null || vehicleSeizure.seizureDurationType == '')? '' : vehicleSeizure.seizureDuration + ' ' + vehicleSeizure.seizureDurationType}}">
                        </div>
                        <div class="col-lg-4" *ngIf="!stopInformation.isIRSSelected || isNoIRSSeizureMade">
                            <label for="seizureDuration">Immediate Seizure Duration</label>
                            <input type="text" readonly class="form-control" id="seizureDuration" name="seizureDuration" value="{{(vehicleSeizure2.seizureDurationType == null || vehicleSeizure2.seizureDurationType == '')? '' : vehicleSeizure2.seizureDuration + ' ' + vehicleSeizure2.seizureDurationType}}">
                        </div>
                    </div>
                    <div class="custom-invalid-feedback mb-3" *ngIf="isFutureSeizureDateTime">
                        <div>Seizure date and time cannot be in the future</div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-4" *ngIf="stopInformation.isIRSSelected && !isNoIRSSeizureMade">
                            <label for="vehicleReleaseDate">Vehicle Release Date</label>
                            <input type="text" readonly class="form-control" id="vehicleReleaseDate" name="vehicleReleaseDate" value="{{vehicleSeizure.vehicleReleaseDate | date:'yyyy/MM/dd'}}">
                        </div>
                        <div class="col-lg-4" *ngIf="!stopInformation.isIRSSelected || isNoIRSSeizureMade">
                            <label for="vehicleReleaseDate">Vehicle Release Date</label>
                            <input type="text" readonly class="form-control" id="vehicleReleaseDate" name="vehicleReleaseDate" value="{{vehicleSeizure2.vehicleReleaseDate | date:'yyyy/MM/dd'}}">
                        </div>
                        <div class="col-lg-8">                    
                            <label for="location">Location of Seizure <span class="label-hint">(Required)</span></label>
                            <input readonly required type="text" class="form-control" id="location" name="location" [formControl]="locationControl" [matAutocomplete]="auto" placeholder="Enter and select a Location" [(ngModel)]="selectedLocationType" [ngClass]="{'is-invalid': (locationControl.touched || isSubmitClicked) && locationControl.invalid}"> 
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayLocation" (optionSelected)="onLocationTypeChange($event.option.value)">
                                <mat-option *ngFor="let locationType of filteredLocations | async" [value]="locationType">
                                    {{locationType.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error class="validation-message" *ngIf="(locationControl.touched || isSubmitClicked) && locationControl.hasError('required')">
                                Location of Seizure is required
                            </mat-error>
                            <mat-error class="validation-message" *ngIf="(locationControl.touched || isSubmitClicked) && locationControl.hasError('forbiddenLocations') && !locationControl.hasError('required')">
                                Invalid Location of Seizure
                            </mat-error>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-12">
                            <label for="additionalLocationInformation">Additional Location Information</label>
                            <input type="text" maxlength="100" class="form-control" id="additionalLocationInformation" name="additionalLocationInformation" placeholder="Enter Additional Location Information" [(ngModel)]="stopInformation.occurrenceLocation.additionalLocationDetails">
                        </div>
                    </div>
                    <ng-container *ngIf="stopInformation.isSDPSelected && !isNoSDPSeizureMade && stopInformation.isIRSSelected && !isNoIRSSeizureMade">
                        <div class="heading-underline"></div>
                        <div class="mb-3 row">
                            <div class="col-lg-6">
                                <label for="secondSeizureType">Second Seizure Type <span class="label-hint">(Required)</span></label>
                                <select [appSelectValidator]="true" #secondSeizureTypeControl="ngModel" class="form-select" id="secondSeizureType" name="secondSeizureType" [(ngModel)]="selectedSeizureType2" (ngModelChange)="onSeizureType2Change($event)" [ngClass]="{'is-invalid':(secondSeizureTypeControl.touched || isSubmitClicked) && secondSeizureTypeControl.errors?.defaultSelected}">
                                    <option [ngValue]="null">Select a Seizure Type</option>
                                    <option *ngFor="let seizureType of seizureTypes2" [ngValue]="seizureType">{{seizureType.name}}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <div>Seizure Type is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-lg-4">
                                <label for="secondSeizureDuration">Immediate Seizure Duration</label>
                                <input type="text" readonly class="form-control" id="secondSeizureDuration" name="secondSeizureDuration" value="{{(vehicleSeizure2.seizureDurationType == null || vehicleSeizure2.seizureDurationType == '')? '' : vehicleSeizure2.seizureDuration + ' ' + vehicleSeizure2.seizureDurationType}}">
                            </div>
                            <div class="col-lg-4">
                                <label for="vehicleReleaseDate">Vehicle Release Date</label>
                                <input type="text" readonly class="form-control" id="secondVehicleReleaseDate" name="secondVehicleReleaseDate" value="{{vehicleSeizure2.vehicleReleaseDate | date:'yyyy/MM/dd'}}">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="card mt-4">
                <div class="card-header">
                    Vehicle Location & Tow Lot Information
                </div>
                <div class="card-body">
                    <div class="mb-3 row">
                        <div class="col-lg-6">                    
                            <label for="towingCompany">Towing Company</label>
                            <input type="text" class="form-control" id="towingCompany" name="towingCompany" [formControl]="towCompanyControl" [matAutocomplete]="autoTowings" placeholder="Select a Towing Company" [(ngModel)]="selectedTowCompanyType" [ngClass]="{'is-invalid': (towCompanyControl.touched || isSubmitClicked) && towCompanyControl.invalid}"> 
                            <mat-autocomplete #autoTowings="matAutocomplete" [displayWith]="displaytowingCompany" (optionSelected)="onTowLotChange($event.option.value)">
                                <mat-option *ngFor="let towCompany of filteredTowCompanies | async" [value]="towCompany" class="mat-option">
                                    {{towCompany.towCompanyName}}
                                </mat-option>
                            </mat-autocomplete> 
                        </div>
                        <div class="col-lg-6">
                            <label for="towingCompanyName">Name of Towing Company <span class="label-hint">(Required)</span></label>
                            <input required #towingCompanyNameControl="ngModel" maxlength="50" type="text" class="form-control" id="towingCompanyName" name="towingCompanyName" placeholder="Enter Name of Company" [(ngModel)]="towCompanyName" [ngClass]="{'is-invalid':(towingCompanyNameControl.touched || isSubmitClicked) && towingCompanyNameControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Name of Towing Company is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="towLotAddress">Tow Lot Address <span class="label-hint">(Required)</span></label>
                            <input required #towLotAddressControl="ngModel" maxlength="100" type="text" class="form-control" id="towLotAddress" name="towLotAddress" placeholder="Street Address" [(ngModel)]="towLotAddress" [ngClass]="{'is-invalid':(towLotAddressControl.touched || isSubmitClicked) && towLotAddressControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Tow Lot Address is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="towCity">City/Town/Village <span class="label-hint">(Required)</span></label>
                            <input required #towCityControl="ngModel" maxlength="30" type="text" class="form-control" id="towCity" name="towCity" placeholder="City/Town/Village" 
                            [(ngModel)]="towLotCity" (ngModelChange)="vehicleSeizure.towLotCity=this.titleCasePipe.transform($event)"
                            [ngClass]="{'is-invalid':(towCityControl.touched || isSubmitClicked) && towCityControl.invalid}">
                            <div class="invalid-feedback">
                                <div>City/Town/Village is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="towPostalCode">Postal Code</label>
                            <input maxlength="10" type="text" class="form-control text-uppercase" id="towPostalCode" name="towPostalCode" placeholder="Enter Postal Code" [(ngModel)]="towLotPostalCode">
                        </div>
                        <div class="col-lg-6">
                            <label for="towLotEmailAddress">Email Address</label>
                            <input type="text" readonly class="form-control" id="towLotEmailAddress" name="towLotEmailAddress" value="{{towLotEmailAddress?towLotEmailAddress:''}}" >
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="towTelephoneNumber">Telephone Number</label>
                            <input type="text" maxlength="15" class="form-control" id="towTelephoneNumber" name="towTelephoneNumber" placeholder="Enter Telephone Number" [(ngModel)]="towLotPhoneNumber">
                        </div>
                        <div class="col-lg-6">
                            <label for="towFaxNumber">Fax Number</label>
                            <input type="text" maxlength="15" class="form-control" id="towFaxNumber" name="towFaxNumber" placeholder="Enter Fax Number" [(ngModel)]="towLotFaxNumber">
                        </div>
                    </div>
                </div>
            </div>  
        </ng-container>
        
        <div class="mb-3">
            <div class="row pt-3">
                <div class="col-12 col-sm-6">                
                    <a class="btn button-secondary btn-block" [routerLink]="['/contravention/submission/vehicle-information']">Back to Vehicle Information</a>
                </div>
                <div class="col-12 col-sm-6 text-end">                
                    <button class="btn button-primary btn-block" (click)="onSubmitClick(vehicleSeizureForm.valid)">Proceed to Print</button>   
                </div>
            </div>
        </div>	
    </div>
</form>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Searching for licence plate information in MOVES. Please wait... </p></ngx-spinner>      
