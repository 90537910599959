import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ContraventionDetailTypes, ContraventionStatusTypes, ContraventionTypes, DocumentTypes, EventTypes, IntakeAppProcess, RoadsideAppealOutcomeTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { RoadsideAppeal } from '@apis/shared/models/roadside-appeal.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { RoadsideAppealOutcomeType } from '@apis/shared/models/types/roadside-appeal-outcome-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CancelContraventionModalComponent } from './modals/cancel-contravention-modal/cancel-contravention-modal.component';
import { ReissueContraventionModalComponent } from './modals/reissue-contravention-modal/reissue-contravention-modal.component';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from 'apps/intake/src/shared/services/document.service';
import { Constants } from '@apis/shared/helpers/constants';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { TicketStopInformation } from '@apis/shared/models/ticket-stop-information.model';
import { IssuanceResponse } from '@apis/shared/models/issuance-response.model';
import { TicketResponse } from '@apis/shared/models/ticket-response.model';
import { CreatePoliceNotesRequest } from '@apis/shared/models/create-police-notes-request.model';
import { SettingService } from 'apps/intake/src/shared/services/setting.service';
import { Setting } from '@apis/shared/models/setting.model';

@Component({
  selector: 'app-print-confirmation',
  templateUrl: './print-confirmation.component.html',
  styleUrls: ['./print-confirmation.component.scss']
})
export class PrintConfirmationComponent implements OnInit {
  stopInformation: StopInformation;
  ticketStopInformation: TicketStopInformation;
  submissionTitle: string;
  irsContravention: Contravention;
  roadsideAppeal: RoadsideAppeal;
  roadsideAppealOutcomeTypes: RoadsideAppealOutcomeType[];
  screenName: string = "";
  contraventionsCount: number;
  seizuresCount: number;
  isOnlyTSA: boolean = false;
  isMultipleTickets: boolean = false;
  isProcessing: boolean = true;
  isSubmitClicked: boolean = false;
  ContraventionTypes = ContraventionTypes;
  TypeTable = TypeTable;
  RoadsideAppealOutcomeTypes = RoadsideAppealOutcomeTypes;
  isRefusal: boolean = false;
  isImmediatelyCancelled: boolean = false;
  ticketResponse: TicketResponse[];
  okTicketResponse: TicketResponse[];
  errorTicketResponse: TicketResponse[];
  policeNotes: string;
  errorMessage: string;
  isPoliceNotesAttached: boolean = false;
  isOneDocumentSplitEnabled: boolean = true;  // One document split option is enabled by default 
                                              // in case if there is a problem in reading the flag from settings

  constructor(private intakeService: IntakeService,
    private documentService: DocumentService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly settingService: SettingService,
    ) { }

  async ngOnInit(): Promise<void> {    

    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.CreatingContravention);

    //Get Lookups
    this.roadsideAppealOutcomeTypes = this.localStorageService.getRoadsideAppealOutcomeTypes();

    //Get Stop and Ticket Stop objects
    this.stopInformation = this.intakeService.getStopInformationModel();
    this.ticketStopInformation = this.intakeService.getTicketStopInformationContext();

    //Check if one document split is enabled
    const settings = await this.settingService.getSettings().pipe().toPromise();
    this.isOneDocumentSplitEnabled = settings.find(s => s.settingName == Constants.Settings.ENABLE_ONE_DOCUMENT_SPLIT)?.settingValue != "false";

    //Check if it is a NAP submission or Ticket submission
    if (this.stopInformation != null && this.stopInformation.stopInformationId != null && this.stopInformation.stopInformationId > 0) //NAP
    {
      //Route to Supporting documents page if it is a legacy submission
      if (this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1)
      this.router.navigateByUrl('/contravention/add-details/supporting-documents');

      this.spinner.show();
      this.screenName = "Processing..";

      if (this.stopInformation.issuanceResponse)
      {
        this.ticketResponse = this.stopInformation.issuanceResponse.ticketResponse;
        this.okTicketResponse = this.ticketResponse.filter(x => x.responseMessage == "ok");
        this.errorTicketResponse = this.ticketResponse.filter(x => x.responseMessage != "ok");
      }

      this.initializeEntities();
      this.screenName =  this.errorTicketResponse && this.errorTicketResponse.length>0? "Something Went Wrong": this.screenName;
      if (!this.stopInformation.isNoticePrinted && !this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission)
      {
        this.downloadOneNotice();
        this.stopInformation.isNoticePrinted = true;
      }
    }
    else if (this.ticketStopInformation != null && this.ticketStopInformation.ticketStopInformationId != null && this.ticketStopInformation.ticketStopInformationId > 0) //Ticket
    {
      if (this.ticketStopInformation.issuanceResponse)
      {
        this.ticketResponse = this.ticketStopInformation.issuanceResponse.ticketResponse;
        this.okTicketResponse = this.ticketResponse.filter(x => x.responseMessage == "ok");
        this.errorTicketResponse = this.ticketResponse.filter(x => x.responseMessage != "ok");
      }

      this.isOnlyTSA = true;
      this.isMultipleTickets = this.ticketStopInformation.tickets.length > 1;
      this.screenName =  this.errorTicketResponse && this.errorTicketResponse.length>0? "Something Went Wrong": "Offence Notice Issued";
      this.isProcessing = false;
      this.spinner.hide();
    }
    else 
    {
      this.router.navigateByUrl('/');
    }
  }

  initializeEntities()
  {
    this.screenName = this.stopInformation.isEmergencySubmission || this.stopInformation.isPaperSubmission? "Submitted to APIS":"Notice(s) Issued";
          
    if (this.stopInformation.isIRSSelected)
    {
      var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
      this.irsContravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));

      // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
      //
      var detailsSelected = this.irsContravention?.contraventionDetails?.split(",");
      var totalDetailsSelected = detailsSelected?.length;
      
      this.isRefusal = (
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail1st && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail2nd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
          (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail3rd && 
            (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
      )

      if (this.irsContravention.contraventionTypeId != ContraventionTypes.IRS24 
        && !this.isRefusal
        && this.irsContravention.roadsideAppeal == null) {
        this.irsContravention.roadsideAppeal = new RoadsideAppeal();  
      }

      this.roadsideAppeal = this.irsContravention.roadsideAppeal;
    }

    this.contraventionsCount = this.stopInformation.contraventions.length + this.stopInformation.warnings.length;
    this.seizuresCount = this.stopInformation.vehicleSeizures.length + this.stopInformation.contraventions.filter(x => x.vehicleSeizure).length;
    this.isImmediatelyCancelled = this.stopInformation.contraventions.some(c => c.events.some(e => e.eventTypeId == 31)) || this.stopInformation.vehicleSeizures.some(c => c.events.some(e => e.eventTypeId == 31));

    this.isProcessing = false;
    this.spinner.hide();
  }

  get isTSA() {
    return this.isOnlyTSA ||
      this.stopInformation['ticketStopInformation'] && this.stopInformation['ticketStopInformation'].ticketStopInformationId > 0;
  }

  isIRSCancelled()
  {
    return this.irsContravention?.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled;
  }

  isIRSReIssued()
  {
    return this.irsContravention?.events?.filter(x=> x.eventTypeId == EventTypes.ContraventionReIssued).length > 0;
  }

  isReadOnly()
  {
    return this.isIRSCancelled() || this.isIRSReIssued();
  }

  isAddDetailsButtonRequired()
  {
     return !((this.irsContravention && this.isIRSCancelled() && this.contraventionsCount == 1 && this.stopInformation.vehicleSeizures.length == 0) ||
        (this.roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration && !this.isIRSCancelled()) ||
        (this.roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && !this.isIRSReIssued()) ||
        (this.contraventionsCount == this.stopInformation.warnings.length && this.seizuresCount == 0));
  }

  PrintSubmission()
  {
    var documentTitle = document.title;
    document.title = this.submissionTitle;
    window.print();
    document.title = documentTitle;
  }

  onAppealPresentedChange() {
    this.roadsideAppeal.hasDriverRequestedRoadsideAppeal = null;
    this.roadsideAppeal.roadsideAppealOutcomeTypeId = null
    this.roadsideAppeal.appealOptionsNotPresentedReason = this.roadsideAppeal.isRecipientPresentedWithInformation? "": this.roadsideAppeal.appealOptionsNotPresentedReason;
  }

  onAppealRequestChange() {
    this.roadsideAppeal.roadsideAppealOutcomeTypeId = null;
  }

  showCancelContraventionModal(isValid: boolean): void {
    if (isValid)
    {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(CancelContraventionModalComponent);

      componentRef.instance.contravention = Object.assign({}, this.irsContravention);
      componentRef.instance.close.subscribe((result: Contravention) => {
        if (result != null) {
          // Refresh contravention entity
          const contraventionIndex = this.stopInformation.contraventions.findIndex(x => x.contraventionId === result.contraventionId);
          this.stopInformation.contraventions[contraventionIndex] = result;
          this.intakeService.saveStopInformationContext();

          this.irsContravention = this.stopInformation.contraventions[contraventionIndex];
        }
        componentRef.destroy();
      });    
    }
  }

  
  showReissueContraventionModal(isValid: boolean): void {
    if (isValid)
    {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(ReissueContraventionModalComponent);

      componentRef.instance.contravention = Object.assign({}, this.irsContravention);
      componentRef.instance.close.subscribe((result: Contravention) => {
        if (result != null) {
          //Refresh whole stop information entity
          this.spinner.show();
          this.intakeService.getStopInformationByIdAsync(this.stopInformation?.stopInformationId?.toString())
            .subscribe((stopInformation: StopInformation) => {
                this.stopInformation = stopInformation; 
                this.intakeService.createStopInformationContext(this.stopInformation);
                this.initializeEntities();
                
                var documentTypes = [DocumentTypes.NAPDocument, DocumentTypes.SeizureNoticePoliceCopy, DocumentTypes.SeizureNoticeDriverCopy, DocumentTypes.SeizureNoticeRegisteredOwnerCopy, DocumentTypes.SeizureNoticeTowLotCopy];

                //Re-Print the new Re-Issued IRS notice and Seizure Notices
                this.stopInformation.documents.filter(x=>!x.isDeleted && x.documentDescription != "(Re-Issued)" && documentTypes.includes(+x.documentTypeId) && (x.documentReferenceNumber == this.irsContravention.contraventionNumber || x.documentReferenceNumber == this.irsContravention.vehicleSeizure?.seizureNumber)).forEach(document => {
                  this.DownloadDocument(document);
                });
            },
            (error: any) => {
              this.screenName = "Something went wrong"
              this.spinner.hide(); 
            }
          );
          //this.irsContravention = result;
        }
        componentRef.destroy();
      });    
    }
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
    if (isValid)
    {
      this.stopInformation.apisStatus = "Details Required";
      this.stopInformation.userIsAddingDetails = true;
      this.stopInformation.userIsIssuingOfficer = true;
      this.intakeService.createStopInformationContext(this.stopInformation);

      // JTI-4995 - If a stop's IRS contravention is cancelled, but the SDP seizure is still active, show SDP seizure details
      if (this.stopInformation.contraventions.length>0 && !this.stopInformation.isOnlySDPActive)
        this.router.navigateByUrl(`/contravention/add-details/contravention-details`);
      else
        this.router.navigateByUrl(`/contravention/add-details/sdp-notice-details`);  
    }
  }

  onTicketSubmitClick() {
    if (this.policeNotes == null || this.policeNotes?.trim().length == 0) {
      this.errorMessage = 'Police Notes is required';
      return;
    }

    var user = this.localStorageService.getUser();

    var request = new CreatePoliceNotesRequest({
      policeNotes: this.policeNotes,
      ticketNumber: this.ticketStopInformation.tickets[0].ticketNumber,
      issuingDetachmentId: this.ticketStopInformation.tickets[0].issuingDetachmentId,
      issuingOfficerName: `${user.firstName} ${user.lastName}`,
      regimentalNumber: user.badgeNumber
    });

    this.spinner.show();
    this.intakeService.createPoliceNotes(request)
    .subscribe((result: any) => {      
        this.isPoliceNotesAttached = true;
        this.spinner.hide();
    }, () => { this.spinner.hide(); });
  }

  downloadNotices()
  {
    if (this.stopInformation.documents.length > 0)
    {
      this.stopInformation.documents.filter(x=>!x.isDeleted).forEach(document => {
        this.DownloadDocument(document);  
      });
    }
  }

  downloadOneNotice()
  {
    this.spinner.show();

    //Download IRS document separately if both IRS and SDP seizures are there in one stop and splitting option is enabled
    if(this.seizuresCount > 1 && this.isOneDocumentSplitEnabled)
    {
      this.documentService.downloadOneDocument(this.stopInformation.stopInformationId, true)
      .subscribe((result: any) => {
        if (result)
        { 
          CommonUtil.openPdfDocument(result, "APIS");
          this.spinner.hide();
        }
      }, () => { this.spinner.hide(); });
    }

    this.documentService.downloadOneDocument(this.stopInformation.stopInformationId, false)
      .subscribe((result: any) => {
        if (result)
        { 
          CommonUtil.openPdfDocument(result, "APIS");
          this.spinner.hide();
        }
      }, () => { this.spinner.hide(); });
  }

  DownloadDocument(document: Document)
  {
    if (document && document.uploadedDate) // If document is uploaded, and not a placeholder
    {
      var documentRefTypeName = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1? this.getDocumentRefName() : "Stop";
      var documentRefTypeNumber = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1? this.getDocumentRefNumber() : this.stopInformation.stopInformationId.toString();
      var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

      if (document.version == Constants.DocumentVersion.VERSION3)
      {
       this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName , document.documentName)
       .subscribe((result: any) => {
          window.open(result.documentSasUri, "_blank");
       });

        return;
      }

      this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          CommonUtil.openPdfDocument(result, document.documentName);
        }
      });
    }
  }

  getDocumentRefNumber(): string {
    if (this.stopInformation.contraventions.length > 0)
      return this.stopInformation.contraventions[0].contraventionNumber;

    if (this.stopInformation.vehicleSeizures.length > 0)
      return this.stopInformation.vehicleSeizures[0].seizureNumber;
      
    return "";  
  }

  getDocumentRefName(): string {
    if (this.stopInformation.contraventions.length > 0)
      return "Contraventions";

    if (this.stopInformation.vehicleSeizures.length > 0)
      return "VehicleSeizures";
      
    return "";  
  }
}



