<div>
    <div class="report-title">Submission Export</div>
    <div class="report-number">{{reportNumber}}</div>
    <div class="card mt-4" *ngIf="isRecipientSectionVisible">
        <div class="card-header">
            <div class="section-header">Recipient Information</div>                                
        </div>            
        <div class="card-body">                    
            <label *ngIf="this.recipient.movesLookupDate">The following recipient information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient.movesLookupDate | date:'mediumDate'}} at {{recipient.movesLookupDate | date:'HH:mm'}}</label>
            <app-report-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Identification Information" [sectionDetails]="recipientIdentificationInformation"></app-report-section-details>

            <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipientContact?.isAddressDifferentFromMOVES">
                <app-report-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-report-section-details>
                <label>The following contact information was provided by the recipient to the officer</label>
                <app-report-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-report-section-details>    
            </ng-container>

            <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                <app-report-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="recipientContactInformation"></app-report-section-details>
            </ng-container>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isContraventionSectionVisible">
        <div class="card-header">
            <div class="section-header">Contravention Details</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="Contravention Information" [sectionDetails]="contraventionInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Occurrence Time & Location" [sectionDetails]="contraventionType"></app-report-section-details>
            <app-report-section-details sectionTitle="Location Conditions" [sectionDetails]="stopInformationDetails"></app-report-section-details>
            <app-report-section-details sectionTitle="Occurrence Information" [sectionDetails]="witnessInformation"></app-report-section-details>
 
            <div class="row" *ngIf="recipient.recipientTypeId!=RecipientTypes.NotInVehicle">
                <div class="col-12 col-sm-4">
                    <div class="label">Passengers</div>
                    <div class="value">{{contravention.stopInformation?.isPassengerInVehicle? 'Yes': 'No'}}</div>
                </div>
                <ng-container *ngIf="contravention.stopInformation?.isPassengerInVehicle">
                    <div class="col-12 col-sm-4">
                        <div class="label">Passenger Positions</div>
                        <div class="value ps-2">
                            <app-passenger-position [stopInformation]="contravention.stopInformation" [isReadOnly]="true"></app-passenger-position>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="label">Additional Passenger Location Details</div>
                        <div class="value">{{contravention.stopInformation?.vehicleOccupantsNotes}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="row" *ngIf="isBodycamVideoActive">
                <div class="col-12 col-sm-4">
                    <div class="label">Is there any video in relation to this event?</div>
                    <div class="value" *ngIf="stopInformation?.hasBodycamVideo == null; else bodycamVideoHasValue">-</div>
                    <ng-template #bodycamVideoHasValue>
                        <div class="value">{{stopInformation?.hasBodycamVideo? 'Yes': 'No'}}</div>
                    </ng-template>
                </div>
            </div>

            <app-report-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds"></app-report-section-details>
            <app-report-section-details sectionTitle="Impairment Screening Information" [sectionDetails]="impairmentScreeningInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Impairment Screening Details" [sectionDetails]="impairmentScreeningDetails"></app-report-section-details>
            <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                <app-report-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}" [sectionDetails]="additionalTest"></app-report-section-details>
            </div>
            <app-report-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds"></app-report-section-details>

            <app-report-section-details *ngIf="contravention?.roadsideAppeal" sectionTitle="Roadside Appeal Information" [sectionDetails]="roadsideAppealInformation"></app-report-section-details>
            <app-report-section-details *ngIf="contravention?.roadsideAppeal" sectionTitle="Roadside Appeal Details" [sectionDetails]="roadsideAppealDetails"></app-report-section-details>

            <app-report-section-details sectionTitle="Officer Information" [sectionDetails]="officerInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isVehicleSectionVisible">
        <div class="card-header">
            <div class="section-header">Vehicle Information</div>                                
        </div>            
        <div class="card-body">     
            <label *ngIf="this.vehicle.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>               
            
            <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                <app-report-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-report-section-details>
                <label>The following information is a description of the vehicle involved in the contravention</label>
                <app-report-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-report-section-details>
            </ng-container>

            <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                <app-report-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-report-section-details>
            </ng-container>
            <app-report-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-report-section-details>
            
            <ng-container *ngIf="recipient.recipientTypeId==RecipientTypes.Driver || recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
                <label *ngIf="this.vehicle.movesLookupDate && !this.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicle?.isNoRegisteredOwner && !this.vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                <app-report-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-report-section-details>
            </ng-container>
            
            <app-report-section-details *ngIf="recipient.recipientTypeId==RecipientTypes.Passenger" sectionTitle="Driver Information" [sectionDetails]="vehicleDriverInformation"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isVehicleSeizureSectionVisible">
        <div class="card-header">
            <div class="section-header">Vehicle Seizure Details</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="Seizure Information" [sectionDetails]="seizureInformation"></app-report-section-details>
            <label *ngIf="this.vehicle.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>               
                
            <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                <app-report-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-report-section-details>
                <label>The following information is a description of the vehicle involved in the contravention</label>
                <app-report-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-report-section-details>
            </ng-container>

            <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                <app-report-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-report-section-details>
            </ng-container>
            <app-report-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-report-section-details>
            
            <ng-container *ngIf="recipient.recipientTypeId==RecipientTypes.Driver || recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
                <label *ngIf="this.vehicle.movesLookupDate && !this.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicle?.isNoRegisteredOwner && !this.vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                <app-report-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-report-section-details>
            </ng-container>
            
            <app-report-section-details *ngIf="recipient.recipientTypeId==RecipientTypes.Passenger" sectionTitle="Driver Information" [sectionDetails]="vehicleDriverInformation"></app-report-section-details>
           
            <app-report-section-details sectionTitle="Vehicle Location & Tow Lot Information" [sectionDetails]="towLotInformation"></app-report-section-details>
            <app-report-section-details *ngIf="isSdp" sectionTitle="Police Narrative" [sectionDetails]="vehicleSeizurePoliceNarrativeDetails"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isSupportingDocumentsSectionVisible">
        <div class="card-header">
            <div class="section-header">Supporting Documents</div>
        </div>

        <div class="card-body">
            <div *ngFor="let document of supportingDocuments">
                <div class="row" style="border-bottom: solid 1px #333;">
                    <div class="col-12 mt-3 pb-0">
                        <label [ngClass]="{'required': !document.isOptional}">{{getDocumentTypeName(document)}} </label>
                    </div>
                    <div class="col-12 mb-0 pb-0">
                        <span *ngIf="document.documentName != null">File Name: {{document.documentName}}</span>
                        <div *ngIf="document.documentName == null && document.isSubmitLater">
                            Will submit at later date
                        </div>
                    </div>
                    <div *ngIf="document.uploadedDate" class="col-12 mb-3">
                        <span>{{getDocumentUploadNameAndDate(document)}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4" *ngIf="isPoliceServiceSectionVisible">
        <div class="card-header">
            <div class="section-header">Police Service Information</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-report-section-details>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div class="section-header">Declaration</div>                                
        </div>            
        <div class="card-body">                    
            <app-report-section-details sectionTitle="" [sectionDetails]="declarationInformation"></app-report-section-details>
            <app-report-section-details sectionTitle="Re-Service Information" [sectionDetails]="reServiceInformation"></app-report-section-details>
        </div>
    </div>
</div>