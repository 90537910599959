import { Component, HostListener, OnInit } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IntakeUser } from '../shared/models/intake-user.model';
import { LookupService } from '../shared/services/lookup.service';
import { OfficerService } from '../shared/services/officer.service';
import { SettingService } from '@apis/shared/services/setting.service';
import { KeycloakService } from 'keycloak-angular';
import { Constants } from '@apis/shared/helpers/constants';
import { Setting } from '@apis/shared/models/setting.model';
import { Observable, of } from 'rxjs';
import { AppUpdateService } from '@apis/shared/services/app-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: IntakeUser;
  isProcessing: boolean = true;
  @HostListener("window:onbeforeunload",["$event"])

  Resource:any = Constants.Resource;
  Permission:any = Constants.Permission;

  clearLocalStorage(event){
      localStorage.clear();
  }

  constructor(
    private readonly lookupService: LookupService,
    private readonly settingService: SettingService,
    private officerService: OfficerService,
    private localStorageService: LocalStorageService,
    private readonly keycloakService: KeycloakService,
    private readonly spinner: NgxSpinnerService,
    private appUpdateService: AppUpdateService){}

  ngOnInit(): void {
    this.spinner.show();
    this.user = this.localStorageService.getUser();

    if (this.keycloakService.isLoggedIn() && (!this.user || this.user?.userName != this.keycloakService.getUsername())) {
      this.spinner.show(); 
      this.user = new IntakeUser();
      
      this.user.userName = this.keycloakService.getUsername();
      
      this.keycloakService.getToken().then(token => {
        this.user.token = token;        
        this.localStorageService.setUser(this.user);
        this.keycloakService.loadUserProfile().then(profile => {
          this.user.badgeNumber = profile["attributes"].badgeNumber?.toString() ?? '';
          this.user.firstName = profile.firstName;
          this.user.lastName = profile.lastName; 
          this.user.displayName = `${this.user.firstName} ${this.user.lastName}`;
          
          this.localStorageService.setUser(this.user);
          
          this.officerService.getOfficerByEmailIdAsync(this.user.userName).subscribe( officer => {
            if (officer != null)
            {
              this.user.badgeNumber = officer.regimentalNumber;
              this.user.detachment = officer.detachmentId;
              this.user.service = officer.agencyId;
              this.user.firstName = officer.firstName;
              this.user.lastName = officer.lastName; 
              this.user.userId = officer.officerId;
              this.user.defaultMunicipalityLocationId = officer.defaultMunicipalityLocationId;
              this.user.defaultCourtLocationId = officer.defaultCourtLocationId;
              this.localStorageService.setUser(this.user);
                
            } 
            this.getLookups().subscribe ( result => {
              if(this.user?.userId) {
                this.getOfflineNumbers(this.user.userId);
              }
            }); 
          },
          (error => {
            this.user.detachment = null;
            this.user.service = null;
            this.user.defaultMunicipalityLocationId = null;
            this.user.defaultCourtLocationId = null;
            this.localStorageService.setUser(this.user);
            this.getLookups().subscribe();
          })
          ) 
        })
      })  
    }
    else {
      this.getLookups().subscribe ( result => {
        this.localStorageService.getUserObservable().subscribe( lsUser => {
          if (lsUser!=null) {
            if (lsUser.userId > 0) {
              this.getOfflineNumbers(this.user.userId);
            } else {
              let totalOfflineNumbers = this.localStorageService.getOfflineNumbers().length;
              console.log(`Undetermined UserId: Offline numbers length: ${totalOfflineNumbers}`);
            }
          } else {
            let totalOfflineNumbers = this.localStorageService.getOfflineNumbers().length;
            console.log(`Undetermined User: Offline numbers length: ${totalOfflineNumbers}`);
          }
        });
      }); 
    }
  }

  getOfflineNumbers(officerId: number)
  {
    let isAllowed: boolean = this.localStorageService.getRolePermissions()?.some( x=> x.resourceName===this.Resource.POLICE_INTAKE_SUBMISSION && 
                                               x.permissionName===this.Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES);
    if (localStorage && isAllowed) {
      this.officerService.getOfficerOfflineIdsAsync(officerId).subscribe( idList => {
        if (idList != null) {
          this.localStorageService.setOfflineNumbers(idList);  
          this.officerService.getOfficerOfflineSeizureIdsAsync(officerId).subscribe( idList => {
            if (idList != null) {
              this.localStorageService.setOfflineSeizureNumbers(idList); 
            }
          }, 
            error => {
              // TODO ... handle error conditions
          })
        }
      },
        error => {
          // TODO ... handle error conditions
      })
    }
  }

 getLookups(): Observable<boolean> {
    //Get lookup items
    this.settingService.getSettings()
    .subscribe((settings: Setting[]) => {
      if (settings) {
        const lookupVersion = settings.find(s => s.settingName == Constants.Settings.LOOKUP_VERSION)?.settingValue;
        const healthCheckTimeout = settings.find(s => s.settingName == Constants.Settings.HEALTH_CHECK_TIMEOUT_LENGTH)?.settingValue;

        // Compare the version of lookups in client and server
        // If the versions are different, clear localstorage and get latest lookups.
        if (this.localStorageService.get(Constants.Settings.LOOKUP_VERSION) != lookupVersion) {
          // Clear localstorage          
          localStorage.clear();
          this.localStorageService.setUser(this.user);

          // temporarily set the timeout larger to account for extra processing of the lookup data (15 seconds)
          this.localStorageService.set(Constants.Settings.HEALTH_CHECK_TIMEOUT_LENGTH, '15000');

          // Get lookups
          this.lookupService.setLookups()      
            .subscribe((result: any) => {
              if (result.circumstanceTypes != null) { this.localStorageService.setCircumstanceTypes(result.circumstanceTypes); }       
              if (result.clientTypes != null) { this.localStorageService.setClientTypes(result.clientTypes); }      
              if (result.contraventionDetailTypes != null) { this.localStorageService.setContraventionDetailTypes(result.contraventionDetailTypes); }
              if (result.contraventionGroundsTypes != null) { this.localStorageService.setContraventionGroundsTypes(result.contraventionGroundsTypes); }
              if (result.contraventionStatusTypes != null) { this.localStorageService.setContraventionStatusTypes(result.contraventionStatusTypes); }
              if (result.contraventionTypes != null) { this.localStorageService.setContraventionTypes(result.contraventionTypes); }
              if (result.countryTypes != null) { this.localStorageService.setCountryTypes(result.countryTypes); }   
              if (result.documentTypes != null) { this.localStorageService.setDocumentTypes(result.documentTypes); }
              if (result.driversLicenceClassTypes != null) { this.localStorageService.setDriversLicenceClassTypes(result.driversLicenceClassTypes); }
              if (result.driversLicenceStatusTypes != null) { this.localStorageService.setDriversLicenceStatusTypes(result.driversLicenceStatusTypes); }
              if (result.genderTypes != null) { this.localStorageService.setGenderTypes(result.genderTypes); }
              if (result.impairmentScreeningTypes != null) { this.localStorageService.setImpairmentScreeningTypes(result.impairmentScreeningTypes); }
              if (result.labTypes != null) { this.localStorageService.setLabTypes(result.labTypes); }
              if (result.locationTypes != null) { this.localStorageService.setLocationTypes(result.locationTypes); }
              if (result.observedByTypes != null) { this.localStorageService.setObservedByTypes(result.observedByTypes); }
              if (result.provinceTypes != null) { this.localStorageService.setProvinceTypes(result.provinceTypes); }
              if (result.seizureTypes != null) { this.localStorageService.setSeizureTypes(result.seizureTypes); }
              if (result.suspectedImpairmentTypes != null) { this.localStorageService.setSuspectedImpairmentTypes(result.suspectedImpairmentTypes); }
              if (result.suspicionTypes != null) { this.localStorageService.setSuspicionTypes(result.suspicionTypes); }
              if (result.testModelTypes != null) { this.localStorageService.setTestModelTypes(result.testModelTypes); }
              if (result.testResultTypes != null) { this.localStorageService.setTestResultTypes(result.testResultTypes); }
              if (result.testTypes != null) { this.localStorageService.setTestTypes(result.testTypes); }
              if (result.vehicleRegistrationStatusTypes != null) { this.localStorageService.setVehicleRegistrationStatusTypes(result.vehicleRegistrationStatusTypes); }
              if (result.agencies != null) { this.localStorageService.setAgencies(result.agencies); }
              if (result.detachments != null) { this.localStorageService.setDetachments(result.detachments); }
              if (result.reServeMethodTypes != null) { this.localStorageService.setReServeMethodTypes(result.reServeMethodTypes); }
              if (result.seizureStatusTypes != null) { this.localStorageService.setSeizureStatusTypes(result.seizureStatusTypes); }
              if (result.rolePermissions != null) {this.localStorageService.setRolePermissions(result.rolePermissions);}
              if (result.driverDeclinedToTestTypes != null) {this.localStorageService.setDriverDeclinedToTestTypes(result.driverDeclinedToTestTypes);}
              if (result.speedingOffences != null) {this.localStorageService.setSpeedingOffences(result.speedingOffences);}
              if (result.identificationTypes != null) {this.localStorageService.setIdentificationTypes(result.identificationTypes);}
              if (result.issueServiceTypes != null) {this.localStorageService.setIssueServiceTypes(result.issueServiceTypes);}
              if (result.officerVehicleTypes != null) {this.localStorageService.setOfficerVehicleTypes(result.officerVehicleTypes);}
              if (result.officerTransportationTypes != null) {this.localStorageService.setOfficerTransportationTypes(result.officerTransportationTypes);}
              if (result.officerUniformTypes != null) {this.localStorageService.setOfficerUniformTypes(result.officerUniformTypes);}
              if (result.speedingDeviceTypes != null) {this.localStorageService.setSpeedingDeviceTypes(result.speedingDeviceTypes);}
              if (result.speedingDeviceMakeTypes != null) {this.localStorageService.setSpeedingDeviceMakeTypes(result.speedingDeviceMakeTypes);}
              if (result.speedingDeviceModelTypes != null) {this.localStorageService.setSpeedingDeviceModelTypes(result.speedingDeviceModelTypes);}
              if (result.contraventionTypeMappings != null) {this.localStorageService.setContraventionTypeMappings(result.contraventionTypeMappings);}
              if (result.roadsideAppealOutcomeTypes != null) { this.localStorageService.setRoadsideAppealOutcomeTypes(result.roadsideAppealOutcomeTypes); }
              if (result.directionTypes != null) { this.localStorageService.setDirectionTypes(result.directionTypes); }
              if (result.noticeCancellationReasonTypes != null) { this.localStorageService.setNoticeCancellationReasonTypes(result.noticeCancellationReasonTypes); }
              if (result.roadConditionTypes != null) { this.localStorageService.setRoadConditionTypes(result.roadConditionTypes); }
              if (result.roadSurfaceTypes != null) { this.localStorageService.setRoadSurfaceTypes(result.roadSurfaceTypes); }
              if (result.weatherConditionTypes != null) { this.localStorageService.setWeatherConditionTypes(result.weatherConditionTypes); }
              if (result.visibilityConditionTypes != null) { this.localStorageService.setVisibilityConditionTypes(result.visibilityConditionTypes); }
              if (result.payCentreTypes != null) { this.localStorageService.setPayCentreTypes(result.payCentreTypes); }
              if (result.conditionCodeTypes != null) { this.localStorageService.setConditionCodeTypes(result.conditionCodeTypes); }
              if (result.vehicleStatusTypes != null) { this.localStorageService.setVehicleStatusTypes(result.vehicleStatusTypes); }
              if (result.declarationTypes != null) { this.localStorageService.setDeclarationTypes(result.declarationTypes); }   
              if (result.highwayCharacteristicsTypes != null) { this.localStorageService.setHighwayCharacteristicsTypes(result.highwayCharacteristicsTypes); }
              if (result.courtTypes != null) { this.localStorageService.setCourtTypes(result.courtTypes); }
              if (result.offenceTypes != null) { this.localStorageService.setOffenceTypes(result.offenceTypes); }
              if (result.highwayCharacteristicsTypes != null) { this.localStorageService.setHighwayCharacteristicsTypes(result.highwayCharacteristicsTypes); }
              if (result.ticketMethodTypes != null) { this.localStorageService.setTicketMethodTypes(result.ticketMethodTypes); }
              if (result.holidayList != null) {this.localStorageService.setHolidayList(result.holidayList);}
              if (result.ticketWithdrawalReasonTypes != null) {this.localStorageService.setTicketWithdrawalReasonTypes(result.ticketWithdrawalReasonTypes);}
              if (result.tsaHolidayList != null) {this.localStorageService.setTsaHolidayList(result.tsaHolidayList);}
              
              // Update the Health Check Timeout length (ms)
              this.localStorageService.set(Constants.Settings.HEALTH_CHECK_TIMEOUT_LENGTH, healthCheckTimeout);
              
              // Update the lookup version in client
              this.localStorageService.set(Constants.Settings.LOOKUP_VERSION, lookupVersion);
              this.isProcessing = false;

              // Check application updates
              this.appUpdateService.checkForUpdates();
            });
        }
        else 
          this.isProcessing = false;
      }
      else
      {
        this.spinner.hide(); 
        this.isProcessing = false;
      }
    },
    error => {
      this.spinner.hide(); 
      this.isProcessing = false;
    });

    return of(true);
  }


}
