import { Component, OnInit, ViewChild, Pipe, PipeTransform} from '@angular/core';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { VehicleRegistrationStatusType } from '@apis/shared/models/types/vehicle-registration-status-type.model';
import { ClientType } from '@apis/shared/models/types/client-type.model';
import { Vehicle } from '@apis/shared/models/vehicle.model';
import { CountryProvinceModel } from '@apis/shared/models/country-province.model';
import { Recipient } from '@apis/shared/models/recipient.model';
import { RegisteredOwner } from '@apis/shared/models/registered-owner.model';
import { RegisteredOwnerContact } from '@apis/shared/models/registered-owner-contact.model';
import { GenderType } from '@apis/shared/models/types/gender-type.model';
import { RecipientIdentification } from '@apis/shared/models/recipient-identification.model';
import { RecipientContact } from '@apis/shared/models/recipient-contact.model';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { MovesService } from 'apps/intake/src/shared/services/moves.service';
import { AgGridAngular } from 'ag-grid-angular';
import { VehicleRegisteredOwnerInformation } from '@apis/shared/models/vehicle-registered-owner-information.model';
import { RecipientTypes, VehicleRegistrationStatusTypes, ClientTypes, IdentificationTypes, SubmissionProgrssStates, IntakeAppProcess, ContraventionTypes } from '@apis/shared/enums/app.enum';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { VehicleDriver } from '@apis/shared/models/vehicle-driver.model';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { ConnectivityService } from 'apps/intake/src/shared/services/connectivity.service';
import { VehicleStatusType } from '@apis/shared/models/types/vehicle-status-type.model';
import { MovesVehicle } from '@apis/shared/models/moves-vehicle.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Constants } from '@apis/shared/helpers/constants';
import { ModdedTitleCasePipe } from '@apis/shared/pipes/moddedTitleCase.pipe';

@Component({
  selector: 'app-vehicle-information',
  templateUrl: './vehicle-information.component.html',
  styleUrls: ['./vehicle-information.component.scss'],
})
export class VehicleInformationComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  stopInformation: StopInformation;
  vehicle: Vehicle;
  movesVehicle: MovesVehicle;
  recipient: Recipient;
  recipientContact: RecipientContact;
  recipientIdentification: RecipientIdentification;

  vehicleDriver: VehicleDriver;
  registeredOwner: RegisteredOwner;
  registeredOwnerContact: RegisteredOwnerContact;
  vehicleCountryProvince: CountryProvinceModel;
  vehicleCountryProvinceMoves: CountryProvinceModel;
  registeredOwnerCountryProvince: CountryProvinceModel;

  vehicleRegistrationStatusTypes: VehicleRegistrationStatusType[];
  clientTypes: ClientType[];
  genderTypes: GenderType[];
  vehicleStatusTypes: VehicleStatusType[];
  
  dateOfBirthPickerConfig: Partial<BsDatepickerConfig>;
  datePickerConfig: Partial<BsDatepickerConfig>;
  isSubmitClicked: boolean = false;
  
  movesModalOverlay: JQuery<HTMLElement>;
  bodyElement: JQuery<HTMLElement>;

  vehicleSearch: Vehicle = new Vehicle();
  vehicleRegisteredOwnerInformation: VehicleRegisteredOwnerInformation[];
  errorMessage: string;
  VehicleRegistrationStatusTypes = VehicleRegistrationStatusTypes;
  RecipientTypes = RecipientTypes;
  ClientTypes = ClientTypes;
  Constants = Constants;
  isSameAsDriverSelectionMade: boolean = false;

  vehicleStatusMap: Map<string, string> = new Map<string, string>([
    ["active", "Active"],
    ["dupvin", "Duplicate VIN"],
    ["nonrepairable", "Non Repairable"],
    ["oop-active", "Active"],
    ["oop-nonrepairable", "Non Repairable"],
    ["oop-rebuilt", "Rebuilt"],
    ["oop-salvage", "Salvage"],
    ["oop-unknown", "Unknown"],
    ["oop-unsafe", "Unsafe"],
    ["rebuilt", "Rebuilt"],
    ["salvage", "Salvage"],
    ["unsafe", "Unsafe"],
    ["unsafe-r", "Unsafe-R"]
  ]);

  columnDetails = [ 
    { headerName: "Make", field: "vehicle.make", checkboxSelection: true },
    { headerName: "Model", field: "vehicle.model" },
    { headerName: "Year", field: "vehicle.year" },
    { headerName: "Color", field: "vehicle.color" },
  ];

  constructor(private intakeService: IntakeService,
              private localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              private connectivityService: ConnectivityService,
              private movesService: MovesService,
              private router: Router,
              private datePipe: DatePipe,
              public moddedTitleCasePipe: ModdedTitleCasePipe,
              public titleCasePipe: TitleCasePipe) {
                this.dateOfBirthPickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
                    maxDate: new Date(2099,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  });
                this.datePickerConfig = Object.assign({}, 
                  {
                    containerClass: 'theme-dark-blue', 
                    showWeekNumbers: false,
                    dateInputFormat: 'YYYY/MM/DD',
                    minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
                    maxDate: new Date(9999,11,31), // 2099/12/31 - Month is 0 based index
                    isAnimated: true,
                    customTodayClass: 'custom-today-class'
                  });  
               }

  ngOnInit(): void {
    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.CreatingContravention);

    this.spinner.hide();
    this.bodyElement = $(document.body);
    this.movesModalOverlay = $(".moves-modal-overlay");

    //Get Types
    this.vehicleRegistrationStatusTypes = this.localStorageService.getVehicleRegistrationStatusTypes();
    this.clientTypes = this.localStorageService.getClientTypes();
    this.genderTypes = this.localStorageService.getGenderTypes();
    this.vehicleStatusTypes = this.localStorageService.getVehicleStatusTypes();
    
    //Get stop information object
    this.stopInformation = this.intakeService.getStopInformationModel();
    this.vehicle = this.stopInformation.vehicle;
    this.recipient = this.stopInformation.recipient;
    this.movesVehicle = this.vehicle?.movesVehicle;

    //Check if this step in the process is allowed or not.
    if (!this.stopInformation?.progressId || this.stopInformation.progressId < SubmissionProgrssStates.VehicleDetails || this.stopInformation.stopInformationId > 0)
      this.router.navigateByUrl('/contravention/submission/recipient-information');

    //Create Vehicle RO instance
    if (this.vehicle.registeredOwner == null && !this.vehicle.isNoRegisteredOwner && this.recipient.recipientTypeId != RecipientTypes.NotInVehicle) {
      this.vehicle.registeredOwner = new RegisteredOwner();

      //Set Defaults
      this.vehicle.registeredOwner.genderId = null;
      this.vehicle.registeredOwner.dateOfBirth = null;

      this.vehicle.registeredOwner.registeredOwnerContact = new RegisteredOwnerContact();  
      
      //Set Defaults
      this.vehicle.registeredOwner.registeredOwnerContact.countryId = 40 //Canada
      this.vehicle.registeredOwner.registeredOwnerContact.provinceId = 1 //Alberta
    }
    
    //Create Vehicle Driver instance
    if (this.vehicle.vehicleDriver == null && this.recipient.recipientTypeId == RecipientTypes.Passenger) {
      this.vehicle.vehicleDriver = new VehicleDriver();
    }

    this.registeredOwner = this.vehicle.registeredOwner;  
    this.registeredOwnerContact = this.vehicle.registeredOwner?.registeredOwnerContact;
    this.vehicleDriver = this.vehicle.vehicleDriver;

    //Check if IsSameAsDriver selection is made or not
    this.isSameAsDriverSelectionMade = this.registeredOwner?.isSameAsDriver != null;
    
    //Initialize vehicle country province control
    this.vehicleCountryProvince = new CountryProvinceModel();
    this.vehicleCountryProvince.countryId = this.vehicle.licensedCountryId;
    this.vehicleCountryProvince.provinceId = this.vehicle.licensedProvinceId;
    this.vehicleCountryProvince.province = this.vehicle.licensedProvince;
    this.vehicleCountryProvince.isCountryRequired = this.vehicle.isAlbertaLicencePlate;
    this.vehicleCountryProvince.isProvinceRequired = this.vehicle.isAlbertaLicencePlate;
    this.vehicleCountryProvince.countryLabel = "Licensed Country";
    this.vehicleCountryProvince.provinceLabel = "Licensed Province";
    this.vehicleCountryProvince.controlSuffixText = "Vehicle";
    this.vehicleCountryProvince.isDisabled = this.vehicle.isAlbertaLicencePlate;

    //Initialize moves vehicle country province control
    this.vehicleCountryProvinceMoves = new CountryProvinceModel();
    this.vehicleCountryProvinceMoves.countryId = this.movesVehicle?.licensedCountryId;
    this.vehicleCountryProvinceMoves.provinceId = this.movesVehicle?.licensedProvinceId;
    this.vehicleCountryProvinceMoves.province = this.movesVehicle?.licensedProvince;
    this.vehicleCountryProvinceMoves.isCountryRequired = false;
    this.vehicleCountryProvinceMoves.isProvinceRequired = false;
    this.vehicleCountryProvinceMoves.countryLabel = "Licensed Country";
    this.vehicleCountryProvinceMoves.provinceLabel = "Licensed Province";
    this.vehicleCountryProvinceMoves.controlSuffixText = "MovesVehicle";
    this.vehicleCountryProvinceMoves.isDisabled = true;

    if (this.recipient.recipientTypeId != RecipientTypes.NotInVehicle)
    {
      //Initialize registered owner country province control
      this.registeredOwnerCountryProvince = new CountryProvinceModel();
      this.registeredOwnerCountryProvince.countryId = this.registeredOwnerContact?.countryId;
      this.registeredOwnerCountryProvince.provinceId = this.registeredOwnerContact?.provinceId;
      this.registeredOwnerCountryProvince.province = this.registeredOwnerContact?.province;
      this.registeredOwnerCountryProvince.isCountryRequired = this.vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered;
      this.registeredOwnerCountryProvince.isProvinceRequired = this.vehicle.vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered;
      this.registeredOwnerCountryProvince.countryLabel = "Country";
      this.registeredOwnerCountryProvince.provinceLabel = "Province";
      this.registeredOwnerCountryProvince.controlSuffixText = "RegisteredOwner";
      this.registeredOwnerCountryProvince.isDisabled = this.isRegisteredOwnerReadOnly();
    }
    
    //scroll to top with tab change
    window.scroll(0,0);
  }

  onVehcleRegistrationStatusChange(vehicleRegistrationStatusTypeId: number)
  {
    if (this.recipient.recipientTypeId == RecipientTypes.Driver || this.recipient.recipientTypeId == RecipientTypes.RegisteredOwner)
    {
      this.registeredOwnerCountryProvince.isCountryRequired = vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered;
      this.registeredOwnerCountryProvince.isProvinceRequired = vehicleRegistrationStatusTypeId != VehicleRegistrationStatusTypes.Unregistered;
    }
  }

  public refreshVehicleCountryProvince(e) {
    this.vehicle.licensedCountryId = e.countryId;
    this.vehicle.licensedProvinceId = e.provinceId;
    this.vehicle.licensedProvince = e.province;
  }

  public refreshRegisteredOwnerCountryProvince(e) {
    this.registeredOwnerContact.countryId = e.countryId;
    this.registeredOwnerContact.provinceId = e.provinceId;
    this.registeredOwnerContact.province = e.province;
  }

  onSameAsDriverChange()
  {
    if (this.registeredOwner?.isSameAsDriver)
    {
      //Update country province model as well
      this.registeredOwnerCountryProvince.countryId = this.recipient.recipientContact.countryId;
      this.registeredOwnerCountryProvince.provinceId = this.recipient.recipientContact.provinceId;
      this.registeredOwnerCountryProvince.province = this.recipient.recipientContact.province;

      if (this.recipient.recipientIdentification.identificationTypeId == IdentificationTypes.DriversLicence)
        this.registeredOwner.driverLicenceNumber = this.recipient.recipientIdentification.identificationNumber;
      
      this.registeredOwner.motorVehicleIdentificationNumber = this.recipient.recipientIdentification.motorVehicleIdentificationNumber;
      this.registeredOwner.firstName = this.recipient.firstName;
      this.registeredOwner.lastName = this.recipient.lastName;
      this.registeredOwner.otherName = this.recipient.otherName;
      this.registeredOwner.genderId = this.recipient.recipientIdentification.genderId;
      this.registeredOwner.dateOfBirth = this.recipient.recipientIdentification.dateOfBirth;
      this.registeredOwnerContact.phoneNumber1 = this.recipient.recipientContact.phoneNumber1;
      this.registeredOwnerContact.addressLine1 = this.recipient.recipientContact.addressLine1;
      this.registeredOwnerContact.addressLine2 = this.recipient.recipientContact.addressLine2;
      this.registeredOwnerContact.addressLine3 = this.recipient.recipientContact.addressLine3;
      this.registeredOwnerContact.countryId = this.recipient.recipientContact.countryId;
      this.registeredOwnerContact.provinceId = this.recipient.recipientContact.provinceId;
      this.registeredOwnerContact.province = this.recipient.recipientContact.province;
      this.registeredOwnerContact.city = this.recipient.recipientContact.city;
      this.registeredOwnerContact.postalCode = this.recipient.recipientContact.postalCode;
    }
    else if(this.isSameAsDriverSelectionMade) //This is to avoid clearing fields for the first time selection.
    {
      if (this.vehicle.isStolenPlate)
        this.clearRegisteredOwnerData();
      else  
        this.autoFillMovesROInfo();
    }

    this.isSameAsDriverSelectionMade = true;
    this.registeredOwnerCountryProvince.isDisabled = this.isRegisteredOwnerReadOnly();
  }

  onTrailerInvolvedChange()
  {
    if (!this.vehicle.isTrailerInvolved)
    {
      this.vehicle.trailerLicencePlateNumber = null;
      this.vehicle.additionalTrailerInformation = null;
      this.onRemoveAdditionalTrailerClick();
    }
  }

  onAddAdditionalTrailerClick()
  {
    this.vehicle.isSecondTrailerInvolved = true;
  }

  onRemoveAdditionalTrailerClick()
  {
    this.vehicle.isSecondTrailerInvolved = false;
    this.vehicle.secondTrailerLicencePlateNumber = null;
    this.vehicle.additionalSecondTrailerInformation = null;
  }

  onSubmitClick(isValid: boolean)
  {
    this.isSubmitClicked = true;
    this.vehicleCountryProvince.isSubmitClicked = true;
    if (this.recipient.recipientTypeId == RecipientTypes.Driver || this.recipient.recipientTypeId == RecipientTypes.RegisteredOwner)
      this.registeredOwnerCountryProvince.isSubmitClicked = true;

    this.clearUnusedROFields();
    if (this.registeredOwnerContact?.postalCode)
      this.registeredOwnerContact.postalCode = this.registeredOwnerContact.postalCode.toUpperCase();
    if (this.vehicle.licencePlateNumber)
      this.vehicle.licencePlateNumber = this.vehicle.licencePlateNumber.toUpperCase();  
    if (this.vehicle.vehicleIdentificationNumber)
      this.vehicle.vehicleIdentificationNumber = this.vehicle.vehicleIdentificationNumber.toUpperCase();
    if (this.vehicle.make)
      this.vehicle.make = this.vehicle.make.toUpperCase(); 
    if (this.vehicle.model)
      this.vehicle.model = this.vehicle.model.toUpperCase();     
    if (this.vehicle.isTrailerInvolved && this.vehicle.trailerLicencePlateNumber)
      this.vehicle.trailerLicencePlateNumber = this.vehicle.trailerLicencePlateNumber.toUpperCase();     
  
    if (isValid)
    {
      this.stopInformation.contraventions.forEach(contravention => {
        contravention.vehicle = this.vehicle;
      });

      this.intakeService.saveStopInformationContext();
      if ((this.stopInformation.isIRSSelected && !this.isCommercialContravention()) || this.stopInformation.isSDPSelected)
      {
        this.stopInformation.progressId = SubmissionProgrssStates.VehicleSeizure;
        this.intakeService.saveStopInformationContext();
        this.router.navigateByUrl('/contravention/submission/vehicle-seizure');
      }
      else
      {
        this.stopInformation.progressId = SubmissionProgrssStates.PrintAndIssue;
        this.intakeService.saveStopInformationContext();
        this.router.navigateByUrl('/contravention/submission/print-and-issue');
      }
    }
    else
      window.scroll(0,0);  
  }

  // JTI-3353
  isCommercialContravention(): boolean {
    var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
    var irsContravention: Contravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));
    if (irsContravention!=null) {
      if( irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial1st ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial2nd ||
          irsContravention.contraventionTypeId == ContraventionTypes.IRSZeroCommercial3rd) {
            return true;
      }
    }
    return false;
  }

  onBlurCallMoves(licencePlateNumber)
  {
    const trimmedPlateNumber = licencePlateNumber.replace(/\s+/g, ''); // Remove whitespace
    if (  trimmedPlateNumber != "" &&
          (this.vehicle?.isAlbertaLicencePlate && (!this.stopInformation.vehicleRegisteredOwnerInformation || this.stopInformation.vehicleRegisteredOwnerInformation.registrations[0]?.plate != this.vehicle?.licencePlateNumber)) &&
          !this.vehicle?.isNoPlate && !this.vehicle?.isStolenPlate
       )
      {
        this.connectivityService.getOnlineStatusAsync().then( isOnline => {
          if (isOnline) {
            this.spinner.show(); 
            this.movesService.getRegisteredOwnerAsync(trimmedPlateNumber, "")
            .subscribe(result => {
              this.vehicle.movesLookupDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
              this.autoFillMovesInfo(result)
              this.spinner.hide(); 
            },
            error => {
              this.vehicleRegisteredOwnerInformation = null;
              this.clearVehicleInformation();
              this.spinner.hide(); 
            });  
          }
        });
      }
  }

  autoFillMovesInfo(vehicleRegisteredOwnerInformation: VehicleRegisteredOwnerInformation)
  {
    this.stopInformation.vehicleRegisteredOwnerInformation = vehicleRegisteredOwnerInformation;
    
    this.vehicle.licencePlateNumber = vehicleRegisteredOwnerInformation.registrations[0]?.plate;
    this.vehicle.vehicleIdentificationNumber = vehicleRegisteredOwnerInformation.vehicle?.vin;
    this.vehicle.year = +vehicleRegisteredOwnerInformation?.vehicle?.year;
    this.vehicle.make = vehicleRegisteredOwnerInformation?.vehicle?.make;
    this.vehicle.model = vehicleRegisteredOwnerInformation?.vehicle?.model;
    this.vehicle.colour = vehicleRegisteredOwnerInformation?.vehicle?.color;
    this.vehicle.clientTypeId = this.localStorageService.getClientTypes().find(x=> x.name == vehicleRegisteredOwnerInformation?.registeredOwner?.type)?.id;
    this.vehicle.licencePlateClass = vehicleRegisteredOwnerInformation?.registrations[0]?.vrc;
    this.vehicle.vehicleStyle = vehicleRegisteredOwnerInformation?.vehicle?.style;
    this.vehicle.vehicleWeight = vehicleRegisteredOwnerInformation?.registrations[0]?.gvw;
    this.vehicle.vehicleSeatingCapacity = vehicleRegisteredOwnerInformation?.vehicle?.seatingCapacity;
    this.vehicle.vehicleStatusTypeId = this.vehicleStatusTypes.find(x=> x.name == (this.vehicleStatusMap.has(vehicleRegisteredOwnerInformation?.vehicle?.vehicleStatus)?this.vehicleStatusMap.get(vehicleRegisteredOwnerInformation?.vehicle?.vehicleStatus):""))?.id;
    this.vehicle.lastServiceType = vehicleRegisteredOwnerInformation?.registrations[0]?.regLastService;
    this.vehicle.lastServiceDate = vehicleRegisteredOwnerInformation?.registrations[0]?.regLastServiceDate? new Date(vehicleRegisteredOwnerInformation?.registrations[0]?.regLastServiceDate):null;

    //JTI-3604: If an Organization client type is selected, isSameAsDriver should be false
    if (this.vehicle.clientTypeId == ClientTypes.Organization) {
      this.registeredOwner.isSameAsDriver = false;
      this.isSameAsDriverSelectionMade = true;
      // The code below calls autoFillMovesROInfo(), so we don't need to call this.onSameAsDriverChange() here
    }

    this.autoFillMovesROInfo();

    if (vehicleRegisteredOwnerInformation?.registrations[0]?.expiryDate)
    {
      if (new Date(vehicleRegisteredOwnerInformation?.registrations[0]?.expiryDate) < new Date())
        this.vehicle.vehicleRegistrationStatusTypeId = 2; //Unregistered
      else
        this.vehicle.vehicleRegistrationStatusTypeId = 1; //Registered
      
        this.vehicle.licencePlateExpiryDate = vehicleRegisteredOwnerInformation?.registrations[0]?.expiryDate? new Date(new Date(vehicleRegisteredOwnerInformation?.registrations[0]?.expiryDate).setHours(0,0,0,0)): null;
    }
  }

  autoFillMovesROInfo()
  {
    var vehicleRegisteredOwnerInformation =  this.stopInformation.vehicleRegisteredOwnerInformation;

    if ((this.recipient.recipientTypeId == RecipientTypes.Driver || this.recipient.recipientTypeId == RecipientTypes.RegisteredOwner) && vehicleRegisteredOwnerInformation)
    {
      this.registeredOwner.driverLicenceNumber = null;
      this.registeredOwner.motorVehicleIdentificationNumber = vehicleRegisteredOwnerInformation?.registeredOwner?.mvid;
      this.registeredOwner.firstName = vehicleRegisteredOwnerInformation?.registeredOwner?.firstName;
      this.registeredOwner.otherName = vehicleRegisteredOwnerInformation?.registeredOwner?.middleName;
      this.registeredOwner.lastName = vehicleRegisteredOwnerInformation?.registeredOwner?.name;
      this.registeredOwner.dateOfBirth = vehicleRegisteredOwnerInformation?.registeredOwner?.dob? new Date(vehicleRegisteredOwnerInformation?.registeredOwner?.dob):null;
      this.registeredOwner.genderId = vehicleRegisteredOwnerInformation?.registeredOwner?.gender? this.localStorageService.getGenderTypes().find(x=> x.code == vehicleRegisteredOwnerInformation?.registeredOwner?.gender)?.id : null;

      this.registeredOwnerContact.addressLine1 = vehicleRegisteredOwnerInformation?.registeredOwner?.addressLine1;
      this.registeredOwnerContact.addressLine2 = vehicleRegisteredOwnerInformation?.registeredOwner?.addressLine2;
      this.registeredOwnerContact.city = vehicleRegisteredOwnerInformation?.registeredOwner?.city;
      this.registeredOwnerContact.addressLine1 = vehicleRegisteredOwnerInformation?.registeredOwner?.addressLine1;

      this.registeredOwnerContact.countryId = this.localStorageService.getCountryTypes().find(x=> x.name == vehicleRegisteredOwnerInformation?.registeredOwner?.country)?.id;
      this.registeredOwnerCountryProvince.countryId = this.registeredOwnerContact.countryId;

      if (this.registeredOwnerContact.countryId == 40 || this.registeredOwnerContact.countryId == 236) // Canada or USA
      {
        this.registeredOwnerContact.provinceId = this.localStorageService.getProvinceTypes().find(x=> x.code == vehicleRegisteredOwnerInformation?.registeredOwner?.province)?.id;
        this.registeredOwnerContact.province = "";

        this.registeredOwnerCountryProvince.provinceId = this.registeredOwnerContact.provinceId;
        this.registeredOwnerCountryProvince.province = "";
      }
      else
      {
        this.registeredOwnerContact.province = vehicleRegisteredOwnerInformation?.registeredOwner?.province;
        this.registeredOwnerContact.provinceId = null;
        
        this.registeredOwnerCountryProvince.provinceId = null
        this.registeredOwnerCountryProvince.province = this.registeredOwnerContact.province;
      }
      
      this.registeredOwnerContact.postalCode = vehicleRegisteredOwnerInformation?.registeredOwner?.postalCode;
      this.registeredOwnerContact.phoneNumber1 = vehicleRegisteredOwnerInformation?.registeredOwner?.primaryPhoneNumber;
    }
    else
    {
      //Clear RO information
      this.registeredOwnerCountryProvince.countryId = null;
      this.registeredOwnerCountryProvince.provinceId = null;
      this.registeredOwnerCountryProvince.province = "";

      this.registeredOwner.driverLicenceNumber = "";
      this.registeredOwner.motorVehicleIdentificationNumber = "";
      this.registeredOwner.firstName = "";
      this.registeredOwner.lastName = "";
      this.registeredOwner.otherName = "";
      this.registeredOwner.genderId = null;
      this.registeredOwner.dateOfBirth = null;
      this.registeredOwnerContact.phoneNumber1 = "";
      this.registeredOwnerContact.addressLine1 = "";
      this.registeredOwnerContact.addressLine2 = "";
      this.registeredOwnerContact.addressLine3 = "";
      this.registeredOwnerContact.countryId = null;
      this.registeredOwnerContact.provinceId = null;
      this.registeredOwnerContact.province = "";
      this.registeredOwnerContact.city = "";
      this.registeredOwnerContact.postalCode = "";
    }

    this.registeredOwnerCountryProvince.isDisabled = this.isRegisteredOwnerReadOnly();
  }

  onAlbertaLicenceChecked(isChecked)
  {
    this.vehicle.licencePlateNumber = "";
    this.clearVehicleInformation();
    this.vehicleCountryProvince.isDisabled = isChecked;

    if (isChecked) {
      this.vehicleCountryProvince.countryId = 40 //Canada
      this.vehicleCountryProvince.provinceId = 1 //Alberta

      this.vehicle.licensedCountryId = 40;
      this.vehicle.licensedProvinceId = 1;
      this.vehicle.licensedProvince = null;
      this.vehicle.isNoPlate = false;
    }  
    else
    {
      this.vehicleCountryProvince.countryId = null;
      this.vehicle.licensedCountryId = null;
      this.vehicle.licensedProvinceId = null;
      this.vehicle.licensedProvince = null;
    }

  }

  clearUnusedROFields()
  {
    // Clear Registered Owner fields that are not shared between organizations, and other types of clients
    if (this.vehicle.clientTypeId == ClientTypes.Organization) {
      this.registeredOwner.driverLicenceNumber = "";
      this.registeredOwner.firstName = "";
      this.registeredOwner.otherName = "";
      this.registeredOwner.genderId = null;
      this.registeredOwner.dateOfBirth = null;
      this.registeredOwnerContact.addressLine3 = "";
      this.registeredOwnerContact.phoneNumber2 = "";
      this.registeredOwnerContact.faxNumber = "";
    }
  }

  onStolenPlateChange()
  {
    if (this.vehicle.isStolenPlate)
    {
      this.vehicle.isNoPlate = false;
    }

    if ((!this.vehicle.isVinNotAvailable || !this.vehicle.isStolenPlate) && this.vehicle.isNoRegisteredOwner)
    {
      this.vehicle.isNoRegisteredOwner = false;
      this.onNoRegisteredOwnerChecked(false);
    }

    this.vehicleCountryProvince.isCountryRequired = (!this.vehicle.isStolenPlate && !this.vehicle.isNoPlate);
    this.vehicleCountryProvince.isProvinceRequired = (!this.vehicle.isStolenPlate && !this.vehicle.isNoPlate);

    this.vehicle.isVinNotAvailable = null;

    if(!this.vehicle.movesLookupDate) return;

    if(this.vehicle.isStolenPlate)
    {
      //Preserve moves lookup vehicle information first
      this.vehicle.movesVehicle = new MovesVehicle();
      this.movesVehicle = this.vehicle.movesVehicle;

      this.movesVehicle.licencePlateNumber = this.vehicle.licencePlateNumber;
      this.movesVehicle.vehicleIdentificationNumber = this.vehicle.vehicleIdentificationNumber;
      this.movesVehicle.vehicleRegistrationStatusTypeId = this.vehicle.vehicleRegistrationStatusTypeId;
      this.movesVehicle.licensedCountryId = this.vehicle.licensedCountryId;
      this.movesVehicle.licensedProvinceId = this.vehicle.licensedProvinceId;
      this.movesVehicle.licensedProvince = this.vehicle.licensedProvince;
      this.movesVehicle.clientTypeId = this.vehicle.clientTypeId;
      this.movesVehicle.make = this.vehicle.make;
      this.movesVehicle.model = this.vehicle.model;
      this.movesVehicle.year = this.vehicle.year;
      this.movesVehicle.colour = this.vehicle.colour;
      this.movesVehicle.licencePlateExpiryDate = this.vehicle.licencePlateExpiryDate;
      this.movesVehicle.licencePlateClass = this.vehicle.licencePlateClass;
      this.movesVehicle.vehicleStyle = this.vehicle.vehicleStyle;
      this.movesVehicle.vehicleWeight = this.vehicle.vehicleWeight;
      this.movesVehicle.vehicleSeatingCapacity = this.vehicle.vehicleSeatingCapacity;
      this.movesVehicle.vehicleStatusTypeId = this.vehicle.vehicleStatusTypeId;
      this.movesVehicle.lastServiceType = this.vehicle.lastServiceType;
      this.movesVehicle.lastServiceDate = this.vehicle.lastServiceDate; 

      //Initialize Moves vehicle country province control
      this.vehicleCountryProvinceMoves = new CountryProvinceModel();
      this.vehicleCountryProvinceMoves.countryId = this.movesVehicle.licensedCountryId;
      this.vehicleCountryProvinceMoves.provinceId = this.movesVehicle.licensedProvinceId;
      this.vehicleCountryProvinceMoves.province = this.movesVehicle.licensedProvince;
      this.vehicleCountryProvinceMoves.isCountryRequired = false;
      this.vehicleCountryProvinceMoves.isProvinceRequired = false;
      this.vehicleCountryProvinceMoves.countryLabel = "Licensed Country";
      this.vehicleCountryProvinceMoves.provinceLabel = "Licensed Province";
      this.vehicleCountryProvinceMoves.controlSuffixText = "MovesVehicle";
      this.vehicleCountryProvinceMoves.isDisabled = true;

      //Clear vehicle information to manual entery
      this.clearVehicleData();

      //Clear RO information for manual entry
      this.clearRegisteredOwnerData();
    } 
    else
    {
      //Restore all vehicle information to it's original state
      this.vehicle.vehicleIdentificationNumber = this.movesVehicle.vehicleIdentificationNumber;
      this.vehicle.vehicleRegistrationStatusTypeId = this.movesVehicle.vehicleRegistrationStatusTypeId;
      this.vehicle.licensedCountryId = this.movesVehicle.licensedCountryId;
      this.vehicle.licensedProvinceId = this.movesVehicle.licensedProvinceId;
      this.vehicle.licensedProvince = this.movesVehicle.licensedProvince;
      this.vehicle.clientTypeId = this.movesVehicle.clientTypeId;
      this.vehicle.make = this.movesVehicle.make;
      this.vehicle.model = this.movesVehicle.model;
      this.vehicle.year = this.movesVehicle.year;
      this.vehicle.colour = this.movesVehicle.colour;
      this.vehicle.licencePlateExpiryDate = this.movesVehicle.licencePlateExpiryDate;
      this.vehicle.licencePlateClass = this.movesVehicle.licencePlateClass;
      this.vehicle.vehicleStyle = this.movesVehicle.vehicleStyle;
      this.vehicle.vehicleWeight = this.movesVehicle.vehicleWeight;
      this.vehicle.vehicleSeatingCapacity = this.movesVehicle.vehicleSeatingCapacity;
      this.vehicle.vehicleStatusTypeId = this.movesVehicle.vehicleStatusTypeId;
      this.vehicle.lastServiceType = this.movesVehicle.lastServiceType;
      this.vehicle.lastServiceDate = this.movesVehicle.lastServiceDate; 

      //JTI-3604: If an Organization client type is selected, isSameAsDriver should be false
      if (this.vehicle.clientTypeId == ClientTypes.Organization) {
        this.registeredOwner.isSameAsDriver = false;
        this.isSameAsDriverSelectionMade = true;
        // The code below calls autoFillMovesROInfo(), so we don't need to call this.onSameAsDriverChange() here
      }

      this.movesVehicle = null;
      this.vehicle.movesVehicle = null;

      this.vehicleCountryProvince.countryId = this.vehicle.licensedCountryId;
      this.vehicleCountryProvince.provinceId = this.vehicle.licensedProvinceId;
      this.vehicleCountryProvince.province = this.vehicle.licensedProvince;

      //Restore MOVES RO Information
      this.autoFillMovesROInfo();
    }   
  }

  onNoPlateChange()
  {
    if (this.vehicle.isNoPlate)
    {
      this.vehicle.isStolenPlate = false;
      this.vehicle.isAlbertaLicencePlate = false;
      this.vehicle.licencePlateNumber = null;
      this.onAlbertaLicenceChecked(false);
    }

    this.vehicleCountryProvince.isCountryRequired = (!this.vehicle.isStolenPlate && !this.vehicle.isNoPlate);
    this.vehicleCountryProvince.isProvinceRequired = (!this.vehicle.isStolenPlate && !this.vehicle.isNoPlate);
  }

  onClientTypeChange()
  {
    //JTI-3604: If an Organization client type is selected, isSameAsDriver should be false
    if (this.vehicle.clientTypeId == ClientTypes.Organization && this.registeredOwner.isSameAsDriver != false) {
      this.registeredOwner.isSameAsDriver = false;
      this.onSameAsDriverChange();
    }
  }

  isVehicleReadOnly()
  {
    return (!!this.vehicle.movesLookupDate) && !this.vehicle.isStolenPlate;
  }

  isMovesSectionVisible()
  {
    return (!!this.vehicle.movesLookupDate) && this.vehicle.isStolenPlate;
  }

  isRegisteredOwnerReadOnly()
  {
    return !!this.vehicle.movesLookupDate && !this.registeredOwner?.isSameAsDriver && !this.vehicle.isStolenPlate;
  }

  onNoRegisteredOwnerChecked(isChecked)
  {
    if(isChecked)
    {
      //Clear registered owner fields
      this.registeredOwner = null;
      this.registeredOwnerContact = null;
      this.vehicle.registeredOwner = null;
    }
    else
    {
      //Create registered owner entity and preserve the data
      if (this.vehicle.registeredOwner == null && (this.recipient.recipientTypeId == RecipientTypes.Driver || this.recipient.recipientTypeId == RecipientTypes.RegisteredOwner)) {
        this.vehicle.registeredOwner = new RegisteredOwner();

        //Set Defaults
        this.vehicle.registeredOwner.genderId = null;
        this.vehicle.registeredOwner.dateOfBirth = null;

        this.vehicle.registeredOwner.registeredOwnerContact = new RegisteredOwnerContact();  
        
        //Set Defaults
        this.vehicle.registeredOwner.registeredOwnerContact.countryId = 40 //Canada
        this.vehicle.registeredOwner.registeredOwnerContact.provinceId = 1 //Alberta
      }

      this.registeredOwner = this.vehicle.registeredOwner;
      this.registeredOwnerContact = this.vehicle.registeredOwner.registeredOwnerContact

      if (!this.vehicle.isStolenPlate)
        this.autoFillMovesROInfo();
    }
  }

  onVINNotAvailableChecked()
  {
    if ((!this.vehicle.isVinNotAvailable || !this.vehicle.isStolenPlate) && this.vehicle.isNoRegisteredOwner)
    {
      this.vehicle.isNoRegisteredOwner = false;
      this.onNoRegisteredOwnerChecked(false);
    }
  }

  private clearVehicleInformation()
  {
    this.clearVehicleData();
    this.clearRegisteredOwnerData();

    this.vehicle.movesLookupDate = null;
    this.vehicle.isVinNotAvailable = null;
  }

  private clearVehicleData()
  {
    this.vehicle.vehicleIdentificationNumber = "";
    this.vehicle.vehicleRegistrationStatusTypeId = null;
    this.vehicle.clientTypeId = null;
    this.vehicle.make = "";
    this.vehicle.model = "";
    this.vehicle.year = null;
    this.vehicle.colour = "";
    this.vehicle.licencePlateExpiryDate = null;
    this.vehicle.licencePlateClass = "";
    this.vehicle.vehicleStyle = "";
    this.vehicle.vehicleWeight = "";
    this.vehicle.vehicleSeatingCapacity = "";
    this.vehicle.vehicleStatusTypeId = null;
    this.vehicle.lastServiceDate = null;
    this.vehicle.lastServiceType = "";
  }

  private clearRegisteredOwnerData()
  {
    this.registeredOwner.driverLicenceNumber = "";
    this.registeredOwner.motorVehicleIdentificationNumber = "";
    this.registeredOwner.firstName = "";
    this.registeredOwner.otherName = "";
    this.registeredOwner.lastName = "";
    this.registeredOwner.genderId = null;
    this.registeredOwner.dateOfBirth = null;

    this.registeredOwnerContact.addressLine1 = "";
    this.registeredOwnerContact.addressLine2 = "";
    this.registeredOwnerContact.addressLine3 = "";
    this.registeredOwnerContact.phoneNumber1 = "";
    this.registeredOwnerContact.phoneNumber2 = "";
    this.registeredOwnerContact.faxNumber = "";
    this.registeredOwnerContact.countryId = null;
    this.registeredOwnerContact.provinceId = null;
    this.registeredOwnerContact.province = "";
    this.registeredOwnerCountryProvince.countryId = null;
    this.registeredOwnerCountryProvince.provinceId = null;
    this.registeredOwnerCountryProvince.province = "";
    this.registeredOwnerContact.city = "";
    this.registeredOwnerContact.postalCode = "";
    this.registeredOwnerCountryProvince.isDisabled = this.isRegisteredOwnerReadOnly();
  }
}
