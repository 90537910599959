<form #recipientInfoForm="ngForm">
    <div class="health-message">{{ticketsHealthStatusMessage}}</div>
    <div class="d-flex flex-column">
        <h1>Recipient{{availableTSA?"/Accused":""}} Information</h1>
        <div class="row mt-3">
            <div class="col-12">
                <label class="w-100">The Recipient{{availableTSA?"/Accused":""}} is:</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input required type="radio" class="custom-control-input" id="recipientDriver" name="recipientClassification" #recipientDriverControl="ngModel" [value]="1" [(ngModel)]="recipient.recipientTypeId" (change)="onRecipientTypeChange()">
                    <label class="custom-control-label" for="recipientDriver">Driver of a vehicle <small>(including car, motorcycle, OHV, bus, etc.)</small></label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="custom-control custom-radio custom-control-inline">
                    <input required type="radio" class="custom-control-input" id="recipientRO" name="recipientClassification" #recipientROControl="ngModel" [value]="2" [(ngModel)]="recipient.recipientTypeId" (change)="onRecipientTypeChange()">
                    <label class="custom-control-label" for="recipientRO">Registered owner of a vehicle <small>(use when issuing a contravention to the Registered Owner only)</small></label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="custom-control custom-radio custom-control-inline">
                    <input required type="radio" class="custom-control-input" id="recipientPassenger" name="recipientClassification" #recipientPassengerControl="ngModel" [value]="3" [(ngModel)]="recipient.recipientTypeId" (change)="onRecipientTypeChange()">
                    <label class="custom-control-label" for="recipientPassenger">Passenger</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="custom-control custom-radio custom-control-inline">
                    <input [disabled]="!isRecipientTypeSelectionRequired" required type="radio" class="custom-control-input" id="recipientNotInVehicle" name="recipientClassification" #recipientNotInVehicleControl="ngModel" [value]="4" [(ngModel)]="recipient.recipientTypeId" (change)="onRecipientTypeChange()">
                    <label class="custom-control-label" for="recipientNotInVehicle">Not in a vehicle <small>(pedestrian, cyclist, Service Alberta incident, etc.)</small></label>
                </div>
                <input required type="hidden" class="custom-control-input" id="recipientClassificationHidden" name="recipientClassification" #recipientClassificationHiddenControl="ngModel" [value]="null" [(ngModel)]="recipient.recipientTypeId" [ngClass]="{'is-invalid':(recipientClassificationHiddenControl.touched || isSubmitClicked) && recipientClassificationHiddenControl.invalid}">
                <div class="invalid-feedback">
                    <div>Please select one</div>
                </div>
            </div>
        </div>
        <!--<div class="row mt-2 mb-3" *ngIf="isAlbertaDriverLicense">
            <div class="col-12 col-sm-6">        
                <button class="btn button-secondary" (click)="onSearchMOVES()">MOVES Driver Licence Look Up</button>                    
            </div>
        </div>-->
        <div class="card mt-2">
            <div class="card-header">
                Recipient{{availableTSA?"/Accused":""}} Identification
            </div>
            <div class="card-body" *ngIf="recipient.recipientTypeId == RecipientTypes.RegisteredOwner">
                If this ticket is for a Registered Owner of a vehicle and you are not able collect the driver's information, proceed to the following page.
            </div>
            <div class="card-body" *ngIf="recipient.recipientTypeId != RecipientTypes.RegisteredOwner">
                <div class="form-group row">
                    <div class="col-12">
                        <div class="section-header">How was the recipient{{availableTSA?"/accused":""}} identification verified?</div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input required type="radio" class="custom-control-input" id="albertaDriverYes" name="driverLicenceClassification" #albertaDriverYesControl="ngModel" [value]="1" [(ngModel)]="recipientIdentification.driversLicenceClassificationId" (change)="onDriverLicenceClassificationChange()">
                            <label class="custom-control-label" for="albertaDriverYes">Alberta DL or ID Card</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input required type="radio" class="custom-control-input" id="albertaDriverNo" name="driverLicenceClassification" #albertaDriverNoControl="ngModel" [value]="2" [(ngModel)]="recipientIdentification.driversLicenceClassificationId" (change)="onDriverLicenceClassificationChange()">
                            <label class="custom-control-label" for="albertaDriverNo">Out of province DL</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input required type="radio" class="custom-control-input" id="noLicenceHistory" name="driverLicenceClassification" #albertaDriverNoControl="ngModel" [value]="3" [(ngModel)]="recipientIdentification.driversLicenceClassificationId" (change)="onDriverLicenceClassificationChange()">
                            <label class="custom-control-label" for="noLicenceHistory">No M.V.I.D./DL history found in MOVES</label>
                        </div>
                        <input required type="hidden" class="custom-control-input" id="driverLicenceClassificationHidden" name="driverLicenceClassification" #driverLicenceClassificationHiddenControl="ngModel" [value]="null" [(ngModel)]="recipientIdentification.driversLicenceClassificationId" [ngClass]="{'is-invalid':(driverLicenceClassificationHiddenControl.touched || isSubmitClicked) && driverLicenceClassificationHiddenControl.invalid}">
                        <div class="invalid-feedback">
                            <div>Please select one</div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="recipientIdentification?.driversLicenceClassificationId && recipientIdentification.driversLicenceClassificationId != DriverLicenceClassification.NoHistryFound">
                    <div class="row">
                        <div class="col-12">
                            <label class="w-100">Verification Method?</label>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="licencePresented" name="licencePresentedClassification" #licencePresentedControl="ngModel" [value]="1" [(ngModel)]="recipientIdentification.licencePresentedClassificationId" (change)="onLicencePresentedClassificationChange()" >
                                <label class="custom-control-label" for="licencePresented">They produced a Driver's Licence or Identification Card</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mt-2">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="licenceViaLookup" name="licencePresentedClassification" #licenceViaLookupControl="ngModel" [value]="2" [(ngModel)]="recipientIdentification.licencePresentedClassificationId" (change)="onLicencePresentedClassificationChange()" >
                                <label class="custom-control-label" for="licenceViaLookup">Driver's Licence or Identification information found via a look-up</label>
                            </div>
                            <input required type="hidden" class="custom-control-input" id="licencePresentedClassificationHidden" name="licencePresentedClassification" #licencePresentedClassificationHiddenControl="ngModel" [value]="null" [(ngModel)]="recipientIdentification.licencePresentedClassificationId" [ngClass]="{'is-invalid':(licencePresentedClassificationHiddenControl.touched || isSubmitClicked) && licencePresentedClassificationHiddenControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Please select one</div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="form-group ml-4" *ngIf="recipientIdentification.licencePresentedClassificationId==LicencePresentedClassification.Other">
                    <div class="row">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="otherIdentification" name="verbalClassification" #otherIdentificationControl="ngModel" [value]="false" [(ngModel)]="isVerbal" (change)="onVerbalClassificationChange()">
                                <label class="custom-control-label" for="otherIdentification">Other form of identification</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mt-2">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="verbalIdentification" name="verbalClassification" #verbalIdentificationControl="ngModel" [value]="true" [(ngModel)]="isVerbal" (change)="onVerbalClassificationChange()">
                                <label class="custom-control-label" for="verbalIdentification">Only verbal ID provided</label>
                            </div>
                        </div>
                        <input required type="hidden" class="custom-control-input" id="verbalClassificationHidden" name="verbalClassification" #verbalClassificationHiddenControl="ngModel" [value]="null" [(ngModel)]="isVerbal" [ngClass]="{'is-invalid':(verbalClassificationHiddenControl.touched || isSubmitClicked) && verbalClassificationHiddenControl.invalid}">
                        <div class="invalid-feedback ml-4">
                            <div>Please select one</div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="recipientIdentification.identificationTypeId==IdentificationTypes.DriversLicence">
                    <ng-container *ngIf="recipientIdentification.driversLicenceClassificationId!=null">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 d-md-flex align-items-md-center mb-2">
                                    <div class="section-header">Driver's Licence or Alberta ID Number and M.V.I.D.</div>
                                    <span class="section-hint"  *ngIf="recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince">(One Required)</span>                   
                                </div>
                            </div> 
                            <div class="row" *ngIf="isRecipientReadOnly()">
                                <div class="col-12">
                                    <label>All recipient{{availableTSA?"/accused":""}} information was generated from the Motor Vehicle System (MOVES) on {{recipient.movesLookupDate | date:'mediumDate'}} at {{recipient.movesLookupDate | date:'HH:mm'}}</label>
                                </div>
                            </div>
                            <div class="row mt-2 mb-3">
                                <div class="col-12 col-sm-6">        
                                    <button class="btn button-secondary" (click)="onScanDriversLicence()">Scan Driver's Licence</button>                    
                                </div>
                            </div>      
                            <div class="row" *ngIf="errorMessage != null && errorMessage != ''">
                                <div class="col-12 mb-4">  
                                    <span class="error">{{errorMessage}}</span>
                                </div>
                            </div>     
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <label for="driversLicenceNumber" [ngClass]="{'section-label': this.recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince}">
                                        Card Number
                                        <span class="label-hint" *ngIf="recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta && !isOnlyMVIDRecord">(Required)</span>
                                    </label>
                                    <input [appAlbertaLicence]="isAlbertaDriverLicense" [appAlbertaLicenceValidator]="isAlbertaDriverLicense" 
                                    [required]="(recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta && !isOnlyMVIDRecord) || (recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince && !recipientIdentification.motorVehicleIdentificationNumber)" maxlength="16" 
                                    #driversLicenceNumberControl="ngModel" type="text" [(ngModel)]="recipientIdentification.identificationNumber" class="form-control" id="driverLicenceNumber" 
                                    name="driverLicenceNumber" placeholder="Driver's Licence Number" [ngClass]="{'is-invalid':(driversLicenceNumberControl.touched || isSubmitClicked) && driversLicenceNumberControl.invalid}"
                                    (blur)="onBlurCallMoves($event.target.value, !driversLicenceNumberControl.invalid, true)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="driversLicenceNumberControl.errors?.required && recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta">Driver's Licence Number is required</div>
                                        <div *ngIf="driversLicenceNumberControl.errors?.required && recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince">Driver's Licence Number or M.V.I.D is required</div>
                                        <div *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberLength && !driversLicenceNumberControl.errors?.required">Atleast 4 digits are required</div>
                                        <div *ngIf="driversLicenceNumberControl.errors?.invalidLicenceNumberFormat && !driversLicenceNumberControl.errors?.required">Only digits are allowed</div>
                                        <div *ngIf="driversLicenceNumberControl.errors?.invalidCheckDigit && !driversLicenceNumberControl.errors?.required">Invalid Alberta Driver's Licence Number.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="mvidNumber" [ngClass]="{'section-label': recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince}">
                                        M.V.I.D
                                        <span class="label-hint" *ngIf="recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta && connectivityService.isUserOnline()">(Required)</span>                   
                                    </label>
                                    <input [appMVIDNumber]="isAlbertaDriverLicense" [appMVIDNumberValidator]="isAlbertaDriverLicense" 
                                    [required]= "(recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta && connectivityService.isUserOnline()) || (recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince && !recipientIdentification.identificationNumber && connectivityService.isUserOnline())" maxlength="10" #mvidNumberControl="ngModel" 
                                    type="text" [(ngModel)]="recipientIdentification.motorVehicleIdentificationNumber" class="form-control" id="mvidNumber" name="mvidNumber" placeholder="M.V.I.D" 
                                    [ngClass]="{'is-invalid':(mvidNumberControl.touched || isSubmitClicked) && mvidNumberControl.invalid}" (blur)="onBlurCallMoves($event.target.value, !mvidNumberControl.invalid, false)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="mvidNumberControl.errors?.required && recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.Alberta">M.V.I.D is required</div>
                                        <div *ngIf="mvidNumberControl.errors?.required && recipientIdentification.driversLicenceClassificationId == DriverLicenceClassification.OutOfProvince">Driver's Licence Number or M.V.I.D is required</div>
                                        <div *ngIf="mvidNumberControl.errors?.invalidMvidNumberLength && !mvidNumberControl.errors?.required">M.V.I.D Number must be 10 digits long</div>
                                        <div *ngIf="mvidNumberControl.errors?.invalidMvidNumberFormat && !mvidNumberControl.errors?.required">Only digits are allowed</div>
                                        <div *ngIf="mvidNumberControl.errors?.invalidCheckDigit && !mvidNumberControl.errors?.required">Invalid M.V.I.D Number.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <label for="surname">Surname <span class="label-hint">(Required)</span></label>
                                    <input required #surnameControl="ngModel" type="text" maxlength="50" [readonly]="isRecipientReadOnly()"
                                    [(ngModel)]="recipient.lastName" (ngModelChange)="recipient.lastName=this.moddedTitleCasePipe.transform($event)" 
                                    class="form-control" id="surname" name="surname" placeholder="Surname" [ngClass]="{'is-invalid':(surnameControl.touched || isSubmitClicked) && surnameControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div>Surname is required</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="firstName">First Name <span class="label-hint">(Required)</span></label>
                                    <input required #firstNameControl="ngModel" type="text" maxlength="50" [readonly]="isRecipientReadOnly()"
                                    [(ngModel)]="recipient.firstName" (ngModelChange)="recipient.firstName=this.moddedTitleCasePipe.transform($event)" 
                                    class="form-control" id="firstName" name="firstName" placeholder="First Name" [ngClass]="{'is-invalid':(firstNameControl.touched || isSubmitClicked) && firstNameControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div>First Name is required</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="middleName">Middle Name</label>
                                    <input type="text" [readonly]="isRecipientReadOnly()"
                                    [(ngModel)]="recipient.otherName" (ngModelChange)="recipient.otherName=this.moddedTitleCasePipe.transform($event)" 
                                    maxlength="50" class="form-control" id="middleName" name="middleName" placeholder="Middle Name">
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <app-country-province (onChangeEvent)="refreshIdentificationCountryProvince($event)" [countryProvince]="driverLicenceCountryProvince"></app-country-province>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-8">
                                    <label for="licenceClass">Licence Class <span *ngIf="!recipient.isNoLicenceProduced && !isOnlyMVIDRecord" class="label-hint">(Required)</span></label>
                                    <select *ngIf="recipientIdentification.issuingCountryId == 40" [appSelectValidator]="!recipient.isNoLicenceProduced && !isOnlyMVIDRecord" 
                                    #licenceClassControl="ngModel" class="form-control" id="licenceClass" name="licenceClass" [(ngModel)]="recipientIdentification.driversLicenceClassTypeId" 
                                    (change)="onLicenceClassChange()" placeholder="Select a Class" [disabled]="isRecipientReadOnly()"
                                    [ngClass]="{'is-invalid':(licenceClassControl.touched || isSubmitClicked) && licenceClassControl.errors?.defaultSelected && !recipient.isNoLicenceProduced}">
                                        <option [ngValue]="null">Select a Class</option> 
                                        <option *ngFor="let driverLicenceClassType of driverLicenceClassTypes" [ngValue]="driverLicenceClassType.id">{{driverLicenceClassType.name}}</option>
                                    </select>
                                    <input *ngIf="recipientIdentification.issuingCountryId != 40" [required]="!recipient.isNoLicenceProduced" #otherLicenceClassControl="ngModel" type="text" [(ngModel)]="recipientIdentification.driversLicenceClass" class="form-control" name=otherLicenceClassControl id=otherLicenceClassControl placeholder="Licence Class" [ngClass]="{'is-invalid': !recipient.isNoLicenceProduced && (otherLicenceClassControl.touched || isSubmitClicked) && otherLicenceClassControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div>Licence Class is required</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="licenceStatus">Licence Status <span *ngIf="!recipient.isNoLicenceProduced" class="label-hint">(Required)</span></label>
                                    <select [appSelectValidator]="!recipient.isNoLicenceProduced" #licenceStatusControl="ngModel" [disabled]="isRecipientReadOnly()"
                                    class="form-control" id="licenceStatus" name="licenceStatus" [(ngModel)]="recipientIdentification.driversLicenceStatusTypeId" 
                                    [ngClass]="{'is-invalid':(licenceStatusControl.touched || isSubmitClicked) && licenceStatusControl.errors?.defaultSelected && !recipient.isNoLicenceProduced}">
                                        <option [ngValue]="null">Select a Licence Status</option>                        
                                        <option *ngFor="let driverLicenceStatusType of driverLicenceStatusTypes" [ngValue]="driverLicenceStatusType.id">{{driverLicenceStatusType.name}}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        <div>Licence Status is required</div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-4" *ngIf="!isRecipientReadOnly()">
                                    <label for="licenceExpiryDate">Licence Expiry Date <span *ngIf="!recipient.isNoLicenceProduced && isAlbertaDriverLicense" class="label-hint">(Required)</span></label>
                                    <input [required]="!recipient.isNoLicenceProduced && isAlbertaDriverLicense" isBasicDate #licenceExpiryDateControl="ngModel"
                                    type="text" bsDatepicker [bsConfig]="licenceExpiryPickerConfig" maxlength="10" [(ngModel)]="recipientIdentification.identificationExpiryDate" 
                                    class="form-control" id="licenceExpiryDate" name="licenceExpiryDate" placeholder="yyyy/mm/dd" [ngClass]="{'is-invalid':(licenceExpiryDateControl.touched || isSubmitClicked) && licenceExpiryDateControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div *ngIf="licenceExpiryDateControl.errors?.required">Licence Expiry Date is required</div>
                                        <div *ngIf="!licenceExpiryDateControl.errors?.required && licenceExpiryDateControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                        <div *ngIf="!licenceExpiryDateControl.errors?.required && !licenceExpiryDateControl.hasError('InvalidYear')">Invalid Licence Expiry Date</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4" *ngIf="isRecipientReadOnly()">
                                    <label for="licenceExpiryDate">Licence Expiry Date <span *ngIf="!recipient.isNoLicenceProduced && isAlbertaDriverLicense" class="label-hint">(Required)</span></label>
                                    <input readonly required type="text" class="form-control" id="licenceExpiryDate" name="licenceExpiryDate" [value]="recipientIdentification.identificationExpiryDate | date:'yyyy/MM/dd'">
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="isGDLDriver">Is GDL/Novice Driver <span *ngIf="!recipient.isNoLicenceProduced && isAlbertaDriverLicense" class="label-hint">(Required)</span></label>
                                    <select [appSelectValidator]="!recipient.isNoLicenceProduced && isAlbertaDriverLicense" #isGDLDriverControl="ngModel"  class="form-control" id="isGDLDriver" 
                                    name="isGDLDriver" [(ngModel)]="intIsGDLDriver" (change)='onGDLDriverChange($event)' [disabled]="isRecipientReadOnly()"
                                    [ngClass]="{'is-invalid':(isGDLDriverControl.touched || isSubmitClicked) && isGDLDriverControl.errors?.defaultSelected && !recipient.isNoLicenceProduced}">
                                        <option value="0">Select if driver is GDL/Novice driver</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        <div>"Is GDL/Novice Driver" is required</div>
                                    </div>
                                </div> 
                                <div class="col-12 col-sm-4" *ngIf="!isRecipientReadOnly()">
                                    <label for="dateOfBirth">Date of Birth <span class="label-hint">(Required)</span></label>
                                    <input required isBirthDate isGreaterThanEndDate="{{minimumAgeDate | date:'yyyy-MM-dd'}}" #dateOfBirthControl="ngModel" type="text" bsDatepicker [bsConfig]="dateOfBirthPickerConfig"
                                    maxlength="10" [(ngModel)]="recipientIdentification.dateOfBirth" class="form-control" id="dateOfBirth" name="dateOfBirth" 
                                    placeholder="yyyy/mm/dd" [ngClass]="{'is-invalid':(dateOfBirthControl.touched || isSubmitClicked) && dateOfBirthControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div *ngIf="dateOfBirthControl.errors?.required">Date of Birth is required</div>
                                        <div *ngIf="!dateOfBirthControl.errors?.required && dateOfBirthControl.hasError('FutureDate')">Date of Birth can't be a future date</div>
                                        <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && dateOfBirthControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                        <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && !dateOfBirthControl.hasError('InvalidYear') && dateOfBirthControl.hasError('LaterDate')">Recipient/Accused must be 12 or older</div>
                                        <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate')  && !dateOfBirthControl.hasError('InvalidYear') && !dateOfBirthControl.hasError('LaterDate')">Invalid Date of Birth</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4" *ngIf="isRecipientReadOnly()">
                                    <label for="dateOfBirth">Date of Birth <span class="label-hint">(Required)</span></label>
                                    <input readonly required type="text" class="form-control" id="dateOfBirth" name="dateOfBirth" [value]="recipientIdentification.dateOfBirth | date:'yyyy/MM/dd'">
                                </div>
                            </div>  
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <label for="ConditionCodes">Condition Codes</label>
                                    <mat-select #conditionCodesMultiControl="ngModel" class="form-control-multiselect" [disabled]="isRecipientReadOnly()"
                                                multiple id="conditionCodes" name="conditionCodes" placeholder="Select the Condition Codes" 
                                                [(ngModel)]="selectedConditionCodes" (selectionChange)="onConditionCodesChange($event.value)" 
                                                [ngClass]="{'is-invalid':(conditionCodesMultiControl.touched || isSubmitClicked) && conditionCodesMultiControl.invalid}">
                                        <mat-option class="mat-primary" *ngFor="let conditionCode of filterConditionCodeTypes(recipientIdentification.issuingProvinceId)" 
                                        [value]="conditionCode.id">{{conditionCode.name}}</mat-option>
                                    </mat-select>
                                    <div class="invalid-feedback">
                                        <div>Contravention Details are required</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="gender">Gender <span *ngIf="!recipient.isNoLicenceProduced" class="label-hint">(Required)</span></label>
                                    <select [appSelectValidator]="!recipient.isNoLicenceProduced" #genderControl="ngModel" [disabled]="isRecipientReadOnly()"
                                    class="form-control" id="gender" name="gender" [(ngModel)]="recipientIdentification.genderId" 
                                    [ngClass]="{'is-invalid':(genderControl.touched || isSubmitClicked) && genderControl.errors?.defaultSelected && !recipient.isNoLicenceProduced}">
                                        <option [ngValue]="null">Select a Gender</option>
                                        <option *ngFor="let genderType of genderTypes" [ngValue]="genderType.id">{{genderType.name}}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        <div>Gender is required</div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="eyeColor">Eye Color</label>
                                    <input type="text" [(ngModel)]="recipient.eyeColor" [readonly]="isRecipientReadOnly()"
                                    class="form-control" id="eyeColor" name="eyeColor" placeholder="Eye Color">
                                </div>
                            </div>  
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <label for="hairColor">Hair Color</label>
                                    <input type="text" [(ngModel)]="recipient.hairColor" [readonly]="isRecipientReadOnly()"
                                    class="form-control" id="hairColor" name="hairColor" placeholder="Hair Color">
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="weight">Weight (kg)</label>
                                    <input type="text" [(ngModel)]="recipient.weight" [readonly]="isRecipientReadOnly()"
                                    class="form-control" id="weight" name="weight" placeholder="Weight">
                                </div>
                                <div class="col-12 col-sm-4">
                                    <label for="height">Height (cm)</label>
                                    <input type="text" [(ngModel)]="recipient.height" [readonly]="isRecipientReadOnly()"
                                    class="form-control" id="height" name="height" placeholder="height">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <label for="restriction">Restrictions</label>
                                <textarea type="text" [(ngModel)]="recipientIdentification.restrictions" [readonly]="isRecipientReadOnly()"
                                class="form-control" id="restriction" name="restriction" placeholder="Restriction" rows="3"></textarea>
                            </div>
                        </div>   
                    </ng-container>    
                </ng-container>

                <ng-container *ngIf="recipientIdentification.identificationTypeId!=IdentificationTypes.DriversLicence && recipientIdentification.identificationTypeId!=IdentificationTypes.Verbal && recipientIdentification.licencePresentedClassificationId == LicencePresentedClassification.Other && isVerbal!=null">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="section-header">Other Form of Identification</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <label for="idType">ID Type <span class="label-hint">(Required)</span></label>
                                <select [appSelectValidator]="true" #idTypeControl="ngModel" class="form-control" id="idType" name="idType" [(ngModel)]="recipientIdentification.identificationTypeId" [ngClass]="{'is-invalid':(idTypeControl.touched || isSubmitClicked) && idTypeControl.errors?.defaultSelected }">
                                    <option [ngValue]="null">Select an ID provided by recipient</option>
                                    <option *ngFor="let identificationType of identificationTypes" [ngValue]="identificationType.id">{{identificationType.name}}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <div>ID Type is required</div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 mb-3" *ngIf="recipientIdentification.identificationTypeId == IdentificationTypes.Other">
                                <label for="otherIdentification">Other <span class="label-hint">(Required)</span></label>                   
                                <input required #otherIdentificationControl="ngModel" type="text" [(ngModel)]="recipientIdentification.identificationOther" class="form-control" id="otherIdentification" name="otherIdentification" placeholder="Describe ID Provided" 
                                [ngClass]="{'is-invalid':(otherIdentificationControl.touched || isSubmitClicked) && otherIdentificationControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>ID Type is required</div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <label for="idNumber">ID Number <span class="label-hint">(Required)</span></label>                   
                                <input required #idNumberControl="ngModel" type="text" [(ngModel)]="recipientIdentification.identificationNumber" class="form-control" id="idNumber" name="idNumber" placeholder="ID Number" 
                                [ngClass]="{'is-invalid':(idNumberControl.touched || isSubmitClicked) && idNumberControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>ID Number is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label for="surname">Surname <span class="label-hint">(Required)</span></label>
                                <input required #surnameControl="ngModel" type="text" maxlength="50" 
                                [(ngModel)]="recipient.lastName" (ngModelChange)="recipient.lastName=this.moddedTitleCasePipe.transform($event)"
                                class="form-control" id="surname" name="surname" placeholder="Surname" [ngClass]="{'is-invalid':(surnameControl.touched || isSubmitClicked) && surnameControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>Surname is required</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="firstName">First Name <span class="label-hint">(Required)</span></label>
                                <input required #firstNameControl="ngModel" type="text" maxlength="50" 
                                [(ngModel)]="recipient.firstName" (ngModelChange)="recipient.firstName=this.moddedTitleCasePipe.transform($event)" 
                                class="form-control" id="firstName" name="firstName" placeholder="First Name" [ngClass]="{'is-invalid':(firstNameControl.touched || isSubmitClicked) && firstNameControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>First Name is required</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="middleName">Middle Name</label>
                                <input type="text" 
                                [(ngModel)]="recipient.otherName" (ngModelChange)="recipient.otherName=this.moddedTitleCasePipe.transform($event)" 
                                maxlength="50" class="form-control" id="middleName" name="middleName" placeholder="Middle Name">
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <app-country-province (onChangeEvent)="refreshIdentificationCountryProvince($event)" [countryProvince]="identificationCountryProvince"></app-country-province>
                    </div>
                
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label for="expiryDate">Expiry Date</label>
                                <input isBasicDate #expiryDateControl="ngModel" type="text" bsDatepicker [bsConfig]="licenceExpiryPickerConfig" maxlength="10" [(ngModel)]="recipientIdentification.identificationExpiryDate" class="form-control" 
                                id="expiryDate" name="expiryDate" placeholder="yyyy/mm/dd" [ngClass]="{'is-invalid':(expiryDateControl.touched || isSubmitClicked) && expiryDateControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="expiryDateControl.errors?.required">Expiry Date is required</div>
                                    <div *ngIf="!expiryDateControl.errors?.required && expiryDateControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                    <div *ngIf="!expiryDateControl.errors?.required && !expiryDateControl.hasError('InvalidYear')">Invalid Expiry Date</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="gender">Gender <span class="label-hint">(Required)</span></label>
                                <select [appSelectValidator]="true" #genderControl="ngModel" class="form-control" id="gender" name="gender" [(ngModel)]="recipientIdentification.genderId" [ngClass]="{'is-invalid':(genderControl.touched || isSubmitClicked) && genderControl.errors?.defaultSelected}">
                                    <option [ngValue]="null">Select a Gender</option>
                                    <option *ngFor="let genderType of genderTypes" [ngValue]="genderType.id">{{genderType.name}}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <div>Gender is required</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="dateOfBirth">Date of Birth <span class="label-hint">(Required)</span></label>
                                <input required isBirthDate isGreaterThanEndDate="{{minimumAgeDate | date:'yyyy-MM-dd'}}" #dateOfBirthControl="ngModel" type="text" bsDatepicker [bsConfig]="dateOfBirthPickerConfig" maxlength="10" [(ngModel)]="recipientIdentification.dateOfBirth" class="form-control" id="dateOfBirth" name="dateOfBirth" placeholder="yyyy/mm/dd" [ngClass]="{'is-invalid':(dateOfBirthControl.touched || isSubmitClicked) && dateOfBirthControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="dateOfBirthControl.errors?.required">Date of Birth is required</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && dateOfBirthControl.hasError('FutureDate')">Date of Birth can't be a future date</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && dateOfBirthControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && !dateOfBirthControl.hasError('InvalidYear') && dateOfBirthControl.hasError('LaterDate')">Recipient/Accused must be 12 or older</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate')  && !dateOfBirthControl.hasError('InvalidYear') && !dateOfBirthControl.hasError('LaterDate')">Invalid Date of Birth</div>
                                </div>
                            </div>
                        </div>  
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label for="eyeColor">Eye Color</label>
                                <input type="text" [(ngModel)]="recipient.eyeColor" class="form-control" id="eyeColor" name="eyeColor" placeholder="Eye Color">
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="hairColor">Hair Color</label>
                                <input type="text" [(ngModel)]="recipient.hairColor" class="form-control" id="hairColor" name="hairColor" placeholder="Hair Color">
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="height">Height (cm)</label>
                                <input type="text" [(ngModel)]="recipient.height" class="form-control" id="height" name="height" placeholder="height">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <label for="weight">Weight (kg)</label>
                            <input type="text" [(ngModel)]="recipient.weight" class="form-control" id="weight" name="weight" placeholder="Weight">
                        </div>
                    </div> 
                </ng-container>  

                <ng-container *ngIf="recipientIdentification.identificationTypeId == IdentificationTypes.Verbal && recipientIdentification.licencePresentedClassificationId == LicencePresentedClassification.Other  && isVerbal!=null">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="section-header">Verbal Identification</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label for="surname">Surname <span class="label-hint">(Required)</span></label>
                                <input required #surnameControl="ngModel" type="text" maxlength="50" 
                                [(ngModel)]="recipient.lastName" (ngModelChange)="recipient.lastName=this.moddedTitleCasePipe.transform($event)"
                                class="form-control" id="surname" name="surname" placeholder="Surname" [ngClass]="{'is-invalid':(surnameControl.touched || isSubmitClicked) && surnameControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>Surname is required</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="firstName">First Name <span class="label-hint">(Required)</span></label>
                                <input required #firstNameControl="ngModel" type="text" maxlength="50" 
                                [(ngModel)]="recipient.firstName" (ngModelChange)="recipient.firstName=this.moddedTitleCasePipe.transform($event)" 
                                class="form-control" id="firstName" name="firstName" placeholder="First Name" [ngClass]="{'is-invalid':(firstNameControl.touched || isSubmitClicked) && firstNameControl.invalid}">
                                <div class="invalid-feedback">
                                    <div>First Name is required</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="middleName">Middle Name</label>
                                <input type="text" 
                                [(ngModel)]="recipient.otherName" (ngModelChange)="recipient.otherName=this.moddedTitleCasePipe.transform($event)" 
                                maxlength="50" class="form-control" id="middleName" name="middleName" placeholder="Middle Name">
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label for="dateOfBirth">Date of Birth <span class="label-hint">(Required)</span></label>
                                <input required isBirthDate isGreaterThanEndDate="{{minimumAgeDate | date:'yyyy-MM-dd'}}" #dateOfBirthControl="ngModel" type="text" bsDatepicker [bsConfig]="dateOfBirthPickerConfig" maxlength="10" [(ngModel)]="recipientIdentification.dateOfBirth" class="form-control" id="dateOfBirth" name="dateOfBirth" placeholder="yyyy/mm/dd" [ngClass]="{'is-invalid':(dateOfBirthControl.touched || isSubmitClicked) && dateOfBirthControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="dateOfBirthControl.errors?.required">Date of Birth is required</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && dateOfBirthControl.hasError('FutureDate')">Date of Birth can't be a future date</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && dateOfBirthControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate') && !dateOfBirthControl.hasError('InvalidYear') && dateOfBirthControl.hasError('LaterDate')">Recipient/Accused must be 12 or older</div>
                                    <div *ngIf="!dateOfBirthControl.errors?.required && !dateOfBirthControl.hasError('FutureDate')  && !dateOfBirthControl.hasError('InvalidYear') && !dateOfBirthControl.hasError('LaterDate')">Invalid Date of Birth</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="gender">Gender <span class="label-hint">(Required)</span></label>
                                <select [appSelectValidator]="true" #genderControl="ngModel" class="form-control" id="gender" name="gender" [(ngModel)]="recipientIdentification.genderId" [ngClass]="{'is-invalid':(genderControl.touched || isSubmitClicked) && genderControl.errors?.defaultSelected}">
                                    <option [ngValue]="null">Select a Gender</option>
                                    <option *ngFor="let genderType of genderTypes" [ngValue]="genderType.id">{{genderType.name}}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <div>Gender is required</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="eyeColor">Eye Color</label>
                                <input type="text" [(ngModel)]="recipient.eyeColor" class="form-control" id="eyeColor" name="eyeColor" placeholder="Eye Color">
                            </div>
                        </div>  
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label for="hairColor">Hair Color</label>
                                <input type="text" [(ngModel)]="recipient.hairColor" class="form-control" id="hairColor" name="hairColor" placeholder="Hair Color">
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="weight">Weight (kg)</label>
                                <input type="text" [(ngModel)]="recipient.weight" class="form-control" id="weight" name="weight" placeholder="Weight">
                            </div>
                            <div class="col-12 col-sm-4">
                                <label for="height">Height (cm)</label>
                                <input type="text" [(ngModel)]="recipient.height" class="form-control" id="height" name="height" placeholder="height">
                            </div>
                        </div>
                    </div>
                </ng-container>  
            </div>
        </div>
        <div class="card mt-4" *ngIf="recipient.recipientTypeId != RecipientTypes.RegisteredOwner">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-sm-6 mb-0">
                        Recipient{{availableTSA?"/Accused":""}} Contact Information
                    </div>
                    <div class="col-12 col-sm-6 mb-0" *ngIf="!isRecipientReadOnly()">
                        <div class="form-check float-right">
                            <input class="form-check-input mb-0" type="checkbox" value="" id="noFixedAddress" name="noFixedAddress"
                            [(ngModel)]="recipientContact.isNoFixedAddress" (ngModelChange)="onNoFixedAddressChecked($event)">
                            <label class="form-check-label mb-0" for="noFixedAddress">
                                No Fixed Address
                            </label>
                        </div>
                    </div>
                </div>    
            </div>
            <div class="card-body">            
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="address1">Address 1 <span *ngIf="!recipientContact.isNoFixedAddress" class="label-hint">(Required)</span></label>
                            <input [required]="!recipientContact.isNoFixedAddress" #address1Control="ngModel" type="text" [(ngModel)]="recipientContact.addressLine1" maxlength="44" 
                            [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            class="form-control" id="address1" name="address1" placeholder="Ex. 123 Street or PO Box, Range Road or Rural Route" 
                            [ngClass]="{'is-invalid':(address1Control.touched || isSubmitClicked) && address1Control.invalid}">
                            <div class="invalid-feedback">
                                <div>Address 1 is required</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <label for="address2">Address 2</label>
                            <input type="text" [(ngModel)]="recipientContact.addressLine2" maxlength="44" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            class="form-control" id="address2" name="address2" placeholder="Ex. Unit Number">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <app-country-province (onChangeEvent)="refreshContactCountryProvince($event)" [countryProvince]="recipientContactCountryProvince"></app-country-province>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <label for="city">City/Town/Village <span *ngIf="!recipientContact.isNoFixedAddress" class="label-hint">(Required)</span></label>
                            <input [required]="!recipientContact.isNoFixedAddress" #cityControl="ngModel" maxlength="30" type="text" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            [(ngModel)]="recipientContact.city" (ngModelChange)="recipientContact.city=this.moddedTitleCasePipe.transform($event)"
                            class="form-control" id="city" name="city" placeholder="City/Town/Village" [ngClass]="{'is-invalid':(cityControl.touched || isSubmitClicked) && cityControl.invalid}">
                            <div class="invalid-feedback">
                                <div>City is required</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <label for="postalCode">Postal Code <span *ngIf="!recipientContact.isNoFixedAddress" class="label-hint">(Required)</span></label>
                            <input [required]="!recipientContact.isNoFixedAddress" #postalCodeControl="ngModel" maxlength="15" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            type="text" [(ngModel)]="recipientContact.postalCode" class="form-control text-uppercase" id="postalCode" name="postalCode" placeholder="{{recipientContactCountryProvince?.countryId == 40? 'A9A 9A9':''}}" [ngClass]="{'is-invalid':(postalCodeControl.touched || isSubmitClicked) && postalCodeControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Postal Code is required</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 d-flex align-items-end">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="addressDifferentFromMOVES" name="addressDifferentFromMOVES" [(ngModel)]="recipientContact.isAddressDifferentFromMOVES" (change)="onAddressDifferentFromMovesChange()">
                                <label class="form-check-label" for="addressDifferentFromMOVES">
                                    Address Different from MOVES
                                </label>
                            </div>
                        </div>
                    </div>                
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <label for="residencePhone">Residence Phone</label>
                            <input type="text" maxlength="15" [(ngModel)]="recipientContact.phoneNumber1" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            class="form-control" id="residencePhone" name="residencePhone" placeholder="Residence Phone">
                        </div>
                        <div class="col-12 col-sm-4">
                            <label for="cellPhone">Cell Phone</label>
                            <input type="text" maxlength="15" [(ngModel)]="recipientContact.phoneNumber3" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            class="form-control" id="cellPhone" name="cellPhone" placeholder="Cell Phone">
                        </div> 
                        <div class="col-12 col-sm-4">
                            <label for="businessPhone">Business Phone</label>
                            <input type="text" maxlength="15" [(ngModel)]="recipientContact.phoneNumber2" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                            class="form-control" id="businessPhone" name="businessPhone" placeholder="Business Phone">
                        </div> 
                    </div> 
                </div>  
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <label for="emailAddress">Email Address</label>
                        <input type="text" maxlength="50" [(ngModel)]="recipientContact.emailAddress" [readonly]="isRecipientReadOnly() && !recipientContact.isAddressDifferentFromMOVES"
                        class="form-control" id="emailAddress" name="emailAddress" placeholder="Email Address">
                    </div>
                </div>            
            </div>
        </div>

        <div class="card mt-4" *ngIf="recipient.recipientTypeId != RecipientTypes.RegisteredOwner && isRecipientReadOnly() && recipientContact.isAddressDifferentFromMOVES">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-sm-6 mb-0">
                        Address returned by MOVES
                    </div>
                </div>    
            </div>
            <div class="card-body">            
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="address1">Address 1</label>
                            <input type="text" [value]="movesRecipientContact.addressLine1" maxlength="44" readonly
                            class="form-control" id="address1" name="address1">
                        </div>
                        <div class="col-12 col-sm-6">
                            <label for="address2">Address 2</label>
                            <input type="text" [value]="movesRecipientContact.addressLine2" maxlength="44" readonly
                            class="form-control" id="address2" name="address2">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <app-country-province [countryProvince]="recipientContactCountryProvinceMoves"></app-country-province>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <label for="city">City/Town/Village</label>
                            <input maxlength="30" type="text" readonly [value]="movesRecipientContact.city" 
                            class="form-control" id="city" name="city">
                        </div>
                        <div class="col-12 col-sm-4">
                            <label for="postalCode">Postal Code</label>
                            <input maxlength="15" readonly type="text" [value]="movesRecipientContact.postalCode" 
                            class="form-control text-uppercase" id="postalCode" name="postalCode">
                        </div>
                    </div>                
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <label for="residencePhone">Residence Phone</label>
                            <input type="text" maxlength="15" [value]="movesRecipientContact.phoneNumber1" readonly
                            class="form-control" id="residencePhone" name="residencePhone">
                        </div>
                        <div class="col-12 col-sm-4">
                            <label for="cellPhone">Cell Phone</label>
                            <input type="text" maxlength="15" [value]="movesRecipientContact.phoneNumber3" readonly
                            class="form-control" id="cellPhone" name="cellPhone">
                        </div> 
                        <div class="col-12 col-sm-4">
                            <label for="businessPhone">Business Phone</label>
                            <input type="text" maxlength="15" [value]="movesRecipientContact.phoneNumber2" readonly
                            class="form-control" id="businessPhone" name="businessPhone">
                        </div> 
                    </div> 
                </div>  
            </div>
        </div>

        <div class="form-group">
            <div class="row pt-3">
                <div class="col-12 col-sm-6">                
                    <a class="btn button-secondary btn-block" [routerLink]="['/']">Back to Dashboard</a>
                </div>
                <div class="col-12 col-sm-6 text-right">                
                    <button class="btn button-primary btn-block" (click)="onSubmitClick(recipientInfoForm.valid, '/contravention/submission/contravention-selection')">Proceed to Contravention Selection</button>   
                </div>
            </div>
            <div class="card info-card row mt-2">
                <div class="col-12">
                    <div>
                        <p>The personal information on this form is collected under the authority of the <span class="italic-text">Traffic Safety Act</span> 
                            and section 33 of the <span class="italic-text">Freedom of Information and Protection of Privacy (FOIP) Act</span> 
                            and will be used for the administration of programs under the <span class="italic-text">Traffic Safety Act.</span> 
                            Inquiries may be directed to SafeRoads Alberta, Alberta Transportation, at telephone number 780-427-7233 (Edmonton and area) 
                            and toll free 310-0000 (in Alberta).
                        </p>
                    </div>          
                </div>
            </div>
        </div>        
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Searching for driver's information in MOVES. Please wait... </p></ngx-spinner>      
</form>

<div class="licence-scanner-modal-overlay">
    <div class="licence-scanner-modal">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-sm-6 mb-0">
                        Scan Licence
                    </div>
                    <div class="col-12 col-sm-6 mb-0">
                        <div class="float-right">
                            <a (click)="showHideLicenceModal(false, true)" class="close-button"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <label>Scan Driver's Licence</label>
                    </div>
                </div>                
                </div>
                <div class="form-group row">
                    <div *ngIf="licenceScanErrorMessage != ''" class="d-flex flex-column flex-md-row pl-4 pb-4">  
                        <span class="error">{{licenceScanErrorMessage}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- MOVES Lookup modal window code is not required but commented it just in case if this feature is required in future. -->
<!-- <div class="moves-modal-overlay">
    <div class="moves-modal">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-sm-6 mb-0">
                        Search MOVES
                    </div>
                    <div class="col-12 col-sm-6 mb-0">
                        <div class="float-right">
                            <a (click)="showHideModal(false)" class="close-button"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <label for="driverLicenseNumberSearch">Driver's Licence Number or M.V.I.D Number</label>
                    </div>
                </div>        
                <div class="form-group row">
                    <div class="col-12 col-sm-6">
                        <input type="text" required [(ngModel)]="operatorSearch.operatorNumber" pattern="^(\d{1,6})-(\d{1,5})$" class="form-control" #driverLicenseNumberSearchControl="ngModel" id="driverLicenseNumberSearch" name="driverLicenseNumberSearch" placeholder="Driver's Licence Number or M.V.I.D Number" [ngClass]="{'is-invalid':(driverLicenseNumberSearchControl.touched && isSearchClicked) && driverLicenseNumberSearchControl.invalid}">
                        <div class="invalid-feedback">
							<div *ngIf="driverLicenseNumberSearchControl.errors?.required">Driver's Licence Number or M.V.I.D Number is required</div>
                            <div *ngIf="driverLicenseNumberSearchControl.errors?.pattern">Invalid Driver's Licence Number or MVID.</div>
                            <div *ngIf="driverLicenseNumberSearchControl.errors?.pattern">Driver’s Licence should be in XXXXXX-XXX format and MVID should be in XXXX-XXXXX.</div>
                        </div>    
                    </div>
                    <div class="col-12 col-sm-4 d-flex align-items-start">        
                        <button class="button-secondary" (click)="onCallSearchMOVES(!driverLicenseNumberSearchControl.invalid)">Search MOVES</button>                    
                    </div>
                </div>
                <div class="form-group row">
                    <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row pl-4 pb-4">  
                        <span class="error">{{errorMessage}}</span>
                    </div>
                </div>

                <div *ngIf="operatorInformation" class="form-group row">
                    <ag-grid-angular 
                        #agGrid
                        style="width: 790px; height: 250px"
                        class="ag-theme-alpine"
                        [rowData]="operatorInformation"
                        [columnDefs]="columnDetails"
                    ></ag-grid-angular>
                </div>

                <div *ngIf="operatorInformation" class="row">
                    <div class="col-12 d-flex justify-content-end align-items-end">  
                        <button class="button-secondary" (click)="onSelectMatch()">Select Match</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
