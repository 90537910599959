import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Agency } from '@apis/shared/models/agency.model';
import { Detachment } from '@apis/shared/models/detachment.model';
import { Officer } from '@apis/shared/models/officer.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as $ from "jquery";
import { KeycloakService } from 'keycloak-angular';
import { OfficerService } from '../../services/officer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IntakeUser } from '../../models/intake-user.model';
import { environment } from '../../../environments/environment';
import { SettingService } from '../../services/setting.service';
import { Setting } from '@apis/shared/models/setting.model';
import { Constants } from '@apis/shared/helpers/constants';
import { ConnectivityService } from '../../services/connectivity.service';
import { Observable, Subscription } from 'rxjs';
import { DarkModeService } from 'angular-dark-mode';
import { IntakeService } from '../../services/intake.service';
import { LocationType } from '@apis/shared/models/types/location-type.model';
import { ModdedTitleCasePipe } from '@apis/shared/pipes/moddedTitleCase.pipe';
import { DateUtil } from '@apis/shared/helpers/date-util';

@Component({
  selector: 'intake-header',
  templateUrl: './intake-header.component.html',
  styleUrls: ['./intake-header.component.scss']
})
export class IntakeHeaderComponent implements OnInit, AfterViewInit {
  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;
  isOnline: boolean = true;
  isOnlineMessage: string = "Online";
  onlineStatusSubscription: Subscription;

  loggedIn: boolean;
  hamburgerMenuOverlay: JQuery<HTMLElement>;
  userName: string;
  bodyElement: JQuery<HTMLElement>;
  profileModalOverlay: JQuery<HTMLElement>;
  officer: Officer = new Officer();
  agencies: Agency[];
  detachments: Detachment[];
  locations: LocationType[];
  courtLocations: LocationType[];
  errorMessage: string = "";
  user: IntakeUser;
  isSubmitClicked: boolean = false;
  isProfileUpdateRequired: boolean = false;
  environmentName: String = "";
  maintenanceMessage: String = "";
  maintenanceMessageHeader: String = "";
  Resource:any = Constants.Resource;
  Permission:any = Constants.Permission;
        
  constructor(private readonly router: Router,
              private keycloakService: KeycloakService,
              private officerService: OfficerService,
              private connectivityService: ConnectivityService,
              private readonly spinner: NgxSpinnerService,
              private readonly localStorageService: LocalStorageService,
              private readonly settingService: SettingService, 
              private darkModeService: DarkModeService,
              private intakeService: IntakeService,
              public moddedTitleCasePipe: ModdedTitleCasePipe
              ) { 
                //this.localStorageService.getLoggedInUserName.subscribe(name => {this.userName = name});
              }
              
           

  ngOnInit(): void {

    this.onlineStatusSubscription = this.connectivityService.isOnlineStatus.subscribe(onlineStatus => {
      this.isOnline = onlineStatus
      if (this.isOnline) {
        this.isOnlineMessage = "Online";
      } else {
        this.isOnlineMessage = "Offline";
      }
    });

    this.environmentName = environment.name;
    this.bodyElement = $(document.body);
    this.profileModalOverlay = $(".profile-modal-overlay");

    this.user = this.localStorageService.getUser();
    this.userName = `${this.user.firstName} ${this.user.lastName}`;

    this.getMaintenanceMessage();
  }

  // If environment name banner is present, add padding and height to prevent overlapping with the header
  ngAfterViewInit() {
    if (this.environmentName != 'Production') {
      document.getElementById("header").style.paddingTop = "40px";
      document.getElementById("header").style.height = "130px";
    }
  }

  ngOnDestroy() {
    this.onlineStatusSubscription.unsubscribe();
  }

  onUserNameClick()
  { 
    this.spinner.show();
    this.errorMessage = "";
    
    if (this.agencies == null || this.detachments == null || this.locations == null || this.courtLocations == null)
    {
      this.agencies = this.localStorageService.getAgencies().filter(x => !x.isDeleted);
      this.detachments = this.localStorageService.getDetachments().filter(x => !x.isDeleted);
      this.locations = this.localStorageService.getLocationTypes();
      
      var courtLocationIds = this.localStorageService.getCourtTypes().filter(x => DateUtil.isActive(x.effectiveDate, x.expiryDate)).map(s => s.courtLocationId);
      this.courtLocations = this.locations.filter(x => courtLocationIds.includes(x.id));
    }
    
    this.showHideModal(true);
    this.user = this.localStorageService.getUser();

    //Get LoggedIn officer's profile
    this.officer.firstName = this.user.firstName;
    this.officer.lastName = this.user.lastName;
    this.officer.regimentalNumber = this.user.badgeNumber;
    this.officer.emailAddress = this.keycloakService.getUsername();

    this.officerService.getOfficerByEmailIdAsync(this.officer.emailAddress)
    .subscribe(officer => {
      if (officer != null)
        this.officer.agencyId = officer.agencyId;
        this.officer.detachmentId = officer.detachmentId;
        this.officer.regimentalNumber = officer.regimentalNumber;
        this.officer.officerId = officer.officerId;
        this.officer.defaultMunicipalityLocationId = officer.defaultMunicipalityLocationId;
        this.officer.defaultCourtLocationId = officer.defaultCourtLocationId;

        // the following makes key cloak the source of truth for the user first and last name
        //
        this.keycloakService.loadUserProfile().then(profile => {

          this.officer.firstName = profile.firstName;
          this.officer.lastName = profile.lastName;

          this.spinner.hide();

        },(error => {
        
          if (error?.status != 404)
            this.errorMessage = "Unable to get user profile. Please try again later"; 
          
          this.officer.agencyId = null;
          this.officer.detachmentId = null;
          this.officer.officerId = 0;
          this.officer.defaultMunicipalityLocationId = null;
          this.officer.defaultCourtLocationId = null;
          this.spinner.hide();
          })
        );
        
      },
      (error => {
        if (error?.status != 404)
          this.errorMessage = "Unable to get user profile. Please try again later"; 
        
        this.officer.agencyId = null;
        this.officer.detachmentId = null;
        this.officer.officerId = 0;
        this.officer.defaultMunicipalityLocationId = null;
        this.officer.defaultCourtLocationId = null;
        this.spinner.hide();
      })
    );
  }

  onLogoutClick()
  {
    this.localStorageService.logout();
    this.keycloakService.logout(`${window.location.origin}/`);    
  }

  //Modal methods
  onProfileCancelClick(): void {
    this.isSubmitClicked = false;
    this.showHideModal();
  }

  onProfileSaveClick(isValid: boolean){
    let profileUpdated: Boolean = false;
    let officerNameChanged: Boolean = false;
    this.isSubmitClicked = true;

    if (isValid)
    {
      let currentUser = this.localStorageService.getUser();
      if (currentUser.firstName.trim() != this.officer.firstName.trim() ||
          currentUser.lastName.trim() != this.officer.lastName.trim() ) {
            officerNameChanged = true;
      }

      this.spinner.show();

      if (this.officer.agencyId == null)
        this.officer.detachmentId = null;

      this.officerService.saveOfficerAsync(this.officer).subscribe(
        (updatedOfficer: Officer) => {
          this.errorMessage = "";
          this.user.badgeNumber = updatedOfficer.regimentalNumber;
          this.user.service = updatedOfficer.agencyId;
          this.user.detachment = updatedOfficer.detachmentId;
          this.user.userId = updatedOfficer.officerId;
          this.user.firstName = updatedOfficer.firstName;
          this.user.lastName = updatedOfficer.lastName;
          this.userName = `${updatedOfficer.firstName} ${updatedOfficer.lastName}`;
          this.user.defaultMunicipalityLocationId = updatedOfficer.defaultMunicipalityLocationId;
          this.user.defaultCourtLocationId = updatedOfficer.defaultCourtLocationId;
          this.localStorageService.setUser(this.user);
          this.isProfileUpdateRequired = false;

          if (officerNameChanged==false) {
            this.spinner.hide();
            this.showHideModal();
        
            //Route to Dashboard page to reload user profile
            this.router.navigateByUrl('/');
          }

          profileUpdated = false;
          this.keycloakService.loadUserProfile().then(profile => {

            // update profile first and last name
            //
            profile.firstName = this.officer.firstName;
            profile.lastName = this.officer.lastName;
            this.officerService.updateKeyCloakUserProfile(profile).subscribe( result => {
              profileUpdated = result;

              this.spinner.hide();
              this.showHideModal();
          
              //Route to Dashboard page to reload user profile
              this.router.navigateByUrl('/');
            }, 
            (error: any) => {
                this.errorMessage = "Unable to update key cloak profile. Please try again later"; 
                this.spinner.hide(); 
            });
            
          }, 
          (error: any) => {
                this.errorMessage = "Unable to update key cloak profile. Please try again later"; 
                this.spinner.hide(); 
          });
        },
        (error: any) => {
          this.errorMessage = "Unable to update key cloak profile. Please try again later"; 
          this.spinner.hide(); 
        }
      );
    }
  }

  onDashboardClick(): void {
    this.router.navigateByUrl('/user-dashboard');
  }

  onReportsClick(): void {
    this.router.navigateByUrl('/reports');
  }

  onHelpAndResourcesClick(): void {
    this.router.navigateByUrl('/help-and-resources');
  }

  onToggle(): void {
    this.darkModeService.toggle();
  }

  private showHideModal(show?: boolean): void {
    if (show) {
      this.bodyElement.addClass("overflow-hidden");
      this.profileModalOverlay.addClass("modal-show");
    } else {
      this.bodyElement.removeClass("overflow-hidden");
      this.profileModalOverlay.removeClass("modal-show");
    }
  }

  private getMaintenanceMessage()
  {
    this.settingService.getSettings()
    .subscribe((settings: Setting[]) => {
      if (settings) {
        this.maintenanceMessage  = settings.find(s => s.settingName == Constants.Settings.INTAKE_MAINTENANCE_MESSAGE)?.settingValue;
        this.maintenanceMessageHeader  = settings.find(s => s.settingName == Constants.Settings.INTAKE_MAINTENANCE_MESSAGE_HEADER)?.settingValue;

        if (this.user && (this.user.detachment == null || this.user.service == null))
        {
          this.officerService.getOfficerByEmailIdAsync(this.keycloakService.getUsername())
          .subscribe(officer => {
            if (officer != null)
              this.user.badgeNumber = officer.regimentalNumber;
              this.user.service = officer.agencyId;
              this.user.detachment = officer.detachmentId;
              this.user.userId = officer.officerId;
              this.user.defaultMunicipalityLocationId = officer.defaultMunicipalityLocationId;
              this.user.defaultCourtLocationId = officer.defaultCourtLocationId
              this.localStorageService.setUser(this.user);
            },
            (error => {
              this.onUserNameClick(); 
              this.isProfileUpdateRequired = true;     
            })
          );
        }
      }
    });
  }
}
