
<div class="submissionMain">    
    <form #PrintConfirmationForm="ngForm">
        <div class="d-flex flex-column">
            <div class="row">
                <div class="col-12 col-sm-10">        
                    <h1>
                        {{screenName}}
                    </h1>
                </div>
                <!-- <div *ngIf="!isProcessing && !stopInformation.isEmergencySubmission" class="col-12 col-sm-2 d-flex align-self-end justify-content-end">
                    <div class="float-end">
                        <div class="print-icon" (click)="downloadNotices()"></div>
                    </div>
                </div> -->
            </div>
            <ng-container *ngIf="!isProcessing">
                <ng-container *ngIf="!stopInformation.isEmergencySubmission">

                    <!-- Road side appeals are not available (at this time) for offline ... keep this code in here for now -->
                    <!-- <ng-container *ngIf="stopInformation.isIRSSelected && !this.isRefusal && (irsContravention?.contraventionTypeId != ContraventionTypes.IRS24 || isIRSReIssued())">
                        <div class="card mt-4">
                            <div class="card-header">
                                <div>Roadside Appeal</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Was the driver presented with Roadside Appeal Information? <span class="label-hint">(Required)</span></label>
                                    </div>
                                </div>
                                <div class="mb-3 row" *ngIf="isReadOnly()">
                                    <div class="col-sm-12">
                                        <label>{{roadsideAppeal.isRecipientPresentedWithInformation?'Yes':'No'}}</label>
                                    </div>
                                </div>
                                <div class="mb-3 row" *ngIf="!isReadOnly()">
                                    <div class="col-sm-12">
                                        <div class="form-check custom-control-inline">
                                            <input required type="radio" class="form-check-input" id="appealInfoYes" name="appealRadio" #appealInfoYesControl="ngModel" [value]="true" [(ngModel)]="roadsideAppeal.isRecipientPresentedWithInformation" (change)="onAppealPresentedChange()" [ngClass]="{'is-invalid': roadsideAppeal.isRecipientPresentedWithInformation == null && (appealInfoYesControl.touched || appealInfoNoControl.touched || isSubmitClicked)}">
                                            <label class="form-check-label" for="appealInfoYes">Yes</label>
                                        </div>
                                        <div class="form-check custom-control-inline">
                                            <input required type="radio" class="form-check-input" id="appealInfoNo" name="appealRadio"  #appealInfoNoControl="ngModel" [value]="false" [(ngModel)]="roadsideAppeal.isRecipientPresentedWithInformation" (change)="onAppealPresentedChange()" [ngClass]="{'is-invalid': roadsideAppeal.isRecipientPresentedWithInformation == null && (appealInfoYesControl.touched || appealInfoNoControl.touched || isSubmitClicked)}">
                                            <label class="form-check-label" for="appealInfoNo">No</label>
                                        </div>
                                        <input required type="radio" style="display:none;" id="appealInfoHidden" name="appealRadio"  #appealInfoHiddenControl="ngModel" [value]="null" [(ngModel)]="roadsideAppeal.isRecipientPresentedWithInformation" [ngClass]="{'is-invalid': roadsideAppeal.isRecipientPresentedWithInformation == null && (appealInfoYesControl.touched || appealInfoNoControl.touched || isSubmitClicked)}">
                                        <div class="invalid-feedback">
                                            <div>Roadside Appeal Information is required</div>
                                        </div>
                                    </div>
                                </div>			
                                <div class="mb-3 row" *ngIf="roadsideAppeal.isRecipientPresentedWithInformation == false">
                                    <div class="col-12">
                                        <label for="appealExplain">If No, Explain?  <span class="label-hint">(Required)</span></label>
                                        <input [readonly]="isReadOnly()" required #appealExplainControl="ngModel" maxlength="200" type="text" class="form-control" id="appealExplain" name="appealExplain" placeholder="Explain Why Roadside Appeal Options Were Not Provided" [(ngModel)]="roadsideAppeal.appealOptionsNotPresentedReason" [ngClass]="{'is-invalid':(appealExplainControl.touched || isSubmitClicked) && appealExplainControl.invalid}">
                                        <div class="invalid-feedback">
                                            <div>Explanation is required</div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="mb-3 row" *ngIf="roadsideAppeal.isRecipientPresentedWithInformation == true">
                                    <div class="col-12">										
                                        <label class="w-100">Did the driver request a Roadside Appeal? <span class="label-hint">(Required)</span></label>
                                        <div *ngIf="isReadOnly()">
                                            <label>{{roadsideAppeal.hasDriverRequestedRoadsideAppeal?'Yes':'No'}}</label>
                                        </div>
                                        <div *ngIf="!isReadOnly()">
                                            <div class="form-check custom-control-inline">
                                                <input required type="radio" class="form-check-input" id="appealRequestedYes" name="appealRequestedRadio" #appealRequestedYesControl="ngModel" [value]="true" [(ngModel)]="roadsideAppeal.hasDriverRequestedRoadsideAppeal" (change)="onAppealRequestChange()" [ngClass]="{'is-invalid': roadsideAppeal.hasDriverRequestedRoadsideAppeal == null && (appealRequestedYesControl.touched || appealRequestedNoControl.touched || isSubmitClicked)}">
                                                <label class="form-check-label" for="appealRequestedYes">Yes</label>
                                            </div>
                                            <div class="form-check custom-control-inline">
                                                <input required type="radio" class="form-check-input" id="appealRequestedNo" name="appealRequestedRadio" #appealRequestedNoControl="ngModel" [value]="false" [(ngModel)]="roadsideAppeal.hasDriverRequestedRoadsideAppeal" (change)="onAppealRequestChange()" [ngClass]="{'is-invalid': roadsideAppeal.hasDriverRequestedRoadsideAppeal == null && (appealRequestedYesControl.touched || appealRequestedNoControl.touched || isSubmitClicked)}">
                                                <label class="form-check-label" for="appealRequestedNo">No</label>
                                            </div>
                                            <input required type="radio" style="display:none;" id="appealRequestedHidden" name="appealRequestedRadio" #appealRequestedHiddenControl="ngModel" [value]="null" [(ngModel)]="roadsideAppeal.hasDriverRequestedRoadsideAppeal"  [ngClass]="{'is-invalid': roadsideAppeal.hasDriverRequestedRoadsideAppeal == null && (appealRequestedYesControl.touched || appealRequestedNoControl.touched || isSubmitClicked)}">
                                            <div class="invalid-feedback">
                                                <div>Roadside Appeal Request Information is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.hasDriverRequestedRoadsideAppeal == true">
                            <div class="card-header">
                                <div>Roadside Appeal Results</div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 mb-2" *ngFor="let roadsideAppealOutcomeType of roadsideAppealOutcomeTypes">	
                                        <div *ngIf="!isReadOnly()" class="form-check custom-control-inline">
                                            <input required type="radio" class="form-check-input" id="appealResult{{roadsideAppealOutcomeType.id}}" name="appealResultRadio" value="{{roadsideAppealOutcomeType.id}}" [(ngModel)]="roadsideAppeal.roadsideAppealOutcomeTypeId">
                                            <label class="form-check-label" for="appealResult{{roadsideAppealOutcomeType.id}}"></label>
                                            <span class="label-message">{{roadsideAppealOutcomeType.description.substring(0, roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name))}} <span class="bold-text">{{roadsideAppealOutcomeType.name}}</span> {{roadsideAppealOutcomeType.description.substring(roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name) + roadsideAppealOutcomeType.name.length)}}</span>
                                        </div>
                                        <div *ngIf="isReadOnly() && roadsideAppeal.roadsideAppealOutcomeTypeId == roadsideAppealOutcomeType.id">
                                            <span class="label-message">{{roadsideAppealOutcomeType.description.substring(0, roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name))}} <span class="bold-text">{{roadsideAppealOutcomeType.name}}</span> {{roadsideAppealOutcomeType.description.substring(roadsideAppealOutcomeType.description.indexOf(roadsideAppealOutcomeType.name) + roadsideAppealOutcomeType.name.length)}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="error" *ngIf="!roadsideAppeal.roadsideAppealOutcomeTypeId && isSubmitClicked">
                                    <div>Roadside Appeal Result Information is required</div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.EqualToOrExceeded || (roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && isIRSReIssued()) || roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.RefusedOrFailedToComply || roadsideAppeal.isRecipientPresentedWithInformation == false || roadsideAppeal.hasDriverRequestedRoadsideAppeal == false">
                            <div class="card-header">
                                <div>Add Details</div>
                            </div>
                            <div class="card-body">
                                <div class="mb-3 row">
                                    <div class="col-12">										
                                        <span class="label-message">You can <span class="bold-text">add details</span> to your notice(s) now, or later on by accessing your <a [routerLink]="['/']">dashboard</a>.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on this contravention or a previously submitted contravention, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support@gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && !isIRSReIssued()">
                            <div class="card-header">
                                <div>Re-issue Contravention</div>
                            </div>
                            <div class="card-body">
                                <div class="mb-3 row">
                                    <div class="col-12">										
                                        <span class="label-message">The Roadside Appeal test result showed less than the first test result.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">										
                                        <span class="label-message">You must <span class="bold-text">update</span> the IRS Type and <span class="bold-text">re-issue</span> this Notice.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration">
                            <div *ngIf="!isIRSCancelled()">
                                <div class="card-header">
                                    <div>Cancel Contravention</div>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3 row">
                                        <div class="col-12">										
                                            <span class="label-message">The Roadside Appeal test result showed less than any prohibited drug or alcohol concentration.</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">										
                                            <span class="label-message">You must <span class="bold-text">cancel</span> this Notice of Administrative Penalty.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isIRSCancelled()">
                                <div class="card-header">
                                    <div>Contravention Cancelled</div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">										
                                            <span class="label-message">This Notice of Administrative Penalty has been successfully <span class="bold-text">cancelled</span>.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" *ngIf="roadsideAppeal.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.Pending">
                            <div class="card-header">
                                <div>Results Pending</div>
                            </div>
                            <div class="card-body">
                                <div class="mb-3 row">
                                    <div class="col-12">										
                                        <span class="label-message">When you receive the test results, you must add that information to the contravention. Access notices from your <a [routerLink]="['/']">dashboard</a> to make any updates.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">										
                                        <span class="label-message">If a correction or assistance is needed on this notice or a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support@gov.ab.ca</a>.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </ng-container> -->

                    <ng-container *ngIf="!stopInformation.isIRSSelected || this.isRefusal || (irsContravention?.contraventionTypeId == ContraventionTypes.IRS24 && !isIRSReIssued())">
                        
                        <!-- Only single seizure -->
                        <ng-container *ngIf="seizuresCount == 1 && contraventionsCount == 0"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Your Seizure Notice has been printed.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>If a correction or assistance is needed on this notice or a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>Return to your <a [routerLink]="['/']">dashboard</a>.</h6>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 

                        <!-- Only single contravention -->
                        <ng-container *ngIf="seizuresCount == 0 && contraventionsCount == 1"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Your Notice of Administrative Penalty has been printed.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>You can add details to this notice now, or later on by accessing your <a [routerLink]="['/']">dashboard</a>.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>If a correction or assistance is needed on this notice or a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</h6>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 

                        <!-- Seizure and Other contraventions -->
                        <ng-container *ngIf="seizuresCount > 0 && contraventionsCount > 0"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Your Seizure Notice and Notice(s) of Administrative Penalty have been printed.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>If a correction or assistance is needed on any of these notices or on a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>Return to your <a [routerLink]="['/']">dashboard</a>.</h6>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 

                        <!-- Multiple contraventions -->
                        <ng-container *ngIf="seizuresCount == 0 && contraventionsCount > 1"> 
                            <div class="card border-0">
                                <div class="row">
                                    <div class="col-12">										
                                        <h6>Your Notices of Administrative Penalty have been printed.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>You can add details to these notice now, or later on by accessing your <a [routerLink]="['/']">dashboard</a>.</h6>
                                    </div>
                                    <div class="col-12">										
                                        <h6>If a correction or assistance is needed on any of these notices or on a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</h6>
                                    </div>
                                </div>
                            </div>
                        </ng-container> 
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="stopInformation.isEmergencySubmission">
                    <div class="card border-0">
                        <div class="row">
                            <div class="col-12">										
                                <h6>Your IRS 24 emergency suspension has been submitted to APIS.</h6>
                            </div>
                            <div class="col-12">										
                                <h4>Next steps:</h4>
                            </div>
                            <div class="col-12">										
                                <h6 class="mt-0 ms-2">1. You must scan and upload the original paper copy of the Notice of Administrative Penalty and, if the vehicle was seized, the Seizure Notice. You can do this now, or later on by accessing your <a [routerLink]="['/']">dashboard</a>.</h6>
                            </div>
                            <div class="col-12">										
                                <h6 class="mt-0 ms-2">2. Depending on the level of impairment of the driver at the time of the stop, you may need to issue another IRS Notice of Administrative Penalty and Vehicle Seizure Notice to the driver for the correct IRS type. If that is the case, click on Return to Dashboard.</h6>
                            </div>
                            <div class="col-12">										
                                <h6>If a correction or assistance is needed on any of these notices or on a previously submitted notice, please contact <a href="mailto:apis.support@gov.ab.ca">apis.support&#64;gov.ab.ca</a>.</h6>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <div class="card border-0"></div>
            
            <div class="mb-3 mt-4">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <a class="btn button-primary btn-block" (click)="onReturnToDashboard()">Return to Dashboard</a>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <a class="btn button-secondary btn-block" (click)="PrintSubmission()">Reprint</a>
                    </div>
                    <!-- <div class="col-12 col-sm-6 text-end" *ngIf="!isProcessing">                
                        <button *ngIf="isAddDetailsButtonRequired()" class="btn button-primary btn-block" (click)="onSubmitClick(PrintConfirmationForm.valid)">Add Details to Notice(s)</button>    
                        <button *ngIf="roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LessThanFirstTest && !isIRSReIssued()" class="btn button-primary btn-block" (click)="showReissueContraventionModal(PrintConfirmationForm.valid)">Re-issue Notice</button>
                        <button *ngIf="roadsideAppeal?.roadsideAppealOutcomeTypeId == RoadsideAppealOutcomeTypes.LestThanAnyConcentration && !isIRSCancelled()" class="btn button-primary btn-block" (click)="showCancelContraventionModal(PrintConfirmationForm.valid)">Cancel Notice</button>
                    </div> -->
                </div>
            </div>        
        </div>
    </form>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>

<div *ngIf="isMobilePrint" class="submission-print" style="margin-left: 20px; float: none !important;">
    <app-offline-roadside-appeal-print [pageBreakRequired]="true" [isMobile]="true"></app-offline-roadside-appeal-print>
    <div *ngFor="let contravention of stopInformation.contraventions;" style="float: none !important;">
        <div *ngIf="isNapPrintingRequired(contravention)">
            <app-offline-nap-mobile-print [pageBreakRequired]="isLastSeizureNap(contravention.contraventionNumber)" [contravention]="contravention"></app-offline-nap-mobile-print>
            <div *ngIf="contraventionHasSeizure(contravention)">
                <app-offline-seizure-mobile-print [pageBreakRequired]="true" [printType]="policeCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-mobile-print>
                <app-offline-seizure-mobile-print [pageBreakRequired]="true" [printType]="driverCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-mobile-print>
                <app-offline-seizure-mobile-print [pageBreakRequired]="true" [printType]="ownerCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-mobile-print>
                <app-offline-seizure-mobile-print [pageBreakRequired]="isLastNap(contravention.contraventionNumber)" [printType]="towlotCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-mobile-print>
            </div>
        </div>
    </div>
    <div *ngFor="let seizure of stopInformation.vehicleSeizures;">
        <app-offline-seizure-mobile-print [pageBreakRequired]="true" [printType]="policeCopy" [vehicleSeizure]="seizure"></app-offline-seizure-mobile-print>
        <app-offline-seizure-mobile-print [pageBreakRequired]="true" [printType]="driverCopy" [vehicleSeizure]="seizure"></app-offline-seizure-mobile-print>
        <app-offline-seizure-mobile-print [pageBreakRequired]="true" [printType]="ownerCopy" [vehicleSeizure]="seizure"></app-offline-seizure-mobile-print>
        <app-offline-seizure-mobile-print [pageBreakRequired]="isLastSeizure(seizure.seizureNumber)" [printType]="towlotCopy" [vehicleSeizure]="seizure"></app-offline-seizure-mobile-print>
    </div>
</div>
<div *ngIf="!isMobilePrint" class="submission-print" style="margin-left: 20px; float: none !important;">
    <app-offline-roadside-appeal-print [pageBreakRequired]="true" [isMobile]="false"></app-offline-roadside-appeal-print>
    <div *ngFor="let contravention of stopInformation.contraventions;" style="float: none !important;">
        <div *ngIf="isNapPrintingRequired(contravention.contraventionNumber)">
            <app-offline-nap-print [pageBreakRequired]="isLastSeizureNap(contravention.contraventionNumber)" [contravention]="contravention"></app-offline-nap-print>
            <div *ngIf="contraventionHasSeizure(contravention)">
                <app-offline-seizure-print [pageBreakRequired]="true" [printType]="policeCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-print>
                <app-offline-seizure-print [pageBreakRequired]="true" [printType]="driverCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-print>
                <app-offline-seizure-print [pageBreakRequired]="true" [printType]="ownerCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-print>
                <app-offline-seizure-print [pageBreakRequired]="isLastNap(contravention.contraventionNumber)" [printType]="towlotCopy" [vehicleSeizure]="contravention.vehicleSeizure"></app-offline-seizure-print>
            </div>
        </div>
    </div>
    <div *ngFor="let seizure of stopInformation.vehicleSeizures;">
        <app-offline-seizure-print [pageBreakRequired]="true" [printType]="policeCopy" [vehicleSeizure]="seizure"></app-offline-seizure-print>
        <app-offline-seizure-print [pageBreakRequired]="true" [printType]="driverCopy" [vehicleSeizure]="seizure"></app-offline-seizure-print>
        <app-offline-seizure-print [pageBreakRequired]="true" [printType]="ownerCopy" [vehicleSeizure]="seizure"></app-offline-seizure-print>
        <app-offline-seizure-print [pageBreakRequired]="isLastSeizure(seizure.seizureNumber)" [printType]="towlotCopy" [vehicleSeizure]="seizure"></app-offline-seizure-print>
    </div>
</div>
