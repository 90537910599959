import { Component, EventEmitter, Input, OnChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, NgForm, ValidatorFn } from '@angular/forms';
import { ContraventionTypes, OffenceTypes, RecipientTypes } from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { Fine } from '@apis/shared/models/fine.model';
import { OperatorInformation } from '@apis/shared/models/operator-information.model';
import { SpeedingOffence } from '@apis/shared/models/speeding-offence.model';
import { SpeedingTicket } from '@apis/shared/models/speeding-ticket.model';
import { ContraventionDetailType } from '@apis/shared/models/types/contravention-detail-type.model';
import { ContraventionType } from '@apis/shared/models/types/contravention-type.model';
import { OffenceType } from '@apis/shared/models/types/offence-type.model';
import { TicketMethodType } from '@apis/shared/models/types/ticket-method-type.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { environment } from 'apps/intake/src/environments/environment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IntakeUser } from '../../models/intake-user.model';
import { TicketService } from '../../services/ticket.service';

@Component({
  selector: 'app-contravention-charge-selection',
  templateUrl: './contravention-charge-selection.component.html',
  styleUrls: ['./contravention-charge-selection.component.scss'],
  outputs: ['onCancelEvent'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ContraventionChargeSelectionComponent implements OnChanges {
  @Input() contravention: Contravention;
  @Input() operatorInformation: OperatorInformation;
  @Input() indexId: number;
  @Input() isSubmitClicked: boolean;
  @Input() recipientTypeId: number;
  @Input() isOutOfProvinceDL: boolean;
  onCancelEvent = new EventEmitter<Contravention>();

  @ViewChild('contraventionDetailsMultiControl', {static: false}) contraventionDetailsMultiControlForm: UntypedFormControl;
  contraventionTypes: any;
  primaryContraventionTypes: any;
  secondaryContraventionTypes: any;
  tertiaryContraventionTypes: any;
  contraventionDetailTypes: ContraventionDetailType[];
  selectedContraventionType: ContraventionType = null;
  selectedSecondaryContraventionType: ContraventionType = null;
  selectedTertiaryContraventionType: ContraventionType = null;
  finallySelectedContraventionType: ContraventionType = null;
  sectionControl = new UntypedFormControl();
  secondarySectionControl = new UntypedFormControl();
  tertiarySectionControl = new UntypedFormControl();
  filteredSections: Observable<string[]>;
  secondaryFilteredSections: Observable<string[]>;
  tertiaryFilteredSections: Observable<string[]>;
  speedingOffences: SpeedingOffence[];
  fine: Fine;
  contraventionDetailsText: string;
  selectedContraventionDetails: number[];
  contraventionDetailsMultiControl: UntypedFormControl;
  user: IntakeUser;
  OffenceTypes = OffenceTypes;
  offenceTypes: OffenceType[];
  availableTSA: boolean = false;
  isFineAmountEditable: boolean = false;
  TypeTable = TypeTable;
  finalFineAmountText: string = "";
  ticketMethodTypes: TicketMethodType[];
  
  constructor(private localStorageService: LocalStorageService,
              private ticketService: TicketService) { 
    //Check if TSA feature is allowed or not
    this.availableTSA = environment.availableTSA && this.ticketService.hasTrafficTicketPermissions();

    //Get Types
    this.ticketMethodTypes = this.localStorageService.getTicketMethodTypes().filter(x => x.code != 'M'); //Camera/Photo Radar is not for Intake submissions
    this.contraventionTypes = this.localStorageService.getContraventionTypes().filter(x => this.availableTSA || x.isIRS || x.isSDP);
    this.primaryContraventionTypes = this.contraventionTypes.filter(x => x.isPrimary);
    this.sectionControl.setValidators(this.validateSections(this.primaryContraventionTypes));
    this.secondarySectionControl.setValidators(this.validateSections(this.secondaryContraventionTypes));
    this.tertiarySectionControl.setValidators(this.validateSections(this.tertiaryContraventionTypes));
  }

  ngOnChanges(): void {    
    this.onLoadProcess();
  }

  onLoadProcess() {
    //Get Types
    this.contraventionTypes = this.localStorageService.getContraventionTypes().filter(x => this.availableTSA || x.isIRS || x.isSDP);
    this.speedingOffences = this.localStorageService.getSpeedingOffences();
    this.offenceTypes = this.localStorageService.getOffenceTypes();

    //Get user profile
    this.user = this.localStorageService.getUser();
    
    //Apply IRS look back restrictions if recipient type is driver
    if (this.recipientTypeId == RecipientTypes.Driver)
      this.applyIRSLookBackRestrictions();
    
    //Apply revipient type charge restrictions
    this.applyRecipientTypeChargeRestrictions();  

    this.contraventionDetailTypes = this.localStorageService.getContraventionDetailTypes();
    this.filteredSections = this.sectionControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSection(value))
    );

    this.secondaryFilteredSections = this.secondarySectionControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSecondarySection(value))
    );

    this.tertiaryFilteredSections = this.tertiarySectionControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTertiarySection(value))
    );

    // Initialize Fine object
    if (this.contravention.fine == null)
        this.contravention.fine = new Fine();
    
    this.fine = this.contravention.fine;    

    //Get contravention type
    if (this.contravention.contraventionTypeId > 0)
    {
      this.selectedContraventionType = this.contraventionTypes.find(x => x.id == this.contravention.contraventionTypeId);
    }

    //Get secondary contravention type
    if (this.contravention.secondaryContraventionTypeId > 0)
    {
      var secondaryContraventionTypeIds = this.localStorageService.getContraventionTypeMappings().filter(x => x.parentContraventionTypeId == this.contravention.contraventionTypeId).map(s => s.childContraventionTypeId);
      if (secondaryContraventionTypeIds?.length > 0)
      {
        this.secondaryContraventionTypes = this.contraventionTypes.filter(x => secondaryContraventionTypeIds.includes(x.id));
        this.secondarySectionControl.setValidators(this.validateSections(this.secondaryContraventionTypes));
      }
      else {
        this.secondaryContraventionTypes = null;  
      }
     
      this.selectedSecondaryContraventionType = this.contraventionTypes.find(x => x.id == this.contravention.secondaryContraventionTypeId);
    }

    //Get tertiary contravention type
    if (this.contravention.tertiaryContraventionTypeId > 0)
    {
      var tertiaryContraventionTypeIds = this.localStorageService.getContraventionTypeMappings().filter(x => x.parentContraventionTypeId == this.contravention.secondaryContraventionTypeId).map(s => s.childContraventionTypeId);
      if (tertiaryContraventionTypeIds?.length > 0)
      {
        this.tertiaryContraventionTypes = this.contraventionTypes.filter(x => tertiaryContraventionTypeIds.includes(x.id));
        this.tertiarySectionControl.setValidators(this.validateSections(this.tertiaryContraventionTypes));
      }
      else {
        this.tertiaryContraventionTypes = null;  
      }
      
      this.selectedTertiaryContraventionType = this.contraventionTypes.find(x => x.id == this.contravention.tertiaryContraventionTypeId);
    }

    //Initialize selected contravention details
    if (this.contravention.contraventionTypeId == ContraventionTypes.IRSFail1st || this.contravention.contraventionTypeId == ContraventionTypes.IRSFail2nd || this.contravention.contraventionTypeId == ContraventionTypes.IRSFail3rd)
    {
      this.selectedContraventionDetails = this.contravention.contraventionDetails.split(',').map(x=> +x);
    }

    //Get final contravention type
    this.finallySelectedContraventionType = (this.selectedTertiaryContraventionType??this.selectedSecondaryContraventionType)??this.selectedContraventionType;
    this.setContraventionDetailText();

    if (this.finallySelectedContraventionType?.isFineAmountEditable)
      this.setFinalAmountText();
  }

  onContraventionDetailsChange(selectedContraventionDetailsValue: number[])
  {
    this.contravention.contraventionDetails = selectedContraventionDetailsValue.toString();
    this.setContraventionDetailText()
  }

  filterContraventionDetailTypes(contraventionTypeId)
  {
    return this.contraventionDetailTypes.filter(x => x.contraventionTypeId == contraventionTypeId);
  }

  onSectionTypeChange(contraventionType: ContraventionType, sectionLevelId: number)
  {
    if (contraventionType == null) {
      this.contravention.suspensionDuration = 0;
      this.contravention.suspensionDurationType = "";
      this.contravention.isLicenceSeized = null;
      this.contravention.isLicenceDestroyed = null;
      this.contravention.additionalSuspension = null;
      this.contravention.remedialEducation = null;

      this.fine.fineAmount = 0;
      this.contravention.demerits = 0;
      this.contravention.tertiaryContraventionTypeId = null;
      this.finallySelectedContraventionType = null;

      switch (sectionLevelId)
      {
        case 1: //Primary Level
          this.contravention.contraventionTypeId = null;
          this.contravention.secondaryContraventionTypeId = null;
          this.secondaryContraventionTypes = null;
          this.tertiaryContraventionTypes = null;
          break;
        case 2: //Secondary Level
          this.contravention.secondaryContraventionTypeId = null;
          this.tertiaryContraventionTypes = null;
          break;
      }
    }
    else
    {
      this.contravention.suspensionDuration = contraventionType.licenceSuspensionDuration;
      this.contravention.suspensionDurationType = contraventionType.licenceSuspensionDurationType;
      this.contravention.isLicenceSeized = contraventionType.isLicenseSeized;
      this.contravention.isLicenceDestroyed = contraventionType.isLicenseDestroyed;
      this.contravention.additionalSuspension = contraventionType.additionalSuspension;
      this.contravention.remedialEducation = contraventionType.remedialEducation;

      this.fine.fineAmount = contraventionType.fineAmount;
      this.contravention.demerits = this.recipientTypeId == RecipientTypes.RegisteredOwner? 0 : contraventionType.demerits; // Demerits are not applicable to RO
      
      switch (sectionLevelId)
      {
        case 1: //Primary Level
          this.contravention.contraventionTypeId = contraventionType.id;
          this.contravention.secondaryContraventionTypeId = null;
          this.contravention.tertiaryContraventionTypeId = null;
          this.tertiaryContraventionTypes = null;
          //check if any secondary charge is required
          var secondaryContraventionTypeIds = this.localStorageService.getContraventionTypeMappings().filter(x => x.parentContraventionTypeId == contraventionType.id).map(s => s.childContraventionTypeId);
          if (secondaryContraventionTypeIds?.length > 0)
          {
            this.secondaryContraventionTypes = this.contraventionTypes.filter(x => secondaryContraventionTypeIds.includes(x.id));
            this.secondarySectionControl.setValidators(this.validateSections(this.secondaryContraventionTypes));
            this.selectedSecondaryContraventionType = null;
          }
          else {
            this.secondaryContraventionTypes = null;  
            this.finallySelectedContraventionType = contraventionType;
          }
          break;
        case 2: //Secondary Level
          this.contravention.secondaryContraventionTypeId = contraventionType.id;
          this.contravention.tertiaryContraventionTypeId = null;
          //check if any tertiary charge is required
          var tertiaryContraventionTypeIds = this.localStorageService.getContraventionTypeMappings().filter(x => x.parentContraventionTypeId == contraventionType.id).map(s => s.childContraventionTypeId);
          if (tertiaryContraventionTypeIds?.length > 0)
          {
            this.tertiaryContraventionTypes = this.contraventionTypes.filter(x => tertiaryContraventionTypeIds.includes(x.id));
            this.tertiarySectionControl.setValidators(this.validateSections(this.tertiaryContraventionTypes));
            this.selectedTertiaryContraventionType = null;
          }
          else {
            this.tertiaryContraventionTypes = null;  
            this.finallySelectedContraventionType = contraventionType;
          }
          break;
        case 3: //Tertiary Level
          this.contravention.tertiaryContraventionTypeId = contraventionType.id;
          this.finallySelectedContraventionType = contraventionType;
          break;
      }

      if (this.finallySelectedContraventionType?.isSpeedingOffence)
      {
        this.contravention.speedingTicket = new SpeedingTicket();
        this.contravention.speedingTicket.issueMethod = "APIS";
        if (this.finallySelectedContraventionType?.isFixedSpeedLimitCharge)
          this.contravention.speedingTicket.speedLimit = 30;
      }
      else
        this.contravention.speedingTicket = null;

      // If it is non TSA SDP then seizure can't be skipped regardless if TSA feature is available or not to issue violation ticket only  
      if (this.finallySelectedContraventionType?.isNonTSASDP && this.contravention.isNoVehicleSeizureMade)
      {
        this.contravention.isNoVehicleSeizureMade = false;
        this.contravention.noVehicleSeizureDetails = null;
      }

      //TSA specific tasks
      if (this.availableTSA && !this.finallySelectedContraventionType?.isIRS && !this.finallySelectedContraventionType?.isSDP)
      {
        //Determine if the offence type should be editable or not and set the contravention's offence type
        var offenceTypeId = this.finallySelectedContraventionType?.offenceTypeId == 0? OffenceTypes.Part2SummonsMandatoryCourt: this.finallySelectedContraventionType?.offenceTypeId;

        //Override the offence type for out of province DLs
        if (offenceTypeId == OffenceTypes.Part3OffenceNotice && this.isOutOfProvinceDL)
          offenceTypeId = OffenceTypes.Part2SummonsVoluntaryPaymentOption;

        this.contravention.offenceTypeId = offenceTypeId;
      }
    }
    
    this.resetContraventionDetails();
  }

  resetContraventionDetails()
  {
    this.contravention.contraventionDetails = '0';
    if (this.contraventionDetailsMultiControlForm) {
      this.contraventionDetailsMultiControlForm.reset();
    }
    this.setContraventionDetailText();
  }

  displaySection(contraventionType: ContraventionType){
    return contraventionType? contraventionType.formattedName : undefined;
  }

  calculateSpeedingFine(setOffenceType: boolean=true)
  {
    if (!isNaN(+this.contravention.speedingTicket.ticketedSpeed) && !isNaN(+this.contravention.speedingTicket.speedLimit) && +this.contravention.speedingTicket.ticketedSpeed > +this.contravention.speedingTicket.speedLimit)
    {
      var kilometersOverSpeeding = this.contravention.speedingTicket.ticketedSpeed - this.contravention.speedingTicket.speedLimit;
      var speedingOffenceType =  this.speedingOffences.find(x=> kilometersOverSpeeding >= x.speedOverFrom && kilometersOverSpeeding <= x.speedOverTo && x.contraventionTypeId == (this.contravention.tertiaryContraventionTypeId??(this.contravention.secondaryContraventionTypeId??this.contravention.contraventionTypeId)));

      this.fine.fineAmount = speedingOffenceType.fineAmount;
      this.contravention.demerits = this.recipientTypeId == RecipientTypes.RegisteredOwner? 0 : speedingOffenceType.demerits; // Demerits are not applicable to RO
      this.contravention.speedingTicket.isPart3Offence = speedingOffenceType.isPart3Offence;
      if (setOffenceType)
        this.contravention.offenceTypeId = speedingOffenceType.isPart3Offence ? ( this.isOutOfProvinceDL ? OffenceTypes.Part2SummonsVoluntaryPaymentOption : OffenceTypes.Part3OffenceNotice) : OffenceTypes.Part2SummonsMandatoryCourt;
    }
    else
    {
      this.fine.fineAmount = 0;
      this.contravention.demerits = 0;
    }
  }

  autofillTicketedSpeed()
  {
    this.contravention.speedingTicket.ticketedSpeed = this.contravention.speedingTicket.recipientVehicleSpeed;
    this.calculateSpeedingFine();
  }

  calculateOffenceType()
  {
    this.setFinalAmountText();
    switch (true) {
      case (this.contravention.fine.fineAmount > 0 && this.contravention.fine.fineAmount <= 1000):
        this.contravention.offenceTypeId = this.isOutOfProvinceDL? OffenceTypes.Part2SummonsVoluntaryPaymentOption : OffenceTypes.Part3OffenceNotice;
        break;
      case (this.contravention.fine.fineAmount > 1000):
        this.contravention.offenceTypeId = OffenceTypes.Part2SummonsVoluntaryPaymentOption;
        break;
      default:
        this.contravention.offenceTypeId = OffenceTypes.Part2SummonsMandatoryCourt;
        break;
    }
  }

  setFinalAmountText()
  {
    if (this.contravention.fine.fineAmount && this.contravention.fine.fineAmount > 0 && this.contravention.fine.fineAmount <= 25000)
    {
      var finalAmount = this.contravention.fine.fineAmount + Math.floor((this.contravention.fine.fineAmount * this.finallySelectedContraventionType.victimFineSurchargePercentage) / 100);
      this.finalFineAmountText = `Fine Amount including ${this.finallySelectedContraventionType.victimFineSurchargePercentage}% VFS is ${finalAmount}`;
    }
    else
      this.finalFineAmountText = "";
  }

  onOffenceTypeChange()
  {
    if (this.contravention.offenceTypeId == OffenceTypes.Part2SummonsMandatoryCourt)
      this.contravention.fine.fineAmount = 0;
    else
    {
      if (this.finallySelectedContraventionType.isSpeedingOffence)
        this.calculateSpeedingFine(false);
      else
        this.contravention.fine.fineAmount = this.finallySelectedContraventionType.fineAmount
    }  
  }

  validateSections(sectionNames: ContraventionType[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = sectionNames?.findIndex(x => {
        return  x.formattedChargeCode == control.value?.formattedChargeCode; //(new RegExp('\^' + x.name + '\$')).test(control.value?.name);
      });
      return index < 0 ? { 'forbiddenSections': { value: control.value } } : null;
    };
  }

  onCancelClick()
  {
    this.onCancelEvent.emit(this.contravention);
  }

  applyIRSLookBackRestrictions()
  {
    if (this.operatorInformation)
    {
      //IRS Zero Commercial
      switch (this.operatorInformation.irsCommercial.toString())
      {
        case "0":
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial2nd).isDisabled = true; 
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial3rd).isDisabled = true; 
          break;
        case "1":
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial1st).isDisabled = true; 
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial3rd).isDisabled = true; 
          break;
        default:  
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial1st).isDisabled = true; 
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroCommercial2nd).isDisabled = true; 
          break;
      }

      //IRS Warn
      switch (this.operatorInformation.irsWarn.toString())
      {
        case "0":
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn2nd).isDisabled = true; 
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn3rd).isDisabled = true; 
          break;
        case "1":
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn1st).isDisabled = true; 
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn3rd).isDisabled = true; 
          break;
        default:  
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn1st).isDisabled = true; 
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSWarn2nd).isDisabled = true; 
          break;
      }

      //IRS Fail
      switch (this.operatorInformation.irsFail.toString())
      {
        case "0":
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSFail2nd).isDisabled = true;
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSFail3rd).isDisabled = true;
          break;
        case "1":
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSFail1st).isDisabled = true;
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSFail3rd).isDisabled = true;
          break;
        default:  
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSFail1st).isDisabled = true;
          this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSFail2nd).isDisabled = true;
          break;
      }
    }

    //IRS Zero Novice
    this.primaryContraventionTypes.find(x=> x.id == ContraventionTypes.IRSZeroNovice).isDisabled = this.contravention?.recipient?.recipientIdentification?.isGDLDriver == false; //IRS Zero Novice
  }

  applyRecipientTypeChargeRestrictions() {
    this.primaryContraventionTypes.filter(x=> x.isApplicableToDriver == (this.recipientTypeId == RecipientTypes.Driver? false:  x.isApplicableToDriver)
      && x.isApplicableToPassenger == (this.recipientTypeId == RecipientTypes.Passenger? false:  x.isApplicableToPassenger)
      && x.isApplicableToRegisteredOwner == (this.recipientTypeId == RecipientTypes.RegisteredOwner? false:  x.isApplicableToRegisteredOwner)
      && x.isApplicableToNotInVehicle == (this.recipientTypeId == RecipientTypes.NotInVehicle? false:  x.isApplicableToNotInVehicle)
      )
      .forEach(restrictedCharge => {
        restrictedCharge.isDisabled = true;
      });
  }

  setContraventionDetailText()
  {
    if (this.finallySelectedContraventionType?.isIRS)
    {
      if (this.finallySelectedContraventionType.id == ContraventionTypes.IRS24 || this.finallySelectedContraventionType.id == ContraventionTypes.IRSFail1st || this.finallySelectedContraventionType.id == ContraventionTypes.IRSFail2nd || this.finallySelectedContraventionType.id == ContraventionTypes.IRSFail3rd )
      {
        this.contraventionDetailsText = Array.prototype.map.call(this.localStorageService.getContraventionDetailTypes().filter(x => this.contravention.contraventionDetails.split(',').includes(x.id.toString())), function(item) { return item.description; }).join("\r\n") 
      }
      else
        this.contraventionDetailsText = this.finallySelectedContraventionType.description;
    }
    else
      this.contraventionDetailsText = "";
  }

  private _filterSection(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.primaryContraventionTypes.filter(option => 
      `${this.displaySection(option).toLowerCase()}`.includes(filterValue));
  }

  private _filterSecondarySection(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.secondaryContraventionTypes.filter(option => 
      `${this.displaySection(option).toLowerCase()}`.includes(filterValue));
  }

  private _filterTertiarySection(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.tertiaryContraventionTypes.filter(option => 
      `${this.displaySection(option).toLowerCase()}`.includes(filterValue));
  }
}
