import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'moddedTitleCasePipe'})
export class ModdedTitleCasePipe implements PipeTransform{

    transform(input: string): string {
        if(input.length === 0){
            return '';
        } else {
            var moddedTitle = input.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.slice(1));
            var normalizer = /[\u0300-\u036F]/g;
            var filteredTitle = moddedTitle.normalize('NFKD').replace(normalizer,'');
            return filteredTitle;
        }
    }
}