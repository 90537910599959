<div *ngIf="document" [ngClass]="getControlClass()" appIntakeDragDrop (fileDropped)="onFileDropped($event)">
    <div *ngIf="isAdditionalDocument && (documentState != DocumentState.Uploaded || documentType == null) && !isHeaderDisabled" class="card-header">
        <div class="row">
            <div class="col-12 col-sm-6 mb-0">
                Add New Supporting Document
            </div>
            <div *ngIf="documentState != DocumentState.Uploading" class="col-12 col-sm-6 mb-0">
                <div class="float-end">
                    <button class="btn btn-link text-danger" (click)="CancelDocument()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="p-4">
        <div class="d-flex flex-column flex-md-row">   
            <div *ngIf="!isAdditionalDocument || (documentState == DocumentState.Uploaded && documentType != null)" class="me-auto mb-2 mb-md-0">
                <div [ngClass]="{'document-name': true}">{{ documentType.name }}</div>
                <div class="max-file-label mt-2" *ngIf="documentState != DocumentState.Uploaded && maxFileSize">Maximum file size allowed is {{maxFileSize}}</div>
                <div class="mt-2" *ngIf="document.url || document.tempUrl">
                    <a href="{{document.url ? document.url : document.tempUrl}}" target="_blank">
                        {{document.originalName}}
                    </a>
                </div>
            </div>
            <div *ngIf="isAdditionalDocument && (documentState != DocumentState.Uploaded || documentType == null)" class="col-12 col-md-7 p-0">
                <ng-template [ngIf]="isHeaderDisabled">
                    <label>{{documentType.name}}</label>
                </ng-template>
                <ng-template [ngIf]="!isHeaderDisabled">
                    <label for="additionalDocumentType">File Category <span class="label-hint">(Required)</span></label>
                    <select [disabled]="documentState == DocumentState.Uploading || documentState == DocumentState.Error" [appSelectValidator]="true" #additionalDocumentTypeControl="ngModel" class="form-select" id="additionalDocumentType" name="additionalDocumentType" [(ngModel)]="documentType" (ngModelChange)="onFileCtegoryChange($event)" [ngClass]="{'is-invalid':(additionalDocumentTypeControl.touched || isSubmitClicked) && additionalDocumentTypeControl.errors?.defaultSelected}">
                        <option [ngValue]="null">Select a File Category</option>
                        <option *ngFor="let documentType of additionalDocumentTypes" [ngValue]="documentType">{{documentType.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>File Category is required</div>
                    </div>
                </ng-template>
                <div class="max-file-label mt-2" *ngIf="maxFileSize">Maximum file size allowed is {{maxFileSize}}</div>
            </div>
            <div class="col-12 col-md-5 p-0 d-flex justify-content-md-end">
                <ng-template [ngIf]="documentState == DocumentState.UploadPeriodExpired">
                    <div class="align-self-center">
                        <span>Upload period expired</span>
                    </div>
                </ng-template>
                <ng-template [ngIf]="documentState == DocumentState.NotUploaded">
                    <div class="align-self-center">
                        <div class="dropzone">
                            <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />
                        </div>
                        <button class="btn" (click)="triggerFileUpload()">
                            <div class="d-flex">
                                <div class="upload-label">
                                    <span>Drag and drop or click to upload</span>
                                </div>
                                <div class="align-self-center">
                                    <svg width='1.5em' height='1.5em' viewBox='0 0 16 16' class='bi bi-upload' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                                        <path fill-rule='evenodd' d='M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8zM5 4.854a.5.5 0 0 0 .707 0L8 2.56l2.293 2.293A.5.5 0 1 0 11 4.146L8.354 1.5a.5.5 0 0 0-.708 0L5 4.146a.5.5 0 0 0 0 .708z'/>
                                        <path fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 2z'/>
                                    </svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="documentState == DocumentState.Uploading">
                    <div class="align-self-center">
                        <div class="progress doc-progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                                [attr.aria-valuenow]="uploadProgress" [style.width]="uploadProgress +'%'" aria-valuemin="0" aria-valuemax="100">{{uploadProgress +'%'}}</div>
                        </div>       
                    </div>
                    
                    <div class="ms-4 align-self-center">
                        <button class="btn btn-link text-danger" (click)="CancelUpload()">
                            Cancel
                        </button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="documentState == DocumentState.Error">
                    <div class="align-self-center">
                        <div class="progress doc-progress">
                            <div class="progress-bar bg-danger" role="progressbar" 
                                [attr.aria-valuenow]="uploadProgress" [style.width]="uploadProgress +'%'" aria-valuemin="0" aria-valuemax="100">{{uploadProgress +'%'}}</div>
                        </div>       
                    </div>
                    
                    <div class="ms-4 align-self-center">
                        <button class="btn btn-link text-danger" (click)="CancelUpload()">
                            Cancel
                        </button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="documentState == DocumentState.Uploaded">            
                    <div class="mt-2 mt-md-0 align-self-md-center">
                        <div class="progress doc-progress">
                            <div *ngIf="document.documentTypeId==DocumentTypes.NAPDocument || document.documentTypeId==DocumentTypes.SeizureNoticePoliceCopy || document.documentTypeId==DocumentTypes.SeizureNoticeDriverCopy || document.documentTypeId==DocumentTypes.SeizureNoticeRegisteredOwnerCopy || document.documentTypeId==DocumentTypes.SeizureNoticeTowLotCopy || (document.documentTypeId==DocumentTypes.RoadsideAppeal && (document.uploadedBy=='APIS System' || document.uploadedBy=='System Generated'))"
                            class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Attached</div>
                            <div *ngIf="document.documentTypeId!=DocumentTypes.NAPDocument && document.documentTypeId!=DocumentTypes.SeizureNoticePoliceCopy && document.documentTypeId!=DocumentTypes.SeizureNoticeDriverCopy && document.documentTypeId!=DocumentTypes.SeizureNoticeRegisteredOwnerCopy && document.documentTypeId!=DocumentTypes.SeizureNoticeTowLotCopy  && !(document.documentTypeId==DocumentTypes.RoadsideAppeal && (document.uploadedBy=='APIS System' || document.uploadedBy=='System Generated'))" 
                            class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Uploaded</div>
                        </div>            
                    </div>
                    <div class="ms-md-4 align-self-md-center" *ngIf="isActionAllowed">
                        <button class="btn btn-link text-danger" (click)="RemoveDocument()">
                            Remove File
                        </button>
                    </div>            
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="errorMessage != ''" class="d-flex flex-column flex-md-row ps-4 pb-4">  
        <span class="error">{{errorMessage}}</span>
    </div>
</div> 
