import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DocumentState } from '@apis/shared/enums/document-state.enum';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { DisclosureUploadOptions } from '@apis/shared/models/disclosure-upload-options.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentService } from '../../services/document.service';
import { DocumentTypes } from '@apis/shared/enums/app.enum';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { Constants } from '@apis/shared/helpers/constants';
import { DisclosureService } from '../../services/disclosure.service';
import { StorageService } from '@apis/shared/services/storage.service';
import { DisclosureDocument } from '@apis/shared/models/disclosure-document.model';
import { concatMap, reduce, tap } from 'rxjs/operators';

@Component({
  selector: 'intake-disclosure-upload',
  templateUrl: './intake-disclosure-upload.component.html',
  styleUrls: ['./intake-disclosure-upload.component.scss'],
  outputs: ['onCancelEvent']
})
export class IntakeDisclosureUploadComponent implements OnInit {
  @Input() document: DisclosureDocument;
  @Input() additionalDocumentTypes: DocumentType[];
  @Input() isHeaderDisabled: boolean
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  onCancelEvent = new EventEmitter<DisclosureDocument>();

  DocumentState = DocumentState;
  documentState = DocumentState.NotUploaded;
  documentType: DocumentType = null;
  isAdditionalDocument: boolean = false;
  uploadProgress: number = 0;
  uploadProgressStyle: string;
  errorMessage: string = "";
  validExtensions: string[]; 
  isActionAllowed: boolean = true;
  maxFileSize: string;
  DocumentTypes = DocumentTypes;
  abortController: AbortController = null;

  constructor(private localStorageService: LocalStorageService,
              private documentService: DocumentService,
              private disclosureService: DisclosureService,
              private storageService: StorageService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isActionAllowed = this.document.url == null;

    //Check if it is a supplemental document
    if (this.document.type == null)
      this.isAdditionalDocument = true;
    else {
      this.documentType = this.localStorageService.getDocumentTypes().find(x => x.name == this.document.type);
      this.isAdditionalDocument = this.documentType.isSupplemental;
      this.validExtensions = this.documentType.acceptedFileTypes.split(',');
    }

    if (this.document.url || this.document.tempUrl) {
      this.documentState = DocumentState.Uploaded;
    }

    this.getMaxFileSizeText();
  }

  getMaxFileSizeText() {
    if (this.documentType?.maximumFileSize)
    {
      this.maxFileSize = this.documentType.maximumFileSize >= 1024? `${(this.documentType.maximumFileSize / 1024).toFixed(0)} GB` : `${this.documentType.maximumFileSize} MB`;
    }
  }

  triggerFileUpload(){
    this.fileDropEl.nativeElement.click();
  }

  /**
   * on file drop handler
   */
  onFileDropped(files) {
    if (this.documentState == DocumentState.NotUploaded && files.length > 0) {
      this.uploadDisclosure(files[0]);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if (files.length > 0) {
      this.uploadDisclosure(files[0]);
    }
  }

  uploadDisclosure(rawFile) {
    const fileName = this.removeExtraDecimals(rawFile?.name);
    const file = new File([rawFile], fileName, {
      lastModified: rawFile.lastModified,
      type: rawFile.type
    });
    if (!this.isActionAllowed)
      return;

    var fileExtension = file?.name.split('.').pop();
    this.errorMessage = "";

    switch (this.documentState)
    {
      case DocumentState.Uploaded:
        this.errorMessage = "Remove previous uploaded file to upload a new file.";
        break;
      
      case DocumentState.Uploading: case DocumentState.Error:
        this.errorMessage = "An upload is already in progress. Please cancel that first to upload a new file.";  
        break;

      case DocumentState.NotUploaded:
        {
          if (this.document.type == null)
          {
            this.errorMessage = "Please select a file category before uploading the file."
            break;
          }

          if (!this.validExtensions.includes(fileExtension.toLowerCase()))
          {
            this.errorMessage = "This type of file is not allowed. Allowed file types are: " + this.documentType.acceptedFileTypes;
            break;
          }

          if (this.documentType.maximumFileSize != 0 && file.size > (this.documentType.maximumFileSize * 1024 * 1024))
          {
            this.errorMessage = "Invalid file size. The maximum allowed file size is " + this.documentType.maximumFileSize.toString() + "MB"; 
            break;
          }
          if(file.size === 0){
            this.errorMessage = "File appears to be corrupt or not converted properly. Please check file and reupload.";
            break;
          }

          // Abortcontroller to cancel the upload request
          this.abortController = new AbortController();

          const fileName = file?.name;
          const fileNameWithType = this.replaceSpecialCharacters(`${this.documentType.name}-${fileName}`);

          const uploadOptions = new DisclosureUploadOptions({
            name: fileNameWithType,
            fullName: `${this.document.documentRefTypeNumber}/${fileNameWithType}`,
            abortController: this.abortController,
            metadata: {
              originalname: fileName,
              type: this.documentType.name,
              containername: 'apis-ticket',
              uploadedBy: this.document.uploadedBy
            }
          });

          this.documentState = DocumentState.Uploading;

          this.disclosureService.getTicketWipContainerSasUri()
          .pipe(
            concatMap((res) => this.storageService.uploadFile(res.containerSasUri, uploadOptions, file)),
            tap(
              (loadedBytes) => { // Next
                let percentage = Math.round((loadedBytes / file.size) * 100);
                this.uploadProgress = percentage;
              },
              (error: any) => { // Error
                if (error?.name != 'AbortError') {
                  this.errorMessage = "Something went wrong. Please try uploading the document again."
                  this.documentState = DocumentState.Error;
                }
              },
              () => { // Complete
                // Do nothing
              }
            ),
            // accumulates all chunk uploads and emits a single value when the upload is completed
            // this avoids multiple get calls when each chunk is uploaded
            reduce((acc, value) => value),
            concatMap(() => this.disclosureService.getTicketWipDisclosureSasUri(this.document.documentRefTypeNumber, fileNameWithType)),
            tap((result) => {
              this.document.originalName = fileName
              this.document.name = fileNameWithType;
              this.document.fullName = `${this.document.documentRefTypeNumber}/${fileNameWithType}`;
              this.document.size = CommonUtil.getDocumentSize(file);
              this.document.contentLength = file.size;
              this.document.tempUrl = result.blobSasUri;

              this.documentState = DocumentState.Uploaded;
              this.uploadProgress = 0;
              this.errorMessage = "";              
              this.changeDetectorRef.detectChanges();
            }, (error: any) => {              
              if (error?.name != 'AbortError') {
                this.uploadProgress = 0;
                this.errorMessage = "Something went wrong. Please try uploading the document again."
                this.documentState = DocumentState.NotUploaded;
                this.changeDetectorRef.detectChanges();
              }
            })
          )
          .subscribe();
          break;
        }
      }    
  }

  CancelUpload(){
    this.abortController.abort();
    this.uploadProgress = 0;
    this.documentState = DocumentState.NotUploaded;
  }

  RemoveDocument(){
    this.document.uploadedDate = null;
    this.document.name = "";
    this.documentState = DocumentState.NotUploaded;
    this.errorMessage = "";
  }

  CancelDocument()
  {
    this.onCancelEvent.emit(this.document);
  }

  onFileCtegoryChange(fileCategory: DocumentType )
  {
    this.document.type = fileCategory.name;
    this.validExtensions = this.documentType.acceptedFileTypes.split(',');
    this.getMaxFileSizeText()
  }

  getControlClass()
  {
    if (this.isActionAllowed)
      return "card mb-4";
    else
      return "card mb-4 disabled-control";
  }

  replaceSpecialCharacters(blobName) {
    // Regular expression pattern to match special characters
    var pattern = /[^a-zA-Z0-9-_.\[\]()]/g;
    
    // Replace special characters with a space
    var cleanedBlobName = blobName.replace(pattern, ' ');
    return cleanedBlobName;
  }

  removeExtraDecimals(blobName) {
    var cleanedBlobName = blobName.replace(/(...)(\.{2,})/g, '$1.');
    return cleanedBlobName
  }
}
