import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Document } from '../models/document.model';
import { APP_CONFIG } from '@apis/app-config';
import { map } from "rxjs/operators";
import { DocumentUploadRequest } from '../models/document-upload-request.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    private apiUrl: string = `${this.appConfig.apiUrl}${this.appConfig.apiV1}`;

    constructor(public http: HttpClient,
        @Inject(APP_CONFIG) public appConfig: any){}

    public getWipContainerSasUri(): Observable<any> {
        return this.http.get(`${this.apiUrl}/document/wip/sasuri`)
        .pipe(
            map(response => response)
        );
    }

    public getDocumentSasUri(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string, storageFileName: string, applicationFileName: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/document/sasuri`, {
            params: {
                "tempFileFolder": tempFileFolder,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber,
                "storageFileName": storageFileName,
                "applicationFileName": applicationFileName
            }
        })
        .pipe(
            map(response => response)
        );
    }

    //File Upload Methods
    public uploadDocumentAsync(file: File, tempFileFolder: string, newFileName: string): Observable<any> {
        let formData = new FormData();
        formData.append("file", file, file.name);

        return this.http.post(`${this.apiUrl}/document`, formData, 
            {        
                observe: "events", 
                reportProgress: true,
                params:{
                    "newFileName": newFileName,
                    "tempFileFolder": tempFileFolder
                }    
            },
        );
    }

    // Document and File related methods
    public uploadFile(file: File, tmpFileFolder: string, documentRefTypeName: string,  documentRefTypeNumber: string, newFileName: string): Observable<any> {
        let formData = new FormData();
        formData.append("file", file, file.name);
                
        return this.http.post(`${this.apiUrl}/file`, formData, 
        {        
            observe: "events", 
            reportProgress: true,
            params:{
            "tmpFileFolder": tmpFileFolder,
            "documentRefTypeName": documentRefTypeName,
            "documentRefTypeNumber": documentRefTypeNumber,
            "newFileName": newFileName
            }
        }
        );
    }

    public downloadFile(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string, storageFileName: string, applicationFileName: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/file`, {
            params: {
                "tempFileFolder": tempFileFolder,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber,
                "storageFileName": storageFileName,
                "applicationFileName": applicationFileName
            },
            responseType: 'blob'
          });
      }

    public downloadDocument(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string, storageFileName: string, applicationFileName: string, documentLocation = ""): Observable<any> {
        if (!documentLocation) { // If documentLocation is null, send an empty string. Otherwise, the backend interprets it as a string ("null") instead of a null value
            documentLocation = "";
        }
        return this.http.get(`${this.apiUrl}/document`, {
            params: {
                "tempFileFolder": tempFileFolder,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber,
                "storageFileName": storageFileName,
                "applicationFileName": applicationFileName,
                "documentLocation": documentLocation
            },
            responseType: 'blob'
          });
    }

    public deleteWipDocument(fileName: string, tempFileFolder: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/document/wip`, {
            params: {
                "fileName": fileName,
                "tempFileFolder": tempFileFolder
            },
          });
    }

    public deleteDocument(storageFileName: string, tempFileFolder: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/document`, {
            params: {
                "fileName": storageFileName,
                "tempFileFolder": tempFileFolder
            }
          });
    }

    public deleteFinalizedDocument(fileName: string, documentRefTypeName: string, documentRefTypeNumber): Observable<any> {
        return this.http.delete(`${this.apiUrl}/document/finalized`, {
            params: {
                "fileName": fileName,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber
            }
        });
    }

    public deletePermanentBlobDocument(documentId: number, contentGuid: string, fileName: string, documentRefTypeName: string, documentRefTypeNumber): Observable<any> {
        return this.http.delete(`${this.apiUrl}/document/permanent/blob`, {
            params: {
                "documentId": documentId.toString(),
                "contentGuid": contentGuid,
                "fileName": fileName,
                "documentRefTypeName": documentRefTypeName,
                "documentRefTypeNumber": documentRefTypeNumber
            }
          });
    }

    public deletePermanentDocument(documentId: number, contentGuid: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/document/permanent`, {
            params: {
                "documentId": documentId.toString(),
                "contentGuid": contentGuid
            }
          });
    }

    public deleteFile(fileName: string, tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/file`, {
            params: {
            "fileName": fileName,
            "tempFileFolder": tempFileFolder,
            "documentRefTypeName": documentRefTypeName,
            "documentRefTypeNumber": documentRefTypeNumber
            }
        });
    }

    public finalizeDocuments(tempFileFolder: string, documentRefTypeName: string, documentRefTypeNumber: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/document/finalizefiles`, {        
                params:{
                    "tempFileFolder": tempFileFolder,
                    "documentRefTypeName": documentRefTypeName,
                    "documentRefTypeNumber": documentRefTypeNumber
                }    
            },
        );
    }

    public updateDocumentAsync(document: Document): Observable<Document> {
        return this.put('document', {document});
    }

    public updateAndFinalizeDocumentsAsync(documentUploadRequest: DocumentUploadRequest): Observable<any> {
        return this.put('documents', {documentUploadRequest}); 
    }

    public getDocumentsByContraventionId(contraventionId: number): Observable<Document[]> {
        return this.http.get(`${this.apiUrl}/documents`, {
            params: {
                "contraventionId": contraventionId.toString()
            }
        }).pipe(
            map((response: any) => Array.from<Document>(response)));
    }

    public deleteDocumentAsync(documentId: number, createPlaceholderOnDelete: boolean): Observable<Document> {
        return this.http.delete(`${this.apiUrl}/documents`, {
            params: {
                documentId: documentId.toString(),
                createPlaceholderOnDelete: createPlaceholderOnDelete
            }}
        ).pipe(
            map(response => new Document(response)));
    }

    put(action: string, body: Object = {}): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        }

        for (var key in body) {
            if (!Object.prototype.hasOwnProperty.call(body, key)) continue;
            body = body[key];
            break;
        }
        var newJson = JSON.stringify(body);
        
        return this.http
          .put(this.apiUrl + '/' + action, newJson, httpOptions)
          .pipe(catchError(this.formatErrors));
    }

    private formatErrors(error: any) {
        return throwError(error.error);
    }  
}
