<div class="d-flex flex-column">
    <div class="d-flex justify-content-between">
        <h1 class="submissionMain">{{pageHeading}}</h1>
        <div class="float-right submissionMain">
            <div class="print-icon" title="Print APIS submission export" (click)="PrintSubmission()"></div>
        </div>
    </div>

    <div class="submissionMain">
        <button class="btn btn-link float-right" (click)="onExpandAllChange()">
            {{isCollapsed? "Expand All" : "Collapse All"}}
        </button> 
    </div>

    <div class="accordion mt-2 submissionMain">
        <div class="card" *ngIf="isRecipientSectionVisible">
            <div class="card-header" (click)="onPlusMinusIconClick('imgRecipientInformation', 'divRecipientInformation')">
                <div>Recipient Information</div>                                
                <div class="plus-minus-icon" >
                    <img id="imgRecipientInformation" src="assets/images/minus.svg" />
                </div>                               
            </div>            
            <div id="divRecipientInformation" class="collapse show">
                <div class="card-body">   
                    <label *ngIf="this.recipient.movesLookupDate">The following recipient information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient.movesLookupDate | date:'mediumDate'}} at {{recipient.movesLookupDate | date:'HH:mm'}}</label>
                    <app-section-details sectionTitle="Recipient Information" [sectionDetails]="recipientInformation"></app-section-details>
                    <app-section-details sectionTitle="Identification Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>

                    <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipientContact?.isAddressDifferentFromMOVES">
                        <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                        <label>The following contact information was provided by the recipient to the officer</label>
                        <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>    
                    </ng-container>

                    <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                        <app-section-details sectionTitle="Recipient Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="isContraventionSectionVisible">
            <div class="card-header" (click)="onPlusMinusIconClick('imgContraventionDetails', 'divContraventionDetails')">
                <div>Contravention Details</div>                                
                <div class="plus-minus-icon" >
                    <img id="imgContraventionDetails" src="assets/images/minus.svg" />
                </div>                               
            </div>            
            <div id="divContraventionDetails" class="collapse show">
                <div class="card-body">               
                    <div *ngFor="let contraventionInfo of contraventionInformation; let index = index;">
                        <app-section-details class="line-break" sectionTitle="Contravention# {{index+1}} " [sectionDetails]="contraventionInfo"></app-section-details>
                    </div>
                    <app-section-details sectionTitle="Occurrence Time & Location" [sectionDetails]="occurrenceInformation"></app-section-details>
                    <app-section-details sectionTitle="Location Conditions" [sectionDetails]="locationConditions"></app-section-details>
                    
                    <app-section-details sectionTitle="Occurrence Information" [sectionDetails]="stopInformationDetails"></app-section-details>
                    <app-section-details sectionTitle="Witness Information" [sectionDetails]="witnessInformation"></app-section-details>
                    <div class="row" *ngIf="recipient.recipientTypeId!=RecipientTypes.NotInVehicle">
                        <div class="col-12 col-sm-4">
                            <div class="label">Passengers</div>
                            <div class="value">{{stopInformation?.isPassengerInVehicle? 'Yes': 'No'}}</div>
                        </div>
                        <ng-container *ngIf="stopInformation?.isPassengerInVehicle">
                            <div class="col-12 col-sm-4">
                                <div class="label">Passenger Positions</div>
                                <div class="value pl-2">
                                    <app-passenger-position [stopInformation]="stopInformation" [isReadOnly]="true"></app-passenger-position>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="label">Additional Passenger Location Details</div>
                                <div class="value">{{stopInformation?.vehicleOccupantsNotes}}</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row" *ngIf="isBodycamVideoActive">
                        <div class="col-12">
                            <div class="label">Is there any video in relation to this event?</div>
                            <div class="value" *ngIf="stopInformation?.hasBodycamVideo == null; else bodycamVideoHasValue">-</div>
                            <ng-template #bodycamVideoHasValue>
                                <div class="value">{{stopInformation?.hasBodycamVideo? 'Yes': 'No'}}</div>
                            </ng-template>
                        </div>
                    </div>

                    <app-section-details sectionTitle="Officer Information" [sectionDetails]="officerInformation"></app-section-details>
                    <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>

                    <app-section-details sectionTitle="Contravention Grounds" [sectionDetails]="contraventionGrounds"></app-section-details>
                    <app-section-details sectionTitle="Impairment Screening Information" [sectionDetails]="impairmentScreeningInformation"></app-section-details>
                    <app-section-details sectionTitle="Impairment Screening Details" [sectionDetails]="impairmentScreeningDetails"></app-section-details>
                    <div *ngFor="let additionalTest of additionalScreeningInformation; let index = index;">
                        <app-section-details sectionTitle="Additional Screening Method/Evidentiary Test {{index+1}}" [sectionDetails]="additionalTest"></app-section-details>
                    </div>
                    <app-section-details sectionTitle="Reasonable Grounds to Believe" [sectionDetails]="reasonableGrounds"></app-section-details>
                    <app-section-details *ngIf="irsContravention?.roadsideAppeal" sectionTitle="Roadside Appeal Information" [sectionDetails]="roadsideAppealInformation"></app-section-details>
                    <app-section-details *ngIf="irsContravention?.roadsideAppeal" sectionTitle="Roadside Appeal Details" [sectionDetails]="roadsideAppealDetails"></app-section-details>
                    <app-section-details *ngIf="stopInformation.isSpeedingSelected" sectionTitle="Speeding Contravention Grounds" [sectionDetails]="speedingInformation"></app-section-details>
                    <app-section-details *ngIf="stopInformation.isSpeedingSelected" sectionTitle="Instrument Type & Testing" [sectionDetails]="speedingDeviceInformation"></app-section-details>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="isVehicleSectionVisible">
            <div class="card-header" (click)="onPlusMinusIconClick('imgVehicleDetails', 'divVehicleDetails')">
                <div>Vehicle Information</div>                                
                <div class="plus-minus-icon" >
                    <img id="imgVehicleDetails" src="assets/images/minus.svg" />
                </div>                               
            </div>            
            <div id="divVehicleDetails" class="collapse show">
                <div class="card-body">     
                    <label *ngIf="this.vehicle.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>               
                    
                    <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                        <label>The following information is a description of the vehicle involved in the contravention</label>
                        <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>

                    <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>
                    <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>
                    
                    <ng-container *ngIf="recipient.recipientTypeId==RecipientTypes.Driver || recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
                        <label *ngIf="this.vehicle.movesLookupDate && !this.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicle.isNoRegisteredOwner && !this.vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                        <app-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                    </ng-container>
                    
                    <app-section-details *ngIf="recipient.recipientTypeId==RecipientTypes.Passenger" sectionTitle="Driver Information" [sectionDetails]="vehicleDriverInformation"></app-section-details>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="isVehicleSeizureSectionVisible">
            <div class="card-header" (click)="onPlusMinusIconClick('imgVehicleSeizureDetails', 'divVehicleSeizureDetails')">
                <div>Vehicle Seizure Details</div>                                
                <div class="plus-minus-icon" >
                    <img id="imgVehicleSeizureDetails" src="assets/images/minus.svg" />
                </div>                               
            </div>            
            <div id="divVehicleSeizureDetails" class="collapse show">
                <div class="card-body">                    
                    <app-section-details sectionTitle="Seizure Information" [sectionDetails]="seizureInformation"></app-section-details>
                    <label *ngIf="this.vehicle.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>               
                    
                    <ng-container *ngIf="this.vehicle?.movesLookupDate && this.vehicle?.isStolenPlate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                        <label>The following information is a description of the vehicle involved in the contravention</label>
                        <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>

                    <ng-container *ngIf="(this.vehicle?.movesLookupDate && !this.vehicle?.isStolenPlate) || !this.vehicle?.movesLookupDate">
                        <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    </ng-container>
                    <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>
                    
                    <ng-container *ngIf="recipient.recipientTypeId==RecipientTypes.Driver || recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
                        <label *ngIf="this.vehicle.movesLookupDate && !this.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicle.isNoRegisteredOwner && !this.vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicle.movesLookupDate | date:'mediumDate'}} at {{vehicle.movesLookupDate | date:'HH:mm'}}</label>
                        <app-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                    </ng-container>
                    
                    <app-section-details *ngIf="recipient.recipientTypeId==RecipientTypes.Passenger" sectionTitle="Driver Information" [sectionDetails]="vehicleDriverInformation"></app-section-details>
                    
                    <app-section-details sectionTitle="Vehicle Location & Tow Lot Information" [sectionDetails]="towLotInformation"></app-section-details>
                    <app-section-details sectionTitle="Police Narrative" [sectionDetails]="vehicleSeizurePoliceNarrativeDetails"></app-section-details>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="isSupportingDocumentsSectionVisible">
            <div class="card-header" (click)="onPlusMinusIconClick('imgSupportingDocuments', 'divSupportingDocuments')">
                <div>Supporting Documents</div>
                <div class="plus-minus-icon">
                    <img id="imgSupportingDocuments" src="assets/images/minus.svg" />
                </div>  
            </div>
            <div id="divSupportingDocuments" class="collapse show">
                <div class="card-body">
                    <div *ngFor="let document of supportingDocuments">
                        <div class="row">
                            <div class="col-12 col-sm-12 mb-0 pb-0">
                                <label [ngClass]="{'required': !document.isOptional}">{{getDocumentTypeName(document)}} </label>
                            </div>
                            <div class="col-12 col-sm-12 mb-4">
                                <button *ngIf="document.documentName != null" class="btn-link" (click)="DownloadDocument(document)">
                                    {{document.documentName}}
                                </button>
                                <div *ngIf="document.documentName == null && document.isSubmitLater">
                                    Will submit at later date
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="isPoliceServiceSectionVisible">
            <div class="card-header" (click)="onPlusMinusIconClick('imgPoliceServiceInformation', 'divPoliceServiceInformation')">
                <div>Police Service Information</div>                                
                <div class="plus-minus-icon" >
                    <img id="imgPoliceServiceInformation" src="assets/images/minus.svg" />
                </div>                               
            </div>            
            <div id="divPoliceServiceInformation" class="collapse show">
                <div class="card-body">                    
                    <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
                    <app-section-details sectionTitle="" [sectionDetails]="serviceInformation"></app-section-details>
                </div>
            </div>
        </div>
    </div>

    <form #reviewConfirmForm="ngForm">
        <div class="form-group row mt-4 submissionMain">
            <div class="col-12 col-sm-8">
                <label for="policeOfficerFullName">Full Name of Submitter <span class="label-hint">(Required)</span></label>
                <input readonly type="text" class="form-control" id="policeOfficerFullName" name="policeOfficerFullName" 
                [value]="policeOfficerFullName">
            </div>
            <div class="col-12 col-sm-4">
                <label for="submissionDate">Date of Submission</label>
                <input type="text" class="form-control" id="submissionDate" readonly value="{{submissionDate | date:'dd MMM, yyyy'}}">
            </div>                
        </div>

        <!-- class submissionMain hides the element when in print mode -->
        <div class="card mt-4 mb-4 submissionMain" *ngIf="stopInformation.userIsIssuingOfficer && officerSubmissionDeclaration != null">
            <div class="container d-flex justify-content-left">
                <div class="row callout">
                    <div class="col-md-1 callout-logo">   
                        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                        </svg>
                    </div>
                    <div class="col-md-11 mt-4 mb-4">   
                        <h5>Declaration <span class="label-hint">(Required)</span></h5>
                        <div class="form-check mt-2">
                            <div class="custom-control custom-checkbox pl-1">
                                <input required #chkConsentControl="ngModel" type="checkbox" class="custom-control-input" id="chkConsent" name="chkConsent" [(ngModel)]="isTrueInformation" [ngClass]="{'is-invalid': (chkConsentControl.touched || isSubmitClicked) && chkConsentControl.invalid}">
                                <label class="custom-control-label section-label" for="chkConsent">
                                    {{officerSubmissionDeclaration.description}}
                                </label>
                                <div class="invalid-feedback">
                                    <div>Declaration must be checked</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- class submissionMain hides the element when in print mode -->
        <div class="form-group submissionMain">
            <div class="row pt-3">
                <div *ngIf="stopInformation.apisStatus === 'Cancelled'; else notCancelled" class="col-12 col-sm-6">
                    <a class="btn button-secondary btn-block" [routerLink]="['/']">Back to Dashboard</a>
                </div>
                <ng-template #notCancelled>
                    <div class="col-12 col-sm-6">
                        <a class="btn button-secondary btn-block" [routerLink]="['/contravention/add-details/supporting-documents']">Back to Supporting Documents</a>
                    </div>
                    <div class="col-12 col-sm-6 text-right">
                        <button class="btn button-primary btn-block" (click)="onSubmitClick(reviewConfirmForm.valid)">Submit</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </form>
</div>
<div *ngIf="stopInformation.submissionVersion != 'P2'" class="submission-print">
    <app-legacy-submission-print [issuerSubmission]="stopInformation"></app-legacy-submission-print>
</div>
<div style="letter-spacing: 0.01px;" *ngIf="stopInformation.submissionVersion == 'P2'" class="submission-print">
    <app-submission-print [stopInformation]="stopInformation"></app-submission-print>
</div>