import { Guid } from "guid-typescript";
import { AdditionalDocumentCategory } from "../enums/additional-document-category.enum";



export class Document {
    public isOptional: boolean = false;
    public documentId: number = 0;
    public contraventionId: number;
    public vehicleSeizureId: number;
    public reviewId: number;
    public lateReviewRequestId: number;
    public paymentAdditionalTimeRequestId: number;
    public eventId: number;
    public contentGuid: string;
    public documentTypeId: number = 0;
    public documentName: string;
    public documentDescription: string;
    public documentExtension: string;
    public documentSize: string;
    public uploadedBy: string;
    public uploadedDate: Date;
    public isSubmitLater: boolean;
    public isPublished: boolean;
    public publishedDate: Date;
    public isDeleted: boolean;
    public originalDocumentId: number;
    public stopInformationId: number;
    public documentReferenceNumber: string;
    public documentLocation: string;
    public judicialReviewId: number;
    public stayOrderId: number;
    public forcePrimaryDocument: boolean;

    //properties to support uploading/downloading
    public tempFileFolder: string;
    public isReadOnly: boolean;
    public isUploadPeriodExpired: boolean;
    public additionalDocumentsCategory: number = AdditionalDocumentCategory.Intake;
    public documentRefTypeName: string;
    public documentRefTypeNumber: string;
    public documentNumber: string = Guid.create().toString();
    public isWitnessStatmentRedacted: boolean;
    public testTypeId: number;
    public version: string;
    public downloadUrl: string;
    public isUploading: boolean;
    public isNewIntakeSubmission: boolean = false;

    constructor(init?: Partial<Document>) {
        
        Object.assign(this, init);
    }
}
