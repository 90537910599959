import { DeclarationType } from './../../../../../../../libs/shared/models/types/declaration-type.model';
import { PoliceServiceInformation } from './../../../../../../../libs/shared/models/police-service-information.model';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Document } from '@apis/shared/models/document.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Contravention } from '@apis/shared/models/contravention.model';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { Router } from '@angular/router';
import { ContraventionTest } from '@apis/shared/models/contravention-test.model';
import { ContraventionDetailTypes, ContraventionTypes, DeclarationTypes, DocumentTypes, DriverDeclinedToTestTypes, RefusalOrFailedClassification, ReviewTypes, SampleClassification, TestTypes } from '@apis/shared/enums/app.enum';
import { AdditionalDocumentCategory } from '@apis/shared/enums/additional-document-category.enum';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { Constants } from '@apis/shared/helpers/constants';
import { DocumentState } from '@apis/shared/enums/document-state.enum';
import { DocumentService } from 'apps/intake/src/shared/services/document.service';
import { isThisSecond } from 'date-fns';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrls: ['./supporting-documents.component.scss']
})
export class SupportingDocumentsComponent implements OnInit {

  documents: Document[];
  documentTypes: DocumentType[];
  documentState = DocumentState.NotUploaded;
  documentExtension: string;
  documentsTracker: Document[];
  documentsLogger: Document[];
  supportingDocumentsDeclaration: string;
  selectedFile: File;
  dropZoneText: string = "Drag and drop or click to upload";
  documentTypeName: string;
  declarationTypes: DeclarationType[];
  documentName: string;
  uploadedDocument: Document;
  storageFileName: string;
  validFileExtensions: string[];
  stopInformation: StopInformation;
  contraventions: Contravention[];
  policeServiceInformation: PoliceServiceInformation;
  vehicleSeizures: VehicleSeizure[];
  errorMessage: string = "";
  isSubmitClicked: boolean = false;
  isTrueInformation: boolean = false;
  userName: string;
  initialDocumentsCount: number = 0;
  uploadProgress: number = 0;
  isAdditionalDocumentAllowed: boolean = true;
  submissionTitle: string;
  isLegacySubmission: boolean = true; //APIS Phase1
  documentRefTypeName: string;
  documentRefTypeNumber: string;
  documentRefTypeId: number;
  isUploadPeriodExpired: boolean = false;
  additionalDocumentTypes: DocumentType[];
  isAIThirdTestVisible: boolean;
  isNewDREProcess: boolean;

  @ViewChild("dropZone") dropZone: ElementRef;

  @Input() showDropZoneAfterUpload: boolean = false;

  @Output() documentAdded = new EventEmitter<Document>();

  constructor(private intakeService: IntakeService, 
              private localStorageService: LocalStorageService,
              private documentService: DocumentService,
              private router: Router) {}    

  ngOnInit(): void {

    //Get Types
    var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
    var speedingContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isSpeedingOffence).map(s => s.id);
    var sdpSeizureTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isSDP).map(s => s.id);
    this.documentTypes = this.localStorageService.getDocumentTypes();
    this.additionalDocumentTypes = this.localStorageService.getDocumentTypes().filter(x => [DocumentTypes.Photo, DocumentTypes.AudioVideo, DocumentTypes.WitnessStatements, DocumentTypes.PoliceNotes, DocumentTypes.OtherOfficerAccount, DocumentTypes.TechnicalMaterial, DocumentTypes.ViolationTicket, DocumentTypes.Other, DocumentTypes.PoliceNarrative].includes(+x.id));
    this.declarationTypes = this.localStorageService.getDeclarationTypes();

    //Get stop information object
    this.stopInformation = this.intakeService.getStopInformationModel();
    this.contraventions = this.stopInformation.contraventions;
    this.vehicleSeizures = this.stopInformation.vehicleSeizures;
    this.policeServiceInformation = (this.contraventions && this.contraventions.length>0)?this.contraventions[0].policeServiceInformation[0]: this.vehicleSeizures[0].policeServiceInformation[0];

    this.userName = JSON.parse(localStorage.getItem("userKey")).userName

    if (this.stopInformation.userIsIssuingOfficer) {
      this.supportingDocumentsDeclaration = this.declarationTypes.find(d => d.id == DeclarationTypes.SupportingDocumentsIssuingOfficerV2).description;
    }

    else {
      this.supportingDocumentsDeclaration = this.declarationTypes.find(d => d.id == DeclarationTypes.SupportingDocumentsNonIssuingOfficerV2).description;
    }

    //Check if it is an existing contravention or seizure then leave documents linked to existing contravention/seizure (legacy)
    //Otherwise link all documents to stop (phase2)
    if ((this.contraventions.length + this.vehicleSeizures.length) == 1 && this.contraventions.length == 1 && this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1) //Legacy Contravention
    {
      this.documents = this.contraventions[0].documents;
      this.documentRefTypeName = "Contraventions";
      this.documentRefTypeNumber = this.contraventions[0].contraventionNumber;
      this.documentRefTypeId = this.contraventions[0].contraventionId;
    }
    else if ((this.contraventions.length + this.vehicleSeizures.length) == 1 && this.vehicleSeizures.length == 1 && this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1) //Legacy SDP Seizure
    {
      this.documents = this.vehicleSeizures[0].documents;
      this.documentRefTypeName = "VehicleSeizures";
      this.documentRefTypeNumber = this.vehicleSeizures[0].seizureNumber;
      this.documentRefTypeId = this.vehicleSeizures[0].vehicleSeizureId;
    }
    else if (this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE2)//Phase 2  
    {
      this.documents = this.stopInformation.documents;
      this.isLegacySubmission = false;
    }

    //Add common documents first
    //Check if Police Narrative document is required or not
    let isPoliceNarrativeDocumentRequired = false;

    if (this.contraventions.length>0)
    {
      if (this.contraventions[0].additionalNotes == null || this.contraventions[0].additionalNotes?.trim().length == 0)
      {
        isPoliceNarrativeDocumentRequired = true;

        //If no police narrative document exists, create placeholder
        if(this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.PoliceNarrative).length == 0)
        {
          var document = new Document()
          var documentType = this.documentTypes.find(x => x.id == DocumentTypes.PoliceNarrative);
          document.documentTypeId = documentType.id;
          this.documents.push(document);
        }
      }
    }

    if (this.vehicleSeizures.length>0)
    {
      if (this.vehicleSeizures[0].additionalNotes == null || this.vehicleSeizures[0].additionalNotes?.trim().length == 0)
      {
        isPoliceNarrativeDocumentRequired = true;

        //If no police narrative document exists, create placeholder
        if (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.PoliceNarrative).length == 0)
        {
          var document = new Document()
          var documentType = this.documentTypes.find(x => x.id == DocumentTypes.PoliceNarrative);
          document.documentTypeId = documentType.id;
          this.documents.push(document);
        }
      }
    }

    if (isPoliceNarrativeDocumentRequired) {
      if (this.documents.filter(x => +x.documentTypeId === DocumentTypes.PoliceNarrative && x.forcePrimaryDocument).length === 0) // If no "primary document" police narrative exists
      {
        let policeNarrativeDoc = this.documents.find(x => +x.documentTypeId === DocumentTypes.PoliceNarrative);
        if (policeNarrativeDoc) {
          policeNarrativeDoc.forcePrimaryDocument = true; // Prevent the user from editing the placeholder
        }
      }
    }

    //Loop through all contraventions and create document placeholders
    this.contraventions.forEach(contravention => {
      //Check if NAP document exists 
      if (!this.isLegacySubmission && !this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.NAPDocument && x.documentReferenceNumber == contravention.contraventionNumber).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.NAPDocument);
        document.documentTypeId = documentType.id;
        document.documentReferenceNumber = contravention.contraventionNumber;
        
        this.documents.push(document);
      }

      //Check if original paper contravention document exists (only phase 1 submissions)
      if (this.isLegacySubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.OriginalPaperContravention).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.OriginalPaperContravention);
        document.documentTypeId = documentType.id;
        this.documents.push(document);
      }

      if (irsContraventionTypeIds.includes(+contravention.contraventionTypeId)) // IRS Contravention
      {
        if (!contravention.isNoVehicleSeizureMade)
        {
          //Check if original vehicle seizure document exists (only phase 1 submissions)
          if (this.isLegacySubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.OriginalPaperSeizure).length == 0))
          {
            var document = new Document()
            var documentType = this.documentTypes.find(x => x.id == DocumentTypes.OriginalPaperSeizure);
            document.documentTypeId = documentType.id;
            this.documents.push(document);
          }

          //Check if all Seizure Notice documents exist (phase 2 submissions)
          if (!this.isLegacySubmission && !this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticePoliceCopy && x.documentReferenceNumber == contravention.vehicleSeizure.seizureNumber).length == 0))
          {
            var document = new Document()
            var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticePoliceCopy);
            document.documentTypeId = documentType.id;
            document.documentReferenceNumber = contravention.vehicleSeizure.seizureNumber;

            this.documents.push(document);
          }
          if (!this.isLegacySubmission && !this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticeDriverCopy && x.documentReferenceNumber == contravention.vehicleSeizure.seizureNumber).length == 0))
          {
            var document = new Document()
            var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticeDriverCopy);
            document.documentTypeId = documentType.id;
            document.documentReferenceNumber = contravention.vehicleSeizure.seizureNumber;

            this.documents.push(document);
          }
          if (!this.isLegacySubmission && !this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticeRegisteredOwnerCopy && x.documentReferenceNumber == contravention.vehicleSeizure.seizureNumber).length == 0))
          {
            var document = new Document()
            var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticeRegisteredOwnerCopy);
            document.documentTypeId = documentType.id;
            document.documentReferenceNumber = contravention.vehicleSeizure.seizureNumber;

            this.documents.push(document);
          }
          if (!this.isLegacySubmission && !this.stopInformation.isEmergencySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticeTowLotCopy && x.documentReferenceNumber == contravention.vehicleSeizure.seizureNumber).length == 0))
          {
            var document = new Document()
            var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticeTowLotCopy);
            document.documentTypeId = documentType.id;
            document.documentReferenceNumber = contravention.vehicleSeizure.seizureNumber;

            this.documents.push(document);
          }
        }

        //Check if Driver Requested a Roadside Appeal or not
        if (contravention.roadsideAppeal?.hasDriverRequestedRoadsideAppeal)
        {
          //Check if Roadside Appeal document exists
          if(this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.RoadsideAppeal).length == 0)
          { 
            var document = new Document()
            var documentType = this.documentTypes.find(x => x.id == DocumentTypes.RoadsideAppeal);
            document.documentReferenceNumber = contravention.contraventionNumber;
            document.documentTypeId = documentType.id;
            document.documentDescription = "First tear-off page of notice, dated and signed";
            document.isOptional = true;
            this.documents.push(document);
          }

          if (this.documents.filter(x => +x.documentTypeId === DocumentTypes.RoadsideAppeal && x.forcePrimaryDocument).length === 0) // If no "primary document" roadside appeal exists
          {
            let roadsideAppealDoc = this.documents.find(x => +x.documentTypeId === DocumentTypes.RoadsideAppeal);
            if (roadsideAppealDoc) {
              roadsideAppealDoc.forcePrimaryDocument = true; // Prevent the user from editing the placeholder
            }
          }
        }

        // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
        //
        var detailsSelected = contravention?.contraventionDetails?.split(",");
        var totalDetailsSelected = detailsSelected?.length;
        
        var isRefusal = (
            (contravention.contraventionTypeId == ContraventionTypes.IRSFail1st && 
              (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
            (contravention.contraventionTypeId == ContraventionTypes.IRSFail2nd && 
              (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
            (contravention.contraventionTypeId == ContraventionTypes.IRSFail3rd && 
              (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
        )

        if (contravention.contraventionTypeId != ContraventionTypes.IRS24 && !isRefusal)
          this.additionalDocumentTypes.push(this.localStorageService.getDocumentTypes().find(x => +x.id == DocumentTypes.RoadsideAppeal));

        //Check if AI Third test is required or not
        this.isAIThirdTestVisible = new Date(contravention.submissionDate) >= new Date(+Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.AI_3RD_TEST_EFFECTIVE_DATE.substring(8, 10))

        //Check if new DRE Process is applicable or not
        this.isNewDREProcess = new Date(contravention.submissionDate) >= new Date(+Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(0, 4), +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Intake.DRE_PROCESS_CHANGE_EFFECTIVE_DATE.substring(8, 10))

        //Check if any screening method was used
        if (contravention.contraventionGroundsTypeId == 1 && contravention.impairmentScreeningInformation != null)
        {
          //Check if any impairment screening test was performed
          if(contravention.impairmentScreeningInformation.impairmentTests != null && contravention.impairmentScreeningInformation.impairmentTests.filter(x => x.testSequence == 0 && ["1","2","3","4","5","6","9"].includes(x.testTypeId.toString())).length > 0)
          {
            //Check if all the required documents exist for that specific test 
            this.CheckAndAddDocuments(contravention, contravention.impairmentScreeningInformation.impairmentTests.find(x => x.testSequence == 0), "Impairment Screening Test");
          }
        }

        //Check if any additional screening test was performed
        if(contravention.impairmentScreeningInformation?.impairmentTests != null && contravention.impairmentScreeningInformation.impairmentTests.filter(x => x.testSequence != 0).length > 0)
        {
          contravention.impairmentScreeningInformation.impairmentTests.filter(x => x.testSequence != 0).forEach((impairmentTest, index) => {
            //Check if all the required documents exist for that specific test 
            this.CheckAndAddDocuments(contravention, impairmentTest, "Additional Screening Test " + impairmentTest.testSequence);
          });
        }
        
        //Check if any screening test was performed for roadside appeal
        if(contravention.roadsideAppeal?.roadsideTests != null && contravention.roadsideAppeal.roadsideTests.filter(x => ["1","2","3","4","5","6","9"].includes(x.testTypeId.toString())).length > 0)
        {
          //Check if all the required documents exist for that specific test 
          this.CheckAndAddDocuments(contravention, contravention.roadsideAppeal.roadsideTests.find(x => ["1","2","3","4","5","6","9"].includes(x.testTypeId.toString())), "Roadside Appeal Screening Test", true);
        }

      //Check if BodycamVideo document is required
      if (this.stopInformation.hasBodycamVideo) {
        if (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.BodycamVideo).length == 0)
        {
          var document = new Document();
          var documentType = this.documentTypes.find(x => x.id == DocumentTypes.BodycamVideo);
          document.documentTypeId = documentType.id;
          this.documents.push(document);
        }
      }

        //Check if any additional document if added
        //var additionalDocTypes: number[] = this.localStorageService.getDocumentTypes().filter(x => x.isSupplemental).map(type => type.id); 

        // if(contravention.documents != null && contravention.documents.filter(x => additionalDocTypes.includes(x.documentTypeId)).length > 0)
        // {
        //   var additionaldocuments = contravention.documents.filter(x => additionalDocTypes.includes(x.documentTypeId));
        //   this.documents = this.documents.concat(additionaldocuments);
        // }
        
        //this.initialDocumentsCount = this.documents.filter(x => x.documentName != null && x.documentName.length > 0 ).length;
      }
      else if (speedingContraventionTypeIds.includes(+(contravention.tertiaryContraventionTypeId??(contravention.secondaryContraventionTypeId??contravention.contraventionTypeId))))
      {
        //Check is Speedometer Certificate document is required or not
        // if(!this.isLegacySubmission && !contravention.speedingTicket.isPacing && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SpeedometerCertificate && x.documentReferenceNumber == contravention.contraventionNumber).length == 0))
        // {
        //   var document = new Document()
        //   var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SpeedometerCertificate);
        //   document.documentTypeId = documentType.id;
        //   document.documentReferenceNumber = contravention.contraventionNumber;
        //   this.documents.push(document);
        // }
        
        //Check is Aircraft Certificate document is required or not
        if(!this.isLegacySubmission && contravention.speedingTicket.isAircraft && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.AircraftCertificate && x.documentReferenceNumber == contravention.contraventionNumber).length == 0))
        {
          var document = new Document()
          var documentType = this.documentTypes.find(x => x.id == DocumentTypes.AircraftCertificate);
          document.documentTypeId = documentType.id;
          document.documentReferenceNumber = contravention.contraventionNumber;
          this.documents.push(document);
        }

        //Check is Operator Report document is required or not
        if(!this.isLegacySubmission && contravention.speedingTicket.speedingDeviceTypeId != null && contravention.speedingTicket.isUploadOperatorReport && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.OperatorsReport && x.documentReferenceNumber == contravention.contraventionNumber).length == 0))
        {
          var document = new Document()
          var documentType = this.documentTypes.find(x => x.id == DocumentTypes.OperatorsReport);
          document.documentTypeId = documentType.id;
          document.documentReferenceNumber = contravention.contraventionNumber;
          this.documents.push(document);
        }
      }
    });

    if (this.documents.find(x=> x.documentTypeId == DocumentTypes.RoadsideAppeal))
      this.documents.find(x=> x.documentTypeId == DocumentTypes.RoadsideAppeal).isOptional = true;

    //Loop through all seizures and create document placeholders
    this.vehicleSeizures.forEach(seizure => {
      //Check if original vehicle seizure document exists (only phase 1 submissions)
      if (this.isLegacySubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.OriginalPaperSeizure).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.OriginalPaperSeizure);
        document.documentTypeId = documentType.id;
        this.documents.push(document);
      }

      //Check if all Seizure Notice documents exist (phase 2 submissions)
      if (!this.isLegacySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticePoliceCopy && x.documentReferenceNumber == seizure.seizureNumber).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticePoliceCopy);
        document.documentTypeId = documentType.id;
        document.documentReferenceNumber = seizure.seizureNumber;

        this.documents.push(document);
      }
      if (!this.isLegacySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticeDriverCopy && x.documentReferenceNumber == seizure.seizureNumber).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticeDriverCopy);
        document.documentTypeId = documentType.id;
        document.documentReferenceNumber = seizure.seizureNumber;

        this.documents.push(document);
      }
      if (!this.isLegacySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticeRegisteredOwnerCopy && x.documentReferenceNumber == seizure.seizureNumber).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticeRegisteredOwnerCopy);
        document.documentTypeId = documentType.id;
        document.documentReferenceNumber = seizure.seizureNumber;

        this.documents.push(document);
      }
      if (!this.isLegacySubmission && !this.stopInformation.isPaperSubmission && (this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.SeizureNoticeTowLotCopy && x.documentReferenceNumber == seizure.seizureNumber).length == 0))
      {
        var document = new Document()
        var documentType = this.documentTypes.find(x => x.id == DocumentTypes.SeizureNoticeTowLotCopy);
        document.documentTypeId = documentType.id;
        document.documentReferenceNumber = seizure.seizureNumber;

        this.documents.push(document);
      }
    });

    // Check if additional documents can be uploaded
    if (this.stopInformation.isLEASubmissionDeadlinePassed) {
      this.isAdditionalDocumentAllowed = false;
      this.isUploadPeriodExpired = true;
    }

    if(!this.stopInformation.userIsIssuingOfficer) {
      var document = new Document()
      var documentType = this.documentTypes.find(x => x.id == DocumentTypes.DeclarationForm);
      document.documentTypeId = documentType.id;

      this.documents.push(document);
    }

    var pictureOfDeviceDocuments = this.documents.filter(x => x.documentTypeId == DocumentTypes.PictureOfDevice && x.uploadedDate == null);

    pictureOfDeviceDocuments.forEach(document => {
      document.documentTypeId = DocumentTypes.PictureOfDeviceV2;
    });

    this.documentsTracker = this.documents.filter(doc => doc.documentId > 0 && doc.uploadedDate != null).map(a => Object.assign({}, a));

    this.documentsLogger = this.documents.filter(a => Object.assign({}, a));

    //Initialize file upload properties
    this.documents.forEach(document => {
      this.initializeFileUploadProperies(document);
    });

    //scroll to top with tab change
    window.scroll(0,0);
  }

  hasChanges() {
    // The check condition should only happen if the APIS status is submitted
    if (this.stopInformation.apisStatus == "Submitted") {
      if (this.stopInformation.isDetailSubmitted && this.documents.every(doc => doc.documentId > 0 && doc.contentGuid?.length > 0)) {
        return this.documents.length != this.documentsTracker.length ||
          this.documents.some((val, index) => val.documentName != this.documentsTracker[index].documentName
          );
      }
      else {
        return true;
      }
    }

    else if (!this.stopInformation.userIsAddingDetails) {
      if (this.documents.length != this.documentsTracker.length) {
        if (this.documents.filter(doc => doc.documentId == 0 || doc.uploadedDate == null).some(val => val.isSubmitLater != true)) {
          return true;
        }
        else {
          return false;
        }
      }
    }
    else {
      return true;
    }
  }

  hasChangesDeclaration() {
    if (this.isLegacySubmission) {
      return false;
    }

    if (this.stopInformation.userIsAddingDetails) {
      if(this.documents.length != this.documentsTracker.length) {
        if (this.documents.filter(doc => doc.documentId == 0 || doc.uploadedDate == null).some(val => val.isSubmitLater != true)) {
          return true;
        }
        else {
          return false;
        }
      }
    }

    else {    
      return true;
    }
  }
  
  PrintSubmission()
  {
    var documentTitle = document.title;
    document.title = 'Review & Confirm screen printout';
    window.print();
    document.title = documentTitle;
  }

  initializeFileUploadProperies(document: Document) {
    document.documentNumber = Guid.create().toString();
    document.tempFileFolder = this.stopInformation.tempFileFolder;
    //document.isReadOnly = false;
    document.documentRefTypeName = this.isLegacySubmission? this.documentRefTypeName :"Stop";
    document.documentRefTypeNumber = this.isLegacySubmission? this.documentRefTypeNumber : this.stopInformation.stopInformationId.toString();
    document.additionalDocumentsCategory = AdditionalDocumentCategory.Intake;
    document.isUploadPeriodExpired = this.isUploadPeriodExpired;

    // JTI-6176: If LEA submissions are enabled by a re-review, intake users are only allowed to upload documents related to the item under re-review.
    // Do not enable uploading to other document placeholders in the stop.
    if (!this.isLegacySubmission
      && document.documentReferenceNumber
      && this.stopInformation.reReviewDocumentReferenceNumber
      && document.documentReferenceNumber != this.stopInformation.reReviewDocumentReferenceNumber
    ) {
      document.isUploadPeriodExpired = true;
    }

    // assume that all witness statements previous uploaded were confirmed as redacted, since, they cannot be uploaded
    // unless the user confirms as such
    if (document.documentTypeId == DocumentTypes.WitnessStatements) {
      document.isWitnessStatmentRedacted = true;
    }
  }

  trackDocument(index: number, obj: any): any {
    return obj.documentNumber;
  }

  AddOptionalDocument() {
    var document = new Document()
    document.documentTypeId = null;
    document.tempFileFolder = this.stopInformation.tempFileFolder;

    if (this.isLegacySubmission)
    {
      if (this.documentRefTypeName == "Contraventions")
        document.contraventionId = this.documentRefTypeId;
      else if (this.documentRefTypeName == "VehicleSeizures")
        document.vehicleSeizureId = this.documentRefTypeId;
    }
    else
      document.stopInformationId = this.stopInformation.stopInformationId;

    this.documents.push(document);
  }
  onCancelDcoument(document: Document)
  {
    this.documents.splice(this.documents.findIndex(x => x==document), 1)
  }

  onUploadDocument(document: Document)
  {
    var cleanedDocumentName = document.documentName.replace(/(...)(\.{2,})/g, '$1.');
    document.documentName = cleanedDocumentName;

    // JTI-6176: If LEA submissions are enabled by a re-review, add a document reference number.
    // Intake users are only allowed to upload documents to the item under re-review, not all of the items in the stop.
    if (!this.isLegacySubmission
      && !document.documentReferenceNumber
      && this.stopInformation.reReviewDocumentReferenceNumber
    ) {
      document.documentReferenceNumber = this.stopInformation.reReviewDocumentReferenceNumber;
    }

    // Save document state in localStorage, in case the user refreshes the page
    this.intakeService.saveStopInformationContext();
  }

  onDeleteDocument()
  {
    // Save document state in localStorage, in case the user refreshes the page
    this.intakeService.saveStopInformationContext();
  }

  CheckAndAddDocuments(contravention: Contravention, impairmentTest: ContraventionTest, testCategory: string, isRoadsideAppeal: boolean = false)
  {
    var documentTypes: number[] = [];

    if (impairmentTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No) // Only add all document if driver has not refused to test
    {
      switch (impairmentTest.testTypeId.toString())
      {
        case "1": //SFST
          documentTypes = [3,4];
          break;
        case "2": //ASD
          documentTypes = [79];
          break;
        case "3": //ADSE
          if (this.stopInformation.userIsAddingDetails) { // If details have previously been submitted, don't add new ADSE placeholders (should this apply to other document types too?)
            documentTypes = [6,119];
          }
          break;
        case "4": //DRE
          if (this.isNewDREProcess && !isRoadsideAppeal)
          {
            if (impairmentTest.drugRecognitionEvaluationTest.isBreathDemandMade) 
              documentTypes = [7,8,9,10,11,12,13,14,15,16,17,impairmentTest.drugRecognitionEvaluationTest.sampleClassificationId == SampleClassification.Blood?DocumentTypes.LaboratoryResults:DocumentTypes.UrineLaboratoryResults];  
            else
              documentTypes = [7,8,9,impairmentTest.drugRecognitionEvaluationTest.sampleClassificationId == SampleClassification.Blood?DocumentTypes.LaboratoryResults:DocumentTypes.UrineLaboratoryResults];    
          }
          else
          {
            documentTypes = [7,8,9];
          }
          
          break;
        case "5": //AI
          if (!this.isAIThirdTestVisible || (!(impairmentTest.approvedInstrumentTest.thirdEvidentiaryTestResult || impairmentTest.approvedInstrumentTest.thirdBreathDemandTime) && !isRoadsideAppeal))
            documentTypes = [10,11,12,13,14,15,16,17,18];
          else
            documentTypes = [10,11,12,13,14,15,16,17];  
          break;
        case "6": //Blood Test
          documentTypes = [19];
          break;
        case "9": //Urine Test
          documentTypes = [48];
          break;
      }
    }
    else if (impairmentTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply) // Only add some documents if driver failed to comply to test
    {
      switch (impairmentTest.testTypeId.toString())
      {
        case "2": //ASD
          documentTypes = [79];
          break;
        case "3": //ADSE
          if (this.stopInformation.userIsAddingDetails) { // If details have previously been submitted, don't add new ADSE placeholders (should this apply to other document types too?)
            documentTypes = [6,119];
          }
          break;
        case "4": //DRE
          if (this.isNewDREProcess && !isRoadsideAppeal)
          {
            if (impairmentTest.drugRecognitionEvaluationTest.isBreathDemandMade)
              documentTypes = [10,11,12,13,14,15,16,17];  
            
            if (impairmentTest.drugRecognitionEvaluationTest?.refusalOrFailedClassificationId != RefusalOrFailedClassification.BreathSample)
              documentTypes = documentTypes.concat([7,8,9]);
          }
          else
            documentTypes = [7,8,9];

          break;
        case "5": //AI
          documentTypes = [10,11,12,13,14,15,16];
          break;
      }
    }
    else if (impairmentTest.testTypeId == TestTypes.DRE && this.isNewDREProcess && !isRoadsideAppeal && impairmentTest.drugRecognitionEvaluationTest?.refusalOrFailedClassificationId != RefusalOrFailedClassification.BreathSample) // Straingt Refusal
    {
      if (impairmentTest.drugRecognitionEvaluationTest.isBreathDemandMade)
        documentTypes = [10,11,12,13,14,15,16,17];

      if (impairmentTest.drugRecognitionEvaluationTest?.refusalOrFailedClassificationId == RefusalOrFailedClassification.ToxicologicalSample)
        documentTypes = documentTypes.concat([7,8,9]);
    }

    //Manage Blood Results separately as there could be 4 documents for each blood test
    if (impairmentTest.testTypeId == TestTypes.Blood && this.isNewDREProcess && !isRoadsideAppeal)
    {
      //Blood Demand
      if ((this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.LaboratoryResults && x.documentDescription == `${testCategory} - Blood Demand`).length == 0) && impairmentTest.bloodSampleTest.isBloodDemand)
      {
        if (impairmentTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No) { // Only add document if driver has not refused/failed to comply
          var document = new Document()
          document.documentTypeId = DocumentTypes.LaboratoryResults;
          document.documentDescription = `${testCategory} - Blood Demand`;
          document.testTypeId = impairmentTest.testTypeId;
          document.documentReferenceNumber = this.isLegacySubmission? null: contravention.contraventionNumber;
          this.documents.push(document);
        }
      }

      //Blood Warrant
      if ((this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.LaboratoryResults && x.documentDescription == `${testCategory} - Blood Warrant`).length == 0) && impairmentTest.bloodSampleTest.isBloodWarrant)
      {
        var document = new Document()
        document.documentTypeId = DocumentTypes.LaboratoryResults;
        document.documentDescription = `${testCategory} - Blood Warrant`;
        document.testTypeId = impairmentTest.testTypeId;
        document.documentReferenceNumber = this.isLegacySubmission? null: contravention.contraventionNumber;
        this.documents.push(document);
      }

      //Search Warrant
      if ((this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.LaboratoryResults && x.documentDescription == `${testCategory} - Search Warrant`).length == 0) && impairmentTest.bloodSampleTest.isSearchWarrant)
      {
        var document = new Document()
        document.documentTypeId = DocumentTypes.LaboratoryResults;
        document.documentDescription = `${testCategory} - Search Warrant`;
        document.testTypeId = impairmentTest.testTypeId;
        document.documentReferenceNumber = this.isLegacySubmission? null: contravention.contraventionNumber;
        this.documents.push(document);
      }

      //Hospital Records
      if ((this.documents == null || this.documents.filter(x => x.documentTypeId == DocumentTypes.HospitalRecords && x.documentDescription == testCategory).length == 0) && impairmentTest.bloodSampleTest.isHospitalRecord)
      {
        var document = new Document()
        document.documentTypeId = DocumentTypes.HospitalRecords;
        document.documentDescription = testCategory;
        document.testTypeId = impairmentTest.testTypeId;
        document.documentReferenceNumber = this.isLegacySubmission? null: contravention.contraventionNumber;
        this.documents.push(document);
      }
    }
    else
    {
      if (this.documents.filter(x => x.documentTypeId == DocumentTypes.PictureOfDevice && x.documentDescription == testCategory).length > 0) {
        documentTypes.splice(documentTypes.findIndex(x => x == 79), 1);
      }

      documentTypes.forEach(documentType => {
        if(this.documents == null || this.documents.filter(x => x.documentTypeId == documentType && x.documentDescription == testCategory).length == 0)
        {
          var document = new Document()
          document.documentTypeId = documentType;
          document.documentDescription = testCategory;
          document.testTypeId = impairmentTest.testTypeId;
          document.documentReferenceNumber = this.isLegacySubmission? null: contravention.contraventionNumber;
          this.documents.push(document);
        }
      });
    }
  }

  onSubmitClick()
  {
    this.isSubmitClicked = true;

    if (this.hasChangesDeclaration()) {
      if(!this.isTrueInformation) {
        return;
      }
    }

    this.stopInformation.hasChangesDeclaration = this.hasChangesDeclaration();

    if(this.validateDocuments()) {
      this.intakeService.saveStopInformationContext();
      this.router.navigateByUrl('/contravention/add-details/review-confirm');     
    } 
  }

  validateDocuments(): boolean {
    //Check if any document is not uploaded
    if (this.documents == null || this.documents.length == 0) 
      return false;
    
    if (this.documents.filter(x=> (x.documentName == null || x.documentName.trim().length == 0) && !x.isSubmitLater).length > 0) 
    {   
      this.errorMessage = "All documents are required";
      return false;
    }

    // JTI-3434: Temporarily disable this check until the legal team determines how to deal with this
    //
    // if (this.documents.filter(x=> (x.documentTypeId == DocumentTypes.WitnessStatements && !x.isWitnessStatmentRedacted)).length > 0) 
    // {   
    //   this.errorMessage = "All witness statements must be confirmed as redacted";
    //   return false;
    // }

    this.errorMessage = "";
    return true;
  }

  showDropZone(): void {
    this.documentName = "";
    this.selectedFile = null;
    this.dropZone.nativeElement.classList.add("show");      
  }

  getDocumentSize(): string {
    if (this.selectedFile == null) {
      return "";
    }

    let size = this.selectedFile.size;

    if (size < 1024) {
      return `${size} bytes`;
    }
      
    if (size >= 1024 && size < 1048576) {
      return `${(size/1024).toFixed(2)}kb`;
    }
      
    if (size >= 1048576 && size < 1073741824) {
      return `${((size/1024)/1024).toFixed(2)}mb`;
    }
          
    if (size >= 1073741824) {
      return `${(((size/1024)/1024)/1024).toFixed(2)}gb`;  
    }      
  }
}
