<div class="dbContainer d-flex flex-column h-100 mt-3 mobile">
    <div class="container" >
        <div class="row">
            <div class="col-12"><h1>{{availableTSA?'Dashboard':'Contravention & Seizure Dashboard'}}</h1></div>
            <div class="col-12 mt-3">
                <app-access-control [Resource]="Resource.POLICE_INTAKE_SUBMISSION" [Permission]="Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES">
                    <button class="btn button-secondary btn-block" (click)="onSubmitContraventionClick()">New Contravention</button>
                </app-access-control>
            </div>
        </div>
        <div class="error">
            {{newContraventionError}}
        </div>
    </div>
</div>
<div class="dbContainer d-flex flex-column h-100 mt-3 desktop">
    <form #dashboardInfoForm="ngForm">
        <div class="dashboard-container" >
            <div class="row">
                <div class="col-9">
                    <h1>{{availableTSA?'Dashboard':'Contravention & Seizure Dashboard'}}</h1>
                </div>
                <div align="end" class="col-3">
                    <app-access-control [Resource]="Resource.POLICE_INTAKE_SUBMISSION" [Permission]="Permission.SUBMIT_CONTRAVENTIONS_AND_SEIZURES">
                        <button class="btn button-secondary btn-block" (click)="onSubmitContraventionClick()">New Contravention</button>
                    </app-access-control>
                </div>
            </div>
            <div align="end" class="error mb-3">
                {{newContraventionError}}
            </div>

            <div class="row" *ngIf="availableTSA">
                <div class="col-12 mt-3">
                    <div class="tabs-container">            
                        <div class="tabs">
                            <div class="tab-header" (click)="onTabHeaderClick($event)">
                                <div id="tabHeader_ContraventionsAndSeizures" class="tab-header-item selected">IRS/Seizure Notices</div>
                                <div id="tabHeader_Violations" class="tab-header-item">Traffic Tickets</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isMobile() && ticketTypeId == TicketTypes.ContraventionsAndSeizures" id="searchElement">
                <div class="mb-3 mt-4">
                    <div class="row">
                        <div class="col-sm">

                            <label for="contraventionDetailsMultiselect">APIS Status </label>
                            <mat-select #contraventionDetailsMultiControl="ngModel"  
                                class="form-control-multiselect form-select" 
                                multiple 
                                id="contraventionDetailsMultiselect" 
                                name="contraventionDetailsMultiselect" 
                                (selectionChange)="onFiltersChanged($event)"
                                placeholder="Select the Contravention Details" 
                                [(ngModel)]="selectedContraventionSeizureStatus"
                                [ngClass]="{'is-invalid':(contraventionDetailsMultiControl.touched || isSubmitClicked) && contraventionDetailsMultiControl.invalid}">
                                <mat-option class="mat-primary mat-option" *ngFor="let contraventionSeizureStatus of contraventionSeizureStatuses" [value]="contraventionSeizureStatus">{{contraventionSeizureStatus}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-sm">
                            <label for="issuingOfficerRegimentalNumber">Issuing Officer Regimental Number</label>
                            <input type="text" maxlength="40" [(ngModel)]="issuingOfficerRegimentalNumber" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="issuingOfficerRegimentalNumber" name="issuingOfficerRegimentalNumber" placeholder="Issuing Officer Regimental Number">
                        </div>
                        <div class="col-sm">
                            <label for="recipientName">Recipient Name</label>
                            <input type="text" maxlength="40" [(ngModel)]="recipientName" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="recipientName" name="recipientName" placeholder="Recipient Name">
                        </div>                    
                        <div class="col-sm">

                            <label for="safeRoadsMultiselect">SafeRoads Status </label>
                            <mat-select #safeRoadsStatusMultiControl="ngModel"  
                                class="form-control-multiselect form-select" 
                                multiple 
                                id="safeRoadsMultiselect" 
                                name="safeRoadsMultiselect" 
                                (selectionChange)="onFiltersChanged($event)"
                                placeholder="Select the SafeRoads Statuses" 
                                [(ngModel)]="selectedSafeRoadsStatuses" 
                                [ngClass]="{'is-invalid':(safeRoadsStatusMultiControl.touched || isSubmitClicked) && safeRoadsStatusMultiControl.invalid}">
                                <mat-option class="mat-primary mat-option" *ngFor="let safeRoadsStatus of safeRoadsStatuses" [value]="safeRoadsStatus">{{safeRoadsStatus}}</mat-option>
                            </mat-select>
                        </div>
                    </div>      
                    <div class="row mt-4">
                        <div class="col-sm-3">
                            <label class="police-filenumber-column" for="policeFileNumber">Police File Number</label>
                            <input type="text" maxlength="20" [(ngModel)]="policeFileNumber" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control police-filenumber-column" id="policeFileNumber" name="policeFileNumber" placeholder="Police File Number">
                        </div>
                        <div class="col-sm-2">
                            <label for="noticeNumber">Notice Number</label>
                            <input type="text" maxlength="20" [(ngModel)]="noticeNumber" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="noticeNumber" name="noticeNumber" placeholder="Notice Number">
                        </div>                
                        <div class="col-sm-2">
                            <label for="filterStartDate">From </label>
                            <input isBasicDate #filterStartDateControl="ngModel" type="text" bsDatepicker 
                                [bsConfig]="datePickerConfig" 
                                maxlength="10" 
                                [(ngModel)]="filterStartDate" 
                                class="form-control" 
                                id="filterStartDate" 
                                name="filterStartDate" 
                                (keyup)="onFiltersChanged($event)"
                                (ngModelChange)="onFiltersChanged($event)"
                                placeholder="yyyy/mm/dd" 
                                [ngClass]="{'is-invalid':(filterStartDateControl.touched || isSubmitClicked) && filterStartDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="!filterStartDateControl.errors?.required && filterStartDateControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!filterStartDateControl.errors?.required && !filterStartDateControl.hasError('InvalidYear')">Invalid Date</div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <label for="filterEndDate">To </label>
                            <input isBasicDate #filterEndDateControl="ngModel" type="text" bsDatepicker 
                                [bsConfig]="datePickerConfig" 
                                maxlength="10" 
                                [(ngModel)]="filterEndDate" 
                                class="form-control" 
                                id="filterEndDate" 
                                name="filterEndDate" 
                                (keyup)="onFiltersChanged($event)"
                                (ngModelChange)="onFiltersChanged($event)"
                                placeholder="yyyy/mm/dd" 
                                [ngClass]="{'is-invalid':(filterEndDateControl.touched || isSubmitClicked) && filterEndDateControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="!filterEndDateControl.errors?.required && filterEndDateControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!filterEndDateControl.errors?.required && !filterEndDateControl.hasError('InvalidYear')">Invalid Date</div>
                            </div>
                        </div>

                        <div class="col-sm" style="margin-top: auto;">                
                            <button class="btn button-primary btn-block"
                            (click)="onSearchButtonClick()">Search</button>   
                        </div>
                        <div *ngIf="isResetEnabled" class="col-sm clear-filters" style="margin-top: auto;" (click)="resetFiltersClick($event)">
                            Reset filters
                        </div> 
                        <div *ngIf="!isResetEnabled" class="col-sm clear-filters" style="margin-top: auto;">
                            &nbsp;
                        </div> 
                    </div>          
                </div>
                <div  *ngIf="itemsSelected() > 0" class="row">
                    <app-access-control [Resource]="Resource.POLICE_INTAKE_DASHBOARD" [Permission]="Permission.CANCEL_MULTIPLE_NOTICES">
                        <div class="col-12">
                            <button type="button" [disabled]="getEligibleSelections() < 2 || getSelectedCancelledNapsCount() > 0" class="btn button-secondary btn-block" (click)="onCancelMultipleNoticesClick()">Cancel Multiple Notices</button>
                        </div>
                    </app-access-control>
                </div>
            </div>
            <div *ngIf="!isMobile() && ticketTypeId == TicketTypes.Violations">
                <form #ticketSearchForm="ngForm">
                    <div class="mb-3 row mt-4">
                        <div class="col-sm-2">
                            <label for="violationTicketNumber">Ticket Number</label>
                            <input type="text" [(ngModel)]="violationTicketNumber" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="violationTicketNumber" name="violationTicketNumber" placeholder="e.g. B12345678Q">
                        </div>
                        <div class="col-sm-2">
                            <label for="accusedFirstName">Accused First Name</label>
                            <input type="text" [(ngModel)]="accusedFirstName" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="accusedFirstName" name="accusedFirstName" placeholder="First name">
                        </div>
                        <div class="col-sm-2">
                            <label for="accusedLastName">Accused Last Name</label>
                            <input type="text" [(ngModel)]="accusedLastName" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="accusedLastName" name="accusedLastName" placeholder="Last name">
                        </div>
                        <div class="col-sm-2">
                            <label for="licencePlate">Licence Plate</label>
                            <input type="text" [(ngModel)]="licencePlate" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="licencePlate" name="licencePlate" placeholder="Licence plate">
                        </div>
                        <div class="col-sm-2">
                            <label for="location">Location</label>
                            <input type="text" [(ngModel)]="location" [matAutocomplete]="autoLocations" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="location" name="location" [formControl]="locationControl" placeholder="Location">
                            <mat-autocomplete #autoLocations="matAutocomplete" [displayWith]="displayLocation">
                                <mat-option *ngFor="let locationType of filteredLocations | async" [value]="locationType">
                                    {{locationType.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error class="validation-message" *ngIf="(locationControl.touched || isSubmitClicked) && location != null && location != '' && locationControl.hasError('forbiddenLocations')">
                                Invalid Location
                            </mat-error>
                        </div>  
                        <div class="col-sm-2">
                            <label for="ticketIssuingOfficer">Issuing Officer</label>
                            <input type="text" [(ngModel)]="officerBadgeNumber" (keydown)="handleKeyboardEvent($event)" (keyup)="onFiltersChanged($event)" class="form-control" id="ticketIssuingOfficer" name="ticketIssuingOfficer" placeholder="Search by badge #">
                        </div>      
                         
                    </div>     
                    <div class="mb-3 row mt-4">
                        <div class="col-sm-2">
                            <label for="offenceDateFrom">Offence Date From</label>
                            <input isBasicDate #filterOffenceDateFromControl="ngModel" type="text" bsDatepicker 
                                [bsConfig]="datePickerConfig" 
                                maxlength="10" 
                                [(ngModel)]="filterOffenceDateFrom" 
                                class="form-control" 
                                id="filterOffenceDateFrom" 
                                name="filterOffenceDateFrom" 
                                (keyup)="onFiltersChanged($event)"
                                (ngModelChange)="onFiltersChanged($event)"
                                placeholder="yyyy/mm/dd" 
                                [ngClass]="{'is-invalid':(filterOffenceDateFromControl.touched || isSubmitClicked) && filterOffenceDateFromControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="!filterOffenceDateFromControl.errors?.required && filterOffenceDateFromControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!filterOffenceDateFromControl.errors?.required && !filterOffenceDateFromControl.hasError('InvalidYear')">Invalid Date</div>
                            </div>
                        </div> 
                        <div class="col-sm-2">
                            <label for="offenceDateTo">Offence Date To</label>
                            <input isBasicDate isLessThanStartDate="{{filterOffenceDateFrom | date:'yyyy-MM-dd'}}"
                                #filterOffenceDateToControl="ngModel" type="text" bsDatepicker 
                                [bsConfig]="datePickerConfig" 
                                maxlength="10" 
                                [(ngModel)]="filterOffenceDateTo" 
                                class="form-control" 
                                id="filterOffenceDateTo" 
                                name="filterOffenceDateTo" 
                                (keyup)="onFiltersChanged($event)"
                                (ngModelChange)="onFiltersChanged($event)"
                                placeholder="yyyy/mm/dd" 
                                [ngClass]="{'is-invalid':(filterOffenceDateToControl.touched || isSubmitClicked) && filterOffenceDateToControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="!filterOffenceDateToControl.errors?.required && filterOffenceDateToControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!filterOffenceDateToControl.errors?.required && !filterOffenceDateToControl.hasError('InvalidYear') && filterOffenceDateToControl.hasError('EarlierDate')">Offence Date To can't be earlier than the Offence Date From</div>
                                <div *ngIf="!filterOffenceDateToControl.errors?.required && !filterOffenceDateToControl.hasError('InvalidYear') && !filterOffenceDateToControl.hasError('EarlierDate')">Invalid Date</div>
                            </div>
                        </div> 
                        <div class="col-sm-2">
                            <label for="trialScheduled">Trial Scheduled</label>
                            <select #trialScheduledControl="ngModel" class="form-select" id="trialScheduled" name="trialScheduled" 
                            [(ngModel)]="trialSetIndicator" (keydown)="handleKeyboardEvent($event)" (keyup)="onTrialScheduledChanged()" (change)="onTrialScheduledChanged()">
                                <option value="">All Tickets</option>
                                <option value="N">No Trial Set</option>
                                <option value="Y">Trial Set</option>
                            </select>
                        </div>       
                        <div class="col-sm-2">
                            <label for="trialDateFrom">Trial Date From</label>
                            <input isBasicDate #filterTrialDateFromControl="ngModel" type="text" bsDatepicker 
                                [bsConfig]="datePickerConfig" 
                                maxlength="10" 
                                [(ngModel)]="filterTrialDateFrom" 
                                class="form-control" 
                                id="filterTrialDateFrom" 
                                name="filterTrialDateFrom" 
                                (keyup)="onFiltersChanged($event)"
                                (ngModelChange)="onFiltersChanged($event)"
                                placeholder="yyyy/mm/dd" 
                                [disabled]="trialSetIndicator=='N'"
                                [ngClass]="{'is-invalid':(filterTrialDateFromControl.touched || isSubmitClicked) && filterTrialDateFromControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="!filterTrialDateFromControl.errors?.required && filterTrialDateFromControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!filterTrialDateFromControl.errors?.required && !filterTrialDateFromControl.hasError('InvalidYear')">Invalid Date</div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <label for="trialDateFrom">Trial Date To</label>
                            <input isBasicDate isLessThanStartDate="{{filterTrialDateFrom | date:'yyyy-MM-dd'}}"
                                #filterTrialDateToControl="ngModel" type="text" bsDatepicker 
                                [bsConfig]="datePickerConfig" 
                                maxlength="10" 
                                [(ngModel)]="filterTrialDateTo" 
                                class="form-control" 
                                id="filterTrialDateTo" 
                                name="filterTrialDateTo" 
                                (keyup)="onFiltersChanged($event)"
                                (ngModelChange)="onFiltersChanged($event)"
                                placeholder="yyyy/mm/dd" 
                                [disabled]="trialSetIndicator=='N'"
                                [ngClass]="{'is-invalid':(filterTrialDateToControl.touched || isSubmitClicked) && filterTrialDateToControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="!filterTrialDateToControl.errors?.required && filterTrialDateToControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
                                <div *ngIf="!filterTrialDateToControl.errors?.required && !filterTrialDateToControl.hasError('InvalidYear') && filterTrialDateToControl.hasError('EarlierDate')">Trial Date To can't be earlier than the Trial Date From</div>
                                <div *ngIf="!filterTrialDateToControl.errors?.required && !filterTrialDateToControl.hasError('InvalidYear') && !filterTrialDateToControl.hasError('EarlierDate')">Invalid Date</div>
                            </div>
                        </div>
                        <div class="col-sm-2 search-button-container">                
                            <div *ngIf="isResetEnabled" class="clear-filters" (click)="resetFiltersClick($event)">
                                Reset filters
                            </div> 
                            <button class="btn button-primary btn-block" [disabled]="!ticketSearchForm.valid || !(location == null || location == '' || locationControl.valid)"
                            (click)="onSearchButtonClick()">Search</button>   
                            
                        </div>
                        
                    </div>
                </form>    
                <div class="error">
                    {{searchValidationError}}
                </div>
                <div  *ngIf="violationItemsSelected() > 0" class="row">
                    <div class="col-3">
                        <button type="button" [disabled]="!isMultipleTicketDisclosureAllowed()" class="btn button-secondary btn-block" (click)="onAddViolationDocumentsClick()">Add Documents</button>
                    </div>
                    <div class="col-3">
                        <app-access-control [Resource]="Resource.POLICE_INTAKE_DASHBOARD" [Permission]="Permission.WITHDRAW_TRAFFIC_TICKET">
                            <button type="button" [disabled]="!isMultipleTicketCancellationAllowed()" class="btn button-secondary btn-block" (click)="onCancelMultipleTicketsClick()">Cancel Multiple Tickets</button>
                        </app-access-control>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- Contraventions and Vehicle Seizures Dashboard -->
    <ng-container *ngIf="ticketTypeId == TicketTypes.ContraventionsAndSeizures">
        <div *ngIf="!isMobile()" class="w-100">
            <div class="d-flex mt-45">
                <table *ngIf="isDataLoaded" id="napTable" class="table table-hover dashboard-container">
                    <thead>
                        <tr style="border: 0;">
                            <th style="width: 25px;">
                                <app-access-control [Resource]="Resource.POLICE_INTAKE_DASHBOARD" [Permission]="Permission.CANCEL_MULTIPLE_NOTICES">
                                    <div class="d-flex" style="margin: auto;">
                                        <input type="checkbox" value="" id="cbSelectAll" (click)="onCheckboxSelect($event)">
                                    </div>
                                </app-access-control>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Notice Number</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Description</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Issuing Officer</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Recipient Name</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Police File Number</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Occurrence<br />Date & Time</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>APIS Status</span>
                                </div>
                            </th>
                            <th  *ngIf="!isDataEntryOrSupervisor" style="width: 200px;">
                                <div class="d-flex">
                                    <span>Action</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredNaps.length > 0">
                        <tr *ngFor="let nap of filteredNaps; let elementIndex=index;" [ngClass]="getRowStyle(nap.stopInformationId)" >
                            <td style="width: 25px; padding: .75rem; vertical-align: top;">
                                <app-access-control [Resource]="Resource.POLICE_INTAKE_DASHBOARD" [Permission]="Permission.CANCEL_MULTIPLE_NOTICES">
                                    <div *ngIf="nap.apisStatus!='Offline' && (nap.includeCheckbox || isSuperUser)" class="form-check">
                                        <input class="form-check-input mb-0 mt-2 checkbox" (click)="onNapCheckBoxClick($event, nap)" [disabled]="isCancelledOrPendingStatus(nap)"  type="checkbox" [(ngModel)]="nap.isSelected" [id]="nap.cbId">
                                    </div>
                                </app-access-control>
                            </td>
                            <td *ngIf="nap.apisStatus!='Offline'">
                                <button class="btn-link" (click)="onNoticeNumberClick(nap.stopInformationId, nap.apisStatus, nap.issuingOfficerEmail, nap.issuingOfficerRegimentalNumber)">{{nap.contraventionNumber}}</button>
                                <br/>
                                <span [ngClass]="getContraventionStatusCssClass(nap.contraventionStatusTypeName)">{{nap.contraventionStatusTypeName}} 
                                    <button *ngIf="nap.reviewNumber!=null && nap.reviewNumber.length>0" class="btn btn-link" (click)="downloadDecisionDocument(nap.stopInformationId, nap.reviewNumber)">(<i class="fa fa-download" aria-hidden="true"></i> Decision Document)</button>
                                </span>
                            </td>
                            <td *ngIf="nap.apisStatus=='Offline'">
                                <span class="btn-link">{{nap.contraventionNumber}}</span>
                                <br/>
                                <span [ngClass]="getContraventionStatusCssClass(nap.contraventionStatusTypeName)">{{nap.contraventionStatusTypeName}}</span>
                            </td>
                            <td>{{nap.description}}</td>
                            <td>{{nap.issuingOfficerRegimentalNumber + ' ' + nap.issuingOfficer}}</td>
                            <td>{{nap.recipientName}}</td>
                            <td>{{nap.policeFileNumber}}</td>
                            <td>{{nap.occurrenceDateTime | date:'yyyy/MM/dd HH:mm'}}</td>
                            <td class="ps-0">
                                <div class="d-flex flex-wrap">
                                    <div class="mb-2"                                         
                                        class="nap-status-text">
                                        <i [ngClass]="getStatusCssClass(nap.apisStatus)"></i> {{nap.apisStatus}}
                                    </div>  
                                </div>
                            </td>
                            <td *ngIf="!isDataEntryOrSupervisor && nap.apisStatus!='Offline'">
                                <span *ngIf="(!nap.isNoticeCancellationRequestSubmitted || nap.noticeCancellationRequestDecisionTypeId == RequestDecisionTypes.Denied) && nap.contraventionStatusTypeName!=='Cancelled' && (isSuperUser || (isIssuingOfficer && nap.isLessThan2Hours))">
                                    <button class="btn-link" (click)="showCancelContraventionModal(nap)">Cancel</button>
                                </span>
                                <span *ngIf="nap.isNoticeCancellationRequestSubmitted && nap.noticeCancellationRequestDecisionTypeId != RequestDecisionTypes.Denied">
                                    {{getNapCancellationRequestStatus(nap)}}
                                </span>
                                <span *ngIf="checkIfPrintable(nap)" class="small-print-icon float-end me-4" width="37px" height="34px" (click)="printNotices(nap.stopInformationId, false)">
                                </span>
                            </td>
                            <td *ngIf="isDataEntryOrSupervisor || nap.apisStatus=='Offline'" class="action-column">
                                <!-- Offline printing from the dash board is not implemented yet...
                                <div  *ngIf="checkIfPrintable(nap)"> 
                                    <span class="small-print-icon float-end" width="37px" height="34px" (click)="printNotices(nap.stopInformationId, true)"></span>
                                </div> 
                                -->
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="filteredNaps.length === 0">
                        <tr>
                            <td colspan="9">No contraventions/seizures found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>

    <!-- Violations Tickets Dashboard -->
    <ng-container *ngIf="ticketTypeId == TicketTypes.Violations">
        <div *ngIf="!isMobile()" class="w-100">
            <div class="d-flex mt-3 top-border">
                <table id="napTable" class="table table-hover dashboard-container">
                    <thead>
                        <tr style="border: 0;">
                            <th style="width: 25px; padding-top: 1.75rem; vertical-align: top;">
                                <div class="d-flex" style="margin: auto;">
                                    <input type="checkbox" value="" id="cbViolationSelectAll" (click)="onViolationSelectAll($event)">
                                </div>
                            </th>
                            <th style="width: 125px;">
                                <div class="d-flex">
                                    <span>Ticket Number</span>
                                </div>
                            </th>
                            <th style="width: 50px;" class="ps-0">
                                
                            </th>
                            <th style="width: 150px;">
                                <div class="d-flex">
                                    <span>Charge<br/>
                                    <p class="small-text">at time of issuance</p></span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Accused<br/>
                                    <p class="small-text">at time of issuance</p></span>
                                </div>
                            </th>
                            <th style="width: 150px;">
                                <div class="d-flex">
                                    <span>Offence Date</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Licence Plate</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Location</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Issuing Officer</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Trial Date</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Status</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span># of Documents<br />Uploaded</span>
                                </div>
                            </th>
                            <th style="width: 200px;">
                                <div class="d-flex">
                                    <span>Action</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="filteredTickets.length > 0">
                        <tr *ngFor="let ticket of filteredTickets; let elementIndex=index;" [ngClass]="getRowStyle(ticket.ticketStopInformationId)" >
                            <td style="width: 25px; padding: .75rem; vertical-align: top;">
                                <div class="form-check">
                                    <input class="form-check-input mb-0 mt-2 ms-2 checkbox" type="checkbox" [(ngModel)]="ticket.isSelected" [id]="ticket.cbId">
                                </div>
                            </td>
                            <!-- <td *ngIf="nap.apisStatus!='Offline'">
                                <span *ngIf="isEditDisabled(nap.issuingOfficer, nap.apisStatus)">{{nap.contraventionNumber}}</span>
                                <button class="btn-link" *ngIf="!isEditDisabled(nap.issuingOfficer, nap.apisStatus)" (click)="onNoticeNumberClick(nap.stopInformationId, nap.apisStatus)">{{nap.contraventionNumber}}</button>
                                <br/>
                                <span [ngClass]="getContraventionStatusCssClass(nap.contraventionStatusTypeName)">{{nap.contraventionStatusTypeName}} 
                                    <button *ngIf="nap.reviewNumber!=null && nap.reviewNumber.length>0" class="btn btn-link" (click)="downloadDecisionDocument(nap.stopInformationId, nap.reviewNumber)">(<i class="fa fa-download" aria-hidden="true"></i> Decision Document)</button>
                                </span>
                            </td>
                            <td *ngIf="nap.apisStatus=='Offline'">
                                <span class="btn-link">{{nap.contraventionNumber}}</span>
                                <br/>
                                <span [ngClass]="getContraventionStatusCssClass(nap.contraventionStatusTypeName)">{{nap.contraventionStatusTypeName}}</span>
                            </td> -->
                            <td *ngIf="ticket.isTicketUnderCancellation">{{ticket.ticketNumber}}</td>
                            <td *ngIf="!ticket.isTicketUnderCancellation"><button class="btn-link" (click)="onTicketNumberClick(ticket)">{{ticket.ticketNumber}}</button></td>
                            <td class="ps-0"><span *ngIf="ticket.trialSetIndicator=='Y'" title="Trial Scheduled" class="tr-flag">TR</span></td>
                            <td>{{ticket.ticketDescription}}</td>
                            <td>{{ticket.accusedName}}</td>
                            <td>{{ticket.offenceDate | date:'mediumDate'}}</td>
                            <td>{{ticket.licencePlate}}</td>
                            <td>{{ticket.location}}</td>
                            <td>{{ticket.issuingOfficer}}</td>
                            <td>{{ticket.trialDate | date:'mediumDate'}}</td>
                            <td class="ps-0">
                                <div class="d-flex flex-wrap">
                                    <div class="mb-2"                                         
                                        class="nap-status-text">
                                        <i [ngClass]="getTicketStatusCssClass(ticket)" class="float-start"></i> {{getTicketStatus(ticket)}}
                                    </div>  
                                </div>
                            </td>
                            <td>{{ticket.numberOfDocumentsUploaded}}</td>
                            <td>
                                <app-access-control [Resource]="Resource.POLICE_INTAKE_DASHBOARD" [Permission]="Permission.WITHDRAW_TRAFFIC_TICKET">
                                    <span *ngIf="!ticket.isTicketCancellationRestricted && ticket.outstandingWarrantIndicator!='Y'">
                                        <button class="btn-link" (click)="showTicketWithdrawModal(ticket)">Cancel</button>
                                    </span>
                                    <span *ngIf="ticket.outstandingWarrantIndicator=='Y'" class="cancellation-error">
                                        Outstanding Warrrant
                                    </span>
                                </app-access-control>
                                <span *ngIf="ticket.numberOfDocumentsUploaded>0" class="small-print-icon float-right" width="37px" height="34px" (click)="printTSANotices(ticket.ticketNumber)"></span>
                            </td>
                        </tr>                      
                    </tbody>
                    <tbody *ngIf="filteredTickets.length === 0">
                        <tr>
                            <td colspan="13">No tickets found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>

    <div *ngIf="!isMobile() && ticketTypeId == TicketTypes.ContraventionsAndSeizures" class="w-100 mb-2">
        <div class="d-flex justify-content-end">  
            <div class="paging">      
                <label for="pageSizeBottom" class="page-size-label">Page Size</label>
                <select class="form-select page-size-select" id="pageSizeBottom" (change)="onPageSizeChange($event)">                        
                    <option value="10" [selected]="+pageSize == 10">10</option>
                    <option value="25" [selected]="+pageSize == 25">25</option>
                    <option value="50" [selected]="+pageSize == 50">50</option>
                    <option value="100" [selected]="+pageSize == 100">100</option>
                </select>     

                <img class="previous-link-icon" src="assets/images/paging-left-arrow.svg"/>
                <div class="previous-link" 
                    [ngClass]="{'disabled': +totalPages==1 || +pageNumber==1 }"
                    (click)="onPreviousLinkClick($event)">Previous</div>

                <ng-container *ngFor="let page of pageNumbers; let i=index;">                            
                    <div id="pageNumberBottom_{{page}}" class="page-number" 
                        [ngClass]="{'selected': +pageNumber == +page, 'me-0': +i==4 }"
                        (click)="onPageNumberClick($event, page)">{{page}}</div>                            
                </ng-container>
                
                <ng-container *ngIf="totalPages > 5 && !isLastRange">                                                        
                    <div class="page-number ellipsis" [ngClass]="{ 'ms-3': pageNumbers.length == 0  }">...</div>
                    <div id="pageNumberBottom_{{totalPages}}" class="page-number ms-0" 
                    [ngClass]="{ 'selected': +pageNumber == +totalPages }"
                    (click)="onPageNumberClick($event, totalPages)">{{totalPages}}</div>
                </ng-container>

                <div class="next-link" 
                    [ngClass]="{'disabled': +totalPages==1 || +pageNumber==+totalPages }"
                    (click)="onNextLinkClick($event)">Next</div>
                <img class="next-link-icon" src="assets/images/paging-left-arrow.svg"/>
            </div>
        </div> 
    </div> 
    <div *ngIf="ticketTypeId == TicketTypes.Violations && filteredTickets.length !== 0" class="d-flex justify-content-center w-100 mb-4">
        <div>
            <button type="button" class="btn button-primary btn-block" (click)="onLoadMoreClick()">Load More</button>
        </div>
    </div> 
    <div *ngIf="isDataLoaded" class="error-panel w-100">
        <span class="error">{{errorMessage}}</span>
    </div>
    <div *ngIf="isDataLoaded && ticketTypeId == TicketTypes.ContraventionsAndSeizures" class="card info-card my-2">
        <p>If a correction or assistance is needed on a previously submitted contravention, please contact <a href="mailto:apis.support@gov.ab.ca?Subject=APIS%20Contravention%20correction%20or%20assistance%20required" target="_blank">apis.support&#64;gov.ab.ca</a>. Cancellations must be requested internally within your agency to your supervisor or APIS super user.</p>
    </div>                             
    
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> {{ spinnerMessage }} </p></ngx-spinner>
