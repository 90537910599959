<form #contraventionInfoForm="ngForm">
	<div class="d-flex flex-column">
		<h1 class="ps-2 ps-md-0">{{stopInformation?.isOnlySDPActive? "Suspended Driver Program Seizure Notice Details":"Contravention Details"}}</h1>
		<div class="card mt-3 mt-lg-4">
			<div class="card-header">
				Occurrence Location
			</div>
			<div class="card-body">
				<div class="mb-3 row">
					<div *ngIf="!stopInformation?.isOnlySDPActive" class="col-12 col-sm-8">
						<label for="contraventionNumber">Contravention Number <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="contraventionNumber" name="contraventionNumber" [value]="contraventionNumbers">
					</div>
					<div *ngIf="stopInformation?.isOnlySDPActive" class="col-12 col-sm-4">
						<label for="contraventionNumber">Seizure Number <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="contraventionNumber" name="contraventionNumber" [value]="contraventionNumbers">
					</div>
					<div class="col-12 col-sm-4">
						<label for="policeFileNumber">Police File # <span class="label-hint">(Max 10 characters)</span></label>
						<input type="text" maxlength="10" class="form-control" id="policeFileNumber" name="policeFileNumber" [(ngModel)]="policeFileNumber" placeholder="Enter Police File Number">
					</div>
				</div>
				<div *ngIf="!stopInformation?.isOnlySDPActive" class="mb-3 row">
					<div class="col-12 col-sm-4">
						<label for="occurrenceDate">Occurrence Date <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="occurrenceDate" name="occurrenceDate" value="{{stopInformation?.contraventions[0]?.occurrenceDate | date:'yyyy/MM/dd'}}">
					</div>
					<div class="col-12 col-sm-4">
						<label for="occurrenceTime">Occurrence Time <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="occurrenceTime" name="occurrenceTime" [value]="stopInformation?.contraventions[0]?.occurrenceTime">
					</div>
				</div>
				<div *ngIf="stopInformation?.isOnlySDPActive" class="mb-3 row">
					<div class="col-12 col-sm-4">
						<label for="seizureDate">Seizure Date <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="seizureDate" name="seizureDate" value="{{stopInformation?.vehicleSeizures[0]?.seizureDate | date:'yyyy/MM/dd'}}">
					</div>
					<div class="col-12 col-sm-4">
						<label for="seizureTime">Seizure Time <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="seizureTime" name="seizureTime" [value]="stopInformation?.vehicleSeizures[0]?.seizureTime">
					</div>
				</div>
				<div class="mb-3 row">
					<div class="col-12 col-sm-6">
						<label for="addressOrHighwayNumber">Address or Highway Number <span class="label-hint">(Required)</span></label>
						<input required #addressOrHighwayNumberControl="ngModel" type="text" maxlength="100" class="form-control" id="addressOrHighwayNumber" name="addressOrHighwayNumber" placeholder="e.g. 123 Street, Highway 16, or GPS coordinates" [(ngModel)]="occurrenceLocation.addressOrHighwayNumber" [ngClass]="{'is-invalid':(addressOrHighwayNumberControl.touched || isSubmitClicked) && addressOrHighwayNumberControl.invalid}">
						<div class="invalid-feedback">
                            <div>Address or Highway Number is required</div>
                        </div>
					</div>
					<div class="col-12 col-sm-6">
						<label for="gpsCoordinates">GPS Coordinates </label>
						<div class="row">
							<div class="col-6">
								<input readonly type="text" maxlength="50" class="form-control" id="gpsCoordinatesLatitude" name="gpsCoordinatesLatitude" placeholder="Coming Soon" [(ngModel)]="occurrenceLocation.latitude">
							</div>
							<div class="col-6">
								<input readonly type="text" maxlength="50" class="form-control" id="gpsCoordinatesLongitude" name="gpsCoordinatesLongitude" placeholder="Coming Soon" [(ngModel)]="occurrenceLocation.longitude">
							</div>
						</div>
					</div>
				</div>

				<div class="mb-3 row">
					<div class="col-12 col-sm-6">
						<label for="nearIntersectionOf">At Intersection of</label>
						<input type="text" maxlength="100" class="form-control" id="nearIntersectionOf" name="nearIntersectionOf" placeholder="Enter street name or highway number" [(ngModel)]="occurrenceLocation.atStreet">
					</div>
					<div class="col-12 col-sm-6">
						<label for="relativeLocation">Relative Location</label>
						<div class="row">
							<div class="col-5">
								<select class="form-select" id="relativeLocationDirection" name="relativeLocationDirection" [(ngModel)]="occurrenceLocation.directionTypeId">
									<option [ngValue]="null">Select Direction</option>
									<option *ngFor="let directionType of directionTypes" [value]="directionType.id">{{directionType.name}}</option>
								</select>
							</div>
							<div class="col-7">
								<input type="text" maxlength="100" class="form-control" id="relativeLocation" name="relativeLocation" placeholder="of street, highway, town etc." [(ngModel)]="occurrenceLocation.relativeLocation">
							</div>
						</div>
					</div>
				</div>

				<div class="mb-3 row">
					<div class="col-12 col-sm-6">
						<label for="location">Municipality At or Near <span class="label-hint">(Required)</span></label>
						<input readonly type="text" class="form-control" id="location" name="location" [value]="selectedLocationType?.name"> 
					</div>

					<!-- If IRS, show a non-editable Pay Centre Code field, with POA pay centre -->
					<div class="col-12 col-sm-6" *ngIf="!this.stopInformation.isOnlySDPActive">
						<div *ngIf="this.stopInformation.isIRSSelected; else noIRSSelected">
							<label for="payCentreLocation">Pay Centre Code <span class="label-hint">(Required)</span></label>
							<input readonly type="text" class="form-control" id="payCentreLocation" name="payCentreLocation" [value]="selectedPayCentreType?.code">
						</div>

						<!-- If we have contraventions (but no IRS contraventions), show an editable Pay Centre Code field -->
						<ng-template #noIRSSelected>
							<div class="col-12 col-sm-6" *ngIf="this.stopInformation.contraventions.length > 0">
								<label for="payCentreLocation">Pay Centre Code <span class="label-hint">(Required)</span></label>
								<input required type="text" class="form-control" id="payCentreLocation" name="payCentreLocation" [formControl]="payCentreControl" [matAutocomplete]="autoPayCentre" placeholder="Enter and select a Pay Centre Code" [(ngModel)]="selectedPayCentreType"  [ngClass]="{'is-invalid': (payCentreControl.touched || isSubmitClicked) && payCentreControl.invalid}">
								<mat-autocomplete #autoPayCentre="matAutocomplete" [displayWith]="displayPayCentre">
									<mat-option *ngFor="let payCentreType of filteredPayCentres | async" [value]="payCentreType">
										{{payCentreType.name}} ({{payCentreType.code}})
									</mat-option>
								</mat-autocomplete>
								<mat-error class="validation-message" *ngIf="(payCentreControl.touched || isSubmitClicked) && payCentreControl.hasError('required')">
									Pay Centre Code is required
								</mat-error>
								<mat-error class="validation-message" *ngIf="(payCentreControl.touched || isSubmitClicked) && payCentreControl.hasError('forbiddenPayCentres') && !payCentreControl.hasError('required')">
									Invalid Pay Centre Code option
								</mat-error>
							</div>
						</ng-template>
					</div>

					<!-- If SDP only, do not display the Pay Centre Code field -->
				</div>

				<div class="row">
					<div class="col-sm-12">
						<label for="additionalLocationInformation">Additional Location Information</label>
						<input type="text" maxlength="100" class="form-control" id="additionalLocationInformation" name="additionalLocationInformation" placeholder="Enter Additional Location Information" [(ngModel)]="occurrenceLocation.additionalLocationDetails">
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="!stopInformation?.isOnlySDPActive">
			<div class="card mt-4">
				<div class="card-header">
					Location Conditions
				</div>
				<div class="card-body">
					<div class="mb-3 row">
						<div class="col-12 col-sm-6">
							<label for="trafficConditions">Traffic Conditions</label>
							<input type="text" maxlength="100" class="form-control" id="trafficConditions" name="trafficConditions" placeholder="Traffic Conditions" [(ngModel)]="stopInformation.trafficCondition">
						</div>
					</div>

					<div class="mb-3 row">
						<div class="col-12 col-sm-6">
							<label for="weatherConditionTypes">Weather Conditions</label>
							<mat-select #weatherConditionTypesControl="ngModel" class="form-control-multiselect" multiple id="weatherConditionTypes" name="weatherConditionTypes" [(ngModel)]="selectedWeatherConditionTypes" placeholder="Select weather condition" (selectionChange)="onWeatherConditionChange($event.value)">
								<mat-option class="mat-primary" *ngFor="let weatherConditionType of weatherConditionTypes" [value]="weatherConditionType.id">{{weatherConditionType.name}}</mat-option>
							</mat-select>
						</div>
						<div class="col-12 col-sm-6" *ngIf="selectedWeatherConditionTypes?.includes(WeatherConditionTypes.Other)">
							<label for="weatherConditionsOther">Other Weather Conditions</label>
							<input type="text" maxlength="100" class="form-control" id="weatherConditionsOther" name="weatherConditionsOther" placeholder="Please specify" [(ngModel)]="stopInformation.weatherConditionOther">
						</div>
					</div>

					<div class="mb-3 row">
						<div class="col-12 col-sm-6">
							<label for="roadSurface">Road Surface</label>
							<mat-select #roadSurfaceControl="ngModel" class="form-control-multiselect" multiple id="roadSurface" name="roadSurface" [(ngModel)]="selectedRoadSurfaceTypes" placeholder="Select road surface" (selectionChange)="onRoadSurfaceConditionChange($event.value)">
								<mat-option class="mat-primary" *ngFor="let roadSurfaceType of roadSurfaceTypes" [value]="roadSurfaceType.id">{{roadSurfaceType.name}}</mat-option>
							</mat-select>
						</div>
						<div class="col-12 col-sm-6" *ngIf="selectedRoadSurfaceTypes?.includes(RoadSurfaceTypes.Other)">
							<label for="roadTypeAndSurfaceOther">Other Road Surface Conditions</label>
							<input type="text" maxlength="100" class="form-control" id="roadTypeAndSurfaceOther" name="roadTypeAndSurfaceOther" placeholder="Please specify" [(ngModel)]="stopInformation.roadSurfaceOther">
						</div>
					</div>

					<div class="mb-3 row">
						<div class="col-12 col-sm-6">
							<label for="roadConditionTypes">Road Conditions</label>
							<mat-select #roadConditionTypesControl="ngModel" class="form-control-multiselect" multiple id="roadConditionTypes" name="roadConditionTypes" [(ngModel)]="selectedRoadConditionTypes" placeholder="Select road condition" (selectionChange)="onRoadConditionChange($event.value)">
								<mat-option class="mat-primary" *ngFor="let roadConditionType of roadConditionTypes" [value]="roadConditionType.id">{{roadConditionType.name}}</mat-option>
							</mat-select>
						</div>
						<div class="col-12 col-sm-6" *ngIf="selectedRoadConditionTypes?.includes(RoadConditionTypes.Other)">
							<label for="roadConditionsOther">Other Road Conditions</label>
							<input type="text" maxlength="100" class="form-control" id="roadConditionsOther" name="roadConditionsOther" placeholder="Please specify" [(ngModel)]="stopInformation.roadConditionOther">
						</div>
					</div>

					<div class="mb-3 row">
						<div class="col-12 col-sm-6">
							<label for="visibilityConditionTypes">Visibility Conditions</label>
							<mat-select #visibilityConditionTypesControl="ngModel" class="form-control-multiselect" multiple id="visibilityConditionTypes" name="visibilityConditionTypes" [(ngModel)]="selectedVisibilityConditionTypes" placeholder="Select visibility condition" (selectionChange)="onVisibilityConditionChange($event.value)">
								<mat-option class="mat-primary" *ngFor="let visibilityConditionType of visibilityConditionTypes" [value]="visibilityConditionType.id">{{visibilityConditionType.name}}</mat-option>
							</mat-select>
						</div>
						<div class="col-12 col-sm-6" *ngIf="selectedVisibilityConditionTypes?.includes(VisibilityConditionTypes.Other)">
							<label for="visibilityOther">Other Visibility Conditions</label>
							<input type="text" maxlength="100" class="form-control" id="visibilityOther" name="visibilityOther" placeholder="Please specify" [(ngModel)]="stopInformation.visibilityConditionOther">
						</div>
					</div>

					<div class="mb-3 row">
						<div class="col-12 col-sm-6">
							<label for="highwayCharacteristicsTypes">Highway Characteristics</label>
							<mat-select #highwayCharacteristicsTypesControl="ngModel" class="form-control-multiselect" multiple id="highwayCharacteristicsTypes" name="highwayCharacteristicsTypes" [(ngModel)]="selectedHighwayCharacteristicsTypes" placeholder="Select highway characteristics" (selectionChange)="onHighwayCharacteristicsChange($event.value)">
								<mat-option class="mat-primary" *ngFor="let highwayCharacteristicsType of highwayCharacteristicsTypes" [value]="highwayCharacteristicsType.id">{{highwayCharacteristicsType.name}}</mat-option>
							</mat-select>
						</div>
						<div class="col-12 col-sm-6" *ngIf="selectedHighwayCharacteristicsTypes?.includes(HighwayCharacteristicsTypes.Other)">
							<label for="highwayCharacteristicsOther">Other Highway Characteristics</label>
							<input type="text" maxlength="100" class="form-control" id="highwayCharacteristicsOther" name="highwayCharacteristicsOther" placeholder="Please specify" [(ngModel)]="stopInformation.highwayCharacteristicsOther">
						</div>
					</div>
				</div>
			</div>
			
			<div class="card mt-4">
				<div class="card-header">
					<div class="row">
						<div class="col-12">
							Occurrence Information
						</div>
					</div>    
				</div>
				<div class="card-body">
					<div class="mb-3 row">
						<div class="col-sm-6">
							<label for="observedBy">Contravention Observed by <span class="label-hint">(Required)</span></label>
							<select [appSelectValidator]="true" #observedByControl="ngModel" class="form-select" id="observedBy" name="observedBy" [(ngModel)]="stopInformation.observedByTypeId" (change)="onObservedByChange()"  [ngClass]="{'is-invalid':(observedByControl.touched || isSubmitClicked) && observedByControl.errors?.defaultSelected}">
								<option [ngValue]="null">Select Observed By</option>
								<option *ngFor="let observedByType of observedByTypes" [value]="observedByType.id">{{observedByType.name}}</option>
							</select>
							<div class="invalid-feedback">
								<div>Observed by is required</div>
							</div>
						</div>
					</div>

					<div class="mb-3 row" *ngIf="stopInformation.observedByTypeId==ObservedByTypes.OtherOfficerObservedSubject">
						<div class="col-12 col-sm-4">
							<label for="officerFirstName">Officer First Name</label>
							<input type="text" maxlength="50" class="form-control" id="officerFirstName" name="officerFirstName" [(ngModel)]="stopInformation.otherOfficerFirstName">
						</div>
						<div class="col-12 col-sm-4">
							<label for="officerLastName">Officer Last Name</label>
							<input type="text" maxlength="50" class="form-control" id="officerLastName" name="officerLastName" [(ngModel)]="stopInformation.otherOfficerLastName">
						</div>
					</div>

					<div *ngIf="stopInformation.observedByTypeId == ObservedByTypes.WitnessObservedSubject">
						<div *ngFor="let witness of witnesses; let index = index;">
							<div class="mb-3 row">
								<div class="col-12 col-sm-4">
									<label for="witnessFirstName">Witness {{index+1}} First Name</label>
									<input type="text" maxlength="50" class="form-control" id=witnessFirstName{{index+1}} name=witnessFirstName{{index+1}} [(ngModel)]="witnesses[index].firstName">
								</div>

								<div class="col-12 col-sm-4">
									<label for="witnessLastName">Witness {{index+1}} Last Name</label>
									<input type="text" maxlength="50" class="form-control" id=witnessLastName{{index+1}} name=witnessLastName{{index+1}} [(ngModel)]="witnesses[index].lastName">
								</div>

								<div class="col-12 col-sm-3">
									<label for="witnessDateOfBirth">Witness {{index+1}} DOB</label>
									<input #witnessDateOfBirthControl="ngModel" isBirthDate type="text" bsDatepicker [bsConfig]="datePickerConfig" class="form-control" id=witnessDateOfBirth{{index+1}}  name=witnessDateOfBirth{{index+1}} maxlength="10" placeholder="yyyy/mm/dd" [(ngModel)]="witnesses[index].dateOfBirth" [ngClass]="{'is-invalid':(witnessDateOfBirthControl.touched || isSubmitClicked) && witnessDateOfBirthControl.invalid}">
									<div class="invalid-feedback">
										<div *ngIf="witnessDateOfBirthControl.errors?.required">Date of Birth is required</div>
										<div *ngIf="!witnessDateOfBirthControl.errors?.required && witnessDateOfBirthControl.hasError('FutureDate')">Date of Birth can't be a future date</div>
										<div *ngIf="!witnessDateOfBirthControl.errors?.required && !witnessDateOfBirthControl.hasError('FutureDate') && witnessDateOfBirthControl.hasError('InvalidYear')">Invalid Year. Only 19xx and 20xx years allowed.</div>
										<div *ngIf="!witnessDateOfBirthControl.errors?.required && !witnessDateOfBirthControl.hasError('FutureDate')  && !witnessDateOfBirthControl.hasError('InvalidYear')">Invalid Witness {{index+1}} Date of Birth</div>
									</div>
								</div>

								<div class="col-sm-1 d-flex justify-content-center align-items-end mb-2">
									<button class="btn-link" (click)="onRemoveWitnessClick(index)">
										Remove
									</button>
								</div>
							</div>
						</div>
					</div>

					<div *ngIf="stopInformation.observedByTypeId == ObservedByTypes.WitnessObservedSubject" class="mb-3 row">
						<div class="col-sm-12">                    
							<button class="btn button-secondary-outline float-start" (click)="onAddWitnessClick()">Add Witness</button>                    
						</div>
					</div>

					<ng-container *ngIf="stopInformation.observedByTypeId==ObservedByTypes.MultipleOfficerOperation">
						<div class="row">
							<div class="col-12">
								<label>Officer Roles <span class="label-hint">(Fill all that apply)</span></label>
							</div>	
						</div>

						<div class="mb-3 row">
							<div class="col-12 col-sm-6">
								<label for="issuerOfficerName">Issuer <span class="label-hint">(Required)</span></label>
								<input readonly type="text" class="form-control" id="issuerOfficerName" name="issuerOfficerName" [value]="stopInformation.multipleOfficerOperationIssuer">
							</div>
							<div class="col-12 col-sm-6">
								<label for="observerOfficerName">Observer(s) <span class="label-hint">(Required)</span></label>
								<input required #observerOfficerNameControl="ngModel" type="text" maxlength="50" class="form-control" id="observerOfficerName" name="observerOfficerName" placeholder="Enter Observer's Name" [(ngModel)]="stopInformation.multipleOfficerOperationObserver" [ngClass]="{'is-invalid':(observerOfficerNameControl.touched || isSubmitClicked) && observerOfficerNameControl.invalid}">
								<div class="invalid-feedback">
									<div>Observer is required</div>
								</div>
							</div>
						</div>

						<div class="mb-3 row">
							<div class="col-12 col-sm-6">
								<div class="row mx-0">
									<div clas="col-6">
										<label class="me-4" for="interceptorOfficerName">Interceptor(s)</label>
									</div>
									<div clas="col-6">
										<div class="form-check">
											<input class="form-check-input mb-0 mt-2" type="checkbox" value="" id="sameAsIssuer" name="sameAsIssuer" [(ngModel)]="stopInformation.isInterceptorSameAsIssuer" (change)="onIntercepterSameAsIssuerChange()">
											<label class="form-check-label mb-0" for="sameAsIssuer">
												Same as Issuer
											</label>
										</div>
									</div>
								</div>
								
								<input [disabled]="stopInformation.isInterceptorSameAsIssuer" type="text" maxlength="50" class="form-control" id="interceptorOfficerName" name="interceptorOfficerName" placeholder="Enter Interceptor's name" [(ngModel)]="stopInformation.multipleOfficerOperationInterceptor">
							</div>
							<div class="col-12 col-sm-6">
								<label for="investigatorOfficerName">Other</label>
								<input type="text" maxlength="50" class="form-control" id="investigatorOfficerName" name="investigatorOfficerName" placeholder="Enter name & role" [(ngModel)]="stopInformation.multipleOfficerOperationInvestigator">
							</div>
						</div>
					</ng-container>

					<div class="mb-3 row">
						<div class="col-sm-4">
							<label for="timeFirstObserved">Time 1st Observed <span class="label-hint">(Required)</span></label>
							<input required #timeFirstObservedControl="ngModel" type="text" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
							pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="timeFirstObserved" name="timeFirstObserved" placeholder="--:--" [(ngModel)]="stopInformation.firstObservedTime" [ngClass]="{'is-invalid':(timeFirstObservedControl.touched || isSubmitClicked) && timeFirstObservedControl.invalid}">
							<div class="invalid-feedback">
								<div *ngIf="timeFirstObservedControl.errors?.required">Time 1st Observed is required</div>
								<div *ngIf="!timeFirstObservedControl.errors?.required">Invalid Time 1st Observed</div>
							</div>
						</div>
						<div class="col-sm-8">
							<label for="firstObservedLocation">1st Observed Location</label>
							<input type="text" maxlength="50" class="form-control" id="firstObservedLocation" name="firstObservedLocation" [(ngModel)]="stopInformation.firstObservedLocation">
						</div>
					</div>

					<!-- Hiding No Traffic Stop option, it is only applicable to Phase 2. 
						Keeping the commented code for visibility 
					<div class="mb-3 row">
						<div class="col-12">
							<div class="form-check">
								<input class="form-check-input mb-0" type="checkbox" value="" id="noTrafficStop" name="noTrafficStop" [(ngModel)]="stopInformation.isNoTrafficStop" (change)="onNoTrafficStopChange()">
								<label class="form-check-label mb-0" for="noTrafficStop">
									No Traffic Stop
								</label>
							</div>
						</div>
					</div>
					-------------------------------------------------------------------->

					<div class="mb-3 row" *ngIf="stopInformation.recipient.recipientTypeId == RecipientTypes.Driver">
						<div class="col-12">
							<label>Does the contravention relate to a vehicle collision? <span class="label-hint">(Required)</span></label>
							<div class="mt-3 ms-3">
								<div class="form-check form-check-inline">
									<input required type="radio" class="form-check-input" id="vehicleCollisionYes" name="vehicleCollision" #vehicleCollisionYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isVehicleCollision" [ngClass]="{'is-invalid': stopInformation.isVehicleCollision == null && (vehicleCollisionYesControl.touched || vehicleCollisionNoControl.touched || isSubmitClicked)}">
									<label class="form-check-label" for="vehicleCollisionYes">Yes</label>
								</div>
								<div class="form-check form-check-inline">
									<input required type="radio" class="form-check-input" id="vehicleCollisionNo" name="vehicleCollision" #vehicleCollisionNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isVehicleCollision" [ngClass]="{'is-invalid': stopInformation.isVehicleCollision == null && (vehicleCollisionYesControl.touched || vehicleCollisionNoControl.touched || isSubmitClicked)}">
									<label class="form-check-label" for="vehicleCollisionNo">No</label>
								</div>
								<input required type="radio" style="display:none;" id="vehicleCollisionHidden" name="vehicleCollision" #vehicleCollisionHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.isVehicleCollision" [ngClass]="{'is-invalid': stopInformation.isVehicleCollision == null && (vehicleCollisionYesControl.touched || vehicleCollisionNoControl.touched || isSubmitClicked)}">
								<div class="invalid-feedback">
									<div>Selection is required</div>
								</div>
							</div>
						</div>
					</div>

					<ng-container *ngIf="stopInformation?.isNoTrafficStop != true">
						<div class="mb-3 row">
							<div class="col-12 col-sm-4">
								<label for="timeOfStop">Time of Stop</label>
								<input type="text" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
								pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="timeOfStop" name="timeOfStop" placeholder="--:--" [(ngModel)]="stopInformation.stopTime">
							</div>
							<div class="col-12 col-sm-6">
								<label for="circumstancesForStop">Circumstances for Stop <span class="label-hint">(Required)</span></label>
								<select [appSelectValidator]="true" #circumstancesForStopControl="ngModel" class="form-select" id="circumstancesForStop" name="circumstancesForStop" [(ngModel)]="stopInformation.circumstanceTypeId" (change)="onCircumstanceChange($event.target.value)" [ngClass]="{'is-invalid':(circumstancesForStopControl.touched || isSubmitClicked) && circumstancesForStopControl.errors?.defaultSelected}">
									<option [ngValue]="null">Select a Circumstance</option>
									<option *ngFor="let circumstanceType of circumstanceTypes" [value]="circumstanceType.id">{{circumstanceType.name}}</option>
								</select>
								<div class="invalid-feedback">
									<div>Circumstances for Stop is required</div>
								</div>
							</div>
						</div>

						<div class="mb-3 row" *ngIf="stopInformation.circumstanceTypeId == 4">
							<div class="col-12">
								<label for="otherCircumstancesForStop">Specify ‘Other’ Circumstances for Stop <span class="label-hint">(Required)</span></label>
								<input required #otherCircumstancesForStopControl="ngModel" maxlength="100" type="text" class="form-control" id="otherCircumstancesForStop" name="otherCircumstancesForStop" [(ngModel)]="stopInformation.circumstanceOther" placeholder="Specify ‘Other’ Circumstances for Stop" [ngClass]="{'is-invalid':(otherCircumstancesForStopControl.touched || isSubmitClicked) && otherCircumstancesForStopControl.invalid}">
								<div class="invalid-feedback">
									<div>‘Other’ Circumstances for Stop is required</div>
								</div>
							</div>
						</div>
					
						<ng-container *ngIf="stopInformation.recipient.recipientTypeId!=RecipientTypes.NotInVehicle">
							<div class="mb-3 row" *ngIf="stopInformation.recipient.recipientTypeId==RecipientTypes.Driver || stopInformation.recipient.recipientTypeId==RecipientTypes.RegisteredOwner">
								<div class="col-12">
									<label>Are there passengers in the vehicle? <span class="label-hint">(Required)</span></label>
									<div class="mt-3 ms-3">
										<div class="form-check form-check-inline">
											<input required type="radio" class="form-check-input" id="passengerInVehicleYes" name="passengerInVehicle" #passengerInVehicleYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isPassengerInVehicle" (change)="onPassengerInVehicleChange()" [ngClass]="{'is-invalid': stopInformation.isPassengerInVehicle == null && (passengerInVehicleYesControl.touched || passengerInVehicleNoControl.touched || isSubmitClicked)}">
											<label class="form-check-label" for="passengerInVehicleYes">Yes</label>
										</div>
										<div class="form-check form-check-inline">
											<input required type="radio" class="form-check-input" id="passengerInVehicleNo" name="passengerInVehicle" #passengerInVehicleNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isPassengerInVehicle" (change)="onPassengerInVehicleChange()" [ngClass]="{'is-invalid': stopInformation.isPassengerInVehicle == null && (passengerInVehicleYesControl.touched || passengerInVehicleNoControl.touched || isSubmitClicked)}">
											<label class="form-check-label" for="passengerInVehicleNo">No</label>
										</div>
										<input required type="radio" style="display:none;" id="passengerInVehicleHidden" name="passengerInVehicle" #passengerInVehicleHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.isPassengerInVehicle" [ngClass]="{'is-invalid': stopInformation.isPassengerInVehicle == null && (passengerInVehicleYesControl.touched || passengerInVehicleNoControl.touched || isSubmitClicked)}">
										<div class="invalid-feedback">
											<div>Selection is required</div>
										</div>
									</div>
								</div>
							</div>

							<ng-container *ngIf="stopInformation.isPassengerInVehicle">
								<div class="row">
									<div class="col-12">
										<label class="w-100" for="additionalPassengerLocationInformation">Indicate passenger positions:</label>
										<div class="mt-3 ms-3 mb-1">
											<div class="form-check form-check-inline">
												<input required type="radio" class="form-check-input" id="twoRows" name="passengerPosition" #twoRowsControl="ngModel" value="1" [(ngModel)]="stopInformation.seatingTypeId" (change)="onSeatingTypeChange()" [ngClass]="{'is-invalid': stopInformation.seatingTypeId == null && (twoRowsControl.touched || threeRowsControl.touched || motorcycleOrAtvControl.touched || otherControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="twoRows">2 rows</label>
											</div>
											<div class="form-check form-check-inline">
												<input required type="radio" class="form-check-input" id="threeRows" name="passengerPosition" #threeRowsControl="ngModel" value="2" [(ngModel)]="stopInformation.seatingTypeId" (change)="onSeatingTypeChange()" [ngClass]="{'is-invalid': stopInformation.seatingTypeId == null && (twoRowsControl.touched || threeRowsControl.touched || motorcycleOrAtvControl.touched || otherControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="threeRows">3 rows</label>
											</div>
											<div class="form-check form-check-inline">
												<input required type="radio" class="form-check-input" id="motorcycleOrAtv" name="passengerPosition" #motorcycleOrAtvControl="ngModel" value="3" [(ngModel)]="stopInformation.seatingTypeId" (change)="onSeatingTypeChange()" [ngClass]="{'is-invalid': stopInformation.seatingTypeId == null && (twoRowsControl.touched || threeRowsControl.touched || motorcycleOrAtvControl.touched || otherControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="motorcycleOrAtv">motorcycle/ATV</label>
											</div>
											<div class="form-check form-check-inline">
												<input required type="radio" class="form-check-input" id="other" name="passengerPosition" #otherControl="ngModel" value="4" [(ngModel)]="stopInformation.seatingTypeId"  (change)="onSeatingTypeChange()" [ngClass]="{'is-invalid': stopInformation.seatingTypeId == null && (twoRowsControl.touched || threeRowsControl.touched || motorcycleOrAtvControl.touched || otherControl.touched || isSubmitClicked)}">
												<label class="form-check-label" for="other">other</label>
											</div>
											<input required type="radio" style="display:none;" id="passengerPositionHidden" name="passengerPosition" #passengerPositionHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.seatingTypeId" [ngClass]="{'is-invalid': stopInformation.seatingTypeId == null && (twoRowsControl.touched || threeRowsControl.touched || motorcycleOrAtvControl.touched || otherControl.touched || isSubmitClicked)}">
											<div class="invalid-feedback">
												<div>Selection is required</div>
											</div>
										</div>
									</div>
								</div>

								<div class="mb-3 row">
									<div class="col-12 col-sm-4 ps-4" *ngIf="stopInformation.seatingTypeId == PassengerSeatingTypes.TwoRows || stopInformation.seatingTypeId == PassengerSeatingTypes.ThreeRows || stopInformation.seatingTypeId == PassengerSeatingTypes.MotorcycleOrATV">
										<app-passenger-position [stopInformation]="stopInformation" [isReadOnly]="false"></app-passenger-position>
									</div>
									<div class="col-12 col-sm-8">
										<textarea class="form-control" id="additionalPassengerLocationInformation" name="additionalPassengerLocationInformation" rows="9" maxlength="5000" placeholder="Enter any additional passenger location information" [(ngModel)]="stopInformation.vehicleOccupantsNotes"></textarea>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
					<div class="mb-3 row" *ngIf="isBodycamVideoActive">
						<div class="col-12">
							<label>Is there any video in relation to this event? <span class="label-hint">(Required)</span></label>
							<div class="mt-3">
								<div class="form-check form-check-inline">
									<input required type="radio" class="form-check-input" id="hasBodycamVideoYes" name="bodycamVideo" #hasBodycamVideoYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.hasBodycamVideo" [ngClass]="{'is-invalid': stopInformation.hasBodycamVideo == null && (hasBodycamVideoYesControl.touched || hasBodycamVideoNoControl.touched || isSubmitClicked)}">
									<label class="form-check-label" for="hasBodycamVideoYes">Yes</label>
								</div>
								<div class="form-check form-check-inline">
									<input required type="radio" class="form-check-input" id="hasBodycamVideoNo" name="bodycamVideo" #hasBodycamVideoNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.hasBodycamVideo" [ngClass]="{'is-invalid': stopInformation.hasBodycamVideo == null && (hasBodycamVideoYesControl.touched || hasBodycamVideoNoControl.touched || isSubmitClicked)}">
									<label class="form-check-label" for="hasBodycamVideoNo">No</label>
								</div>
								<input required type="radio" style="display:none;" id="hasBodycamVideoHidden" name="BodycamVideo" #hasBodycamVideoHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.hasBodycamVideo" [ngClass]="{'is-invalid': stopInformation.hasBodycamVideo == null && (hasBodycamVideoYesControl.touched || hasBodycamVideoNoControl.touched || isSubmitClicked)}">
								<div class="invalid-feedback">
									<div>Selection is required</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>			

			<div class="card mt-4">
				<div class="card-header">
					Officer Information
				</div>
				<div class="card-body">
					<div class="mb-3 row">
						<div class="col-12 col-sm-4">
							<label for="officerTransportation">Peace Officer Transportation</label>
							<select class="form-select" id="officerTransportation" name="officerTransportation" [(ngModel)]="stopInformation.officerTransportationTypeId">
								<option [ngValue]="null">Select Officer Transportation</option>
								<option *ngFor="let officerTransportationType of officerTransportationTypes" [value]="officerTransportationType.id">{{officerTransportationType.name}}</option>
							</select>
						</div>
						<ng-container *ngIf="stopInformation.officerTransportationTypeId == OfficerTransportationTypes.InMarkedPatrolVehicle">
							<div class="col-12 col-sm-4">
								<label for="vehicleType">Type of Vehicle</label>
								<select class="form-select" id="vehicleType" name="vehicleType" [(ngModel)]="stopInformation.officerVehicleTypeId">
									<option [ngValue]="null">Select Vehicle Type</option>
									<option *ngFor="let vehicleType of officerVehicleTypes" [value]="vehicleType.id">{{vehicleType.name}}</option>
								</select>
							</div>

							<div class="col-12 col-sm-4">
								<label for="vehicleNumber">Vehicle Number</label>
								<input type="text" class="form-control" id="vehicleNumber" name="vehicleNumber" maxlength="10" placeholder="Enter your police vehicle number" [(ngModel)]="stopInformation.officerVehicleNumber">
							</div>
						</ng-container>
						<div class="col-12 col-sm-8" *ngIf="stopInformation.officerTransportationTypeId == OfficerTransportationTypes.InUnMarkedPatrolVehicle || stopInformation.officerTransportationTypeId == OfficerTransportationTypes.Other">
							<label for="otherTransportation">Please describe:</label>
							<input type="text" class="form-control" id="otherTransportation" name="otherTransportation" maxlength="100" [(ngModel)]="stopInformation.officerTransportationOther">
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-sm-6">
							<label for="officerUniform">Officer Uniform</label>
							<select class="form-select" id="officerUniform" name="officerUniform" [(ngModel)]="stopInformation.officerUniformTypeId">
								<option [ngValue]="null">Select Officer's Uniform</option>
								<option *ngFor="let officerUniformType of officerUniformTypes" [value]="officerUniformType.id">{{officerUniformType.name}}</option>
							</select>
						</div>
						
						<div class="col-12 col-sm-6" *ngIf="stopInformation.officerUniformTypeId == OfficerUniformTypes.Other">
							<label for="otherUniform">Specify 'Other' Uniform</label>
							<input type="text" class="form-control" id="otherUniform" name="otherUniform" placeholder="e.g. wearing high visibility vest" [(ngModel)]="stopInformation.officerUniformOther">
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<div class="card mt-4">
			<div class="card-header">
				Police Narrative & Additional Notes
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-12">
						<label for="notes">Police Narrative / Additional Notes</label>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-sm-12">
						<label class="section-label">
							Please provide your typed police narrative here or attach a copy of your notes in the Supporting Documents section. One or the other is required for complete submission.
							<span class="bold-text">Third party information must be vetted from all documents prior to uploading.</span>
						</label>
					</div>
				</div>    
				<div class="row mt-2">
					<div class="col-sm-12">
						<textarea #notesControl="ngModel" class="form-control" id="notes" name="notes" rows="7" placeholder="Please Provide Typed Police Narratives Here..." [(ngModel)]="additionalNotes"></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="mb-3">
			<div class="row pt-3">
				<div class="col-12 col-sm-6">                
					<button class="btn button-secondary btn-block" (click)="onBackClick()">Back to Confirmation Screen</button>   
				</div>
				<div class="col-12 col-sm-6 text-end">                
					<button class="btn button-primary btn-block" (click)="onSubmitClick(contraventionInfoForm.valid)">{{(!stopInformation.isOnlySDPActive && (stopInformation.isIRSSelected || stopInformation.isSpeedingSelected)) ? "Proceed to Additional Details" : "Proceed to Supporting Documents"}}</button>
                </div>
			</div>
		</div>	
	</div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white"> Loading...</p></ngx-spinner>